import { PropType, defineComponent } from 'vue'
const MoreVertIcon = new URL('@/assets/icons/More-vert-icon.svg', import.meta.url)
  .href;

export default defineComponent({
  name: 'files-grid',
  props: {
    folder: {
      type: {} as PropType<{
        id: number
        name: string
        size: string
        labels: { id: number; name: string }[]
        user: { name: string; id: number; imageUrl: string }
      }>,
      required: false,
      default: {},
    },
  },
  data() {
    return {
      MoreVertIcon
    }
  },
  methods: {},
  mounted() {
    console.log(this.folder)
  },
})
