import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-135f045b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout-change-modal" }
const _hoisted_2 = { class: "kanban-row" }
const _hoisted_3 = { class: "kanban-column-header" }
const _hoisted_4 = { class: "kanban-column-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaModal = _resolveComponent("VaModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VaModal, {
      modelValue: _ctx.localIsShow,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localIsShow) = $event)),
      onOk: _ctx.emitApplyChanges,
      "cancel-text": "Cancel",
      "ok-text": "Apply",
      size: "large",
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeModal()))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localGroups, (group) => {
            return (_openBlock(), _createElementBlock("div", {
              key: group.id,
              class: "kanban-column"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h3", null, _toDisplayString(group.title), 1)
              ])
            ]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localGroups, (group) => {
            return (_openBlock(), _createElementBlock("div", {
              key: group.id,
              class: "kanban-column"
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h3", null, _toDisplayString(group.title), 1)
              ])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "onOk"])
  ]))
}