import { defineComponent } from 'vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import TimeRange from '@/components/TimeRange/index.vue'
import DatePickerModal from '@/components/DatePickerModal/index.vue'
import { useForm } from 'vuestic-ui'
import { ContactType, getContacts } from '@/services/contacts'
import { truncateText } from '@/lib/utils'
import { getCompanyConfigurations } from '@/services/companyConfigurations'
import { getAllCountries } from '@/services/address'
import { getAllStages } from '@/services/stages'
import { getMatterById, updateMatter } from '@/services/legalMatter'
import Cookies from 'js-cookie'
import { getTeamMates } from '@/services/company'

export default defineComponent({
  name: 'LegalMattersDetails',
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')

    const taskBarTabs = [
      { id: 0, title: 'DASHBOARD', icon: 'dashboard' },
      { id: 1, title: 'MATTER INFO', icon: 'info' },
      { id: 2, title: 'LINKED INFO', icon: 'task' },
      { id: 3, title: 'TASKS', icon: 'assignment' },
      { id: 4, title: 'CALENDAR', icon: 'calendar_month' },
      { id: 5, title: 'MILESTONES', icon: 'flag' },
      { id: 6, title: 'FILES', icon: 'file_copy' },
      { id: 7, title: 'FINANCIAL', icon: 'attach_money' },
      { id: 8, title: 'HEARINGS', icon: 'gavel' },
      { id: 9, title: 'ENGAGEMENT LETTER', icon: 'article' },
      { id: 10, title: 'EMAILS', icon: 'mail' },
      { id: 11, title: 'KYC DOCUMENTS', icon: 'article' },
    ]

    return {
      isValid,
      validate,
      reset,
      resetValidation,
      selectedDate: null,
      tabs: taskBarTabs,
      activeTab: 'MATTER INFO',
      activeTabId: 1,
      relationships: [
        { name: 'Legal Matter', details: 'Recent 2', subDetail: 'child' },
        { name: 'Legal Matter', details: 'Recent 2', subDetail: 'child' },
      ],

      matterDetail: {} as any,

      case_personal_of_counsel: false,

      countriesLoading: false,
      contactsLoading: false,
      stagesLoading: false,
      loading: false,
      loadingUpdate: false,
      membersLoading: false,

      contactPage: 1,
      contactSearchKeyword: '',
      contactsItemPerPage: 10,
      contactTotalItems: 0,
      contacts: [] as any,
      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      courtOptions: [] as any,
      statusOptions: [] as any,
      categoryOptions: [] as any,
      specializationOptions: [] as any,
      presentationTypeOption: ['Plaintiff', 'Defendant'],

      countries: [] as any,
      truncateText,

      stages: [] as any,
      teamMembers: [] as any,

      form: {
        id: '',
        name: '',
        representing_contact: '' as any,
        court: '' as any,
        court_case_number: '',
        judge: '' as any,
        representation_type: '',
        opponent_contact: '' as any,
        status: '' as any,
        case_manager_ids: [] as any,
        personnel_ids: [] as any,
        specialization: '' as any,
        category: '' as any,
        jurisdriction: '' as any,
        region: '',
        notes: '',
        section_stage_items: '' as any,
        start_date: '',
        end_date: '',
        due_date: '',
        parent_id: '',
      },
    }
  },
  methods: {
    handleFormSubmit() {
      console.log('Form submitted')
    },
    changeTab(tab: { title: string; id: number }) {
      this.activeTabId = tab.id
      this.activeTab = tab.title
    },
    handleSelectedDate(date: any) {
      this.form.start_date = date.start
      this.form.end_date = date.end
    },
    handleSelectDueDate(date: any) {
      this.form.due_date = date.date
    },
    ///////////////////////////////////////////////////////////////
    handleStageMenuItemClick(stage: any) {
      if (
        this.form &&
        this.form.section_stage_items &&
        this.form.section_stage_items[0] &&
        this.form.section_stage_items[0]?.section_stage
      ) {
        this.form.section_stage_items[0].section_stage = stage
      }
    },
    handleBackToParent(id: any) {
      this.$router.push({ path: `/legal-matters/${id}` })
    },
    handleStatusMenuItemClick(status: any) {
      this.form.status = status
    },
    ///////////////////////////////////////////////////////////////
    async handleGetMattersByID(id: string) {
      this.loading = true
      const response = await getMatterById(id)

      if (response.success) {
        this.matterDetail = response.data
        this.populateForm()
        console.log(this.matterDetail)

        if (response.data.section_stage_items[0]?.section_stage.section_id) {
          this.handleGetStagesSection(
            response.data.section_stage_items[0]?.section_stage.section_id
          )
        } else {
          this.stages = []
        }
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loading = false
    },
    populateForm() {
      const form = this.form
      const matterDetail = this.matterDetail

      form.id = matterDetail?.id || ('' as any)
      form.name = matterDetail?.name || ('' as any)
      form.representing_contact =
        matterDetail?.representing_contact || ('' as any)
      form.court = matterDetail?.court || ('' as any)
      form.court_case_number = matterDetail?.court_case_number || ('' as any)
      form.judge = matterDetail?.judge || ('' as any)
      form.representation_type =
        matterDetail?.representation_type || ('' as any)
      form.opponent_contact = matterDetail?.opponent_contact || ('' as any)
      form.status = matterDetail?.status || ('' as any)
      form.specialization = matterDetail?.specialization || ('' as any)
      form.category = matterDetail?.category || ('' as any)
      form.jurisdriction = matterDetail?.jurisdriction || ('' as any)
      form.region = matterDetail?.region || ('' as any)
      form.notes = matterDetail?.notes || ('' as any)
      form.section_stage_items =
        matterDetail?.section_stage_items || ('' as any)
      form.start_date = matterDetail?.start_date || ('' as any)
      form.end_date = matterDetail?.end_date || ('' as any)
      form.due_date = matterDetail?.due_date || ('' as any)
      form.parent_id = matterDetail?.parent_id || ('' as any)

      form.case_manager_ids =
        matterDetail?.legal_matter_users
          .filter((user: any) => user.relationship_type === 'case_manager')
          .map((user: any) => user.user.id) || ('' as any)
      form.personnel_ids =
        matterDetail?.legal_matter_users
          .filter((user: any) => user.relationship_type === 'personnel')
          .map((user: any) => user.user.id) || ('' as any)
    },
    ///////////////////////////////////////////////////////////////
    async handleUpdate() {
      this.loadingUpdate = true

      const {
        section_stage_items,
        court,
        judge,
        representing_contact,
        opponent_contact,
        case_manager_ids,
        personnel_ids,
        status,
        specialization,
        category,
        jurisdriction,
        representation_type,
        ...form
      } = this.form

      const response = await updateMatter(this.$route.params.id as string, {
        section_stage_id: section_stage_items[0]?.section_stage.id,
        court_id: court.id,
        judge_id: judge.id,
        representing_contact_id: representing_contact.id,
        opponent_contact_id: opponent_contact.id,
        case_manager_ids: case_manager_ids,
        personnel_ids: personnel_ids,
        status_id: status.id,
        specialization_id: specialization.id,
        category_id: category.id,
        jurisdriction_id: jurisdriction.id,
        representation_type: representation_type.toLocaleLowerCase(),
        ...form,
      })

      if (response.success) {
        this.$vaToast.init({
          message: 'Matter updates successfully!',
          color: 'success',
        })
        this.handleGetMattersByID(this.$route.params.id as string)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loadingUpdate = false
    },
    ///////////////////////////////////////////////////////////////
    async handleGetContacts() {
      this.contactsLoading = true

      const response = await getContacts({
        contact_type: ContactType.IndividualContact,
        per_page: this.contactsItemPerPage,
        page: this.contactPage,
        keyword: this.contactSearchKeyword,
      })
      if (response.success) {
        this.contacts = response.data.list
        this.contactTotalItems =
          response.data.total_pages * this.contactsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.contactsLoading = false
    },
    handleSearchContacts(search: string) {
      this.contactSearchKeyword = search
      console.log(this.contactSearchKeyword)

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts()
      }, 500)
    },
    //////////////////////////////////////////////////////////////
    async handleGetCompanyConfigurations() {
      this.contactsLoading = true

      const response = await getCompanyConfigurations()
      if (response.success) {
        const data = response.data.list.reduce((acc: any, config: any) => {
          const { configuration_type } = config

          if (!acc[configuration_type]) {
            acc[configuration_type] = []
          }

          acc[configuration_type]?.push(config)
          return acc
        }, {} as any)

        this.courtOptions = data?.court
        this.statusOptions = data?.legal_matter_status
        this.categoryOptions = data?.legal_matter_category
        this.specializationOptions = data?.legal_matter_specialization
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.contactsLoading = false
    },
    //////////////////////////////////////////////////////////////
    async handleGetAllCountries() {
      this.countriesLoading = true
      const response = await getAllCountries()

      if (response.success) {
        this.countries = response.data
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.countriesLoading = false
    },
    //////////////////////////////////////////////////////////////
    async handleGetStagesSection(section_id: any) {
      this.stagesLoading = true
      const response = await getAllStages(section_id)
      if (response.success) {
        this.stages = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.stagesLoading = false
    },
    ////////////////////////////////////////////////////
    async handleGetMembers() {
      this.membersLoading = true
      const user = Cookies.get('haqqUser') as string
      const response = await getTeamMates(
        JSON.parse(user)?.user_roles[0].roleable_id
      )
      if (response.success) {
        this.teamMembers = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.membersLoading = true
    },
  },
  mounted() {
    this.handleGetContacts()
    this.handleGetCompanyConfigurations()
    this.handleGetAllCountries()
    this.handleGetMembers()
    this.handleGetMattersByID(this.$route.params.id as string)
  },
  watch: {
    form: {
      handler(data) {
        console.log({
          form: data,
        })
      },
      deep: true,
    },
    '$route.params.id'(newId) {
      // Logic to handle ID change
      this.handleGetMattersByID(newId)
      this.handleGetContacts()
      this.handleGetCompanyConfigurations()
      this.handleGetAllCountries()
    },
  },
  components: {
    SharedHeader,
    TabsBar,
    TimeRange,
    DatePickerModal,
  },
})
