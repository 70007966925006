<template>
  <SharedHeader titleWidth="30rem" title="Hearings">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left"></div>
      <div class="sub-SharedHeader-right">

      </div>

    </div>

  </SharedHeader>



  <!--  

  <SearchAndViews :activeView="activeView" :withViews="true" @search="searchData" @changeView="changeView" />

  <ActionsBar :showSelectButton="true" :showDownloadIcon="true" :showArchieveIcon="true" :showLabelIcon="true"
    :showShareIcon="true" :secondAddButton="$t('actionsBar.addHearing')" :editColumns="true"
    @add="$stores.ui.showAddTaskPopup = true" />
  
   <div v-if="activeView === 'list'">
    <HearingsTable :isSelect="isSelect" @selectedRows="getSelectedRows" />
  </div>

  <div class="hearing-card-view" v-if="activeView === 'board'">
    <div class="board-container">
      <div class="scrollable-cards">
        <HearingCards v-for="(card, index) in cardsList" :key="index" :data="card" testy='testy' />
      </div>
    </div>


  </div>
  -->

  <div class="container">
    <ComingSoon />

  </div>



</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" scoped></style>