import { defineComponent } from 'vue'
import MatterTable from '@/components/LegalMatter/Table/index.vue'
import AddMatterModal from '@/components/LegalMatter/AddMatterModal/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import DraggableColumns from '@/components/DraggableColumns/index.vue'
import KanbanInfiniteLoad from '@/components/KanbanInfiniteLoad/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import { getAllSections } from '@/services/sections'
import { getAllStages, getStageItems } from '@/services/stages'
import { addMatter, updateMatter } from '@/services/legalMatter'

export default defineComponent({
  name: 'ContactsPage',
  data() {
    return {
      activeTabId: 0,
      activeTab: 'Matters',
      activeView: 'board',
      selectedRows: [],
      isSelect: false,
      isAddMatter: false,
      sections: [] as any,
      stages: [] as any,
      draggedTaskId: null,

      addMatterLoading: false,
      isSectionsLoading: false,
      isStagesLoading: false,
      isMattersLoading: false,
      loadingUpdate: false,
    }
  },
  methods: {
    handleTicketMoved(data: any) {
      this.handleUpdateMatteToStage(data.id, data)
    },
    changeTab(tab: { title: string; id: number }) {
      this.activeTabId = tab.id
      this.activeTab = tab.title
    },
    changeView(view: string) {
      this.activeView = view
    },
    searchData(search: string) {
      console.log(search)
    },
    getSelectedRows(rows: any) {
      this.selectedRows = rows
    },
    toggleAddMatter() {
      this.isAddMatter = !this.isAddMatter
    },
    async handleCreateMatter(form: any) {
      this.addMatterLoading = true
      const response = await addMatter(form)
      if (response.success) {
        this.$vaToast.init({
          message: 'Matter created successfully!',
          color: 'success',
        })
        this.handleGetAllSections()
        this.toggleAddMatter()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.addMatterLoading = false
    },

    async handleUpdateMatteToStage(id: any, data: any) {
      this.loadingUpdate = true
      const response = await updateMatter(id, data)

      if (response.success) {
        // this.$vaToast.init({
        //   message: 'Task updates successfully!',
        //   color: 'success',
        // })
        // this.handleGetTaskByID()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loadingUpdate = false
    },

    async handleGetMattersByStage(id: number) {
      this.isMattersLoading = true
      const response = await getStageItems(this.activeTabId, id)
      const tickets = response.data.list
      const stage = this.stages.find((s: any) => s.id === id)
      if (stage) {
        stage.tickets = tickets
      }
      this.isMattersLoading = false
    },

    async handleGetStagesSection(section_id: any) {
      this.isStagesLoading = true

      const response = await getAllStages(section_id)
      if (response.success) {
        this.stages = response.data.list
        await Promise.all(
          this.stages.map((stage: any) =>
            this.handleGetMattersByStage(stage.id)
          )
        )
        this.$stores.matter.stages = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isStagesLoading = false
    },

    async handleGetAllSections() {
      this.isSectionsLoading = true
      const response = await getAllSections('legal_matters')
      if (response.success) {
        this.sections = response.data.list
        this.activeTabId = response.data.list[0]?.id
        this.activeTab = response.data.list[0]?.name
        this.handleGetStagesSection(response.data.list[0].id)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isSectionsLoading = false
    },
  },
  mounted() {
    this.handleGetAllSections()
  },
  components: {
    MatterTable,
    TabsBar,
    SearchAndViews,
    ActionsBar,
    SharedHeader,
    DraggableColumns,
    KanbanInfiniteLoad,
    ComingSoon,
    AddMatterModal,
  },
})
