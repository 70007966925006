<template>
  <div class="financial-dashboard">
    <InlineTabs
      :tabs="tabs"
      :activeTabId="activeTabId"
      @changeTab="changeTab"
    />
    <div v-if="activeTabId === 0" class="general-container">
      <div class="left">
        <Card :title="$t('card.totalTimeBilled')">
          <ColumnsChart />
        </Card>
        <Card :title="$t('card.balanceSheet')">
          <div class="balance-sheet-container">
            <div class="table left-table">
              <Row :title="$t('row.assets')" />
              <Table
                :header="$t('table.header.currentAssets')"
                :items="currentAssets"
                :total-value="totalCurrentAssets"
              />
              <Table
                :header="$t('table.header.nonCurrentAssets')"
                :items="nonCurrentAssets"
                :total-value="totalNonCurrentAssets"
                :is-last="true"
              />
              <Row
                :is-total="true"
                :title="$t('row.total') + ' ' + $t('row.assets')"
                :value="totalAssets"
                class="total-row"
              />
            </div>
            <div class="table right-table">
              <Row :title="$t('row.liabilitiesAndEquity')" />
              <Table
                :header="$t('table.header.currentLiabilities')"
                :items="currentLiabilities"
                :total-value="totalCurrentLiabilities"
              />
              <Table
                :header="$t('table.header.nonCurrentLiabilities')"
                :items="nonCurrentLiabilities"
                :total-value="totalNonCurrentLiabilities"
              />
              <Table
                :header="$t('table.header.equity')"
                :items="equity"
                :total-value="totalEquity"
                :is-last="true"
              />
              <Row
                :is-total="true"
                :title="$t('row.total') + ' ' + $t('row.assets')"
                :value="totalLiabilitiesAndEquity"
                class="total-row"
              />
            </div>
          </div>
        </Card>
        <Card :title="$t('card.invoiceSummary')">
          <div class="invoice-summary">
            <div class="summary">
              <div class="summary-item">
                <h4>${{ invoiced }}</h4>
                <p>{{ $t('invoiceSummuary.invoiced') }}</p>
              </div>
              <div class="summary-item">
                <h4 class="green">${{ received }}</h4>
                <p>{{ $t('invoiceSummuary.received') }}</p>
              </div>
              <div class="summary-item">
                <h4 class="red">${{ outstanding }}</h4>
                <p>{{ $t('invoiceSummuary.outstanding') }}</p>
              </div>
            </div>
            <div class="chart">
              <DonutChart />
            </div>
          </div>
        </Card>
        <Card :title="$t('card.partnerOrLawyerProductivity')">
          <DataTable
            :headers="partnerOrLawyerHeaders"
            :list="partnerOrLawyerList"
          />
        </Card>
      </div>
      <div class="right">
        <Card :title="$t('card.profitAndLoss')">
          <div class="rows">
            <Row :title="$t('row.revenue')" :value="profitAndLoss.revenue" />
            <Row :title="$t('row.expenses')" :value="profitAndLoss.expenses" />
            <Row
              :isTotal="true"
              :title="$t('row.netIncome')"
              :value="profitAndLoss.netIncome"
            />
          </div>
        </Card>
        <Card :title="$t('card.expenseSummary')">
          <div class="rows">
            <Row
              :isTotal="true"
              :title="$t('row.total')"
              :value="expenseSummary.total"
            />
          </div>
        </Card>
        <Card :title="$t('card.clientProfitability')">
          <div class="rows">
            <Row
              :title="$t('row.revenue')"
              :value="clientProfitability.revenue"
            />
            <Row
              :title="$t('row.expenses')"
              :value="clientProfitability.expenses"
            />
            <Row
              :isTotal="true"
              :title="$t('row.netIncome')"
              :value="clientProfitability.netIncome"
            />
          </div>
        </Card>
      </div>
    </div>
    <div v-if="activeTabId === 1" class="client-container">
      <div class="top">
        <Card
          :title="$t('card.outstandingInvoiceReport')"
          :tag="outstandingInvoiceReportTag"
        >
          <DataTable
            :headers="outstandingInvoiceReportHeaders"
            :list="outstandingInvoiceReportList"
          />
        </Card>
        <Card
          :title="$t('card.paymentHistoryReport')"
          :tag="paymentHistoryReportTag"
        >
          <DataTable
            :headers="paymentHistoryReportHeaders"
            :list="paymentHistoryReportList"
          />
        </Card>
      </div>
      <Card :title="$t('card.clientActivityReport')">
        <DataTable
          :headers="clientActivityReportHeaders"
          :list="clientActivityReportList"
        />
      </Card>
      <Card :title="$t('card.agingReports')" :tag="agingReportsTag">
        <DataTable :headers="agingReportsHeaders" :list="agingReportsList" />
      </Card>
      <Card :title="$t('card.detailsBillingReports')">
        <DataTable
          :headers="detailsBillingReportsHeaders"
          :list="detailsBillingReportsList"
        />
      </Card>
    </div>
    <div v-if="activeTabId === 2" class="time-container">
      <Card
        :title="$t('card.totalTimeBilled')"
        class="time-card"
        :tag="totalTimeBilledTag"
      >
        <DataTable
          :headers="totalTimeBilledHeaders"
          :list="totalTimeBilledList"
        />
      </Card>
      <Card
        :title="$t('card.nonBillableHours')"
        class="time-card"
        :tag="nonBillableHoursTag"
      >
        <DataTable
          :headers="nonBillableHoursHeaders"
          :list="nonBillableHoursList"
        />
      </Card>
      <Card
        :title="$t('card.matterTimeBreakdown')"
        class="time-card"
        :tag="matterTimeBreakdownTag"
      >
        <DataTable
          :headers="matterTimeBreakdownHeaders"
          :list="matterTimeBreakdownList"
        />
      </Card>
      <Card
        :title="$t('card.utilizationrate')"
        class="time-card"
        :tag="utilizationrateTag"
      >
        <DataTable
          :headers="utilizationrateHeaders"
          :list="utilizationrateList"
        />
      </Card>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
