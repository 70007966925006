import { defineComponent } from 'vue';
const MoreVertIcon = new URL('@/assets/icons/More-vert-icon.svg', import.meta.url)
    .href;


export default defineComponent({
    name: 'TasksTable',
    data() {
        return {
            selectedRows: [],
            MoreVertIcon,
            headers: [
                { title: '#', align: 'start', key: 'id' },
                { title: 'NAME', align: 'start', key: 'name' },
                { title: 'DESCRIPTION', align: 'start', key: 'description' },
                { title: 'ASSIGNED TO', align: 'start', key: 'assigned_to' },
                { title: 'TASK OWNER', align: 'start', key: 'owner' },
                { title: 'STATUS', align: 'start', key: 'status' },
                { title: 'START DATE', align: 'start', key: 'start_date' },
                { title: 'END DATE', align: 'start', key: 'end_date' },
                { title: 'DUE DATE', align: 'start', key: 'dueDate' },
                { title: 'TAG', align: 'start', key: 'tag' },
                { title: 'PRIORITY', align: 'center', key: 'priority' },
                { title: '# FILES', align: 'start', key: 'files' },
                { title: 'WORKING HOURS', align: 'start', key: 'workingHours' },
            ],
        }
    },
    emits: ['selectedRows'],
    methods: {
        handleButtonClick(item: any) {
            // Handle the button click for the specific item (row)
            console.log('Button clicked for:', item);
        },
        formattedDate(newDate: any) {
            const date = new Date(newDate);

            const formattedDate = date.toLocaleDateString('en-US', {
                month: 'short', // "Sep" for September
                day: 'numeric',
                year: 'numeric',
            });

            const formattedTime = date.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false // Use 24-hour format (change to true for 12-hour format)
            });

            return `${formattedDate} ${formattedTime}`;
        }
    },
    watch: {
        selectedRows() {
            this.$emit('selectedRows', this.selectedRows);
        }
    }
})