<template>
  <button>
    <img
      :src="require('../../assets/icons/add-icon.svg')"
      alt="add"
      class="add"
      @click="$emit('add')"
    />
  </button>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
