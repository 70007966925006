import { ContactType, getContacts } from '@/services/contacts'
import { getInvoiceById, updateInvoice } from '@/services/invoices'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import SharedHeader from '@/components/sharedHeader/index.vue'

const tlaLogoImage = new URL(
  '@/assets/images/tribonian_law_advisors_logo.png',
  import.meta.url
).href

export default defineComponent({
  name: 'InvoiceDetailPage',
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')

    return {
      isValid,
      validate,
      reset,
      resetValidation,
      tlaLogoImage,
      loading: false,
      updateLoading: false,
      invoiceDetails: {} as any,
      form: {
        id: '',
        contact: {
          id: '',
          specific_data: {
            individual_first_name: '',
            individual_last_name: '',
          },
        } as any,
        created_at: '',
        currency: 'UAE',
        discount_type: 'percentage',
        discount_type_amount: 0,
        tax: '',
        customer_email: '',
        customer_name: '',
        invoice_date: '' as any,
        terms_and_conditions_note: '',
        payment_details_note: '',
        signature_note: '',
        general_note: '',
        invoice_items: [
          {
            id: '',
            billable: {},
            billable_id: '',
            billable_type: '',
            class_name: '',
            created_at: '',
            description: ' ',
            quantity: 1 as any,
            tax_amount: '',
            tax_percentage: '',
            total_amount: '',
            unit_price: '',
            discount_type: 'percentage',
            discount_type_amount: 0 as any,
          },
        ],
        invoice_number: '',
        payment_due_date: '' as any,
        status: '',
        tax_amount: '',
        total_amount: '',
      },
      showPreviewModal: false,
      contactsLoading: false,
      contactPage: 1,
      contactSearchKeyword: '',
      contactsItemPerPage: 10,
      contactTotalItems: 0,
      contacts: [] as any,
      debounceTimeout: null as ReturnType<typeof setTimeout> | null, // Holds the debounce timeout ID
      exchangeRate: 3.6725,
    }
  },
  methods: {
    handleFormSubmit() {
      console.log('Form submitted')
    },
    async handleGetInvoiceByID() {
      this.loading = true
      const response = await getInvoiceById(this.$route.params.id as string)
      if (response.success) {
        this.invoiceDetails = response.data
        this.populateForm()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      console.log(this.invoiceDetails)

      this.loading = false
    },
    async handleGetContacts() {
      this.contactsLoading = true

      const response = await getContacts({
        contact_type: ContactType.IndividualContact,
        per_page: this.contactsItemPerPage,
        page: this.contactPage,
        keyword: this.contactSearchKeyword,
      })
      if (response.success) {
        this.contacts = response.data.list
        this.contactTotalItems =
          response.data.total_pages * this.contactsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.contactsLoading = false
    },
    handleSearchContacts(search: string) {
      this.contactSearchKeyword = search
      console.log(this.contactSearchKeyword)

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts()
      }, 500)
    },

    handleSelectContact(contact: any) {
      if (
        contact?.email_addresses &&
        contact?.email_addresses[0]?.email_address
      ) {
        this.form.customer_email = contact?.email_addresses[0]?.email_address
      }
    },
    populateForm() {
      console.log('test')

      //   this.form = { ...this.form, ...this.invoiceDetails }
      const form = this.form
      const invoiceDetails = this.invoiceDetails

      form.id = invoiceDetails.id
      form.contact = invoiceDetails.individual_contact || {
        id: '',
        specific_data: { individual_first_name: '', individual_last_name: '' },
      }
      form.created_at = invoiceDetails.created_at
      form.currency = invoiceDetails.currency
      form.customer_email = invoiceDetails.customer_email
      form.customer_name = invoiceDetails.customer_name
      form.invoice_date = new Date(invoiceDetails.invoice_date)
      form.invoice_items = invoiceDetails.invoice_items
      form.invoice_number = invoiceDetails.invoice_number
      form.payment_due_date = new Date(invoiceDetails.payment_due_date)
      form.status = invoiceDetails.status
      form.tax_amount = invoiceDetails.tax_amount
      form.total_amount = invoiceDetails.total_amount
      form.terms_and_conditions_note = invoiceDetails.terms_and_conditions_note
      form.payment_details_note = invoiceDetails.payment_details_note
      form.signature_note = invoiceDetails.signature_note
      form.general_note = invoiceDetails.general_note
      form.discount_type = invoiceDetails.discount_type || 'percentage'
      form.discount_type_amount = invoiceDetails.discount_type_amount
    },
    // totalAmount(
    //   quantity: string | number,
    //   price: string,
    //   tax_percentage: string | number
    // ) {
    //   const subtotal = +quantity * +price
    //   const taxAmount = subtotal * (+tax_percentage / 100)
    //   const total = subtotal + taxAmount

    //   return Math.round(total * 1000) / 1000
    // },
    // computedSubTotal() {
    //   const grandTotal = this.form.invoice_items.reduce((total, item) => {
    //     const quantity = parseFloat(item.quantity) || 0
    //     const unit_price = parseFloat(item.unit_price) || 0
    //     const tax_percentage = parseFloat(item.tax_percentage) || 0

    //     const subtotal = quantity * unit_price
    //     const tax_amount = subtotal * (tax_percentage / 100)
    //     const item_total = subtotal + tax_amount

    //     return total + item_total
    //   }, 0)

    //   return parseFloat(grandTotal.toFixed(3))
    // },
    // computedGrandTotal() {
    //   const subTotal = this.computedSubTotal()
    //   let discountedTotal: any
    //   if (this.form.discount_type === 'percentage') {
    //     discountedTotal =
    //       subTotal - subTotal * (this.form.discount_type_amount / 100)
    //   } else {
    //     discountedTotal = subTotal - this.form.discount_type_amount
    //   }
    //   return parseFloat(discountedTotal.toFixed(3))
    // },
    totalAmount(
      quantity: string | number,
      price: string | number,
      tax_percentage: string | number
    ) {
      const subtotal = +quantity * +price
      const taxAmount = subtotal * (+tax_percentage / 100)
      const total = subtotal + taxAmount

      return Math.round(total * 1000) / 1000
    },
    computedSubTotal() {
      let grandTotal = this.form.invoice_items.reduce((total, item) => {
        const quantity = parseFloat(item.quantity) || 0
        const unit_price = parseFloat(item.unit_price) || 0
        const tax_percentage = parseFloat(item.tax_percentage) || 0

        const subtotal = quantity * unit_price
        const tax_amount = subtotal * (tax_percentage / 100)
        const item_total = subtotal + tax_amount

        return total + item_total
      }, 0)

      // Convert to selected currency if needed
      if (this.form.currency === 'USD') {
        grandTotal = grandTotal / this.exchangeRate
      }

      return parseFloat(grandTotal.toFixed(3))
    },
    computedGrandTotal() {
      const subTotal = this.computedSubTotal()
      let discountedTotal

      if (this.form.discount_type === 'percentage') {
        discountedTotal =
          subTotal - (subTotal * this.form.discount_type_amount) / 100
      } else {
        discountedTotal = subTotal - this.form.discount_type_amount
      }

      discountedTotal = Math.max(0, discountedTotal)
      discountedTotal = parseFloat(discountedTotal.toFixed(3))

      return `${discountedTotal} ${this.form.currency}`
    },
    removeInvoiceItem(index: number) {
      if (this.form.invoice_items.length > 1) {
        this.form.invoice_items.splice(index, 1)
      }
    },
    addInvoiceItem() {
      this.form.invoice_items.push({
        ...this.form.invoice_items,
        billable_type: '',
        billable_id: '',
        description: '',
        quantity: 1 as any,
        unit_price: '0',
        tax_percentage: '0',
        id: '',
        billable: {},
        class_name: '',
        created_at: '',
        tax_amount: '',
        total_amount: '',
        discount_type: 'percentage',
        discount_type_amount: 0 as any,
      })
    },
    async handleUpdateInvoice() {
      const {
        invoice_date,
        payment_due_date,
        invoice_items,
        contact,
        ...subForm
      } = this.form
      this.updateLoading = true
      const response = await updateInvoice(this.$route.params.id as string, {
        invoice_date: invoice_date.toString(),
        payment_due_date: payment_due_date.toString(),
        invoice_items_attributes: invoice_items,
        contact_id: contact.id,
        ...subForm,
      })
      if (response.success) {
        this.$vaToast.init({
          message: 'Invoice updated successfully!',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.updateLoading = false
    },
    printInvoice() {
      // Getting the invoice preview HTML content
      let printContents = document.getElementById('invoice-preview')?.innerHTML

      if (printContents) {
        // Getting the tlaLogoImage URL
        const tlaLogoImage = new URL(
          '@/assets/images/tribonian_law_advisors_logo.png',
          import.meta.url
        ).href

        // Replace Vue-bound logo reference with the static image tag with the actual URL
        printContents = printContents.replace(
          ':src="tlaLogoImage"',
          `src="${tlaLogoImage}"`
        )

        // Create a new window for printing
        const printWindow = window.open('', '', 'height=1200,width=800')
        if (printWindow) {
          // Write the contents to the new window, including the bottom-left logo styling
          printWindow.document.write(
            '<html><head><title>Invoice</title><style>'
          )
          printWindow.document.write(`
            #bottom-logo {
              position: fixed;
              bottom: 20px;
              left: 20px;
              width: 100px;
              height: 100px;
            }
          </style></head><body>`)
          printWindow.document.write(printContents)
          printWindow.document.write('</body></html>')
          printWindow.document.close()

          // Ensure the window is ready before printing
          printWindow.onload = function () {
            printWindow.print()
          }
        }
      }
    },
    toggleItemSwitch(index: number): void {
      console.log([index])

      this.form.invoice_items[index].discount_type =
        this.form.invoice_items[index].discount_type === 'amount'
          ? 'percentage'
          : 'amount'
    },
    toggleInvoiceSwitch() {
      this.form.discount_type =
        this.form.discount_type === 'amount' ? 'percentage' : 'amount'
    },
  },
  mounted() {
    this.handleGetInvoiceByID()
    this.handleGetContacts()
  },

  components: {
    SharedHeader,
  },
  watch: {
    form: {
      handler(data) {
        console.log({
          form: data,
        })
      },
      deep: true,
    },
  },
})
