<template>
  <div class="matters-table-container">
    <v-data-table-server
      return-object
      :loading="false"
      v-model="selectedRows"
      show-select
      :headers="headers"
      :items="list"
      :items-length="list?.length"
      items-per-page="10"
    >
      <template #[`item.client`]="{ item }">
        <td class="matters-client-td">
          <VaButton round icon="person" size="small" />{{ item.client }}
        </td>
      </template>
      <template #[`item.leadLawyers`]="{ item }">
        <td class="matters-leadLawyers-td">
          <VaButton round icon="person" size="small" />{{ item.leadLawyers }}
        </td>
      </template>
      <template #[`item.lawyers`]="{ item }">
        <td class="matters-lawyers-td">
          <VaButton round icon="person" size="small" />{{ item.lawyers }}
        </td>
      </template>

      <template #[`item.status`]="{ item }">
        <td
          :class="`matters-status-td status-${item.status
            .toLowerCase()
            .replace(' ', '-')}`"
        >
          {{ item.status }}
        </td>
      </template>

      <template #[`item.action`]="{ item }">
        <td class="matter-action-td">
          <va-dropdown :offset="[0, -70]">
            <template #anchor>
              <img :src="MoreVertIcon" alt="MoreVertIcon" />
            </template>
            <VaDropdownContent>
              <p class="matter-action-option" @click="handleButtonClick(item)">
                Open with
              </p>
              <p class="matter-action-option" @click="handleButtonClick(item)">
                Request Signature
              </p>
              <p class="matter-action-option" @click="handleButtonClick(item)">
                Preview
              </p>
              <p class="matter-action-option" @click="handleButtonClick(item)">
                Rename
              </p>
              <p class="matter-action-option" @click="handleButtonClick(item)">
                File Info
              </p>
              <p class="matter-action-option" @click="handleButtonClick(item)">
                Make A Copy
              </p>
              <p class="matter-action-option" @click="handleButtonClick(item)">
                Access Permissions
              </p>
              <p class="matter-action-option" @click="handleButtonClick(item)">
                Share
              </p>
              <p class="matter-action-option" @click="handleButtonClick(item)">
                Move
              </p>
              <p class="matter-action-option" @click="handleButtonClick(item)">
                Make Available Offline
              </p>
              <p class="matter-action-option" @click="handleButtonClick(item)">
                delete
              </p>
            </VaDropdownContent>
          </va-dropdown>
        </td>
      </template>
    </v-data-table-server>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" scoped></style>
