import { apiRequest } from './apiUtils'

const countries = [] as any[]
export const getAllCountries = () => {
  if (countries.length > 0)
    return {
      success: true,
      data: { list: countries },
      status: 200,
      message: 'Countries cached',
    }
  return apiRequest('get', '/countries').then((response) => {
    countries.push(...response.data.list)
    return response
  })
}

export const getAllStates = (id: string) => {
  return apiRequest('get', `/states/by_country/${id}`)
}

export const getAllCities = (id: string) => {
  return apiRequest('get', `/cities/by_state/${id}`)
}
