import { defineComponent } from 'vue'
import KanbanColumn from '@/components/kanbanColumn/index.vue'
import Table from '@/components/contacts/contactsTable/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import OrganizationTable from '@/components/Organizations/table/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import AllContactsTable from '@/components/contacts/AllContacts/Table/index.vue'
import AddContactModal from '@/components/contacts/AddContactModal/index.vue'
import AddOrganizationModal from '@/components/Organizations/AddOrganizationModal/index.vue'
import ContactCard from '@/components/contacts/AllContacts/Card/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import {
  addIndividualContact,
  addOrganizationContact,
  ContactType,
  getContacts,
} from '@/services/contacts'

const UploadAvatarIcon = new URL(
  '@/assets/icons/upload-avatar-icon.svg',
  import.meta.url
).href
const UploadLogoIcon = new URL(
  '@/assets/icons/upload-logo-icon.svg',
  import.meta.url
).href

export default defineComponent({
  name: 'ContactsPage',
  data() {
    return {
      addContactLoading: false,
      tabs: [
        { id: 0, title: 'Dashboard' },
        { id: 1, title: 'People' },
        { id: 2, title: 'Organizations' },
        { id: 3, title: 'Clients' },
        { id: 4, title: 'Leads' },
        { id: 5, title: 'Current Matters' },
        { id: 6, title: 'Retainers' },
      ],
      activeTabId: 1,
      activeTab: 'People',
      activeView: 'list',
      selectedRows: [],
      isSelect: false,
      isImportContacts: false,
      uploadFilesList: [],
      isAddTabModal: false,
      isAddOrganizationForm: false,
      isAddContact: false,
      isAddContactForm: false,
      files: [],
      kyc_documents: [],
      contactTypeOptions: ['Person', 'Organization'],
      sexOptions: ['male', 'female'],
      UploadAvatarIcon,
      UploadLogoIcon,
      tableLoading: false,

      contactSearchKeyword: '',
      contactsItemPerPage: 10,
      contactSortField: '' as
        | 'IndividualContact'
        | 'OrganizationContact'
        | undefined,
      contactSortDirection: '' as 'asc' | 'desc' | undefined,
      contactPage: 1,
      contactTotalItems: 0,

      organizationSearchKeyword: '',
      organizationsItemPerPage: 10,
      organizationsSortField: '' as
        | 'IndividualContact'
        | 'OrganizationContact'
        | undefined,
      organizationsSortDirection: '' as 'asc' | 'desc' | undefined,
      organizationPage: 1,
      organizationTotalItems: 0,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null, // Holds the debounce timeout ID
    }
  },
  methods: {
    changeTab(tab: { title: string; id: number }) {
      this.activeTabId = tab.id
      this.activeTab = tab.title
    },
    changeView(view: string) {
      this.activeView = view
    },
    searchContactData(search: string) {
      this.contactSearchKeyword = search
      console.log(this.contactSearchKeyword)

      // If there is an existing debounce timeout, clear it
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      // Set a new debounce timeout (e.g., 300ms delay)
      this.debounceTimeout = setTimeout(() => {
        // Call the function to fetch contacts after the debounce delay
        this.handleGetContacts()
      }, 500) // Adjust the debounce delay as needed (300ms is common)
    },
    searchOrganizationData(search: string) {
      this.organizationSearchKeyword = search
      console.log(this.organizationSearchKeyword)

      // If there is an existing debounce timeout, clear it
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      // Set a new debounce timeout (e.g., 300ms delay)
      this.debounceTimeout = setTimeout(() => {
        // Call the function to fetch contacts after the debounce delay
        this.handleGetOrganizations()
      }, 500) // Adjust the debounce delay as needed (300ms is common)
    },

    getSelectedRows(rows: any) {
      this.selectedRows = rows
    },
    handleToggleImportContacts() {
      this.isImportContacts = true
    },
    handleAddTab(payload: string) {
      console.log(payload)
    },
    toggleAddContactForm() {
      this.isAddContact = false
      this.isAddContactForm = true
    },
    cancelForm() {
      this.isAddContactForm = false
      this.isAddOrganizationForm = false
    },

    async handleGetContacts() {
      this.tableLoading = true

      const response = await getContacts({
        contact_type: ContactType.IndividualContact,
        per_page: this.contactsItemPerPage,
        page: this.contactPage,
        sort_by_field: this.contactSortField,
        sort_by_direction: this.contactSortDirection,
        keyword: this.contactSearchKeyword,
      })
      if (response.success) {
        this.$stores.mockContacts.allContacts = response
        this.contactTotalItems =
          response.data.total_pages * this.contactsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.tableLoading = false
    },

    async handleGetOrganizations() {
      this.tableLoading = true
      const response = await getContacts({
        contact_type: ContactType.OrganizationContact,
        per_page: this.organizationsItemPerPage,
        page: this.organizationPage,
        sort_by_field: this.organizationsSortField,
        sort_by_direction: this.organizationsSortDirection,
        keyword: this.organizationSearchKeyword,
      })

      if (response.success) {
        this.$stores.mockContacts.allOrganizations = response.data
        this.organizationTotalItems =
          response.data.total_pages * this.organizationsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.tableLoading = false
    },

    async handleCreateContact(form: any) {
      this.addContactLoading = true
      const response = await addIndividualContact(form)
      if (response.success) {
        this.handleGetContacts()
        this.$vaToast.init({
          message: 'Contact created successfully!',
          color: 'success',
        })
        this.cancelForm()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.addContactLoading = false
    },

    async handleCreateOrganization(form: any) {
      this.addContactLoading = true
      const response = await addOrganizationContact(form)
      if (response.success) {
        this.$vaToast.init({
          message: 'Contact created successfully!',
          color: 'success',
        })
        this.handleGetOrganizations()
        this.cancelForm()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.addContactLoading = false
    },
    async handlePaginationContacts({ page, itemsPerPage, sortBy }: any) {
      this.contactsItemPerPage = itemsPerPage
      this.contactPage = page
      if (sortBy.length > 0) {
        this.contactSortField = sortBy[0].key.split('.')[1]
        this.contactSortDirection = sortBy[0].order
      } else {
        this.contactSortField = undefined
        this.contactSortDirection = undefined
      }

      await this.handleGetContacts()
    },
    async handlePaginationOrganizations({ page, itemsPerPage, sortBy }: any) {
      this.organizationsItemPerPage = itemsPerPage
      this.organizationPage = page
      console.log(sortBy)

      if (sortBy?.length > 0) {
        this.organizationsSortField = sortBy[0].key.split('.')[1]
        this.organizationsSortDirection = sortBy[0].order
      } else {
        this.organizationsSortField = undefined
        this.organizationsSortDirection = undefined
      }
      await this.handleGetOrganizations()
    },
  },

  beforeRouteLeave(to, from, next) {
    this.$vaToast.closeAll()
    next()
  },

  mounted() {
    this.handleGetContacts()
    this.handleGetOrganizations()
  },
  watch: {
    isAddContactForm: {
      handler(data) {
        console.log({ isAddContactForm: data })
      },
    },
  },

  components: {
    KanbanColumn,
    Table,
    TabsBar,
    SearchAndViews,
    ActionsBar,
    ComingSoon,
    SharedHeader,
    AllContactsTable,
    ContactCard,
    OrganizationTable,
    AddContactModal,
    AddOrganizationModal,
  },
})
