import { defineStore } from 'pinia';
import { CalendarStore } from './index.d';

export const useCalendarStore = defineStore('calendar', {
  state: (): CalendarStore => ({
    type: 'Monthly',
    types: ['Monthly', 'Weekly', 'Daily'],
    value: [new Date()],
    events: [] ,
    colors: [
      'blue',
      'indigo',
      'deep-purple',
      'cyan',
      'green',
      'orange',
      'grey darken-1',
    ],
    titles: [
      'Meeting',
      'Holiday',
      'PTO',
      'Travel',
      'Event',
      'Birthday',
      'Conference',
      'Party',
    ],
    addNewType: '',
    searchKeyword:'',
  }),
});
