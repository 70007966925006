import { defineComponent } from 'vue';
import Close from '@/assets/icons/Close.vue';

export default defineComponent({
  name: 'CalendarFilters',
  components: {
    Close,
  },
  computed: {
    filters(): string[] {
      return this.$stores.calendar.titles;
    },
  },
});
