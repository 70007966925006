import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Card',
  props: {
    title: {
      type: String,
      required: true,
    },
    tag: {
      type: Object as () => { icon: string; title: string; value: string },
      default: () => ({ icon: '', title: '', value: '' }),
    },
  },
  computed: {
    hasTag(): boolean {
      return !!this.tag.title && !!this.tag.value && !!this.tag.icon
    },
  },
})
