<template>
  <v-card>
    <v-layout>
      <FilterDrawer />
      <div class="container">
        <!-- Sidebar with minimize functionality -->
        <VaSidebar
          :minimized="minimized"
          color="#fff"
          animated
          width="18rem"
          class="sidebar"
        >
          <div v-if="!minimized" class="all-workspaces">
            <div class="workspaces-info-container">
              <v-menu
                v-model="showWorkSpaces"
                :close-on-content-click="false"
                location="bottom center"
              >
                <template v-slot:activator="{ props }">
                  <div class="workspaces-info" v-bind="props">
                    <div class="workspace">
                      <img
                        :src="tlaLogoImage"
                        alt="Logo"
                        width="0.51rem"
                        height="0.51rem"
                      />
                      <div class="workspace-data">
                        <h2>Tribonian Law Advisors</h2>
                        <p>Law Firm</p>
                        <p class="online-members">1/40 online</p>
                      </div>
                    </div>
                  </div>
                  <div class="img-container">
                    <img
                      v-bind="props"
                      :src="currentWorkspaceImage"
                      alt="Logo"
                      class="open-workspaces-btn"
                    />
                  </div>
                </template>

                <!-- This is the content that will appear inside the menu -->
                <v-card>
                  <div class="workspaces-list">
                    <p>SWITCH WORKPLACES</p>
                    <ul>
                      <li
                        v-for="n in 3"
                        :key="n"
                        @click="showWorkSpaces = false"
                      >
                        <div class="workspace">
                          <img src="https://i.pravatar.cc/300" alt="Logo" />
                          <div class="workspace-data">
                            <h2>Workspace Name</h2>
                            <p>Swiss Verein</p>
                          </div>
                        </div>
                        <span class="online-members"
                          >69 0f 120 members online</span
                        >
                      </li>
                    </ul>
                    <va-button
                      icon-right="add"
                      class="mx-2 px-3 py-1"
                      border-radius="20px"
                      ><span class="add-btn"></span>Add New Workspace</va-button
                    >
                  </div>
                </v-card>
              </v-menu>

              <div class="toggle expand-toggle">
                <img
                  v-if="!minimized"
                  @click="minimized = !minimized"
                  :src="currentSideBarImage"
                  alt="close"
                />
              </div>
            </div>

            <div class="workspace-divider">
              <VaDivider />
            </div>

            <!-- <div class="img-container">
              <img v-if="!isWorkspacesOpen" :src="currentWorkspaceImage" alt="Logo" class="open-workspaces-btn"
                 />
            </div> -->

            <!-- <div class="workspaces-list" v-if="isWorkspacesOpen">
              <p>SWITCH WORKPLACES</p>
              <ul>
                <li v-for="n in 3" :key="n">
                  <div class="workspace">
                    <img src="https://i.pravatar.cc/300" alt="Logo" />
                    <div class="workspace-data">
                      <h2>Workspace Name</h2>
                      <p>Swiss Verein</p>
                    </div>
                  </div>
                  <span class="online-members">69 0f 120 members online</span>
                </li>
              </ul>
              <va-button icon-right="add" class="mx-2 px-3 py-1" border-radius="20px"><span class="add-btn"></span>Add
                New Workspace</va-button>
              <img :src="currentWorkspaceImage" alt="Logo" class="close-workspaces-btn"
                 />
            </div> -->
          </div>

          <div class="toggle collapsed-toggle">
            <img
              v-if="minimized"
              @click="minimized = !minimized"
              :src="currentSideBarImage"
              alt="open"
            />
          </div>

          <div class="user user-collapsed" :class="{ hide: !minimized }">
            <img :src="tlaLogoImage" alt="User" />
          </div>

          <div class="link-container" v-for="view in views" :key="view.name">
            <VaSidebarItem
              hoverColor="rgba(0, 44, 108, 0.1)"
              :to="view.path"
              activeColor="rgba(0, 44, 108, 0.1)"
              :active="
                view.path === '/'
                  ? $route.path === view.path
                  : $route.path.includes(view.path)
              "
            >
              <router-link :to="view.path" class="link">
                <va-icon :name="view.icon" color="primary" size="1.5rem" />
                <span v-if="!minimized">{{ view.name }}</span>
              </router-link>
            </VaSidebarItem>

            <VaDivider />
          </div>
          <div class="link-container">
            <div class="link">
              <VaSidebarItem
                hoverColor="transparent"
                :active="$route.path.includes('Log Out')"
                @click="handleLogOut"
              >
                <va-icon name="logout" color="primary" size="1.5rem" />
                <span v-if="!minimized">Log Out</span>
              </VaSidebarItem>
            </div>
            <VaDivider />
          </div>
        </VaSidebar>

        <div class="main" :class="{ 'collapsed-sidebar-main': !minimized }">
          <div class="header">
            <div class="header-search">
              <router-link to="/">
                <VaButton
                  color="#fff"
                  border-color="#000"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    white-space: nowrap;
                    gap: 0.3rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  "
                >
                  <p class="header-text-logo">HAQQI AI</p>
                  <img :src="logoImage" alt="LOGO" />
                </VaButton>
              </router-link>

              <VaInput
                size="large"
                type="search"
                placeholder="Global Search..."
                preset="bordered"
              >
                <template #appendInner>
                  <VaIcon name="search" color="primary" size="2rem" />
                </template>
              </VaInput>

              <!-- recording -->
              <div class="time-record-container">
                <VaButton
                  preset="primary"
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    white-space: nowrap;
                    min-width: 80px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  "
                >
                  <div
                    class="timer-container"
                    style="display: flex; align-items: center; gap: 10px"
                  >
                    <img
                      :src="timerStore.isPlaying ? stopImage : playImage"
                      alt="LOGO"
                      @click="timerStore.toggleTimer"
                      style="width: 1.8rem; height: 1.8rem; margin-right: 5px"
                    />

                    <div
                      v-if="timerStore.elapsedTime > 0"
                      style="flex-shrink: 0; width: 3.3rem"
                    >
                      {{ timerStore.formattedTime }}
                    </div>

                    <v-menu
                      v-model="showRecord"
                      :close-on-content-click="false"
                      location="bottom right"
                    >
                      <template v-slot:activator="{ props }">
                        <img
                          v-bind="props"
                          :src="
                            require('../../src/assets/icons/open-workspaces-icon.svg')
                          "
                          alt="Record icon"
                          style="
                            width: 1.8rem;
                            height: 1.8rem;
                            margin-left: 5px;
                          "
                        />
                      </template>
                      <v-card width="24rem" height="70vh">
                        <div class="time-log-header">
                          <div class="time-log-header-tabs">
                            <p>Timer</p>
                            <p>Manual</p>
                            <p>Range</p>
                          </div>
                          <VaInput
                            background="#97537a"
                            value="ali"
                            placeholder=""
                            class="custom-input"
                          >
                            <template #prependInner>
                              <VaIcon name="group" color="#fff" />
                            </template>
                          </VaInput>
                          <VaInput
                            background="#97537a"
                            value="ali"
                            placeholder=""
                          >
                            <template #prependInner>
                              <img
                                :src="descriptionImage"
                                alt="description icon"
                              />
                            </template>
                          </VaInput>
                          <div class="time-log-header-bottom">
                            <div
                              class="circular-background-stop-image"
                              @click="timerStore.toggleTimer"
                            >
                              <img
                                :src="
                                  timerStore.isPlaying ? stopImage : playImage
                                "
                                class="stop-image"
                              />
                            </div>
                            {{ timerStore.formattedTime }}
                            <VaButton
                              round
                              icon="attach_money"
                              color="#b8c4d6"
                              class="dollar-button"
                            />
                          </div>
                        </div>
                        <div class="time-log-section">
                          <div class="time-log-section-titles">
                            <p>Me</p>
                            <p>Eptalex Global</p>
                          </div>

                          <div class="time-log-section-details-container">
                            <div
                              v-for="(record, index) in timerStore.timeRecords"
                              :key="index"
                              class="time-log-section-details"
                            >
                              <div class="circular-background-stop-image">
                                <img :src="playImage" class="play-image" />
                              </div>
                              <div>
                                <p>Lorem Ipsum More To Come</p>
                                <p>
                                  <img
                                    :src="description2Image"
                                    alt="description icon"
                                  />
                                  So this description is for testyyyyyyyyyy
                                </p>
                                <p>{{ record.duration }} <span>5 Mar</span></p>
                              </div>
                              <VaIcon name="more_vert" class="vert-icon" />
                            </div>
                          </div>
                        </div>
                        <!-- <div>
                        <h3>Time Records:</h3>
                        <ol>
                          <li v-for="(record, index) in timerStore.timeRecords" :key="index">
                            - Started at: {{ record.startTime }} | Duration: {{ record.duration }}
                            <VaDivider />
                          </li>
                        </ol>
                      </div> -->
                      </v-card>
                    </v-menu>
                  </div>
                </VaButton>
              </div>

              <div class="meet-icons">
                <img :src="teamsImage" alt="TEAMS" />
                <img :src="zoomImage" alt="ZOOM" />
              </div>

              <div class="get-verified">
                <img :src="verifiedImage" alt="VERIFIED" />
                <VaButton>GET VERIFIED</VaButton>
              </div>

              <!-- /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->

              <div class="notification-button">
                <v-menu
                  v-model="showNotifications"
                  :close-on-content-click="false"
                  location="bottom center"
                >
                  <template v-slot:activator="{ props }">
                    <VaBadge
                      v-if="notificationNumber > 0"
                      overlap
                      :text="notificationNumber"
                    >
                      <div class="notification-header-logo" v-bind="props">
                        <va-icon
                          name="notifications"
                          color="#ffffff"
                          size="1.5rem"
                        />
                      </div>
                    </VaBadge>
                  </template>

                  <v-card
                    class="notification-container pa-4"
                    width="25rem"
                    height="90vh"
                  >
                    <div class="notification-header">
                      <p>
                        Notification
                        <VaButton size="small" round>
                          {{ notificationNumber }}
                        </VaButton>
                      </p>
                      <span>
                        <VaButton
                          preset="secondary"
                          round
                          icon="open_with"
                          @click="handleCloseNotification"
                        />
                        <VaButton
                          preset="secondary"
                          round
                          icon="close"
                          @click="handleCloseNotification"
                        />
                      </span>
                    </div>
                    <div class="notification-sub-header">
                      <span class="notification-sub-header-left">
                        <VaButton size="small"> Unread </VaButton>
                        <VaButton preset="primary" size="small">
                          Show all
                        </VaButton>
                      </span>
                      <span>
                        <VaButton preset="secondary" round icon="filter_list" />
                        <VaButton preset="secondary" round icon="settings" />
                      </span>
                    </div>
                    <VaDivider />
                    <div class="notification-details">
                      <div><va-icon name="error" size="2rem" /></div>
                      <div>
                        <p class="detail">
                          "Lawyers, listen up! Your firm's website needs your
                          attention - an important alert notification has just
                          been issued."
                        </p>
                      </div>
                      <div>
                        <p class="time">
                          12 min ago
                          <VaButton preset="secondary" round icon="close" />
                        </p>
                      </div>
                    </div>
                    <div class="notification-accordion">
                      <VaAccordion v-model="value" class="max-w-sm" multiple>
                        <VaCollapse
                          v-for="(collapse, index) in collapses"
                          :key="index"
                          :header="collapse.title"
                          solid
                          v-model="value[index]"
                        >
                          <template #header="{ value, attrs, iconAttrs }">
                            <div v-bind="attrs" class="collapse-header">
                              <h2 class="collapse-title">
                                {{ collapse.title }}
                                <VaButton size="small" round> 1 </VaButton>
                              </h2>
                              <VaIcon
                                :name="!value ? 'va-arrow-down' : 'va-arrow-up'"
                                v-bind="iconAttrs"
                              />
                            </div>
                            <VaDivider class="divider" />
                          </template>

                          <template #body>
                            <div
                              :v-if="data"
                              v-for="(data, index) in collapse.data"
                              :key="index"
                              class="collapse-body"
                            >
                              <span
                                ><va-icon name="account_circle" size="2rem" />
                              </span>
                              <div class="collapse-body-content">
                                <p class="name">{{ data.content }}</p>
                                <p class="position">{{ data.position }}</p>
                                <div class="company">
                                  <p class="company-name">
                                    {{ data.companyName }}
                                  </p>
                                  <div class="company-team">
                                    <VaIcon name="groups" color="primary" />
                                    <div>
                                      <p>Team member:</p>
                                      <p>{{ data.team }}</p>
                                    </div>
                                  </div>
                                </div>
                                <p class="context">
                                  Nam gravida augue scelerisque ornare tellus
                                  gravida nullam sed. Dapibus feugiat massa
                                  pretium morbi eget.
                                </p>
                              </div>
                              <div class="menu-time">
                                <img :src="MoreVertIcon" alt="MoreVertIcon" />
                                <p>{{ data.time }}</p>
                              </div>
                            </div>
                          </template>
                        </VaCollapse>
                      </VaAccordion>
                    </div>
                  </v-card>
                </v-menu>
              </div>

              <!-- /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->

              <div class="user">
                <div class="user-info">
                  <h3>Jamal Yehya</h3>
                  <p>Operations Manager</p>
                  <!-- <div class="company-name"><span>Tribonian Law Advisors</span></div> -->
                </div>
                <img :src="tlaImage" alt="User" class="user-info-img" />
                <!-- <va-icon name="settings" color="primary" size="2rem" class="user-info-icon" /> -->
              </div>
            </div>

            <!-- <div class="languages">
                <VaMenu :keepAnchorWidth="true">
                  <template #anchor>
                    <div class="selected-language" @click="changeLanguage = !changeLanguage">
                      <img :src="currentFlag" alt="Language Flag" />
                      <span>{{ currentLanguage }}</span>
                      <img v-if="!changeLanguage" :src="require('../../src/assets/icons/open-workspaces-icon.svg')
                        " alt="Language Flag" />
                      <img v-if="changeLanguage" :src="require('../../src/assets/icons/close-workspaces-icon.svg')
                        " alt="Language Flag" />
                    </div>
                  </template>

                  <VaMenuItem v-if="lang !== 'ar'" @click="selectLanguage('ar')">
                    <div class="language-dropdown-item">
                      <img :src="arabicImage" alt="Arabic Flag" />
                      <p>
                        {{ $t('lang.ar') }}
                      </p>
                    </div>
                  </VaMenuItem>
                  <VaMenuItem v-if="lang !== 'en'" @click="selectLanguage('en')">
                    <div class="language-dropdown-item">
                      <img :src="englishImage" alt="English Flag" />
                      <p>
                        {{ $t('lang.en') }}
                      </p>
                    </div>
                  </VaMenuItem>
                  <VaMenuItem v-if="lang !== 'fr'" @click="selectLanguage('fr')">
                    <div class="language-dropdown-item">
                      <img :src="frenchImage" alt="French Flag" />
                      <p>
                        {{ $t('lang.fr') }}
                      </p>
                    </div>
                  </VaMenuItem>
                </VaMenu>
              </div> -->
          </div>

          <div
            class="page-content"
            :class="{ 'collapsed-sidebar-main': !minimized }"
          >
            <div class="content" :class="{ 'calendar-content': isCalendar }">
              <router-view />
            </div>
            <div class="chats">
              <ChatComponent />
            </div>
          </div>
        </div>
      </div>
    </v-layout>
  </v-card>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
