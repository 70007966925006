import { defineComponent } from 'vue'
import draggable from 'vuedraggable'
import ContactCard from '@/components/contacts/contactCard/index.vue'
import LegalMatterCard from '@/components/LegalMatter/legalMatterCard/index.vue'
import FinancialCard from '@/components/financial/card/index.vue'
import TaskCard from '@/components/Task/Card/index.vue'

export default defineComponent({
  name: 'KanbanColumn',
  props: {
    title: {
      type: String,
      required: true,
    },
    contactsList: {
      type: Array,
      required: false,
      default: () => [],
    },
    legalMattersList: {
      type: Array,
      required: false,
      default: () => [],
    },
    tasksList: {
      type: Array,
      required: false,
      default: () => [],
    },
    isContacts: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLegalMatters: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTasks: {
      type: Boolean,
      required: false,
      default: false,
    },
    expensesList: {
      type: Array,
      required: false,
      default: () => [],
    },
    isFinancial: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    log(evt: Event) {
      window.console.log(evt)
    },
    sayHi() {
      console.log('hi')
    },
    navigateToDetail(id: string | number) {
      this.$router.push({ name: 'TaskDetails', params: { id: id } })
    },
  },
  computed: {
    list(): Array<unknown> {
      switch (true) {
        case this.isContacts:
          return this.contactsList
        case this.isLegalMatters:
          return this.legalMattersList
        case this.isTasks:
          return this.tasksList
        case this.isFinancial:
          return this.expensesList
        default:
          return []
      }
    },
  },
  components: {
    draggable,
    ContactCard,
    LegalMatterCard,
    TaskCard,
    FinancialCard,
  },
})
