<template>
  <div class="shared-header-container">
    <h1 :style="{ width: titleWidth }">
      <VaIcon v-if="flag" :name="flag" size="large" color="primary" />
      {{ title }}
    </h1>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SharedHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    flag: {
      type: String,
      required: true,
    },
    titleWidth: {
      type: String,
      default: '25%',
    },
  },
})
</script>

<style lang="scss" scoped>
.shared-header-container {
  @include flex-box(row, start, center);
  padding: 20px 0;

  h1 {
    width: 25%;
    color: $primary;
    font-size: 24px;
    font-weight: 300;
    text-transform: capitalize;
  }
}
</style>
