import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "calendar-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UIButton = _resolveComponent("UIButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_UIButton, {
      class: _normalizeClass(["filter-box", { active: _ctx.activeFilter === 'all' }]),
      ref: "allBtn",
      buttonText: "all",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setActiveFilter('all')))
    }, null, 8, ["class"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter, index) => {
      return (_openBlock(), _createBlock(_component_UIButton, {
        class: _normalizeClass(["filter-box", { active: _ctx.activeFilter === filter }]),
        ref_for: true,
        ref: `${filter}Btn`,
        key: index,
        buttonText: filter,
        "icon-before": _ctx.icons[index],
        onClick: ($event: any) => (_ctx.setActiveFilter(filter))
      }, null, 8, ["class", "buttonText", "icon-before", "onClick"]))
    }), 128))
  ]))
}