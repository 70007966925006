<template>
  <p>CONTACT ID: #{{ contactDetails.id }}</p>
  <SharedHeader
    titleWidth="30rem"
    :title="
      `${form?.specific_data?.individual_first_name || ''} ${
        form?.specific_data?.individual_middle_name || ''
      } ${form?.specific_data?.individual_last_name || ''}`.trim()
    "
  >
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left"></div>
      <div class="sub-SharedHeader-right"></div>
    </div>
  </SharedHeader>

  <TabsBar :tabs="tabs" :activeTabId="activeTabId" @changeTab="changeTab" />

  <VaForm
    v-if="activeTabId == 1"
    ref="ContactDetailsFormRef"
    class="contact-info-container"
  >
    <div class="contact-image">
      <!-- <img src="https://i.pravatar.cc/302" class="contact-profile"
        :alt="`${contactDetails.first_name || ''} ${contactDetails.middle_name || ''} ${contactDetails.last_name || ''}`"> -->
      <div class="avatar-container">
        <!-- <img v-if="form?.specific_data" :src="form.specific_data?.individual_profile_picture?.url" alt="Avatar"
          class="avatar" /> -->
        <div class="avatar-fallback">
          {{
            getInitials(
              form?.specific_data?.individual_first_name || '',
              form?.specific_data?.individual_last_name || ''
            )
          }}
        </div>
      </div>
      <p>
        <img
          :src="verifiedImage"
          alt="VERIFIED"
          class="contact-profile-icon"
        />Verified <br />
        HAQQ User
      </p>
      <div class="delete-button">
        <VaButton @click="isDeleteModal = !isDeleteModal"> Delete </VaButton>
        <VaModal
          v-model="isDeleteModal"
          message="Would you like to Delete?"
          ok-text="Delete"
          size="auto"
          @ok="handleDelete"
        />
      </div>
    </div>

    <VaInput
      placeholder="Title"
      background="#ECF0F1"
      v-model="form.specific_data.individual_title"
      style="width: 49%"
      label="Title"
    />

    <VaInput
      placeholder="First Name"
      background="#ECF0F1"
      v-model="form.specific_data.individual_first_name"
      style="width: 49%"
      :rules="[(value) => value && value.length > 0]"
      label="First Name"
      requiredMark
    />

    <VaInput
      placeholder="Middle Name"
      background="#ECF0F1"
      v-model="form.specific_data.individual_middle_name"
      style="width: 49%"
      label="Middle Name"
    />

    <VaInput
      placeholder="Last Name"
      background="#ECF0F1"
      v-model="form.specific_data.individual_last_name"
      style="width: 49%"
      :rules="[(value) => value && value.length > 0]"
      label="Last Name"
      requiredMark
    />

    <VaInput
      placeholder="Suffix"
      background="#ECF0F1"
      v-model="form.specific_data.individual_suffix"
      style="width: 49%"
      label="Suffix"
    />

    <VaInput
      placeholder="Job Title"
      background="#ECF0F1"
      v-model="form.specific_data.individual_job_title"
      style="width: 49%"
      label="job title"
    />

    <VaInput
      placeholder="Tax Identification Number"
      background="#ECF0F1"
      v-model="form.specific_data.individual_tax_identification_number"
      style="width: 49%"
      label="Tax Identification Number"
    />

    <VaDateInput
      placeholder="Date Of Birth"
      label="Date Of Birth"
      background="#ECF0F1"
      style="width: 49%"
      v-model="form.specific_data.individual_date_of_birth"
      manual-input
    />
    <VaInput
      placeholder="Nationality"
      background="#ECF0F1"
      v-model="form.specific_data.individual_nationality"
      style="width: 49%"
      label="Nationality"
    />

    <div class="form-sex">
      <p>Gender</p>
      <VaRadio
        v-model="form.specific_data.individual_gender"
        :options="['male', 'female']"
      />
    </div>

    <div style="width: 100%">
      <div v-for="(emailAddress, index) in form.email_addresses" :key="index">
        <VaInput
          background="#ECF0F1"
          v-model="emailAddress.email_address"
          style="width: 99%"
          :rules="[
          (value: string) => !!value ,
          (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || 'Invalid email address'
        ]"
          :label="`${emailAddress.label}`"
          requiredMark
        />
      </div>
      <!-- Button to add more email input fields -->
      <!-- <button @click="addEmailAddress">Add Another Email</button> -->
    </div>

    <div style="width: 100%">
      <div v-for="(phoneNB, index) in form.phone_numbers" :key="index">
        <VaInput
          background="#ECF0F1"
          v-model="phoneNB.phone_number"
          style="width: 99%"
          :label="`${phoneNB.label}`"
          requiredMark
          :rules="[(value) => value && value.length > 0]"
        />
      </div>
      <!-- Button to add more email input fields -->
      <!-- <button @click="addEmailAddress">Add Another Email</button> -->
    </div>

    <div style="width: 49%">
      <VaSelect
        background="#ECF0F1"
        v-model="form.relationship_owner.id"
        style="width: 100%; height: 2rem"
        label="Relationship Owner"
        requiredMark
        searchable
        :options="teamMembers"
        :highlight-matched-text="false"
        :text-by="(option) => `${option?.first_name} ${option?.last_name}`"
        value-by="id"
        :rules="[(v) => !!v]"
      />
    </div>

    <div style="width: 49%">
      <VaSelect
        background="#ECF0F1"
        v-model="form.assigned_to.id"
        style="width: 100%; height: 2rem; margin-bottom: 1.8rem"
        label="Assigned To"
        requiredMark
        searchable
        :options="teamMembers"
        :highlight-matched-text="false"
        :text-by="(option) => `${option?.first_name} ${option?.last_name}`"
        value-by="id"
        :rules="[(v) => !!v]"
      />
    </div>
    <div style="width: 49%">
      <VaSelect
        placeholder="Referred by"
        background="#ECF0F1"
        v-model="form.referred_by"
        style="width: 100%; height: auto"
        label="Referred by"
        searchable
        clearable
        :highlight-matched-text="false"
        :options="contacts"
        :text-by="
          (option) =>
            `${option?.specific_data?.individual_first_name} ${option?.specific_data?.individual_last_name}`
        "
        :loading="contactsLoading"
        @update:search="handleSearchContacts"
      />
    </div>
    <div style="width: 49%">
      <VaSelect
        placeholder="Link To Organization"
        background="#ECF0F1"
        v-model="form.organization_contact"
        style="width: 100%; height: auto"
        label="Link To Organization"
        searchable
        clearable
        :highlight-matched-text="false"
        :options="organizations"
        :text-by="
          (option) => truncateText(option?.specific_data?.organization_name, 50)
        "
        :loading="organizationsLoading"
        @update:search="handleSearchOrganizations"
      />
    </div>
    <!-- <div style="width: 49%">
      <VaSelect
        background="#ECF0F1"
        v-model="form.designated_point_of_contact.id"
        style="width: 100%; height: 2rem; margin-bottom: 1.8rem"
        label="Designated point of contact"
        searchable
        :options="teamMembers"
        :highlight-matched-text="false"
        :text-by="(option) => `${option?.first_name} ${option?.last_name}`"
        value-by="id"
      />
    </div> -->

    <VaTextarea
      max-rows="11"
      v-model="form.notes"
      label="Note "
      style="width: 99%"
      autosize
      placeholder="Add your note here..."
      background="#ECF0F1"
    />

    <VaAccordion v-model="isAddressAccordion">
      <VaCollapse header="Address">
        <template #content>
          <div style="width: 79%">
            <VaSelect
              placeholder="Country"
              background="#ECF0F1"
              v-model="form.contact_addresses[0].country.id"
              :loading="countriesLoading"
              style="width: 100%; height: 2rem"
              label="Country"
              requiredMark
              searchable
              :options="countries?.list"
              :highlight-matched-text="false"
              :text-by="(option) => option?.name"
              value-by="id"
              :rules="[(v) => !!v]"
              @update:modelValue="handleGetState"
            />
          </div>
          <VaInput
            placeholder="Zip Code"
            background="#ECF0F1"
            v-model="form.contact_addresses[0].zip_code"
            style="width: 19%"
            label="Postal/Zip Code"
          />
          <div style="width: 49%">
            <VaSelect
              placeholder="Province/State"
              background="#ECF0F1"
              v-model="form.contact_addresses[0].state.name"
              :loading="statesLoading"
              style="width: 100%; height: 2rem; margin-bottom: 1.8rem"
              label="Province/State"
              searchable
              :options="states?.list"
              :highlight-matched-text="false"
              :text-by="(option) => option?.name"
              value-by="id"
              :rules="[(v) => !!v]"
              @update:modelValue="handleGetCities"
            />
          </div>

          <div style="width: 49%">
            <VaSelect
              placeholder="City"
              background="#ECF0F1"
              v-model="form.contact_addresses[0].city.id"
              :loading="citiesLoading"
              style="width: 100%; height: 2rem; margin-bottom: 1.8rem"
              label="City"
              searchable
              :options="cities?.list"
              :highlight-matched-text="false"
              :text-by="(option) => option?.name"
              value-by="id"
              :rules="[(v) => !!v]"
            />
          </div>
          <VaInput
            placeholder="Street"
            background="#ECF0F1"
            v-model="form.contact_addresses[0].street_name"
            style="width: 49%"
            label="Street"
          />
          <VaInput
            placeholder="Building"
            background="#ECF0F1"
            v-model="form.contact_addresses[0].building_name"
            style="width: 49%"
            label="Building"
          />

          <VaInput
            placeholder="Office Number"
            background="#ECF0F1"
            v-model="form.contact_addresses[0].phone_number"
            style="width: 49%"
            label="Office number"
          />

          <VaInput
            placeholder="Floor"
            background="#ECF0F1"
            v-model="form.contact_addresses[0].floor"
            style="width: 49%"
            label="Floor"
          />

          <VaInput
            placeholder="Google Maps Link"
            background="#ECF0F1"
            v-model="form.contact_addresses[0].google_maps_url"
            style="width: 99%"
            label="Google Maps Link"
          />
        </template>
      </VaCollapse>
    </VaAccordion>
    <div class="action-buttons">
      <VaButton preset="secondary" border-color="primary" @click="handleBack">
        Back
      </VaButton>

      <VaButton
        :loading="loadingUpdate"
        preset="primary"
        @click="validate() && handleUpdate()"
      >
        Update
      </VaButton>
    </div>
  </VaForm>

  <div v-else class="container">
    <ComingSoon />
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
