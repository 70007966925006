<template>
  <div class="folder">
    <div class="folder-header">
      <div class="info">
        <img src="../../assets/icons/file-icon.svg" alt="folder icon" />
        <div class="name-container">
          <h3 class="name">{{ folder.name }}</h3>
          <p class="size">{{ folder.size }}</p>
        </div>
      </div>
      <div class="folder-options">
        <img :src="MoreVertIcon" alt="MoreVertIcon" />
      </div>
    </div>
    <div class="labels">
      <div v-for="label in folder.labels" :key="label.id" class="label">
        <p>{{ label.name }}</p>
      </div>
    </div>
    <div class="user">
      <img :src="folder.user.imageUrl" :alt="folder.user.name" />
      <h4>{{ folder.user.name }}</h4>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
