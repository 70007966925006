import { defineStore } from 'pinia'

export const generalDataStore = defineStore({
    id: 'generalData',
    state: () => ({
        members: null,
    }),
    actions: {
        setMembers(data: any) {
            this.members = data;
        },
    }
})
