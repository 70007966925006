import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../assets/icons/monetary-icon.svg'


const _withScopeId = n => (_pushScopeId("data-v-0969d4e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file" }
const _hoisted_2 = { class: "folder-header" }
const _hoisted_3 = { class: "info" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "file-details" }
const _hoisted_6 = { class: "name-container" }
const _hoisted_7 = {
  src: _imports_0,
  alt: "money icon"
}
const _hoisted_8 = { class: "name" }
const _hoisted_9 = { class: "size" }
const _hoisted_10 = { class: "file-options" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "labels" }
const _hoisted_13 = {
  key: 0,
  class: "file-preview"
}
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "user" }
const _hoisted_16 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _ctx.icon,
          alt: "folder icon",
          class: "file-type"
        }, null, 8, _hoisted_4),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("img", _hoisted_7, null, 512), [
              [_vShow, _ctx.file.monetized]
            ]),
            _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.file.name), 1)
          ]),
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.file.size), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("img", {
          src: _ctx.MoreVertIcon,
          alt: "MoreVertIcon"
        }, null, 8, _hoisted_11)
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.file.labels, (label) => {
        return (_openBlock(), _createElementBlock("div", {
          key: label.id,
          class: "label"
        }, [
          _createElementVNode("p", null, _toDisplayString(label.name), 1)
        ]))
      }), 128))
    ]),
    (_ctx.file.previewUrl)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("iframe", {
            src: _ctx.file.previewUrl,
            frameborder: "0",
            style: {"border":"none","width":"100%","height":"100%"},
            lazy: ""
          }, null, 8, _hoisted_14)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("img", {
        src: _ctx.file.user.imageUrl,
        alt: _ctx.file.user.name
      }, null, 8, _hoisted_16),
      _createElementVNode("h4", null, _toDisplayString(_ctx.file.user.name), 1)
    ])
  ]))
}