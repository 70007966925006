import { defineComponent } from 'vue';


const MoreVertIcon = new URL('@/assets/icons/More-vert-icon.svg', import.meta.url)
    .href;


export default defineComponent({
    name: 'MatterTable',
    data() {
        return {
            selectedRows: [],
            MoreVertIcon,
            headers: [
                { title: '#', align: 'start', key: 'id' },
                { title: 'Internal Matter ID', align: 'start', key: 'internalMatterId' },
                { title: 'MATTER NAME', align: 'start', key: 'matterName' },
                { title: 'STATUS', align: 'start', key: 'status' },
                { title: 'CLIENT', align: 'start', key: 'client' },
                { title: 'LEAD LAWYERS', align: 'start', key: 'leadLawyers' },
                { title: 'LAWYERS', align: 'start', key: 'lawyers' },
                { title: 'JURISDICTION', align: 'starts', key: 'jurisdiction' },
                { title: 'SPECIALIZATION', align: 'starts', key: 'specialization' },
                { title: 'CATEGORY', align: 'starts', key: 'category' },
                { title: 'TYPE', align: 'starts', key: 'type' },
                { title: 'STAGE', align: 'starts', key: 'stage' },
                { title: 'CREATED DATE', align: 'starts', key: 'createdDate' },
                { title: 'MODIFIED DATE', align: 'starts', key: 'modifiedDate' },
                { title: 'REGION', align: 'starts', key: 'region' },
                { title: 'LATEST NOTES', align: 'starts', key: 'latestNotes' },
                { title: '', align: 'starts', key: 'action' },
            ],
            list: [
                {
                    'id': 120,
                    'internalMatterId': '12730/2023',
                    'matterName': 'Abdullah Al Rabeeha',
                    'status': 'Not Started',
                    'client': 'Ramy Torbey',
                    'leadLawyers': 'Antoine Kanaan',
                    'lawyers': 'Tarek, Kamal',
                    'jurisdiction': 'Lebanon',
                    'specialization': 'Criminal Defence',
                    'category': 'Corporate Crimes',
                    'type': 'Litigation',
                    'stage': 'Pre-Litigation',
                    'createdDate': '10/3/2023',
                    'modifiedDate': '1/15/2024',
                    'region': 'Baabda',
                    'latestNotes': 'Lorem ipsum dolor sit amet con...'
                },
                {
                    'id': 119,
                    'internalMatterId': 'MBC2',
                    'matterName': 'شيب بلا رصيد',
                    'status': 'On Hold',
                    'client': 'Ibrahim Abdel',
                    'leadLawyers': 'Jihad Ramiz',
                    'lawyers': 'Tarek, Kamal',
                    'jurisdiction': 'Lebanon, Nigeria',
                    'specialization': 'Criminal Defence',
                    'category': 'Corporate Crimes',
                    'type': 'Litigation',
                    'stage': 'Pre-Litigation',
                    'createdDate': '10/3/2023',
                    'modifiedDate': '1/15/2024',
                    'region': 'Baabda',
                    'latestNotes': 'Lorem ipsum dolor sit amet con...'
                },
                {
                    'id': 118,
                    'internalMatterId': '1011',
                    'matterName': 'ABC',
                    'status': 'On Hold',
                    'client': 'Marwan Kassis',
                    'leadLawyers': 'Antoine Kanaan',
                    'lawyers': 'Tarek, Kamal',
                    'jurisdiction': 'Lebanon, France',
                    'specialization': 'Criminal Defence',
                    'category': 'Corporate Crimes',
                    'type': 'Litigation',
                    'stage': 'Pre-Litigation',
                    'createdDate': '10/3/2023',
                    'modifiedDate': '1/15/2024',
                    'region': 'Baabda',
                    'latestNotes': 'Lorem ipsum dolor sit amet con...'
                },
                {
                    'id': 117,
                    'internalMatterId': '1010',
                    'matterName': 'Illlazem Bel Tasjil',
                    'status': 'Cancelled',
                    'client': 'Charbel Khalil',
                    'leadLawyers': 'Ramy Torbey',
                    'lawyers': 'Tarek, Kamal',
                    'jurisdiction': 'Lebanon, United States',
                    'specialization': 'Criminal Defence',
                    'category': 'Corporate Crimes',
                    'type': 'Litigation',
                    'stage': 'Execution',
                    'createdDate': '10/3/2023',
                    'modifiedDate': '1/15/2024',
                    'region': 'Baabda',
                    'latestNotes': 'Lorem ipsum dolor sit amet con...'
                },
                {
                    'id': 116,
                    'internalMatterId': '234',
                    'matterName': 'Feryal Incorp',
                    'status': 'In Progress',
                    'client': 'Keith Eastman',
                    'leadLawyers': 'Antoine Kanaan',
                    'lawyers': 'Tarek, Kamal',
                    'jurisdiction': 'Martinique',
                    'specialization': 'Criminal Defence',
                    'category': 'Corporate Crimes',
                    'type': 'Litigation',
                    'stage': 'Court Of Appeals',
                    'createdDate': '10/3/2023',
                    'modifiedDate': '1/18/2024',
                    'region': 'Baabda',
                    'latestNotes': 'Lorem ipsum dolor sit amet con...'
                },
                {
                    'id': 115,
                    'internalMatterId': 'MC78 n',
                    'matterName': 'Lease',
                    'status': 'Transferred',
                    'client': 'Charbel Khalil',
                    'leadLawyers': 'Ramy Torbey',
                    'lawyers': 'Tarek, Kamal',
                    'jurisdiction': 'Luxembourg',
                    'specialization': 'Criminal Defence',
                    'category': 'Corporate Crimes',
                    'type': 'Litigation',
                    'stage': 'First Instance',
                    'createdDate': '10/3/2023',
                    'modifiedDate': '1/15/2024',
                    'region': 'Baabda',
                    'latestNotes': 'Lorem ipsum dolor sit amet con...'
                },
                {
                    'id': 114,
                    'internalMatterId': 'KLPC32',
                    'matterName': 'Jennifer',
                    'status': 'Not Retained',
                    'client': 'Anthony Grace',
                    'leadLawyers': 'Antoine Kanaan',
                    'lawyers': 'Tarek, Kamal',
                    'jurisdiction': 'Qatar',
                    'specialization': 'Criminal Defence',
                    'category': 'Corporate Crimes',
                    'type': 'Litigation',
                    'stage': 'Court Of Cassation',
                    'createdDate': '10/3/2023',
                    'modifiedDate': '1/15/2024',
                    'region': 'Baabda',
                    'latestNotes': 'Lorem ipsum dolor sit amet con...'
                }
            ]

        }
    },
    emits: ['selectedRows'],
    methods: {
        handleButtonClick(item: any) {
            // Handle the button click for the specific item (row)
            console.log('Button clicked for:', item);
        },
    },
    watch: {
        selectedRows() {
            this.$emit('selectedRows', this.selectedRows);
        }
    }
})