import { defineComponent } from 'vue';
import SharedHeader from '@/components/sharedHeader/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'


export default defineComponent({
  components: {
    ComingSoon,
    SharedHeader,
  },
})