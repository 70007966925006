import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f1b24d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "matters-table-container" }
const _hoisted_2 = { class: "matters-client-td" }
const _hoisted_3 = { class: "matters-leadLawyers-td" }
const _hoisted_4 = { class: "matters-lawyers-td" }
const _hoisted_5 = { class: "matter-action-td" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaButton = _resolveComponent("VaButton")!
  const _component_VaDropdownContent = _resolveComponent("VaDropdownContent")!
  const _component_va_dropdown = _resolveComponent("va-dropdown")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_data_table_server, {
      "return-object": "",
      loading: false,
      modelValue: _ctx.selectedRows,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRows) = $event)),
      "show-select": "",
      headers: _ctx.headers,
      items: _ctx.list,
      "items-length": _ctx.list?.length,
      "items-per-page": "10"
    }, {
      [`item.client`]: _withCtx(({ item }) => [
        _createElementVNode("td", _hoisted_2, [
          _createVNode(_component_VaButton, {
            round: "",
            icon: "person",
            size: "small"
          }),
          _createTextVNode(_toDisplayString(item.client), 1)
        ])
      ]),
      [`item.leadLawyers`]: _withCtx(({ item }) => [
        _createElementVNode("td", _hoisted_3, [
          _createVNode(_component_VaButton, {
            round: "",
            icon: "person",
            size: "small"
          }),
          _createTextVNode(_toDisplayString(item.leadLawyers), 1)
        ])
      ]),
      [`item.lawyers`]: _withCtx(({ item }) => [
        _createElementVNode("td", _hoisted_4, [
          _createVNode(_component_VaButton, {
            round: "",
            icon: "person",
            size: "small"
          }),
          _createTextVNode(_toDisplayString(item.lawyers), 1)
        ])
      ]),
      [`item.status`]: _withCtx(({ item }) => [
        _createElementVNode("td", {
          class: _normalizeClass(`matters-status-td status-${item.status
            .toLowerCase()
            .replace(' ', '-')}`)
        }, _toDisplayString(item.status), 3)
      ]),
      [`item.action`]: _withCtx(({ item }) => [
        _createElementVNode("td", _hoisted_5, [
          _createVNode(_component_va_dropdown, { offset: [0, -70] }, {
            anchor: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.MoreVertIcon,
                alt: "MoreVertIcon"
              }, null, 8, _hoisted_6)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_VaDropdownContent, null, {
                default: _withCtx(() => [
                  _createElementVNode("p", {
                    class: "matter-action-option",
                    onClick: ($event: any) => (_ctx.handleButtonClick(item))
                  }, " Open with ", 8, _hoisted_7),
                  _createElementVNode("p", {
                    class: "matter-action-option",
                    onClick: ($event: any) => (_ctx.handleButtonClick(item))
                  }, " Request Signature ", 8, _hoisted_8),
                  _createElementVNode("p", {
                    class: "matter-action-option",
                    onClick: ($event: any) => (_ctx.handleButtonClick(item))
                  }, " Preview ", 8, _hoisted_9),
                  _createElementVNode("p", {
                    class: "matter-action-option",
                    onClick: ($event: any) => (_ctx.handleButtonClick(item))
                  }, " Rename ", 8, _hoisted_10),
                  _createElementVNode("p", {
                    class: "matter-action-option",
                    onClick: ($event: any) => (_ctx.handleButtonClick(item))
                  }, " File Info ", 8, _hoisted_11),
                  _createElementVNode("p", {
                    class: "matter-action-option",
                    onClick: ($event: any) => (_ctx.handleButtonClick(item))
                  }, " Make A Copy ", 8, _hoisted_12),
                  _createElementVNode("p", {
                    class: "matter-action-option",
                    onClick: ($event: any) => (_ctx.handleButtonClick(item))
                  }, " Access Permissions ", 8, _hoisted_13),
                  _createElementVNode("p", {
                    class: "matter-action-option",
                    onClick: ($event: any) => (_ctx.handleButtonClick(item))
                  }, " Share ", 8, _hoisted_14),
                  _createElementVNode("p", {
                    class: "matter-action-option",
                    onClick: ($event: any) => (_ctx.handleButtonClick(item))
                  }, " Move ", 8, _hoisted_15),
                  _createElementVNode("p", {
                    class: "matter-action-option",
                    onClick: ($event: any) => (_ctx.handleButtonClick(item))
                  }, " Make Available Offline ", 8, _hoisted_16),
                  _createElementVNode("p", {
                    class: "matter-action-option",
                    onClick: ($event: any) => (_ctx.handleButtonClick(item))
                  }, " delete ", 8, _hoisted_17)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ])
      ]),
      _: 2
    }, 1032, ["modelValue", "headers", "items", "items-length"])
  ]))
}