<template>
  <VaModal
    v-model="isAddOrganizationFormBool"
    size="auto"
    hide-default-actions
    @update:modelValue="toggleAddOrganizationForm"
  >
    <template #header>
      <div class="create-form-header">
        <h1>Add Organization</h1>
        <VaIcon
          class="header-icon"
          name="close"
          size="30px"
          @click="toggleAddOrganizationForm"
        />
      </div>
    </template>
    <VaForm ref="form" class="add-contact-form-modal">
      <div class="add-contact-form-modal-body">
        <div class="left">
          <VaFileUpload v-model="files" file-types="csv,png" undo>
            <div class="custom-upload-file">
              <img :src="UploadLogoIcon" alt="" />
              <div class="custom-upload-file-text">
                <h1>Upload Logo Picture</h1>
                <h2>RECOMMENDED 1000X1000 PX</h2>
                <h3>Max. 1.3Mb</h3>
                <div>
                  <VaButton
                    preset="secondary"
                    border-color="primary"
                    size="small"
                    >Upload</VaButton
                  >
                </div>
              </div>
            </div>
          </VaFileUpload>

          <VaInput
            placeholder="Organization Name"
            background="#ECF0F1"
            v-model="form.organization_name"
            style="width: 100%"
            label="organization name"
            requiredMark
            :rules="[(value) => value && value.length > 0]"
          />

          <VaInput
            placeholder="Email"
            background="#ECF0F1"
            v-model="form.organization_owner"
            style="width: 100%"
            label="organization Owner"
          />

          <!-- <div>
            <VaSelect
              background="#ECF0F1"
              v-model="form.organization_owner"
              style="width: 100%; height: 2rem; margin-bottom: 1.8rem"
              label="Organization Owner"
              requiredMark
              searchable
              :options="teamMembers"
              :highlight-matched-text="false"
              :text-by="(option) => `${option.first_name} ${option.last_name}`"
              value-by="id"
              :rules="[(v) => !!v]"
            />
          </div> -->

          <div>
            <VaSelect
              background="#ECF0F1"
              placeholder="Organization Type"
              v-model="form.organization_type"
              style="width: 100%; height: 2rem; margin-bottom: 1.5rem"
              label="Organization Type"
              requiredMark
              :options="organizationTypeOptions"
              :highlight-matched-text="false"
              :text-by="(option) => option.name"
              value-by="id"
              :rules="[(v) => !!v]"
            />
          </div>

          <div>
            <VaSelect
              placeholder="Organization Industry"
              background="#ECF0F1"
              v-model="form.organization_industry"
              style="width: 100%; height: 2rem; margin-bottom: 1.5rem"
              label="industry"
              clearable
              selected-top-shown
              :options="['TYPE 1', 'TYPE 2']"
              :highlight-matched-text="false"
              :rules="[(v) => !!v]"
            />
          </div>

          <VaInput
            placeholder="Tax Identification Number"
            background="#ECF0F1"
            v-model="form.organization_tax_identification_number"
            style="width: 100%"
            label="tax identification number"
          />

          <VaInput
            placeholder="Registration Incorporation Number"
            background="#ECF0F1"
            v-model="form.organization_registration_number"
            style="width: 100%"
            label="registration incorporation Number"
          />

          <VaDateInput
            placeholder="MM/DD/YYYY"
            label="Date Of Foundation"
            background="#ECF0F1"
            v-model="form.organization_date_of_foundation"
            manual-input
          />

          <div>
            <VaSelect
              placeholder="Designed Point Of Contact"
              background="#ECF0F1"
              v-model="form.designated_point_of_contact_id"
              style="width: 100%; height: 2rem; margin-bottom: 1.8rem"
              label="designed point of contact"
              requiredMark
              searchable
              :options="teamMembers"
              :highlight-matched-text="false"
              :text-by="(option) => `${option.first_name} ${option.last_name}`"
              value-by="id"
              :rules="[(v) => !!v]"
            />
          </div>

          <div>
            <VaSelect
              placeholder="Jurisdiction Of Incorporation"
              background="#ECF0F1"
              v-model="form.organization_jurisdiction_of_incorporation"
              :loading="countriesLoading"
              style="width: 100%; height: 2rem; margin-bottom: 1.5rem"
              label="jurisdiction of incorporation"
              searchable
              requiredMark
              :options="countries?.list"
              :highlight-matched-text="false"
              :text-by="(option) => option.name"
              value-by="name"
              :rules="[(v) => !!v]"
            />
          </div>

          <VaInput
            placeholder="Organizational Website"
            background="#ECF0F1"
            v-model="form.website_url"
            style="width: 100%"
            label="organizational website"
          />

          <!-- <VaInput
            background="#ECF0F1"
            v-model="form.linkedin_url"
            style="width: 100%"
            label="linked in"
          /> -->

          <!-- <VaInput
            background="#ECF0F1"
            v-model="form.link_to_organization"
            style="width: 100%"
            label="link to organization"
          /> -->

          <div>
            <VaSelect
              placeholder="Link To Organization"
              background="#ECF0F1"
              v-model="form.link_to_organization"
              style="width: 100%; height: auto"
              label="Link To Organization"
              searchable
              clearable
              :highlight-matched-text="false"
              :options="organizations"
              :text-by="
                (option) =>
                  truncateText(option.specific_data.organization_name, 50)
              "
              :loading="organizationsLoading"
              @update:search="handleSearchOrganizations"
            />
          </div>

          <VaSelect
            placeholder="Relationship Owner"
            background="#ECF0F1"
            v-model="form.relationship_owner_id"
            style="width: 100%; height: 2rem; margin-bottom: 1.8rem"
            label="Relationship Owner"
            requiredMark
            searchable
            :options="teamMembers"
            :highlight-matched-text="false"
            :text-by="(option) => `${option.first_name} ${option.last_name}`"
            value-by="id"
            :rules="[(v) => !!v]"
          />

          <VaSelect
            placeholder="Assigned To"
            background="#ECF0F1"
            v-model="form.assigned_to_id"
            style="width: 100%; height: 2rem; margin-bottom: 1.8rem"
            label="Assigned To"
            requiredMark
            searchable
            :options="teamMembers"
            :highlight-matched-text="false"
            :text-by="(option) => `${option.first_name} ${option.last_name}`"
            value-by="id"
            :rules="[(v) => !!v]"
          />

          <VaSelect
            placeholder="Referred by"
            background="#ECF0F1"
            v-model="form.referred_by"
            style="width: 100%; height: 2rem; margin-bottom: 1.8rem"
            label="Referred by"
            requiredMark
            searchable
            :options="teamMembers"
            :highlight-matched-text="false"
            :text-by="(option) => `${option.first_name} ${option.last_name}`"
            value-by="id"
            :rules="[(v) => !!v]"
          />
          <div>
            <div v-for="(phoneNB, index) in form.phone_numbers" :key="index">
              <VaInput
                placeholder="Phone Number"
                background="#ECF0F1"
                v-model="phoneNB.phone_number"
                style="width: 100%"
                :label="`${phoneNB.label} *`"
                :rules="[(value) => (value && value.length > 0) || 'Required']"
              />
            </div>
            <!-- Button to add more email input fields -->
            <!-- <button @click="addEmailAddress">Add Another Email</button> -->
          </div>

          <div>
            <div
              v-for="(emailAddress, index) in form.email_addresses"
              :key="index"
            >
              <VaInput
                placeholder="Email"
                background="#ECF0F1"
                v-model="emailAddress.email_address"
                style="width: 100%"
                :rules="[
      (value: string) => !!value || 'Required',
      (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || 'Invalid email address'
    ]"
                :label="`${emailAddress.label} *`"
              />
            </div>
            <!-- Button to add more email input fields -->
            <!-- <button @click="addEmailAddress">Add Another Email</button> -->
          </div>

          <VaTextarea
            max-rows="7"
            v-model="form.about"
            label="about"
            style="width: 100%"
            autosize
            background="#ECF0F1"
            placeholder="Add your note here..."
          />

          <VaAccordion v-model="isAddressAccordion">
            <VaCollapse header="Address" class="address-accordion">
              <div class="address-accordion">
                <div style="width: 68.5%">
                  <VaSelect
                    placeholder="Country"
                    background="#ECF0F1"
                    v-model="form.country"
                    :loading="countriesLoading"
                    style="width: 100%; height: 2rem; margin-bottom: 1.5rem"
                    label="Country"
                    requiredMark
                    searchable
                    :options="countries?.list"
                    :highlight-matched-text="false"
                    :text-by="(option) => option.name"
                    value-by="id"
                    :rules="[(v) => !!v]"
                    @update:modelValue="handleGetState"
                  />
                </div>

                <VaInput
                  placeholder="Zip Code"
                  background="#ECF0F1"
                  v-model="form.contact_addresses_attributes[0].zip_code"
                  style="width: 28.5%"
                  label="Postal/Zip Code"
                />

                <VaSelect
                  placeholder="Province/State"
                  background="#ECF0F1"
                  v-model="form.province_state"
                  :loading="statesLoading"
                  style="width: 100%; height: 2rem; margin-bottom: 1.5rem"
                  label="Province/State"
                  requiredMark
                  searchable
                  :options="states?.list"
                  :highlight-matched-text="false"
                  :text-by="(option) => option.name"
                  value-by="id"
                  :rules="[(v) => !!v]"
                  @update:modelValue="handleGetCities"
                />

                <VaSelect
                  placeholder="City"
                  background="#ECF0F1"
                  v-model="form.contact_addresses_attributes[0].city_id"
                  :loading="citiesLoading"
                  style="width: 100%; height: 2rem; margin-bottom: 1.5rem"
                  label="City"
                  requiredMark
                  searchable
                  :options="cities?.list"
                  :highlight-matched-text="false"
                  :text-by="(option) => option.name"
                  value-by="id"
                  :rules="[(v) => !!v]"
                />

                <VaInput
                  placeholder="Street"
                  background="#ECF0F1"
                  v-model="form.contact_addresses_attributes[0].street_name"
                  style="width: 100%"
                  label="Street"
                />

                <VaInput
                  placeholder="Building"
                  background="#ECF0F1"
                  v-model="form.contact_addresses_attributes[0].building_name"
                  style="width: 100%"
                  label="Building"
                />

                <VaInput
                  placeholder="Office Number"
                  background="#ECF0F1"
                  v-model="form.contact_addresses_attributes[0].phone_number"
                  style="width: 48.5%"
                  label="Office Number"
                />

                <VaInput
                  placeholder="Floor"
                  background="#ECF0F1"
                  v-model="form.contact_addresses_attributes[0].floor"
                  style="width: 48.5%"
                  label="Floor"
                />

                <VaInput
                  placeholder="Google Maps Link"
                  background="#ECF0F1"
                  v-model="form.contact_addresses_attributes[0].google_maps_url"
                  style="width: 100%"
                  label="Google Maps Link"
                />

                <!-- <VaInput background="#ECF0F1" v-model="form.contact_addresses_attributes.address_tag"
                    style="width: 100%;" label="Address Tag" /> -->
              </div>
            </VaCollapse>
          </VaAccordion>

          <VaAccordion v-model="isBankAccordion">
            <VaCollapse header="Bank Account" class="address-accordion">
              <div class="address-accordion">
                <VaInput
                  placeholder="Iban"
                  background="#ECF0F1"
                  v-model="form.contact_bank_accounts_attributes[0].iban"
                  style="width: 100%"
                  label="iban"
                  requiredMark
                  :rules="[(v) => !!v]"
                />
                <VaInput
                  placeholder="swift/bic code"
                  background="#ECF0F1"
                  v-model="
                    form.contact_bank_accounts_attributes[0].swift_or_bic
                  "
                  style="width: 100%"
                  label="swift/bic code"
                  requiredMark
                  :rules="[(v) => !!v]"
                />
                <VaInput
                  placeholder="Account Number"
                  background="#ECF0F1"
                  v-model="
                    form.contact_bank_accounts_attributes[0].account_number
                  "
                  style="width: 100%"
                  label="account number"
                  requiredMark
                  :rules="[(v) => !!v]"
                />
                <VaInput
                  placeholder="Bank Name"
                  background="#ECF0F1"
                  v-model="form.contact_bank_accounts_attributes[0].bank_name"
                  style="width: 100%"
                  label="bank name"
                  requiredMark
                  :rules="[(v) => !!v]"
                />
                <VaInput
                  placeholder="Branch Name"
                  background="#ECF0F1"
                  v-model="form.contact_bank_accounts_attributes[0].branch_name"
                  style="width: 100%"
                  label="branch name"
                />

                <VaSelect
                  placeholder="Branch Country"
                  background="#ECF0F1"
                  v-model="
                    form.contact_bank_accounts_attributes[0].branch_country
                  "
                  :loading="countriesLoading"
                  style="width: 100%; height: 2rem; margin-bottom: 1.5rem"
                  label="Branch country"
                  requiredMark
                  :rules="[(v) => !!v]"
                  searchable
                  :options="countries?.list"
                  :highlight-matched-text="false"
                  :text-by="(option) => option.name"
                  value-by="name"
                />

                <VaInput
                  placeholder="Routing Number"
                  background="#ECF0F1"
                  v-model="
                    form.contact_bank_accounts_attributes[0].routing_number
                  "
                  style="width: 100%"
                  label="routing number"
                />

                <VaInput
                  placeholder="Sort Code"
                  background="#ECF0F1"
                  v-model="form.contact_bank_accounts_attributes[0].short_code"
                  style="width: 100%"
                  label="sort code"
                />

                <VaInput
                  placeholder="BSB number"
                  background="#ECF0F1"
                  v-model="form.contact_bank_accounts_attributes[0].bsb_number"
                  style="width: 100%"
                  label="BSB number"
                />

                <VaInput
                  placeholder="sifcs code"
                  background="#ECF0F1"
                  v-model="form.contact_bank_accounts_attributes[0].fcs_code"
                  style="width: 100%"
                  label="sifcs code"
                />
                <VaInput
                  placeholder="Transit Number"
                  background="#ECF0F1"
                  v-model="
                    form.contact_bank_accounts_attributes[0].transit_number
                  "
                  style="width: 100%"
                  label="transit number"
                />
                <VaInput
                  placeholder="Clabe"
                  background="#ECF0F1"
                  v-model="form.contact_bank_accounts_attributes[0].clabe"
                  style="width: 100%"
                  label="clabe"
                />
                <VaInput
                  placeholder="Other"
                  background="#ECF0F1"
                  v-model="form.contact_bank_accounts_attributes[0].extra_notes"
                  style="width: 100%"
                  label="other"
                />
              </div>
            </VaCollapse>
          </VaAccordion>
        </div>

        <VDivider vertical />
        <div class="right">
          <VaCheckbox
            v-model="form.kyc_completed"
            class="mb-6"
            label="KYC Complete"
          />

          <VaTextarea
            max-rows="11"
            v-model="form.kyc_notes"
            label="KYC Notes"
            style="width: 100%"
            autosize
            background="#ECF0F1"
          />

          <div class="custom-upload-file-area-kyc-container">
            <h3>KYC DOCUMENTS</h3>
            <VaFileUpload v-model="kyc_documents">
              <div class="custom-upload-file-area-kyc">
                <p class="mb-2">Upload or drag and drop files here</p>
              </div>
            </VaFileUpload>
          </div>

          <VaTextarea
            max-rows="11"
            v-model="form.extra_notes"
            label="Office Notes"
            style="width: 100%"
            autosize
            background="#ECF0F1"
          />
        </div>
      </div>
    </VaForm>
    <template #footer>
      <div class="add-contact-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="toggleAddOrganizationForm"
          >Cancel</VaButton
        >
        <VaButton
          :loading="addContactLoading"
          @click="validate() && handleCreateOrganization()"
          >Save
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
