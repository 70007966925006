import { defineStore } from 'pinia'



export const useContactsStore = defineStore({
    id: 'contacts',
    state: () => ({
        allContacts: [] as any,
        mockContacts: [
            {
                id: '1',
                firstName: 'John',
                middleName: 'William',
                lastName: 'Doe',
                title: 'Mr.',
                suffix: 'Jr.',
                sex: 'Male',
                dateOfBirth: '1980-06-15',
                primaryEmail: 'johndoe@gmail.com',
                primaryPhoneNumber: '+1-555-123-4567',
                provinceState: 'California',
                country: 'United States',
                city: 'Los Angeles',
                districtCounty: 'Westside',
                officeNumber: '102',
                floor: '5',
                building: 'Tech Tower',
                street: 'Sunset Blvd',
                postalCode: '90001',
                addressTag: 'Home',
                nationality: 'United States',
                organizationLink: 'https://www.techcorp.com',
                relationshipOwner: 'Jane Smith',
                assignedTo: 'Michael Johnson',
                referredBy: 'Alex Roberts',
                googleMapsLink: 'https://maps.google.com',
                type: 'Person',
                tin: '123-45-6789',
                personalWebsite: 'https://www.johndoe.com',
                socialMediaLinks: ['https://facebook.com/johndoe', 'https://twitter.com/johndoe'],
                organizationLinks: ['https://www.techcorp.com'],
                isKYC: true,
                KYCNote: 'Verified on 2023-01-01',
                note: 'Long-time client with consistent communication',
                action: 'Edit/Delete'
            },
            {
                img: 'https://i.pravatar.cc/302',
                id: '2',
                firstName: 'Emily',
                middleName: 'Rose',
                lastName: 'Williams',
                title: 'Ms.',
                suffix: '',
                sex: 'Female',
                dateOfBirth: '1992-11-08',
                primaryEmail: 'emily.williams@yahoo.com',
                primaryPhoneNumber: '+44-207-555-9876',
                provinceState: 'Greater London',
                country: 'United Kingdom',
                city: 'London',
                districtCounty: 'Camden',
                officeNumber: '25B',
                floor: '3',
                building: 'Greenwich Plaza',
                street: 'Baker Street',
                postalCode: 'NW1 5LS',
                addressTag: 'Work',
                nationality: 'United Kingdom',
                organizationLink: 'https://www.londonlaw.co.uk',
                relationshipOwner: 'Chris Evans',
                assignedTo: 'David Thompson',
                referredBy: 'Linda Harris',
                googleMapsLink: 'https://maps.google.co.uk',
                type: 'Person',
                tin: 'AB123456C',
                personalWebsite: 'https://www.emilywrites.com',
                socialMediaLinks: ['https://linkedin.com/in/emilywilliams', 'https://instagram.com/emilywrites'],
                organizationLinks: ['https://www.londonlaw.co.uk'],
                isKYC: true,
                KYCNote: 'KYC completed via online portal',
                note: 'New client, referred by a partner',
                action: 'Edit/Delete'
            },
            {
                img: 'https://i.pravatar.cc/303',
                id: '3',
                firstName: 'Michael',
                middleName: 'James',
                lastName: 'Brown',
                title: 'Dr.',
                suffix: '',
                sex: 'Male',
                dateOfBirth: '1975-04-23',
                primaryEmail: 'michael.brown@healthcare.com',
                primaryPhoneNumber: '+61-3-5555-6666',
                provinceState: 'Victoria',
                country: 'Australia',
                city: 'Melbourne',
                districtCounty: 'Central',
                officeNumber: 'Suite 14',
                floor: '2',
                building: 'Health Center',
                street: 'Collins Street',
                postalCode: '3000',
                addressTag: 'Office',
                nationality: 'Australia',
                organizationLink: 'https://www.healthcare.com',
                relationshipOwner: 'Sarah Connor',
                assignedTo: 'Emma Watson',
                referredBy: 'Dr. Alex Clark',
                googleMapsLink: 'https://maps.google.com.au',
                type: 'Person',
                tin: '987-65-4321',
                personalWebsite: 'https://www.drmichaelbrown.com',
                socialMediaLinks: ['https://twitter.com/drmichaelbrown', 'https://linkedin.com/in/michaelbrown'],
                organizationLinks: ['https://www.healthcare.com'],
                isKYC: false,
                KYCNote: 'Pending verification',
                note: 'Needs follow-up for KYC completion',
                action: 'Edit/Delete'
            },
            {
                img: 'https://i.pravatar.cc/304',
                id: '4',
                firstName: 'Sophia',
                middleName: 'Claire',
                lastName: 'Miller',
                title: 'Mrs.',
                suffix: '',
                sex: 'Female',
                dateOfBirth: '1985-02-14',
                primaryEmail: 'sophia.miller@hotmail.com',
                primaryPhoneNumber: '+33-1-2345-6789',
                provinceState: 'Île-de-France',
                country: 'France',
                city: 'Paris',
                districtCounty: 'Montmartre',
                officeNumber: '67',
                floor: '6',
                building: 'Eiffel Tower Offices',
                street: 'Champs-Élysées',
                postalCode: '75008',
                addressTag: 'Home',
                nationality: 'France',
                organizationLink: 'https://www.parisconsulting.fr',
                relationshipOwner: 'Jean Dupont',
                assignedTo: 'Marie Lefevre',
                referredBy: 'Luc Besson',
                googleMapsLink: 'https://maps.google.fr',
                type: 'Person',
                tin: 'FR0987654321',
                personalWebsite: 'https://www.sophiaconsults.fr',
                socialMediaLinks: ['https://facebook.com/sophiamiller', 'https://linkedin.com/in/sophiamiller'],
                organizationLinks: ['https://www.parisconsulting.fr'],
                isKYC: true,
                KYCNote: 'Verified in person',
                note: 'Frequent traveler, prefers email communication',
                action: 'Edit/Delete'
            },
            {
                img: 'https://i.pravatar.cc/305',
                id: '5',
                firstName: 'David',
                middleName: 'Lee',
                lastName: 'Garcia',
                title: 'Mr.',
                suffix: '',
                sex: 'Male',
                dateOfBirth: '1990-09-10',
                primaryEmail: 'david.garcia@gmail.com',
                primaryPhoneNumber: '+34-910-555-1234',
                provinceState: 'Madrid',
                country: 'Spain',
                city: 'Madrid',
                districtCounty: 'Salamanca',
                officeNumber: '101',
                floor: '4',
                building: 'Plaza Mayor',
                street: 'Gran Via',
                postalCode: '28013',
                addressTag: 'Work',
                nationality: 'Spain',
                organizationLink: 'https://www.madridventures.com',
                relationshipOwner: 'Carlos Alvarez',
                assignedTo: 'Lucia Rodriguez',
                referredBy: 'Fernando Torres',
                googleMapsLink: 'https://maps.google.es',
                type: 'Person',
                tin: 'ES1122334455',
                personalWebsite: 'https://www.davidgarciaventures.com',
                socialMediaLinks: ['https://linkedin.com/in/davidgarcia', 'https://twitter.com/davidgarcia'],
                organizationLinks: ['https://www.madridventures.com'],
                isKYC: true,
                KYCNote: 'Verified via email',
                note: 'High-potential client with interest in tech startups',
                action: 'Edit/Delete'
            },
            {
                img: 'https://i.pravatar.cc/306',
                id: '6',
                firstName: 'Amelia',
                middleName: 'Grace',
                lastName: 'Martinez',
                title: 'Ms.',
                suffix: '',
                sex: 'Female',
                dateOfBirth: '1995-12-05',
                primaryEmail: 'amelia.martinez@outlook.com',
                primaryPhoneNumber: '+52-55-2345-6789',
                provinceState: 'Mexico City',
                country: 'Mexico',
                city: 'Mexico City',
                districtCounty: 'Polanco',
                officeNumber: '56',
                floor: '12',
                building: 'Torre Latinoamericana',
                street: 'Reforma Avenue',
                postalCode: '06600',
                addressTag: 'Work',
                nationality: 'Mexico',
                organizationLink: 'https://www.mexicoconsulting.mx',
                relationshipOwner: 'Luis Hernandez',
                assignedTo: 'Pedro Morales',
                referredBy: 'Ana Rodriguez',
                googleMapsLink: 'https://maps.google.com.mx',
                type: 'Person',
                tin: 'MX1234567890',
                personalWebsite: 'https://www.ameliamconsulting.mx',
                socialMediaLinks: ['https://linkedin.com/in/ameliamartinez', 'https://twitter.com/ameliamartinez'],
                organizationLinks: ['https://www.mexicoconsulting.mx'],
                isKYC: false,
                KYCNote: 'Pending documentation',
                note: 'Potential client with high-value projects',
                action: 'Edit/Delete'
            },
            {
                img: 'https://i.pravatar.cc/307',
                id: '7',
                firstName: 'Noah',
                middleName: 'Alexander',
                lastName: 'Nguyen',
                title: 'Mr.',
                suffix: '',
                sex: 'Male',
                dateOfBirth: '1983-03-18',
                primaryEmail: 'noah.nguyen@gmail.com',
                primaryPhoneNumber: '+84-24-5555-6789',
                provinceState: 'Hanoi',
                country: 'Vietnam',
                city: 'Hanoi',
                districtCounty: 'Ba Dinh',
                officeNumber: '89',
                floor: '10',
                building: 'Lotus Tower',
                street: 'Hoang Dieu Street',
                postalCode: '10000',
                addressTag: 'Work',
                nationality: 'Vietnam',
                organizationLink: 'https://www.vietnamtech.vn',
                relationshipOwner: 'Minh Tran',
                assignedTo: 'Phuong Le',
                referredBy: 'Duc Nguyen',
                googleMapsLink: 'https://maps.google.com.vn',
                type: 'Person',
                tin: 'VN9876543210',
                personalWebsite: 'https://www.noahnguyen.com',
                socialMediaLinks: ['https://facebook.com/noahnguyen', 'https://linkedin.com/in/noahnguyen'],
                organizationLinks: ['https://www.vietnamtech.vn'],
                isKYC: true,
                KYCNote: 'Verified by local representative',
                note: 'Reliable client with tech background',
                action: 'Edit/Delete'
            },
            {
                img: 'https://i.pravatar.cc/308',
                id: '8',
                firstName: 'Liam',
                middleName: 'Oscar',
                lastName: 'Wilson',
                title: 'Mr.',
                suffix: 'Sr.',
                sex: 'Male',
                dateOfBirth: '1978-07-30',
                primaryEmail: 'liam.wilson@businessmail.com',
                primaryPhoneNumber: '+27-11-555-1234',
                provinceState: 'Gauteng',
                country: 'South Africa',
                city: 'Johannesburg',
                districtCounty: 'Sandton',
                officeNumber: '12',
                floor: '3',
                building: 'Business Hub',
                street: 'Nelson Mandela Square',
                postalCode: '2196',
                addressTag: 'Office',
                nationality: 'South Africa',
                organizationLink: 'https://www.joburgbusiness.co.za',
                relationshipOwner: 'Tebogo Mokoena',
                assignedTo: 'Sipho Dlamini',
                referredBy: 'Zama Khumalo',
                googleMapsLink: 'https://maps.google.co.za',
                type: 'Person',
                tin: 'ZA1122334455',
                personalWebsite: 'https://www.liamwilson.co.za',
                socialMediaLinks: ['https://linkedin.com/in/liamwilson', 'https://twitter.com/liamwilson'],
                organizationLinks: ['https://www.joburgbusiness.co.za'],
                isKYC: true,
                KYCNote: 'Verified via local office',
                note: 'Corporate client with expanding business',
                action: 'Edit/Delete'
            },
            {
                img: 'https://i.pravatar.cc/309',
                id: '9',
                firstName: 'Chloe',
                middleName: 'Isabelle',
                lastName: 'Garcia',
                title: 'Ms.',
                suffix: '',
                sex: 'Female',
                dateOfBirth: '1998-01-22',
                primaryEmail: 'chloe.garcia@consulting.com',
                primaryPhoneNumber: '+65-6777-1234',
                provinceState: 'Central Singapore',
                country: 'Singapore',
                city: 'Singapore',
                districtCounty: 'Orchard',
                officeNumber: '76A',
                floor: '11',
                building: 'Marina Bay Financial Centre',
                street: 'Raffles Place',
                postalCode: '048619',
                addressTag: 'Office',
                nationality: 'Singapore',
                organizationLink: 'https://www.singaporeconsulting.sg',
                relationshipOwner: 'Kim Lee',
                assignedTo: 'Wei Tan',
                referredBy: 'Yun Wong',
                googleMapsLink: 'https://maps.google.sg',
                type: 'Person',
                tin: 'SG1234567890',
                personalWebsite: 'https://www.chloegarcia.sg',
                socialMediaLinks: ['https://linkedin.com/in/chloegarcia', 'https://twitter.com/chloegarcia'],
                organizationLinks: ['https://www.singaporeconsulting.sg'],
                isKYC: true,
                KYCNote: 'Verified online',
                note: 'Strong background in international consulting',
                action: 'Edit/Delete'
            },
        ],
        allOrganizations: [] as any
    }),
    getters: {
        // getItemById: (state) => (id: string | string[]) => {
        //     if (state.allContacts.length > 1) {
        //         return state.allContacts.find((contact: any) => contact?.id === id)
        //     }
        // }
    }
})
