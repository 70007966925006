import { Card } from '@/views/Hearings/script';
import { defineComponent } from 'vue';
import { PropType } from 'vue';

export default defineComponent({
    name: 'HearingCard',
    props: {
        data: {
            type: Object as PropType<Card>,
            required: true,
        },
    },
    data(props) {
        // Destructuring the data prop
        const { title, date, assignedTo, status, client, lawyer, id } = props.data || {};

        return {
            title,
            date,
            assignedTo,
            status,
            client,
            lawyer,
            id
        };
    },

});



