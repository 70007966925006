<template>
  <div class="card-section">
    <div class="task-view">
      <VaCard>
        <router-link :to="`/tasks/${id}`">
          <VaCardContent>
            <p>{{ text }}</p>
            <span>
              <!-- <VaButton size="small" round icon="person" /> -->
              {{ formattedDateRange }}
            </span>
          </VaCardContent>
        </router-link>
      </VaCard>
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" scoped></style>
