<template>
  <div class="calendar-filters">
    <button class="filter-box" v-for="(filter, index) in filters" :key="index">
      {{ filter }}
      <Close />
    </button>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" />
