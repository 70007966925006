import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomePage from '@/views/Home/index.vue'
import ContactsPage from '@/views/Contacts/index.vue'
import ContactDetailsPage from '@/views/Contacts/ContactDetails/index.vue'
import OrganizationDetailsPage from '@/views/Contacts/OrganizationDetails/index.vue'
import CalendarPage from '@/views/Calendar/index.vue'
import LegalMattersPage from '@/views/legalMatters/index.vue'
import LegalMattersDetailsPage from '@/views/legalMatters/MatterDetails/index.vue'
import HearingsPage from '@/views/Hearings/index.vue'
import HearingsDetailsPage from '@/views/Hearings/Details/index.vue'
import LegalLibraryPage from '@/views/LegalLibrary/index.vue'
import FilesPage from '@/views/files/index.vue'
import FinancialPage from '@/views/financial/index.vue'
import InvoicePage from '@/views/financial/Invoice/index.vue'
import ExpensePage from '@/views/financial/Expense/index.vue'
import TasksPage from '@/views/Tasks/index.vue'
import TaskDetailsPage from '@/views/Tasks/Details/index.vue'
import FirmPage from '@/views/firm/index.vue'
import LegalMarketPage from '@/views/LegalMarket/index.vue'
import EmailsPage from '@/views/Emails/index.vue'
import NotFound from '@/components/NotFound/index.vue'
import DefaultLayout from '../../layouts/Default/index.vue' // Import the layout component
import Cookies from 'js-cookie'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('@/views/Login/index.vue'),
    meta: { layout: 'none' }, // No layout for login
  },
  {
    path: '/',
    component: DefaultLayout, // Wrap with the layout
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'HomeView',
        component: HomePage,
      },
      {
        path: 'contacts',
        name: 'ContactsView',
        component: ContactsPage,
      },
      {
        path: 'contacts/:id',
        name: 'ContactDetailsView',
        component: ContactDetailsPage,
      },
      {
        path: 'organization/:id',
        name: 'OrganizationDetailsView',
        component: OrganizationDetailsPage,
      },
      {
        path: 'calendar',
        name: 'CalendarView',
        component: CalendarPage,
      },
      {
        path: 'legal-matters',
        name: 'LegalMattersView',
        component: LegalMattersPage,
      },
      {
        path: 'legal-matters/:id',
        name: 'LegalMattersDetails',
        component: LegalMattersDetailsPage,
      },
      {
        path: 'hearings',
        name: 'HearingsView',
        component: HearingsPage,
      },
      {
        path: 'hearings/:id',
        name: 'HearingsDetailsView',
        component: HearingsDetailsPage,
      },
      {
        path: 'legal-library',
        name: 'LegalLibrary',
        component: LegalLibraryPage,
      },
      {
        path: 'files',
        name: 'FilesView',
        component: FilesPage,
      },
      {
        path: 'financial',
        name: 'FinancialView',
        component: FinancialPage,
      },
      {
        path: 'financial/invoice/:id',
        name: 'InvoiceView',
        component: InvoicePage,
      },
      {
        path: 'financial/expense/:id',
        name: 'ExpenseView',
        component: ExpensePage,
      },
      {
        path: 'tasks',
        name: 'Tasks',
        component: TasksPage,
      },
      {
        path: 'tasks/:id',
        name: 'TaskDetails',
        component: TaskDetailsPage,
      },
      {
        path: 'firm',
        name: 'Firm',
        component: FirmPage,
      },
      {
        path: 'legal-market',
        name: 'LegalMarket',
        component: LegalMarketPage,
      },
      {
        path: 'emails',
        name: 'Emails',
        component: EmailsPage,
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*', // This catches all undefined routes
    name: 'NotFound',
    component: NotFound,
    meta: { requiresAuth: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// Add authentication guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!Cookies.get('authaqq') // Example of auth check (customize this to your needs)
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next({ name: 'LoginView' }) // Redirect to login page if not authenticated
  } else {
    next() // Proceed to the route
  }
})

export default router
