import draggable from 'vuedraggable'
export default {
  name: 'table-example',
  display: 'Table',
  order: 8,
  components: {
    draggable,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dragging: false,
    }
  },
}
