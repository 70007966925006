import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0aecfec4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "body-expense-form" }
const _hoisted_3 = { class: "UQC-section" }
const _hoisted_4 = { class: "TDT-section" }
const _hoisted_5 = { class: "ESE-section" }
const _hoisted_6 = { class: "upload-docs" }
const _hoisted_7 = { class: "add-expense-form-modal-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaInput = _resolveComponent("VaInput")!
  const _component_VaSelect = _resolveComponent("VaSelect")!
  const _component_VaTextarea = _resolveComponent("VaTextarea")!
  const _component_VaDateInput = _resolveComponent("VaDateInput")!
  const _component_VaButton = _resolveComponent("VaButton")!
  const _component_VaFileUpload = _resolveComponent("VaFileUpload")!
  const _component_VaForm = _resolveComponent("VaForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", _hoisted_1, "EXPENSE NAME: " + _toDisplayString(_ctx.form.name), 1),
    _createVNode(_component_VaForm, {
      ref: "form",
      class: "create-expense-form",
      onSubmit: _withModifiers(_ctx.handleFormSubmit, ["prevent"])
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_VaInput, {
            loading: _ctx.loading,
            background: "#ECF0F1",
            modelValue: _ctx.form.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event)),
            label: "Name",
            placeholder: "Name",
            requiredMark: "",
            rules: [(value) => value && value.length > 0]
          }, null, 8, ["loading", "modelValue", "rules"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_VaInput, {
              loading: _ctx.loading,
              background: "#ECF0F1",
              modelValue: _ctx.form.unit_price,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.unit_price) = $event)),
              style: {"width":"33%"},
              label: "Unit price",
              placeholder: "Unit Price",
              requiredMark: "",
              type: "number",
              rules: [(value) => value && value.length > 0]
            }, null, 8, ["loading", "modelValue", "rules"]),
            _createVNode(_component_VaInput, {
              loading: _ctx.loading,
              background: "#ECF0F1",
              modelValue: _ctx.form.quantity,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.quantity) = $event)),
              style: {"width":"33%"},
              label: "Quantity",
              placeholder: "Quantity",
              requiredMark: "",
              type: "number",
              rules: [
            (value) => value !== null && value !== undefined && value > 0,
          ]
            }, null, 8, ["loading", "modelValue", "rules"]),
            _createVNode(_component_VaSelect, {
              loading: _ctx.loading,
              style: {"width":"33%"},
              label: "currency",
              background: "#ECF0F1",
              modelValue: _ctx.form.currency,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.currency) = $event)),
              options: ['USD', 'UAE']
            }, null, 8, ["loading", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_VaInput, {
              loading: _ctx.loading,
              style: {"width":"33%"},
              background: "#ECF0F1",
              modelValue: _ctx.form.total_amount,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.total_amount) = $event)),
              label: "total value",
              placeholder: "Total Value",
              requiredMark: "",
              type: "number",
              rules: [(value) => value && value.length > 0]
            }, null, 8, ["loading", "modelValue", "rules"]),
            _createVNode(_component_VaInput, {
              loading: _ctx.loading,
              style: {"width":"33%"},
              background: "#ECF0F1",
              modelValue: _ctx.form.declared_amount,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.declared_amount) = $event)),
              label: "Declared value",
              placeholder: "Declared Value",
              requiredMark: "",
              type: "number",
              rules: [(value) => value && value.length > 0]
            }, null, 8, ["loading", "modelValue", "rules"]),
            _createVNode(_component_VaInput, {
              loading: _ctx.loading,
              style: {"width":"32%"},
              background: "#ECF0F1",
              modelValue: _ctx.form.total_tax,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.total_tax) = $event)),
              label: "Tax value",
              placeholder: "Tax Value",
              requiredMark: "",
              type: "number",
              rules: [(value) => value && value.length > 0]
            }, null, 8, ["loading", "modelValue", "rules"])
          ]),
          _createVNode(_component_VaSelect, {
            loading: _ctx.loading,
            style: {"width":"100%"},
            label: "Category",
            background: "#ECF0F1",
            modelValue: _ctx.form.category,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.category) = $event)),
            options: _ctx.categoryOption
          }, null, 8, ["loading", "modelValue", "options"]),
          _createVNode(_component_VaTextarea, {
            loading: _ctx.loading,
            "max-rows": "11",
            modelValue: _ctx.form.description,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.description) = $event)),
            label: "description",
            placeholder: "Add your description here...",
            requiredMark: "",
            style: {"width":"100%","height":"10rem"},
            autosize: "",
            background: "#ECF0F1",
            rules: [(value) => value && value.length > 0]
          }, null, 8, ["loading", "modelValue", "rules"]),
          _createVNode(_component_VaSelect, {
            background: "#ECF0F1",
            modelValue: _ctx.form.billed_to_id,
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.billed_to_id) = $event)),
            style: {"width":"100%","height":"1.5rem"},
            label: "Paid To",
            placeholder: "Paid To",
            searchable: "",
            "highlight-matched-text": false,
            options: _ctx.contacts,
            "text-by": 
          (option) =>
            `${option?.specific_data?.individual_first_name} ${option?.specific_data?.individual_last_name}`
        ,
            loading: _ctx.contactsLoading,
            "onUpdate:search": _ctx.handleSearchContacts
          }, null, 8, ["modelValue", "options", "text-by", "loading", "onUpdate:search"]),
          _createVNode(_component_VaSelect, {
            loading: _ctx.contactsLoading,
            background: "#ECF0F1",
            modelValue: _ctx.form.from_wallet_id,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.from_wallet_id) = $event)),
            style: {"width":"100%","height":"1.5rem"},
            label: "Paid By",
            placeholder: "Paid By",
            searchable: "",
            options: _ctx.contacts,
            "highlight-matched-text": false,
            "text-by": 
          (option) =>
            `${option.specific_data?.individual_first_name} ${option.specific_data?.individual_last_name}`
        ,
            "onUpdate:search": _ctx.handleSearchContacts
          }, null, 8, ["loading", "modelValue", "options", "text-by", "onUpdate:search"]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_VaDateInput, {
              placeholder: "MM/DD/YYYY",
              loading: _ctx.loading,
              modelValue: _ctx.form.due_date,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.due_date) = $event)),
              "manual-input": "",
              label: "Expenses due date",
              requiredMark: ""
            }, null, 8, ["loading", "modelValue"]),
            _createVNode(_component_VaSelect, {
              loading: _ctx.loading,
              label: "Status",
              placeholder: "Status",
              background: "#ECF0F1",
              modelValue: _ctx.form.status,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.status) = $event)),
              options: _ctx.statusOptions,
              "value-by": "value"
            }, null, 8, ["loading", "modelValue", "options"])
          ]),
          _createVNode(_component_VaSelect, {
            loading: _ctx.loading,
            label: "expense type",
            placeholder: "Expense Type",
            background: "#ECF0F1",
            modelValue: _ctx.form.expense_type,
            "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.expense_type) = $event)),
            options: _ctx.expenseTypeOptions,
            "value-by": "text"
          }, null, 8, ["loading", "modelValue", "options"]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_VaFileUpload, {
              modelValue: _ctx.files,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.files) = $event)),
              disabled: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_VaButton, {
                  icon: "add",
                  preset: "secondary",
                  "border-color": "primary",
                  size: "small"
                })
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createTextVNode(" Upload documents (.jpg,.jpeg,.pdf,.png) ")
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_VaButton, {
            preset: "secondary",
            "border-color": "primary",
            onClick: _ctx.handleToggleAddModal
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cancel")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_VaButton, {
            onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.validate() && _ctx.handleUpdateExpense())),
            loading: _ctx.updateLoading
          }, {
            default: _withCtx(() => [
              _createTextVNode("Save")
            ]),
            _: 1
          }, 8, ["loading"])
        ])
      ]),
      _: 1
    }, 8, ["onSubmit"])
  ], 64))
}