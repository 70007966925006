<template>
  <VaModal
    v-model="isAddMatterBool"
    size="auto"
    hide-default-actions
    fixed-layout
    noOutsideDismiss
    @update:modelValue="handleToggleAddModal"
  >
    <template #header>
      <div class="create-matter-form-header">
        <h1>Create Matter</h1>
        <VaIcon
          class="header-icon"
          name="close"
          size="30px"
          @click="handleToggleAddModal"
        />
      </div>
    </template>
    <VaForm
      ref="form"
      class="create-matter-form"
      @submit.prevent="handleFormSubmit"
    >
      <div class="container">
        <VaInput
          background="#ECF0F1"
          v-model="form.name"
          style="width: 100%"
          label="name"
          requiredMark
          :rules="[(value) => value && value.length > 0]"
          placeholder="Name"
        />
        <div class="client-section">
          <VaSelect
            background="#ECF0F1"
            v-model="form.representing_contact_id"
            label="client"
            searchable
            :highlight-matched-text="false"
            :options="contacts"
            :text-by="
              (option) =>
                `${option?.specific_data?.individual_first_name} ${option?.specific_data?.individual_last_name}`
            "
            :loading="contactsLoading"
            @update:search="handleSearchContacts"
            placeholder="Select Client"
            value-by="id"
          />
          <VaSelect
            v-model="form.representation_type"
            :options="presentationTypeOption"
            label="representing"
            background="#ECF0F1"
            placeholder="Representing"
          />
        </div>

        <p><VaIcon name="link" /> Link to another Legal Matter</p>

        <div class="court-section">
          <VaSelect
            v-model="form.court_id"
            :options="courtOptions"
            label="Court"
            requiredMark
            :rules="[(value) => value]"
            style="width: 80%"
            background="#ECF0F1"
            placeholder="Court Type"
            :text-by="(option) => truncateText(option?.name, 110)"
            value-by="id"
          />

          <VaInput
            background="#ECF0F1"
            v-model="form.court_case_number"
            style="width: 20%"
            placeholder="Court ID"
            requiredMark
            :rules="[(value) => value && value.length > 0]"
            label="Court ID "
          />
        </div>

        <div class="jn-opp-section">
          <VaSelect
            background="#ECF0F1"
            v-model="form.judge_id"
            style="width: 100%; height: 1.5rem; padding-bottom: 1.5rem"
            label="judge name"
            requiredMark
            :rules="[(value) => value]"
            clearable
            searchable
            :highlight-matched-text="false"
            :options="contacts"
            :text-by="
              (option) =>
                `${option.specific_data?.individual_first_name} ${option.specific_data?.individual_last_name}`
            "
            :loading="contactsLoading"
            @update:search="handleSearchContacts"
            placeholder="Select The Judge"
            value-by="id"
          />
          <VaSelect
            background="#ECF0F1"
            v-model="form.opponent_contact_id"
            style="width: 100%; height: 1.5rem"
            label="opponent name"
            requiredMark
            :rules="[(value) => value]"
            clearable
            searchable
            :highlight-matched-text="false"
            :options="contacts"
            :text-by="
              (option) =>
                `${option?.specific_data?.individual_first_name} ${option?.specific_data?.individual_last_name}`
            "
            :loading="contactsLoading"
            @update:search="handleSearchContacts"
            placeholder="Select The Opponent"
            value-by="id"
          />
        </div>

        <div class="status-stage-section">
          <VaSelect
            label="Status"
            background="#ECF0F1"
            v-model="form.status_id"
            :options="statusOptions"
            :text-by="(option) => option?.name"
            value-by="id"
          />
          <VaSelect
            label="Stage"
            background="#ECF0F1"
            v-model="form.section_stage_id"
            :options="$stores.matter.stages"
            :text-by="(option) => option?.name"
            value-by="id"
          />
        </div>

        <VaSelect
          disabled
          background="#ECF0F1"
          style="width: 100%; height: 1.5rem"
          label="Team"
          clearable
          searchable
          :highlight-matched-text="false"
          :options="contacts"
          :text-by="
            (option) =>
              `${option?.specific_data?.individual_first_name} ${option?.specific_data?.individual_last_name}`
          "
          :loading="contactsLoading"
          @update:search="handleSearchContacts"
          placeholder="Select A Team"
          value-by="id"
        />
        <VaSelect
          background="#ECF0F1"
          v-model="form.case_manager_ids"
          style="width: 100%; height: 1.5rem"
          label="case manager"
          requiredMark
          :rules="[(value) => value]"
          searchable
          :highlight-matched-text="false"
          :options="teamMembers"
          :text-by="(option) => `${option?.first_name} ${option?.last_name}`"
          :loading="contactsLoading"
          @update:search="handleSearchContacts"
          placeholder="Select Case Manager"
          value-by="id"
          multiple
          :max-visible-options="2"
        />
        <div class="case-personal">
          <div style="width: 70%">
            <VaSelect
              background="#ECF0F1"
              v-model="form.personnel_ids"
              label="personnel"
              clearable
              searchable
              :highlight-matched-text="false"
              :options="teamMembers"
              :text-by="
                (option) => `${option?.first_name} ${option?.last_name}`
              "
              :loading="contactsLoading"
              @update:search="handleSearchContacts"
              placeholder="Select A Personnel"
              value-by="id"
              multiple
              :max-visible-options="2"
            />
          </div>
          <VaCheckbox
            disabled
            style="height: 0.5rem"
            v-model="case_personal_of_counsel"
            class="mb-6"
            label="Of Counsel"
          />
        </div>
        <div class="spe-cat-section">
          <VaSelect
            style="width: 100%"
            label="Specialization"
            clearable
            background="#ECF0F1"
            v-model="form.specialization_id"
            :options="specializationOptions"
            placeholder="Specialization"
            :text-by="(option) => option?.name"
            value-by="id"
          />
          <VaSelect
            style="width: 100%"
            label="Category"
            clearable
            background="#ECF0F1"
            v-model="form.category_id"
            :options="categoryOptions"
            placeholder="Category"
            :text-by="(option) => option?.name"
            value-by="id"
          />
        </div>

        <VaSelect
          placeholder="Jurisdictions"
          background="#ECF0F1"
          v-model="form.jurisdriction_id"
          :loading="countriesLoading"
          style="width: 100%; height: auto"
          label="Jurisdictions"
          requiredMark
          searchable
          :options="countries?.list"
          :highlight-matched-text="false"
          :text-by="(option) => option?.name"
          value-by="id"
          :rules="[(v) => !!v]"
        />
        <VaInput
          style="width: 100%"
          background="#ECF0F1"
          v-model="form.region"
          label="Region"
          placeholder="Region"
        />
        <VaTextarea
          max-rows="11"
          v-model="form.notes"
          label="notes"
          style="width: 100%; height: 10rem"
          autosize
          background="#ECF0F1"
          placeholder="Enter your notes here..."
        />
      </div>
    </VaForm>

    <template #footer>
      <div class="add-matter-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleAddModal"
          >Cancel</VaButton
        >
        <VaButton
          @click="validate() && handleCreateMatter()"
          :loading="addMatterLoading"
          >Save</VaButton
        >
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
