<template>
  <div class="tasks-table-container">
    <v-data-table-server
      v-model:selection="selectedRows"
      :loading="loading"
      :headers="headers"
      :items="$stores.mockContacts.allContacts.data?.list"
      show-select
      class="custom-table"
      :items-length="localTotalItems"
      :items-per-page="itemsPerPage"
      @update:options="handleLoadMore"
      :items-per-page-options="[10, 25, 50]"
    >
      <template #[`item.specific_data.individual_first_name`]="{ item }">
        <router-link :to="`/contacts/${item?.id}`">
          <p>{{ item?.specific_data.individual_first_name || 'N/A' }}</p>
        </router-link>
      </template>

      <template #[`item.specific_data.individual_middle_name`]="{ item }">
        <router-link :to="`/contacts/${item?.id}`">
          <p>{{ item?.specific_data.individual_middle_name || 'N/A' }}</p>
        </router-link>
      </template>

      <template #[`item.specific_data.individual_last_name`]="{ item }">
        <router-link :to="`/contacts/${item?.id}`">
          <p>{{ item?.specific_data.individual_last_name || 'N/A' }}</p>
        </router-link>
      </template>

      <template #[`item.kyc_completed`]="{ item }">
        <div class="kyc-status">
          <va-icon
            :name="item?.kyc_completed ? 'check_circle' : 'cancel'"
            :color="item?.kyc_completed ? 'green' : 'red'"
          />
          <span :style="{ color: item?.kyc_completed ? 'green' : 'red' }">
            {{ item?.kyc_completed }}
          </span>
        </div>
      </template>

      <template #[`item.email_addresses`]="{ item }">
        <div v-for="(email, index) in item.email_addresses" :key="index">
          <a :href="`mailto:${email?.email_address}`" class="email-link">
            <va-icon name="mail" color="green" />
            {{ email?.email_address || 'N/A' }}
          </a>
        </div>
      </template>

      <template #[`item.phone_numbers`]="{ item }">
        <div v-for="(phone, index) in item.phone_numbers" :key="index">
          <a :href="`tel:${phone?.phone_number}`" class="phone-link">
            <va-icon
              name="phone"
              :color="phone?.phone_number ? 'green' : 'red'"
            />
            {{ phone?.phone_number || 'N/A' }} ({{ phone?.label }})
          </a>
        </div>
      </template>

      <!-- <template #[`item.social_media_links`]="{ item }">
        <div class="social-media-icons">
          <a
            v-if="item?.linkedin_url"
            :href="item?.linkedin_url"
            target="_blank"
          >
            <p>{{ item?.linkedin_url }}</p>
          </a>
          <a
            v-if="item?.instagram_url"
            :href="item?.instagram_url"
            target="_blank"
          >
            <p>{{ item?.instagram_url }}</p>
          </a>
          <a
            v-if="item?.facebook_url"
            :href="item?.facebook_url"
            target="_blank"
          >
            <p>{{ item?.facebook_url }}</p>
          </a>
        </div>
      </template> -->

      <template #[`item.organization`]="{ item }">
        <div class="social-media-icons">
          <a
            v-if="item?.organization"
            :href="item?.organization"
            target="_blank"
          >
            <p>{{ item?.organization || 'N/A' }}</p>
          </a>
        </div>
      </template>

      <template #[`item.website_url`]="{ item }">
        <div>
          <a v-if="item?.website_url" :href="item?.website_url" target="_blank">
            <p>{{ item?.website_url || 'N/A' }}</p>
          </a>
        </div>
      </template>

      <template #[`item.referred_by`]="{ item }">
        <p>
          {{ item?.referred_by?.first_name || '' }}
          {{ item?.referred_by?.last_name || '' }}
        </p>
      </template>

      <template #[`item.assigned_to`]="{ item }">
        <p>
          {{ item?.assigned_to?.first_name || '' }}
          {{ item?.assigned_to?.last_name || '' }}
        </p>
      </template>

      <template #[`item.relationship_owner`]="{ item }">
        <p>
          {{ item?.relationship_owner?.first_name || '' }}
          {{ item?.relationship_owner?.last_name || '' }}
        </p>
      </template>

      <template #[`item.contact_type`]="{}">
        <p>Person</p>
      </template>

      <template #[`item.img`]="{ item }">
        <va-avatar
          :src="item?.img"
          :alt="item?.first_name + ' ' + item?.last_name"
          size="small"
        >
          <span style="color: black" v-if="!item?.img"
            >{{ item?.first_name?.charAt(0)
            }}{{ item?.last_name?.charAt(0) }}</span
          >
        </va-avatar>
      </template>

      <template #[`item.notes`]="{ item }">
        <p class="tasks-note-td">{{ item?.notes || 'No notes available' }}</p>
      </template>

      <template #[`item.kyc_notes`]="{ item }">
        <p class="tasks-note-td">{{ item?.kyc_notes || 'No KYC notes' }}</p>
      </template>

      <template #[`item.action`]="{ item }">
        <td class="task-action-td">
          <va-dropdown :offset="[0, -70]" close-on-content-click>
            <template #anchor>
              <img :src="MoreVertIcon" alt="MoreVertIcon" />
            </template>
            <va-dropdown-content>
              <!-- <p class="task-action-option" @click="handleEdit(item)">
                                <va-icon name="edit" /> Edit
                            </p>
                            <p class="task-action-option" @click="confirmDelete(item)">
                                <va-icon name="delete" /> Delete
                            </p> -->
              <p class="task-action-option" @click="viewProfile(item)">
                <va-icon name="person" /> View Profile
              </p>
            </va-dropdown-content>
          </va-dropdown>
        </td>
      </template>
    </v-data-table-server>

    <va-modal v-model="deleteDialog" title="Confirm Deletion">
      <h1>Are you sure you want to delete this item?</h1>
    </va-modal>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" scoped></style>
