import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44d63231"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table" }
const _hoisted_2 = { class: "col-8" }
const _hoisted_3 = { class: "table" }
const _hoisted_4 = { class: "table-header" }
const _hoisted_5 = { class: "header-row" }
const _hoisted_6 = { class: "header-text" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_draggable = _resolveComponent("draggable")!
  const _component_rawDisplayer = _resolveComponent("rawDisplayer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("table", _hoisted_3, [
        _createElementVNode("thead", _hoisted_4, [
          _createElementVNode("tr", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
              return (_openBlock(), _createElementBlock("th", {
                scope: "col",
                key: header.feild,
                style: _normalizeStyle({ minWidth: header.width, verticalAlign: 'middle' })
              }, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(header.name), 1)
              ], 4))
            }), 128))
          ])
        ]),
        _createVNode(_component_draggable, {
          modelValue: _ctx.list,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
          tag: "tbody",
          class: "draggable-body",
          "item-key": (row) => row.id
        }, {
          item: _withCtx(({ element }) => [
            _createElementVNode("tr", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
                return (_openBlock(), _createElementBlock("td", {
                  key: header.feild
                }, [
                  (header.feild === 'icon')
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "icon",
                        src: element[header.feild]
                      }, null, 8, _hoisted_7))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.setClass(header.feild))
                      }, _toDisplayString(element[header.feild]), 3))
                ]))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "item-key"])
      ])
    ]),
    _createVNode(_component_rawDisplayer, {
      class: "col-3",
      value: _ctx.list,
      title: "List"
    }, null, 8, ["value"])
  ]))
}