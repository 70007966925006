import { PropType, defineComponent } from 'vue'
import FileCard from '@/components/fileCard/index.vue'
import FolderCard from '@/components/folderCard/index.vue'

export default defineComponent({
  name: 'files-grid',
  props: {
    files: {
      type: [] as PropType<
        {
          id: number
          name: string
          size: string
          labels: string[]
          user: { name: string; id: number; imageUrl: string }
          type: string
          image: string
          monetized: boolean
          previewUrl?: string
        }[]
      >,
      required: false,
      default: () => [],
    },
    folders: {
      type: [] as PropType<
        {
          id: number
          name: string
          size: string
          labels: string[]
          user: { name: string; id: number; imageUrl: string }
        }[]
      >,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  components: {
    FileCard,
    FolderCard,
  },
  methods: {},
  mounted() {
    console.log(this.folders)
    console.log(this.files)
  },
})
