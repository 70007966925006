export function getMonthAndYearFromDate(dateString: string) {
  const date = new Date(dateString)
  const month = date.toLocaleString('default', { month: 'long' })
  const year = date.getFullYear()
  return { month, year }
}

export function removeNullableFields(obj: any) {
  // Iterate through the object entries
  for (const key in obj) {
    // If the value is an object or array, recursively clean it
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      removeNullableFields(obj[key])

      // If it's an array, filter out empty objects or empty strings
      if (Array.isArray(obj[key])) {
        obj[key] = obj[key].filter(
          (item: any) =>
            (typeof item === 'object' && Object.keys(item).length > 0) ||
            item !== ''
        )
      }
      // If the object is now empty after cleaning, delete the property
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key]
      }
    }
    // Remove if the value is null, undefined, or an empty string
    if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
      delete obj[key]
    }
  }
  return obj
}

export function truncateText(text: string, charLimit: number) {
  if (!text) return ''
  return text.length > charLimit ? text.substring(0, charLimit) + '...' : text
}
