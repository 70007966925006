Copy code
<template>
  <div class="table">
    <div class="col-8">
      <table class="table">
        <thead class="table-header">
          <tr class="header-row">
            <th
              v-for="header in headers"
              :key="header.field"
              :style="{ width: header.width }"
            >
              {{ header.name }}
            </th>
          </tr>
        </thead>
        <draggable v-model="list" tag="tbody" item-key="id">
          <template #item="{ element }">
            <tr>
              <td
                v-for="header in headers"
                :key="header.field"
                :style="{ width: header.width }"
                :class="header.field === 'approved' ? 'approved' : 'cell'"
              >
                <img
                  class="icon"
                  v-if="header.field === 'approved' && element[header.field]"
                  src="@/assets/icons/approved-icon.svg"
                />
                <div
                  v-else-if="
                    header.field === 'approved' && !element[header.field]
                  "
                ></div>
                <div v-else class="cell">{{ element[header.field] }}</div>
              </td>
            </tr>
          </template>
        </draggable>
      </table>
    </div>
    <rawDisplayer class="col-3" :value="list" title="List" />
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style scoped lang="scss" src="./styles.scss"></style>
