import { defineComponent } from 'vue'
import Tree from '@/components/tree/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import Accordion from '@/components/FirmTabs/accordion/index.vue'
import TimeSheet from '@/components/FirmTabs/TimeSheet/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'

export default defineComponent({
  name: 'HomePage',
  components: {
    Tree,
    SharedHeader,
    TabsBar,
    Accordion,
    ComingSoon,
    TimeSheet,
  },
  data() {
    return {
      tabs: [
        { id: 0, title: 'Time Sheet' },
        { id: 1, title: 'Organizational Structure' },
        { id: 2, title: 'Workspaces, Teams & Members' },
        { id: 3, title: 'Files' },
        { id: 4, title: 'About Us' },
        { id: 5, title: 'Hiring' },
        { id: 6, title: 'Dashboard' },
        { id: 7, title: 'Announcements' },
      ],
      activeTabId: 0,
      activeTab: 'Time Sheet',
      user: {
        id: 1,
        name: 'John Doe',
        avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
        company: 'Company Name',
        title: 'CEO',
      },
      users: [
        {
          id: 1,
          name: 'John Doe',
          avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
          company: 'Company Name',
          title: 'Accountant',
        },
        {
          id: 2,
          name: 'Jane Doe',
          avatar: 'https://randomuser.me/api/portraits/women/2.jpg',
          company: 'Company Name',
          title: 'Designer',
        },
        {
          id: 3,
          name: 'Mike Doe',
          avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
          company: 'Company Name',
          title: 'Developer',
        },
      ],
    }
  },
  methods: {
    changeTab(tab: { title: string; id: number }) {
      this.activeTabId = tab.id
      this.activeTab = tab.title
      this.$router.push({ query: { tab: tab.title } })
    },
  },
})
