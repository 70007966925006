import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30e1bac8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-container" }
const _hoisted_2 = { class: "table-header" }
const _hoisted_3 = { class: "table-item-title" }
const _hoisted_4 = { class: "table-item-value" }
const _hoisted_5 = { class: "table-total-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.header), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id,
        class: "table-item"
      }, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(item.title), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(item.value), 1)
      ]))
    }), 128)),
    _createElementVNode("div", {
      class: _normalizeClass(["table-total", { 'without-border': _ctx.isLast }])
    }, [
      _createElementVNode("p", _hoisted_5, "total " + _toDisplayString(_ctx.header), 1),
      _createElementVNode("p", {
        class: _normalizeClass(["table-total-value", { 'red-text': _ctx.isRed, 'green-text': _ctx.isGreen }])
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$stores.financial.currency), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.totalDisplayValue), 1)
      ], 2)
    ], 2)
  ]))
}