import { ContactType, getContacts } from '@/services/contacts'
import { getNextInvoiceNumber } from '@/services/invoices'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'

export default defineComponent({
  name: 'AddInvoiceForm',
  props: {
    addInvoiceLoading: {
      type: Boolean,
      default: false,
    },
    isAddInvoice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')

    return {
      isAddInvoiceBool: this.isAddInvoice,
      isValid,
      validate,
      reset,
      resetValidation,
      searchQuery: '',
      contactsLoading: false,
      invoiceNumberLoading: false,
      invoice_number: '',
      form: {
        individual_contact_id: '',
        invoice_number: '',
        status: 'pending',
        customer_name: '',
        customer_email: '',
        invoice_date: new Date(),
        payment_due_date: new Date(),
        terms_and_conditions_note: '',
        payment_details_note: '',
        signature_note: '',
        general_note: '',
        currency: 'UAE',
        discount_type: 'percentage',
        discount_type_amount: 0,
        tax: 0,
        invoice_items_attributes: [
          {
            billable_type: '',
            billable_id: '',
            description: '',
            quantity: 1 as any,
            unit_price: 3.6725 as any,
            tax_percentage: 0 as any,
            discount_type: 'percentage',
            discount_type_amount: 0 as any,
          },
        ],
      },
      contactPage: 1,
      contactSearchKeyword: '',
      contactsItemPerPage: 10,
      contactTotalItems: 0,
      contacts: [] as any,
      debounceTimeout: null as ReturnType<typeof setTimeout> | null, // Holds the debounce timeout ID
      exchangeRate: 3.6725,
    }
  },
  methods: {
    async handleGetNextInvoiceNumber() {
      this.invoiceNumberLoading = true
      const response = await getNextInvoiceNumber()
      if (response.success) {
        this.invoice_number = response.data.next_invoice_number
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.invoiceNumberLoading = false
    },
    //////////////////////////////////////////////////////////////////////////////////////

    async handleGetContacts() {
      this.contactsLoading = true

      const response = await getContacts({
        contact_type: ContactType.IndividualContact,
        per_page: this.contactsItemPerPage,
        page: this.contactPage,
        keyword: this.contactSearchKeyword,
      })
      if (response.success) {
        this.contacts = response.data.list
        this.contactTotalItems =
          response.data.total_pages * this.contactsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.contactsLoading = false
    },
    handleSearchContacts(search: string) {
      this.contactSearchKeyword = search
      console.log(this.contactSearchKeyword)

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts()
      }, 500)
    },

    handleSelectContact(contact: any) {
      console.log({ contact })
      this.form.customer_email =
        contact?.email_addresses && contact?.email_addresses[0]?.email_address
    },

    //////////////////////////////////////////////////////////////////////////////////////

    handleToggleAddModal() {
      this.$emit('handleToggleAddModal')
    },
    handleFormSubmit() {
      console.log('Form submitted')
    },
    addInvoiceItem() {
      this.form.invoice_items_attributes.push({
        billable_type: '',
        billable_id: '',
        description: '',
        quantity: 1,
        unit_price: 0,
        tax_percentage: 12,
        discount_type: 'percentage',
        discount_type_amount: 0 as any,
      })
    },
    removeInvoiceItem(index: number) {
      if (this.form.invoice_items_attributes.length > 1) {
        this.form.invoice_items_attributes.splice(index, 1)
      }
    },
    async handleCreateInvoice() {
      this.$emit('handleCreateInvoice', this.form)
    },

    totalAmount(
      quantity: string | number,
      price: string | number,
      tax_percentage: string | number
    ) {
      const subtotal = +quantity * +price
      const taxAmount = subtotal * (+tax_percentage / 100)
      const total = subtotal + taxAmount

      return Math.round(total * 1000) / 1000
    },
    computedSubTotal() {
      let grandTotal = this.form.invoice_items_attributes.reduce(
        (total, item) => {
          const quantity = parseFloat(item.quantity) || 0
          const unit_price = parseFloat(item.unit_price) || 0
          const tax_percentage = parseFloat(item.tax_percentage) || 0

          const subtotal = quantity * unit_price
          const tax_amount = subtotal * (tax_percentage / 100)
          const item_total = subtotal + tax_amount

          return total + item_total
        },
        0
      )

      // Convert to selected currency if needed
      if (this.form.currency === 'USD') {
        grandTotal = grandTotal / this.exchangeRate
      }

      return parseFloat(grandTotal.toFixed(4))
    },
    computedGrandTotal() {
      const subTotal = this.computedSubTotal()
      let discountedTotal

      if (this.form.discount_type === 'percentage') {
        discountedTotal =
          subTotal - (subTotal * this.form.discount_type_amount) / 100
      } else {
        discountedTotal = subTotal - this.form.discount_type_amount
      }

      discountedTotal = Math.max(0, discountedTotal)
      discountedTotal = parseFloat(discountedTotal.toFixed(4))

      return `${discountedTotal} ${this.form.currency}`
    },
    toggleItemSwitch(index: number): void {
      console.log([index])

      this.form.invoice_items_attributes[index].discount_type =
        this.form.invoice_items_attributes[index].discount_type === 'amount'
          ? 'percentage'
          : 'amount'
    },
    toggleInvoiceSwitch() {
      this.form.discount_type =
        this.form.discount_type === 'amount' ? 'percentage' : 'amount'
    },
  },
  watch: {
    isAddInvoice: {
      handler(data) {
        this.isAddInvoiceBool = data
        if (data) {
          this.handleGetContacts()
          this.handleGetNextInvoiceNumber()
        }
      },
    },
    form: {
      handler(data) {
        console.log({
          form: data,
        })
      },
      deep: true,
    },
  },
})
