import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9337ec56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "shared-header-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaIcon = _resolveComponent("VaIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", {
      style: _normalizeStyle({ width: _ctx.titleWidth })
    }, [
      (_ctx.flag)
        ? (_openBlock(), _createBlock(_component_VaIcon, {
            key: 0,
            name: _ctx.flag,
            size: "large",
            color: "primary"
          }, null, 8, ["name"]))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
    ], 4),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}