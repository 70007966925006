import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-094ca377"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kanban-row" }
const _hoisted_2 = ["data-stage-index"]
const _hoisted_3 = {
  key: 0,
  class: "kanban-list-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaSkeleton = _resolveComponent("VaSkeleton")!
  const _component_TaskCard = _resolveComponent("TaskCard")!
  const _component_LegalMatterCard = _resolveComponent("LegalMatterCard")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stages, (stage, stageIndex) => {
      return (_openBlock(), _createElementBlock("div", {
        key: stage?.id,
        "data-stage-index": stageIndex,
        class: "kanban-column"
      }, [
        _createElementVNode("div", {
          class: "kanban-column-header",
          style: _normalizeStyle({
          color: stage?.color || 'black',
          borderBottom: '0.5rem solid ' + (stage?.color || 'red'),
        })
        }, [
          _createElementVNode("h3", null, _toDisplayString(stage?.name), 1),
          _createTextVNode(" " + _toDisplayString(stage.section_stage_items_count), 1)
        ], 4),
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_VaSkeleton, {
                style: {"border-radius":"8px"},
                inline: "",
                width: "269px",
                height: "153px"
              }),
              _createVNode(_component_VaSkeleton, {
                style: {"border-radius":"8px"},
                inline: "",
                width: "269px",
                height: "153px"
              })
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_draggable, {
          modelValue: _ctx.localStages[stageIndex].tickets,
          "onUpdate:modelValue": ($event: any) => ((_ctx.localStages[stageIndex].tickets) = $event),
          group: "tickets",
          onEnd: _ctx.onDragEnd,
          class: "kanban-list-group",
          "data-stage-id": stage.id
        }, _createSlots({ _: 2 }, [
          (_ctx.isTasks)
            ? {
                name: "item",
                fn: _withCtx(({ element }) => [
                  _createVNode(_component_TaskCard, { task: element }, null, 8, ["task"])
                ]),
                key: "0"
              }
            : (_ctx.isLegalMatters)
              ? {
                  name: "item",
                  fn: _withCtx(({ element }) => [
                    _createVNode(_component_LegalMatterCard, { matter: element }, null, 8, ["matter"])
                  ]),
                  key: "1"
                }
              : undefined
        ]), 1032, ["modelValue", "onUpdate:modelValue", "onEnd", "data-stage-id"])
      ], 8, _hoisted_2))
    }), 128))
  ]))
}