<template>
  <div>
    <SharedHeader
      titleWidth="30rem"
      :title="`INVOICE NUMBER: #${form.invoice_number}`"
    >
      <div class="sub-SharedHeader">
        <div class="sub-SharedHeader-left"></div>
        <div class="sub-SharedHeader-right"></div>
      </div>
    </SharedHeader>
    <!-- <h3 class="title">INVOICE NUMBER: #{{ form.invoice_number }}</h3> -->
    <VaForm
      ref="form"
      class="create-invoice-form"
      @submit.prevent="handleFormSubmit"
    >
      <div class="scrollable-container">
        <div class="create-invoice-form-container">
          <div style="width: 76%">
            <VaSelect
              background="#ECF0F1"
              v-model="form.contact"
              style="width: 70%; height: 1.5rem; margin-bottom: 1.8rem"
              label="Paid To"
              searchable
              :highlight-matched-text="false"
              :options="contacts"
              :text-by="
                (option) =>
                  `${option?.specific_data?.individual_first_name} ${option?.specific_data?.individual_last_name}`
              "
              :loading="contactsLoading"
              @update:search="handleSearchContacts"
              @update:modelValue="(option) => handleSelectContact(option)"
            />
          </div>
          <VaInput
            background="#ECF0F1"
            v-model="form.invoice_number"
            style="width: 22%"
            label="Invoice number"
            disabled
          />
          <div style="width: 76%">
            <VaInput
              background="#ECF0F1"
              v-model="form.customer_name"
              style="width: 70%"
              label="Title"
              placeholder="Invoice Title"
              requiredMark
              :rules="[(value) => value && value.length > 0]"
            />
          </div>
          <div class="form-invoice-date">
            <VaDateInput
              placeholder="MM/DD/YYYY"
              label="Invoice Date"
              background="#ECF0F1"
              v-model="form.invoice_date"
              manual-input
            />
          </div>
          <div style="width: 76%">
            <VaInput
              background="#ECF0F1"
              v-model="form.customer_email"
              style="width: 70%"
              label="customer email"
              placeholder="Customer Email"
              requiredMark
              :rules="[
                (value: string) => !!value ,
                (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) 
              ]"
            />
          </div>
          <div class="form-payment-due">
            <VaDateInput
              placeholder="MM/DD/YYYY"
              label="payment due"
              background="#ECF0F1"
              v-model="form.payment_due_date"
              manual-input
            />
          </div>
        </div>

        <VaDivider inset />

        <div class="items-container">
          <table class="invoice-table">
            <!-- Header Section -->
            <thead>
              <tr class="invoice-header">
                <th class="invoice-item-header">Items</th>
                <th class="invoice-quantity-header">Quantity</th>
                <th class="invoice-price-header">Price</th>
                <th class="invoice-amount-header">Amount</th>
                <th class="invoice-amount-header">Tax</th>
                <th class="invoice-amount-header">Discount Type</th>
                <th class="invoice-amount-header">Discount</th>
                <th class="invoice-delete-header"></th>
              </tr>
            </thead>

            <!-- Items Section -->
            <tbody>
              <tr
                v-for="(item, index) in form.invoice_items"
                :key="index"
                class="invoice-row"
              >
                <td class="invoice-item-cell">
                  <VaInput
                    background="#fff"
                    v-model="item.description"
                    class="invoice-item-input"
                    placeholder="Enter item description"
                    :rules="[(value) => value && value.length > 0]"
                  />
                </td>
                <td class="invoice-quantity-cell">
                  <VaInput
                    background="#fff"
                    v-model="item.quantity"
                    type="number"
                    min="1"
                    class="invoice-quantity-input"
                  />
                </td>
                <td class="invoice-price-cell">
                  <VaInput
                    background="#fff"
                    v-model="item.unit_price"
                    type="number"
                    class="invoice-price-input"
                  />
                </td>
                <td class="invoice-amount-cell">
                  {{
                    totalAmount(
                      item.quantity,
                      item.unit_price,
                      item.tax_percentage
                    )
                  }}
                </td>
                <td class="invoice-quantity-cell">
                  <VaInput
                    background="#fff"
                    v-model="item.tax_percentage"
                    type="number"
                    class="invoice-quantity-input"
                    ><template #prependInner>
                      <VaIcon name="percent" color="secondary" /> </template
                  ></VaInput>
                </td>
                <td class="invoice-quantity-cell">
                  <div class="switch-container">
                    <div
                      @click="toggleItemSwitch(index)"
                      :class="[
                        'switch',
                        { 'switch-on': item.discount_type === 'amount' },
                      ]"
                    >
                      <div class="switch-handle"></div>
                    </div>
                    <span class="switch-label">{{
                      item.discount_type === 'amount' ? 'Amnt' : '%'
                    }}</span>
                  </div>
                </td>
                <td class="invoice-quantity-cell">
                  <VaInput
                    background="#fff"
                    v-model="item.discount_type_amount"
                    type="number"
                    class="invoice-quantity-input"
                  >
                    <template
                      v-if="item.discount_type === 'percentage'"
                      #prependInner
                    >
                      <VaIcon name="percent" color="secondary" />
                    </template>
                  </VaInput>
                </td>
                <td class="invoice-delete-cell">
                  <VaIcon
                    name="delete"
                    class="invoice-delete-icon"
                    color="#bbb"
                    @click="removeInvoiceItem(index)"
                  />
                </td>
              </tr>
              <!-- Item Tax Percentage Section -->
              <tr class="invoice-tax-row"></tr>
              <!-- Add Item Button -->
              <tr class="invoice-add-item-row">
                <td colspan="5" class="invoice-add-item-cell">
                  <va-button
                    @click="addInvoiceItem"
                    class="add-item-button"
                    icon="add_circle"
                    color="primary"
                    preset="secondary"
                  >
                    Add an item
                  </va-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <VaDivider />
      </div>

      <div class="bottom-section">
        <div class="subtotal-price">
          <p>subtotal</p>
          <span>{{ computedSubTotal() }}</span>
        </div>
        <div class="total-price">
          <div class="switch-container">
            <span class="switch-label1">{{
              form.discount_type === 'amount' ? 'Amnt' : '%'
            }}</span>
            <div
              @click="toggleInvoiceSwitch()"
              :class="[
                'switch',
                { 'switch-on': form.discount_type === 'amount' },
              ]"
            >
              <div class="switch-handle"></div>
            </div>
          </div>
          <p>Discount</p>
          <VaInput
            style="width: 6rem"
            background="#fff"
            v-model="form.discount_type_amount"
            type="number"
            ><template #prependInner>
              <VaIcon
                v-if="form.discount_type === 'percentage'"
                name="percent"
                color="secondary"
              /> </template
          ></VaInput>
        </div>
        <!-- <div class="total-price">
          <p>Tax</p>
          <VaInput
            style="width: 6rem"
            background="#fff"
            v-model="form.tax"
            type="number"
            ><template #prependInner>
              <VaIcon name="percent" color="secondary" /> </template
          ></VaInput>
        </div> -->
        <div class="total-price">
          <h2>Total</h2>
          <div style="width: 5rem">
            <VaSelect
              background="#fff"
              v-model="form.currency"
              :options="['USD', 'UAE']"
            />
          </div>
          <span>{{ computedGrandTotal() }}</span>
        </div>
      </div>

      <div class="text-area-section">
        <div class="payment-group">
          <VaSelect
            style="width: 33%"
            label="payment due"
            placeholder="Select Payment Due"
            background="#fff"
            v-model="form.payment_details_note"
            :options="['Due Today', 'Net 15', 'Net 30', 'Net 60']"
          />
          <VaSelect
            style="width: 33%"
            label="payment address"
            placeholder="Select Payment Address"
            background="#fff"
            v-model="form.signature_note"
            :options="['UAE BANK', 'US BANK']"
          />
          <VaSelect
            style="width: 33%"
            label="terms"
            placeholder="Select Terms"
            background="#fff"
            v-model="form.terms_and_conditions_note"
            :options="['Term 1', 'Term 2', 'Term 3']"
          />
        </div>
        <VaTextarea
          max-rows="7"
          v-model="form.general_note"
          label="general note"
          placeholder="Add your note here..."
          style="width: 100%; height: 10rem"
          autosize
        />
      </div>

      <VaButton @click="printInvoice" color="info" icon="visibility">
        Preview Invoice
      </VaButton>
      <!-- <VaButton @click="printInvoiceDirectly" color="info" icon="visibility">
        Preview Invoice
      </VaButton> -->

      <div class="add-contact-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleGetInvoiceByID"
          >Reset</VaButton
        >
        <VaButton
          @click="validate() && handleUpdateInvoice()"
          :loading="updateLoading"
          >Save</VaButton
        >
      </div>
    </VaForm>
    <div style="display: none">
      <div
        id="invoice-preview"
        class="invoice-preview-container"
        style="
          font-family: Arial, sans-serif;
          padding: 20px;
          border: 1px solid #ddd;
          display: flex;
          flex-direction: column;
        "
      >
        <!-- Header Section -->
        <div
          class="invoice-header"
          style="
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
          "
        >
          <div class="invoice-title">
            <h2 style="font-size: 36px; color: #3498db">Invoice</h2>
          </div>
          <div class="invoice-meta" style="text-align: right">
            <p><strong>Date:</strong> {{ form.invoice_date }}</p>
            <p><strong>Invoice #:</strong> {{ form.invoice_number }}</p>
          </div>
        </div>

        <!-- Address Section -->
        <div
          class="invoice-address"
          style="
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
          "
        >
          <div class="from" style="width: 45%">
            <p><strong>From:</strong></p>
            <p>____________________________________</p>
            <p>____________________________________</p>
          </div>
          <div class="bill-to" style="width: 45%">
            <p><strong>Paid To:</strong></p>
            <p>{{ form.customer_name }}</p>
            <p>{{ form.customer_email }}</p>
          </div>
        </div>

        <!-- Items Section -->
        <div class="invoice-items" style="margin-top: 20px">
          <table style="width: 100%; border-collapse: collapse">
            <thead>
              <tr style="background-color: #3498db; color: white">
                <th style="border: 1px solid #ddd; padding: 10px">
                  Description
                </th>
                <th style="border: 1px solid #ddd; padding: 10px">Quantity</th>
                <th style="border: 1px solid #ddd; padding: 10px">Price</th>
                <th style="border: 1px solid #ddd; padding: 10px">Tax</th>
                <th style="border: 1px solid #ddd; padding: 10px">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in form.invoice_items" :key="index">
                <td style="border: 1px solid #ddd; padding: 10px">
                  {{ item.description }}
                </td>
                <td
                  style="
                    border: 1px solid #ddd;
                    padding: 10px;
                    text-align: center;
                  "
                >
                  {{ item.quantity }}
                </td>
                <td
                  style="
                    border: 1px solid #ddd;
                    padding: 10px;
                    text-align: right;
                  "
                >
                  {{ item.unit_price }}
                </td>
                <td
                  style="
                    border: 1px solid #ddd;
                    padding: 10px;
                    text-align: right;
                  "
                >
                  {{ item.tax_percentage }}%
                </td>
                <td
                  style="
                    border: 1px solid #ddd;
                    padding: 10px;
                    text-align: right;
                  "
                >
                  {{
                    totalAmount(
                      item.quantity,
                      item.unit_price,
                      item.tax_percentage
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Summary Section -->
        <div
          class="invoice-summary"
          style="
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div class="terms-and-conditions" style="width: 45%">
            <p><strong>Terms and Conditions:</strong></p>
            <p>{{ form.terms_and_conditions_note }}</p>
          </div>
          <div class="payment-summary" style="width: 45%">
            <table style="width: 100%; border-collapse: collapse">
              <tr>
                <td style="padding: 10px">Subtotal:</td>
                <td style="text-align: right">{{ computedGrandTotal() }}</td>
              </tr>
              <!-- <tr>
                  <td style="padding: 10px">Tax Rate:</td>
                  <td style="text-align: right">{{ taxRate }}%</td>
                </tr>
                <tr>
                  <td style="padding: 10px">Tax:</td>
                  <td style="text-align: right">{{ computedTax }}</td>
                </tr> -->
              <tr style="background-color: #3498db; color: white">
                <td style="padding: 10px">
                  <strong>Total Amount Due:</strong>
                </td>
                <td style="text-align: right">
                  <strong>{{ computedGrandTotal() }}</strong>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Barcode Section -->
        <div
          class="barcode-section"
          style="margin-top: 20px; text-align: center"
        >
          <!-- <img
              src="{{ barcodeSource }}"
              alt="Invoice Barcode"
              style="width: 200px; height: 50px; margin-top: 20px"
            /> -->
          <!-- <VaIcon
              name="qr_code"
              size="10rem"
              style="width: 500px; height: 50px; margin-top: 20px"
            /> -->
        </div>

        <!-- Payment Terms Section -->
        <div
          class="payment-terms"
          style="
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div style="width: 45%">
            <p><strong>Payment Details:</strong></p>
            <p>{{ form.payment_details_note }}</p>
          </div>
          <div style="width: 45%">
            <p><strong>Signature:</strong></p>
            <p>{{ form.signature_note }}</p>
          </div>
        </div>
        <div style="width: 100%">
          <p><strong>Notes:</strong></p>
          <p>{{ form.general_note }}</p>
        </div>
        <img
          id="bottom-logo"
          :src="tlaLogoImage"
          alt="Logo"
          width="100"
          height="100"
          style="position: fixed; bottom: 20px; left: 20px"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
