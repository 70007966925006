import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e3415380"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kanban-row" }
const _hoisted_2 = {
  key: 0,
  class: "kanban-list-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaSkeleton = _resolveComponent("VaSkeleton")!
  const _component_ContactCard = _resolveComponent("ContactCard")!
  const _component_LegalMatterCard = _resolveComponent("LegalMatterCard")!
  const _component_TaskCard = _resolveComponent("TaskCard")!
  const _component_FinancialCard = _resolveComponent("FinancialCard")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localGroups, (group) => {
      return (_openBlock(), _createElementBlock("div", {
        key: group.id,
        class: "kanban-column"
      }, [
        _createElementVNode("div", {
          class: "kanban-column-header",
          style: _normalizeStyle({
          color: group.color,
          borderBottom: '0.5rem solid ' + group.color,
        })
        }, [
          _createElementVNode("h3", null, _toDisplayString(group.title), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.groupList[group.id]?.length), 1)
        ], 4),
        (_ctx.isLoadingLocal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_VaSkeleton, {
                style: {"border-radius":"8px"},
                inline: "",
                width: "269px",
                height: "153px"
              }),
              _createVNode(_component_VaSkeleton, {
                style: {"border-radius":"8px"},
                inline: "",
                width: "269px",
                height: "153px"
              })
            ]))
          : (_openBlock(), _createBlock(_component_draggable, {
              key: 1,
              modelValue: _ctx.groupList[group.id],
              "onUpdate:modelValue": ($event: any) => ((_ctx.groupList[group.id]) = $event),
              itemKey: "name",
              onEnd: _ctx.onEventDrop,
              class: "kanban-list-group"
            }, _createSlots({ _: 2 }, [
              (_ctx.isContacts)
                ? {
                    name: "item",
                    fn: _withCtx(({ element }) => [
                      _createVNode(_component_ContactCard, { contact: element }, null, 8, ["contact"])
                    ]),
                    key: "0"
                  }
                : (_ctx.isLegalMatters)
                  ? {
                      name: "item",
                      fn: _withCtx(({ element }) => [
                        _createVNode(_component_LegalMatterCard, {
                          matter: element,
                          showCheckbox: _ctx.isSelect,
                          selectedCards: _ctx.selectedCards,
                          "onUpdate:selectedCards": _ctx.handleSelectedCards
                        }, null, 8, ["matter", "showCheckbox", "selectedCards", "onUpdate:selectedCards"])
                      ]),
                      key: "1"
                    }
                  : (_ctx.isTasks)
                    ? {
                        name: "item",
                        fn: _withCtx(({ element }) => [
                          _createVNode(_component_TaskCard, {
                            task: element,
                            showCheckbox: _ctx.isSelect,
                            selectedCards: _ctx.selectedCards,
                            "onUpdate:selectedCards": _ctx.handleSelectedCards
                          }, null, 8, ["task", "showCheckbox", "selectedCards", "onUpdate:selectedCards"])
                        ]),
                        key: "2"
                      }
                    : (_ctx.isFinancial)
                      ? {
                          name: "item",
                          fn: _withCtx(({ element }) => [
                            _createVNode(_component_FinancialCard, { expense: element }, null, 8, ["expense"])
                          ]),
                          key: "3"
                        }
                      : undefined
            ]), 1032, ["modelValue", "onUpdate:modelValue", "onEnd"]))
      ]))
    }), 128))
  ]))
}