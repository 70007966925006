<template>
  <v-navigation-drawer
    v-model="drawer"
    temporary
    location="right"
    style="min-width: 40%"
  >
    <div class="filters-drawer">
      <div class="filters-drawer__header">
        <h3>{{ $t("calendar.filters") }}</h3>
        <Close @click="closeDrawer" />
      </div>
      <div class="filters-drawer__boxes">
        <FilterBoxes />
      </div>
      <v-text-field
        prepend-inner-icon="mdi-magnify"
        :label="$t('general.search')"
        :loading="loading"
        hide-details
        single-line
        density="compact"
        @click:prepend-inner="search"
        variant="solo"
      ></v-text-field>
      <v-select
        :label="$t('views.contacts')"
        :items="['Label 1', 'Label 2', 'Label 3']"
        color="#b8c4d6"
        base-color="#b8c4d6"
        rounded="5px"
        hide-details
      ></v-select>
      <v-select
        :label="$t('views.legalMatters')"
        :items="['Label 1', 'Label 2', 'Label 3']"
        color="#b8c4d6"
        base-color="#b8c4d6"
        rounded="5px"
        hide-details
      ></v-select>
      <v-select
        :label="$t('general.locations')"
        :items="['Label 1', 'Label 2', 'Label 3']"
        color="#b8c4d6"
        base-color="#b8c4d6"
        rounded="5px"
        hide-details
      ></v-select>
      <v-select
        :label="$t('general.tags')"
        :items="['Label 1', 'Label 2', 'Label 3']"
        color="#b8c4d6"
        base-color="#b8c4d6"
        rounded="5px"
        hide-details
      ></v-select>
    </div>
  </v-navigation-drawer>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" />
