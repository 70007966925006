import { defineComponent, ref, computed, watch } from 'vue'

const StartDateImage = new URL(
  '@/assets/icons/start-date-icon.svg',
  import.meta.url
).href

export default defineComponent({
  name: 'DatePickerModalComponent',
  props: {
    date: {
      type: String,
      required: true,
    },
    shape: {
      type: String,
      default: 'button',
      validator: (value: string) => {
        return ['button', 'icon', 'button-icon'].includes(value)
      },
    },
  },
  setup(props, { emit }) {
    const showDatePicker = ref<boolean>(false)

    // Function to safely parse date strings
    const parseDate = (dateString: string): Date | null => {
      const parsedDate = new Date(dateString)
      return isNaN(parsedDate.getTime()) ? null : parsedDate
    }

    // Initialize selectedDate with parsed date
    const selectedDate = ref<any>(parseDate(props.date) ?? null)

    // Initialize time
    const hour = ref<number>(selectedDate.value?.getHours() ?? 0)
    const minute = ref<number>(selectedDate.value?.getMinutes() ?? 0)

    // Watch for changes in props.date and update selectedDate
    watch(
      () => props.date,
      (newDate: string) => {
        const parsedDate = parseDate(newDate)
        if (parsedDate) {
          selectedDate.value = parsedDate
          // Update hours and minutes when props change
          hour.value = parsedDate.getHours()
          minute.value = parsedDate.getMinutes()
        }
      },
      { immediate: true }
    )

    // Computed property to format the selected date for display, including time
    const formattedSelectedDate = computed(() => {
      const date = selectedDate.value
      if (date) {
        const optionsWithoutYear: Intl.DateTimeFormatOptions = {
          month: 'short',
          day: 'numeric',
        }
        const dateString = date.toLocaleDateString('en-US', optionsWithoutYear)
        const timeString = `${hour.value
          .toString()
          .padStart(2, '0')}:${minute.value.toString().padStart(2, '0')}`

        return `${dateString} ${timeString}`
      }
      return null
    })

    const resetDate = (): void => {
      selectedDate.value = null
      hour.value = 0
      minute.value = 0
      emit('update:date', '') // Reset the date and emit to parent
    }

    const saveDate = (): void => {
      const date = selectedDate.value

      if (date) {
        // Set the selected hour and minute directly without modifying the timezone
        date.setHours(hour.value)
        date.setMinutes(minute.value)
      }

      // No need to subtract timezone offset here
      const dateUTC = date ? date.toISOString() : null

      emit('update:date', {
        date: dateUTC,
      })

      showDatePicker.value = false // Close the date picker after saving
    }

    if (window.ResizeObserver) {
      const resizeObserver = new ResizeObserver(() => {
        // Empty callback to prevent the error
      })
      resizeObserver.observe(document.body) // Observe the body or a relevant container
    }

    return {
      showDatePicker,
      selectedDate,
      hour,
      minute,
      formattedSelectedDate,
      resetDate,
      saveDate,
      StartDateImage,
    }
  },
})
