<template>
  <div v-if="isTotal" class="row total-row">
    <p class="total-title">{{ title }}</p>
    <p
      class="total-value"
      :class="{ 'red-text': isRed, 'green-text': isGreen }"
    >
      <small class="currency">{{ $stores.financial.currency }}</small>
      {{ displayValue }}
    </p>
  </div>
  <div v-else class="row">
    <p class="title">{{ title }}</p>
    <p class="value" v-if="displayValue">
      <small class="currency">{{ $stores.financial.currency }}</small>
      {{ displayValue }}
    </p>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style scoped lang="scss" src="./styles.scss"></style>
