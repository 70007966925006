import { defineComponent } from 'vue'
import Button from '../ui/button/index.vue'

const addIcon = new URL('../../assets/icons/add-no-border.svg', import.meta.url)
  .href

const whiteAddIcon = new URL(
  '../../assets/icons/add-no-border-white.svg',
  import.meta.url
).href
const editColumnsIcon = new URL(
  '../../assets/icons/edit-columns.svg',
  import.meta.url
).href

const deleteIcon = new URL('../../assets/icons/delete.svg', import.meta.url)
  .href

export default defineComponent({
  name: 'ActionsBar',
  props: {
    showSelectButton: {
      type: Boolean,
      default: false,
    },
    showDownloadIcon: {
      type: Boolean,
      default: false,
    },
    showArchieveIcon: {
      type: Boolean,
      default: false,
    },
    showLabelIcon: {
      type: Boolean,
      default: false,
    },
    showShareIcon: {
      type: Boolean,
      default: false,
    },
    addButton: {
      type: String,
      default: '',
    },
    secondAddButton: {
      type: String,
      default: '',
    },
    editColumns: {
      type: Boolean,
      default: false,
    },
    deleteButton: {
      type: String,
      default: '',
    },
    updateButton: {
      type: String,
      default: '',
    },
    isFilterColumns: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      addIcon,
      editColumnsIcon,
      whiteAddIcon,
      deleteIcon,
      select: false,
      showDeleteButton: false,
      showUpdateButton: false,
    }
  },
  methods: {

    addContact() {
      console.log('AddContact')
    },
    addLead() {
      this.$emit('add')
      console.log('AddLead')
    },
    secondButtonAction() {
      this.$emit('SecondButtonAction')
      console.log('secondButtonAction')
    },
    addButtonAction() {
      this.$emit('addButtonAction')
      console.log('addButtonAction')
    }
  },
  emits: ['SecondButtonAction', 'add', 'addButtonAction', 'buttonClick',
    'buttonClick2'],
  components: {
    Button,
  },
  watch: {
    select(newValue) {
      console.log('select changed to:', newValue);
    }
  }
})
