<template>
  <div class="files-grid">
    <div v-for="folder in folders" :key="folder.id" class="folder">
      <FolderCard :folder="folder" />
    </div>
  </div>
  <div class="files-grid">
    <div v-for="file in files" :key="file.id" class="file">
      <FileCard :file="file" />
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
