import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "date-picker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaDatePicker = _resolveComponent("VaDatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VaDatePicker, {
      modelValue: _ctx.$stores.calendar.value[0],
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$stores.calendar.value[0]) = $event))
    }, null, 8, ["modelValue"])
  ]))
}