import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-068e0c0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "files-grid" }
const _hoisted_2 = { class: "files-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FolderCard = _resolveComponent("FolderCard")!
  const _component_FileCard = _resolveComponent("FileCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.folders, (folder) => {
        return (_openBlock(), _createElementBlock("div", {
          key: folder.id,
          class: "folder"
        }, [
          _createVNode(_component_FolderCard, { folder: folder }, null, 8, ["folder"])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file) => {
        return (_openBlock(), _createElementBlock("div", {
          key: file.id,
          class: "file"
        }, [
          _createVNode(_component_FileCard, { file: file }, null, 8, ["file"])
        ]))
      }), 128))
    ])
  ], 64))
}