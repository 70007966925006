import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4daa9bd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "header"
}
const _hoisted_2 = { class: "info-value" }
const _hoisted_3 = { class: "info-title" }
const _hoisted_4 = {
  id: "chart",
  class: "chart-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.info.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id,
              class: _normalizeClass(["info", { last: item.id === _ctx.info.length }])
            }, [
              _createElementVNode("p", _hoisted_2, _toDisplayString(item.value), 1),
              _createElementVNode("p", _hoisted_3, _toDisplayString(item.title), 1)
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_apexchart, {
        type: "bar",
        height: "350",
        options: _ctx.chartOptions,
        series: _ctx.series
      }, null, 8, ["options", "series"])
    ])
  ]))
}