<template>
  <SharedHeader titleWidth="30rem" title="Legal Library">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left"></div>
      <div class="sub-SharedHeader-right">

      </div>
    </div>
  </SharedHeader>
  <div class="container">
    <ComingSoon />
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style scoped lang="scss" src="./styles.scss"></style>