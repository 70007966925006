import { truncateText } from '@/lib/utils'
import { getAllCities, getAllCountries, getAllStates } from '@/services/address'
import { getTeamMates } from '@/services/company'
import { ContactType, getContacts } from '@/services/contacts'
import Cookies from 'js-cookie'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'

const UploadAvatarIcon = new URL(
  '@/assets/icons/upload-avatar-icon.svg',
  import.meta.url
).href

export default defineComponent({
  name: 'AddContactForm',
  props: {
    addContactLoading: {
      type: Boolean,
      default: false,
    },
    isAddContactForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')
    const form = {
      individual_title: '',
      individual_job_title: '',
      individual_first_name: '',
      individual_middle_name: '',
      individual_last_name: '',
      individual_suffix: '',
      individual_gender: 'male',
      individual_nationality: '',
      individual_date_of_birth: null,
      individual_tax_identification_number: '',
      email_addresses: [
        {
          label: 'Primary',
          email_address: '',
        },
      ],
      linkedin_url: '',
      phone_numbers: [
        {
          label: 'Primary',
          phone_number: '',
        },
      ],
      organization_contact_id: '' as any,
      relationship_owner_id: '',
      assigned_to_id: '',
      referred_by_id: '' as any,
      notes: '',

      contact_addresses_attributes: [
        {
          city_id: '',
          floor: '',
          zip_code: '',
          street_name: '',
          building_name: '',
          phone_number: '',
          google_maps_url: '',
        },
      ],
      province_state: '',
      country: '',
      address_tag: '',
      kyc_completed: false,
      kyc_notes: '',
      extra_notes: '',
      website_url: '',
    }
    return {
      isAddContactFormBool: this.isAddContactForm,
      isValid,
      validate,
      reset,
      resetValidation,
      form,
      sexOptions: ['male', 'female'],
      teamMembers: [] as any,
      files: [],
      kyc_documents: [],
      UploadAvatarIcon,
      isAddressAccordion: false,
      countriesLoading: false,
      statesLoading: false,
      citiesLoading: false,
      countries: [] as any,
      states: [] as any,
      cities: [] as any,
      contactsLoading: false,
      contactPage: 1,
      contactSearchKeyword: '',
      contactsItemPerPage: 10,
      contactTotalItems: 0,
      contacts: [] as any,

      organizationsLoading: false,
      organizationPage: 1,
      organizationSearchKeyword: '',
      organizationsItemPerPage: 10,
      organizationTotalItems: 0,
      organizations: [] as any,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
      truncateText,
    }
  },
  methods: {
    handleFormSubmit() {
      console.log('Form submitted')
    },
    toggleAddContactForm() {
      this.isAddContactFormBool = !this.isAddContactFormBool
      this.$emit('toggleAddContactForm')
    },
    handleCreateContact() {
      const { referred_by_id, organization_contact_id, ...updatedForm } =
        this.form

      this.$emit('handleCreateContact', {
        referred_by_id: referred_by_id.id,
        organization_contact_id: organization_contact_id.id,
        ...updatedForm,
      })
    },
    async handleGetMembers() {
      const user = Cookies.get('haqqUser') as string
      const response = await getTeamMates(
        JSON.parse(user)?.user_roles[0].roleable_id
      )
      if (response.success) {
        this.teamMembers = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
    },
    async handleGetAllCountries() {
      this.countriesLoading = true
      const response = await getAllCountries()

      if (response.success) {
        this.countries = response.data
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.countriesLoading = false
    },
    async handleGetState(value: any) {
      this.statesLoading = true
      const response = await getAllStates(value)

      if (response.success) {
        this.states = response.data
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.statesLoading = false
    },
    async handleGetCities(value: any) {
      this.citiesLoading = true
      const response = await getAllCities(value)

      if (response.success) {
        this.cities = response.data
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.citiesLoading = false
    },
    ////////////////////////////////////////////////////////////////
    async handleGetContacts() {
      this.contactsLoading = true

      const response = await getContacts({
        contact_type: ContactType.IndividualContact,
        per_page: this.contactsItemPerPage,
        page: this.contactPage,
        keyword: this.contactSearchKeyword,
      })
      if (response.success) {
        this.contacts = response.data.list
        this.contactTotalItems =
          response.data.total_pages * this.contactsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.contactsLoading = false
    },
    handleSearchContacts(search: string) {
      this.contactSearchKeyword = search

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts()
      }, 500)
    },
    /////////////////////////////////////////////////////////////////////
    async handleGetOrganizations() {
      this.organizationsLoading = true

      const response = await getContacts({
        contact_type: ContactType.OrganizationContact,
        per_page: this.organizationsItemPerPage,
        page: this.organizationPage,
        keyword: this.organizationSearchKeyword,
      })
      if (response.success) {
        this.organizations = response.data.list
        this.organizationTotalItems =
          response.data.total_pages * this.organizationsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.organizationsLoading = false
    },
    handleSearchOrganizations(search: string) {
      this.organizationSearchKeyword = search

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetOrganizations()
      }, 500)
    },
  },
  watch: {
    isAddContactForm: {
      handler(data) {
        this.isAddContactFormBool = data
        if (data) {
          this.handleGetMembers()
          this.handleGetAllCountries()
          this.handleGetContacts()
          this.handleGetOrganizations()
        }
      },
    },
    form: {
      handler(newForm) {
        console.log('Form updated:', newForm)
      },
      deep: true,
    },
  },
})
