import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "calendar-filters" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Close = _resolveComponent("Close")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter, index) => {
      return (_openBlock(), _createElementBlock("button", {
        class: "filter-box",
        key: index
      }, [
        _createTextVNode(_toDisplayString(filter) + " ", 1),
        _createVNode(_component_Close)
      ]))
    }), 128))
  ]))
}