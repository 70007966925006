import { apiRequest } from './apiUtils'

// useless
export const getAllMatters = () => {
  return apiRequest('get', '/legal_matters')
}

export const getMatterById = (id: string) => {
  return apiRequest('get', `/legal_matters/${id}`)
}

export const addMatter = (body: any) => {
  return apiRequest('post', '/legal_matters', body)
}

export const updateMatter = (id: string, body: any) => {
  return apiRequest('put', `/legal_matters/${id}`, body)
}

export const deleteMatter = (id: string) => {
  return apiRequest('delete', `/legal_matters/${id}`)
}
