import { defineComponent } from 'vue'
const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url
).href

export default defineComponent({
  name: 'AllContactsTable',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    totalItems: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      localTotalItems: this.totalItems,
      selectedRows: [],
      MoreVertIcon,
      deleteDialog: false,
      deleteItem: null,
      currentPage: 1,
      currentSortBy: [] as any,
      currentItemsPerPage: this.itemsPerPage,
      headers: [
        {
          title: '',
          align: 'start',
          key: 'assigned_to.avatar.url',
          sortable: false,
        },
        {
          title: 'First Name',
          align: 'start',
          key: 'specific_data.individual_first_name',
          sortable: true,
        },
        {
          title: 'Middle Name',
          align: 'start',
          key: 'specific_data.individual_middle_name',
          sortable: false,
        },
        {
          title: 'Last Name',
          align: 'start',
          key: 'specific_data.individual_last_name',
          sortable: true,
        },
        {
          title: 'Title',
          align: 'start',
          key: 'specific_data.individual_title',
          sortable: false,
        },
        {
          title: 'Email',
          align: 'start',
          key: 'email_addresses',
          sortable: false,
        },
        {
          title: 'Phone Number',
          align: 'start',
          key: 'phone_numbers',
          sortable: false,
        },
        {
          title: 'Province/State',
          align: 'start',
          key: 'contact_addresses[0].state.name',
          sortable: false,
        },
        {
          title: 'Country',
          align: 'start',
          key: 'contact_addresses[0].country.name',
          sortable: false,
        },
        {
          title: 'City',
          align: 'start',
          key: 'contact_addresses[0].city.name',
          sortable: false,
        },
        {
          title: 'Floor',
          align: 'start',
          key: 'contact_addresses[0].floor',
          sortable: false,
        },
        {
          title: 'Building',
          align: 'start',
          key: 'contact_addresses[0].building_name',
          sortable: false,
        },
        {
          title: 'Street',
          align: 'start',
          key: 'contact_addresses[0].street_name',
          sortable: false,
        },
        {
          title: 'Postal/Zip Code',
          align: 'start',
          key: 'contact_addresses[0].zip_code',
          sortable: false,
        },
        {
          title: 'Nationality',
          align: 'start',
          key: 'specific_data.individual_nationality',
          sortable: false,
        },
        {
          title: 'Relationship Owner',
          align: 'start',
          key: 'relationship_owner',
          sortable: false,
        },
        {
          title: 'Assigned To',
          align: 'start',
          key: 'assigned_to',
          sortable: false,
        },
        {
          title: 'Referred By',
          align: 'start',
          key: 'referred_by',
          sortable: false,
        },
        {
          title: 'Google Maps Link',
          align: 'start',
          key: 'contact_addresses[0].google_maps_url',
          sortable: false,
        },
        {
          title: 'Tax Identification Number',
          align: 'start',
          key: 'specific_data.individual_tax_identification_number',
          sortable: false,
        },
        {
          title: 'Personal Website',
          align: 'start',
          key: 'website_url',
          sortable: false,
        },
        // {
        //   title: 'Social Media Links',
        //   align: 'start',
        //   key: 'social_media_links',
        //   sortable: false,
        // },
        {
          title: 'Organization',
          align: 'start',
          key: 'organization',
          sortable: false,
        },
        {
          title: 'KYC Complete',
          align: 'start',
          key: 'kyc_completed',
          sortable: false,
        },
        {
          title: 'KYC Note',
          align: 'start',
          key: 'kyc_notes',
          sortable: false,
        },
        { title: 'Note', align: 'start', key: 'notes', sortable: false },
        { title: '', align: 'start', key: 'action', sortable: false },
      ],
    }
  },
  emits: ['selectedRows', 'loadMore'],
  methods: {
    handleEdit(item: any) {
      console.log('Editing', item)
    },
    confirmDelete(item: null) {
      this.deleteItem = item
      this.deleteDialog = true
    },
    confirmDeleteAction() {
      console.log('Deleting', this.deleteItem)
      this.deleteDialog = false
    },
    viewProfile(item: { id: any }) {
      this.$router.push(`/contacts/${item?.id}`)
    },
    getTagColor(tag: string) {
      if (tag === 'Home') return 'blue'
      if (tag === 'Work') return 'green'
      return 'gray'
    },
    handleLoadMore({
      page,
      itemsPerPage,
      sortBy,
    }: {
      page: number
      itemsPerPage: number
      sortBy: { key: string; order?: 'asc' | 'desc' }[]
    }) {
      const { currentPage, currentItemsPerPage, currentSortBy } = this

      // Check if there are any changes in pagination or sorting
      const hasPaginationChanged =
        page !== currentPage || itemsPerPage !== currentItemsPerPage
      const hasSortingChanged =
        JSON.stringify(sortBy) !== JSON.stringify(currentSortBy) // Compare sorting objects

      // Only emit if there's a change in pagination or sorting
      if (hasPaginationChanged || hasSortingChanged) {
        this.$emit('loadMore', { page, itemsPerPage, sortBy })

        // Update state after the change
        this.currentPage = page
        this.currentItemsPerPage = itemsPerPage
        this.currentSortBy = sortBy
      }
    },
  },
  watch: {
    selectedRows() {
      this.$emit('selectedRows', this.selectedRows)
    },
    totalItems(newValue) {
      this.localTotalItems = newValue
    },
  },
})
