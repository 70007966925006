<template>
  <div class="tabs-btns-container">
    <div class="tabs-bar">
      <div
        v-for="tab in tabs"
        :key="tab.id"
        class="tab"
        :class="{ active: tab.id === activeTabId }"
        @click="changeTab(tab.id)"
      >
        {{ tab.title }}
      </div>
    </div>
    <div class="btns">
      <Button
        button-text="Period"
        :text-color="'#2D3748'"
        :icon-before="Calendar"
        :custom-styles="{
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#fff',
          border: '0.5px solid #E2E8F0',
        }"
        :hover-styles="{
          border: '0.5px solid #2D3748',
        }"
        @click="$emit('period')"
      />
      <Button
        button-text="Contact 1"
        :text-color="'#2D3748'"
        :icon-before="Person"
        :custom-styles="{
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#fff',
          border: '0.5px solid #E2E8F0',
        }"
        :hover-styles="{
          border: '0.5px solid #2D3748',
        }"
        @click="$emit('Contact 1')"
      />
      <Button
        button-text="Contact 2"
        :text-color="'#2D3748'"
        :icon-before="Person"
        :custom-styles="{
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#fff',
          border: '0.5px solid #E2E8F0',
        }"
        :hover-styles="{
          border: '0.5px solid #2D3748',
        }"
        @click="$emit('Contact 2')"
      />
      <Button
        button-text="Currency"
        :text-color="'#2D3748'"
        :icon-before="Money"
        :custom-styles="{
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#fff',
          border: '0.5px solid #E2E8F0',
        }"
        :hover-styles="{
          border: '0.5px solid #2D3748',
        }"
        @click="$emit('Currency')"
      />
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
