import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import TasksTable from '@/components/Task/Table/index.vue'
import AddTaskModal from '@/components/Task/AddTaskModal/index.vue'
import DraggableColumns from '@/components/DraggableColumns/index.vue'
import KanbanInfiniteLoad from '@/components/KanbanInfiniteLoad/index.vue'
import UpdateLayoutColumns from '@/components/updateLayoutColumns/index.vue'
import TimeRange from '@/components/TimeRange/index.vue'
import DatePickerModal from '@/components/DatePickerModal/index.vue'
import { addTask, updateTask } from '@/services/tasks'
import { getAllSections } from '@/services/sections'
import { getAllStages, getStageItems } from '@/services/stages'

export default defineComponent({
  name: 'TasksPage',
  data() {
    return {
      flagMenuOpen: false,
      loadingUpdate: false,
      membersLoading: false,
      teamMembers: [] as any,
      sections: [] as any,
      groups: this.$stores.mockTasks.groups,
      stages: [] as any,
      activeTabId: 0,
      activeTab: '',
      activeView: 'board',
      selectedRows: [],
      isSelect: false,
      isShowUpdateLayoutModal: false,
      isLoading: false,
      isTasksLoading: false,
      isAddTask: false,
      isAddLoading: false,

      draggedTaskId: null,
    }
  },
  methods: {
    handleTicketMoved(data: any) {
      this.handleUpdateTaskToStage(data.id, data)
    },
    changeTab(tab: { title: string; id: number }) {
      this.activeTabId = tab.id
      this.activeTab = tab.title
    },
    changeView(view: string) {
      this.activeView = view
    },
    searchData(search: string) {
      console.log(search)
    },
    getSelectedRows(rows: any) {
      this.selectedRows = rows
    },
    updateGroups(newGroups: any) {
      this.groups = newGroups
    },
    updateTasks(newTasks: any) {
      // this.list = newTasks
    },
    handleApplyLayoutChanges() {
      console.log('Your changes applied well!')
    },

    handleToggleAddTaskModal() {
      this.isAddTask = !this.isAddTask
    },

    async handleCreateTask(form: any) {
      this.isAddLoading = true

      const response = await addTask({
        ...form,
        billable: form.billable.toString(),
      })
      if (response.success) {
        this.$vaToast.init({ message: 'Task Added!', color: 'success' })
        this.handleGetAllSections()
        this.isAddTask = false
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isAddLoading = false
    },

    async handleUpdateTaskToStage(id: any, data: any) {
      this.loadingUpdate = true
      const response = await updateTask(id, data)

      if (response.success) {
        // this.$vaToast.init({
        //   message: 'Task updates successfully!',
        //   color: 'success',
        // })
        // this.handleGetTaskByID()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loadingUpdate = false
    },

    async handleGetTasksByStage(id: number) {
      this.isTasksLoading = true
      const response = await getStageItems(this.activeTabId, id)
      const tickets = response.data.list
      const stage = this.stages.find((s: any) => s.id === id)
      if (stage) {
        stage.tickets = tickets
      }
      this.isTasksLoading = false
    },

    async handleGetStagesSection(section_id: any) {
      this.isLoading = true

      const response = await getAllStages(section_id)
      if (response.success) {
        this.stages = response.data.list
        await Promise.all(
          this.stages.map((stage: any) => this.handleGetTasksByStage(stage.id))
        )
        this.$stores.mockTasks.status = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isLoading = false
    },

    // async loadMoreTasks(section_id: any, stage_id: any) {
    //   this.handleGetTasksByStage(section_id, stage_id); // Fetch next page of tasks when requested
    // },

    async handleGetAllSections() {
      this.isLoading = true
      const response = await getAllSections('tasks')
      if (response.success) {
        this.sections = response.data.list
        this.activeTabId = response.data.list[0].id
        this.activeTab = response.data.list[0].name
        this.handleGetStagesSection(response.data.list[0].id)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.isLoading = false
    },
  },
  mounted() {
    this.handleGetAllSections()
  },
  components: {
    TasksTable,
    TabsBar,
    SearchAndViews,
    ActionsBar,
    SharedHeader,
    DraggableColumns,
    UpdateLayoutColumns,
    TimeRange,
    DatePickerModal,
    KanbanInfiniteLoad,
    AddTaskModal,
  },
  watch: {
    isAddTask: {
      handler(data) {
        console.log({ isAddTask: data })
      },
    },
  },
})
