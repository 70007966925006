<template>
  <div class="calendar-buttons">
    <UIButton
      class="filter-box"
      ref="allBtn"
      buttonText="all"
      :class="{ active: activeFilter === 'all' }"
      @click="setActiveFilter('all')"
    />
    <UIButton
      class="filter-box"
      :class="{ active: activeFilter === filter }"
      :ref="`${filter}Btn`"
      v-for="(filter, index) in filters"
      :key="index"
      :buttonText="filter"
      :icon-before="icons[index]"
      @click="setActiveFilter(filter)"
    />
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" />
