import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    expense: {
      type: Object as () => {
        id: number
        title: string
        amount: number
        account: string
      },
      required: true,
    },
  },
  mounted() {
    console.log('hello')
    console.log(this.expense)
  },
})
