<template>
  <div class="kanban-row">
    <div v-for="group in localGroups" :key="group.id" class="kanban-column">
      <div
        class="kanban-column-header"
        :style="{
          color: group.color,
          borderBottom: '0.5rem solid ' + group.color,
        }"
      >
        <h3>{{ group.title }}</h3>
        {{ groupList[group.id]?.length }}
      </div>
      <div v-if="isLoadingLocal" class="kanban-list-group">
        <VaSkeleton
          style="border-radius: 8px"
          inline
          width="269px"
          height="153px"
        />
        <VaSkeleton
          style="border-radius: 8px"
          inline
          width="269px"
          height="153px"
        />
      </div>
      <draggable
        v-else
        v-model="groupList[group.id]"
        itemKey="name"
        @end="onEventDrop"
        class="kanban-list-group"
      >
        <template v-if="isContacts" #item="{ element }">
          <ContactCard :contact="element" />
        </template>
        <template v-else-if="isLegalMatters" #item="{ element }">
          <LegalMatterCard
            :matter="element"
            :showCheckbox="isSelect"
            :selectedCards="selectedCards"
            @update:selectedCards="handleSelectedCards"
          />
        </template>
        <template v-else-if="isTasks" #item="{ element }">
          <TaskCard
            :task="element"
            :showCheckbox="isSelect"
            :selectedCards="selectedCards"
            @update:selectedCards="handleSelectedCards"
          />
        </template>
        <template v-else-if="isFinancial" #item="{ element }">
          <FinancialCard :expense="element" />
        </template>
      </draggable>
    </div>
    <!-- <div class="new-group">
            <input v-model="newGroupTitle.title" placeholder="New Group Title" />
            <button @click="addGroup">Add Group</button>
        </div> -->
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
