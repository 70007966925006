import { defineComponent } from 'vue';
import { useDate } from 'vuetify';
import { DateObject, EventObject } from './index';

export default defineComponent({
  mounted() {
    const adapter = useDate();
    this.getEvents({
      start: adapter.startOfDay(adapter.startOfMonth(new Date())) as Date,
      end: adapter.endOfDay(adapter.endOfMonth(new Date())) as Date,
    });
  },
  computed: {
    calendarViewMode(): 'month' | 'week' | 'day' {
      if (this.$stores.calendar.type === 'Monthly') {
        return 'month';
      }
      if (this.$stores.calendar.type === 'Weekly') {
        return 'week';
      }
      return 'day';
    },
  },
  methods: {
    getEvents({ start, end }: DateObject): void {
      const events = [];
      const min = start;
      const max = end;
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);

        events.push({
          title:
            this.$stores.calendar.titles[
              this.rnd(0, this.$stores.calendar.titles.length - 1)
            ],
          start: first,
          end: second,
          color:
            this.$stores.calendar.colors[
              this.rnd(0, this.$stores.calendar.colors.length - 1)
            ],
          allDay: !allDay,
        });
      }

      this.$stores.calendar.events = events;
    },
    getEventColor(event: EventObject) {
      return event.color;
    },
    rnd(a: number, b: number) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
});
