import { removeNullableFields } from '@/lib/utils'
import API from './API'

// Utility function to handle all API requests
export const apiRequest = async (
  method: 'get' | 'post' | 'put' | 'delete',
  url: string,
  data?: any,
  params?: any
) => {
  try {
    const updatedData = method === 'put' ? data : removeNullableFields(data)
    const updatedParams =
      method === 'put' ? params : removeNullableFields(params)

    const response = await API({
      method,
      url,
      data: updatedData,
      params: updatedParams,
    })

    return {
      success: true,
      data: response.data,
      status: response.status,
    }
  } catch (error: any) {
    console.error(
      `API ${method.toUpperCase()} request to ${url} failed:`,
      error
    )
    return {
      success: false,
      status: error.response?.status || 500,
      message: error.response?.data?.message || 'An unknown error occurred',
    }
  }
}
