<template>
  <SharedHeader titleWidth="30rem" title="Contacts">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left"></div>
      <div class="sub-SharedHeader-right"></div>
    </div>
  </SharedHeader>

  <TabsBar
    :tabs="tabs"
    :activeTabId="activeTabId"
    @changeTab="changeTab"
    @addTab="handleAddTab"
  />

  <VaModal v-model="isImportContacts" ok-text="Upload">
    <VaFileUpload
      file-types="csv,png"
      undo
      fileIncorrectMessage="Only CSV files are accepted. (eg: file)"
      v-model="uploadFilesList"
    >
      <div class="fileUpload-container">
        <div class="custom-upload-file-area">
          <p class="upload-text">
            <i class="fas fa-cloud-upload-alt"></i>
            <span class="highlight">Click</span> or
            <span class="highlight">drag & drop</span> to upload your file
          </p>
        </div>
      </div>
    </VaFileUpload>
  </VaModal>

  <div class="leads-container" v-if="activeTab === 'Leads'">
    <SearchAndViews
      :activeView="activeView"
      :withViews="true"
      @search="searchData"
      @changeView="changeView"
    />

    <ActionsBar
      :showSelectButton="true"
      :showDownloadIcon="true"
      :showArchieveIcon="true"
      :showLabelIcon="true"
      :showShareIcon="true"
      :addButton="$t('actionsBar.addContact')"
      secondAddButton="Import Contacts"
      :editColumns="true"
      :isFilterColumns="true"
      @SecondButtonAction="handleToggleImportContacts"
      @addButtonAction="isAddContact = !isAddContact"
    />

    <div class="contacts-kanban" v-if="activeView === 'board'">
      <!-- <KanbanColumn :contactsList="list1" :isContacts="true" title="Raw" />
      <KanbanColumn :contactsList="list2" :isContacts="true" title="Booked Meeting" />
      <KanbanColumn :contactsList="list3" :isContacts="true" title="Proposal Sent" /> -->
    </div>

    <div v-else-if="activeView === 'list'">
      <Table />
    </div>
  </div>

  <div class="all-contacts" v-else-if="activeTab === 'People'">
    <SearchAndViews
      @search="searchContactData"
      :search="contactSearchKeyword"
    />

    <ActionsBar
      :showSelectButton="true"
      :showDownloadIcon="true"
      :showArchieveIcon="true"
      :showLabelIcon="true"
      :showShareIcon="true"
      :addButton="$t('actionsBar.addContact')"
      secondAddButton="Import Contacts"
      :editColumns="true"
      :isFilterColumns="true"
      @SecondButtonAction="handleToggleImportContacts"
      @addButtonAction="isAddContact = !isAddContact"
    />
    <!-- :deleteButton="$t('actionsBar.deleteContact')" :updateButton="$t('actionsBar.updateLead')" -->
    <VaModal v-model="isAddContact" size="auto" hide-default-actions>
      <template #header>
        <div class="create-form-header">
          <h1>Contact Creation</h1>
          <VaIcon
            class="header-icon"
            name="close"
            size="30px"
            @click="toggleAddContactForm"
          />
        </div>
      </template>
      <div class="add-contact-modal-container">
        <h2>Send a platform invite link to the contact.</h2>
        <VaInput background="#ECF0F1" placeholder="Type email" />
        <VaButton> Send Invite </VaButton>

        <h2>
          Or, create the contact <b @click="toggleAddContactForm">here</b>.
        </h2>
      </div>
    </VaModal>

    <AddContactModal
      @toggleAddContactForm="cancelForm"
      @handleCreateContact="handleCreateContact"
      :addContactLoading="addContactLoading"
      :isAddContactForm="isAddContactForm"
    />

    <AllContactsTable
      @selectedRows="getSelectedRows"
      :loading="tableLoading"
      :itemsPerPage="contactsItemPerPage"
      @loadMore="handlePaginationContacts"
      :totalItems="contactTotalItems"
    />

    <!-- <div
      v-else-if="activeView === 'board'"
      class="contact-cards-container-wrapper"
    >
      <div
        v-for="(contact, index) in $stores.mockContacts.allContacts?.data?.list"
        :key="index"
        class="contact-cards-container"
      >
        <ContactCard :contact="contact" />
      </div>
    </div> -->
  </div>

  <div
    class="Organizations-container"
    v-else-if="activeTab === 'Organizations'"
  >
    <!-- <SearchAndViews @search="searchData" /> -->
    <SearchAndViews
      @search="searchOrganizationData"
      :search="organizationSearchKeyword"
    />

    <ActionsBar
      :showSelectButton="true"
      :showDownloadIcon="true"
      :showArchieveIcon="true"
      :showLabelIcon="true"
      :showShareIcon="true"
      :addButton="$t('actionsBar.addOrganization')"
      :editColumns="true"
      :isFilterColumns="true"
      @addButtonAction="isAddOrganizationForm = !isAddOrganizationForm"
    />
    <!-- :deleteButton="$t('actionsBar.deleteContact')" :updateButton="$t('actionsBar.updateLead')" -->

    <AddOrganizationModal
      @toggleAddOrganizationForm="cancelForm"
      @handleCreateOrganization="handleCreateOrganization"
      :addOrganizationLoading="addContactLoading"
      :isAddOrganizationForm="isAddOrganizationForm"
    />

    <OrganizationTable
      @selectedRows="getSelectedRows"
      :loading="tableLoading"
      :itemsPerPage="organizationsItemPerPage"
      @loadMore="handlePaginationOrganizations"
      :totalItems="organizationTotalItems"
    />
  </div>

  <div v-else class="container">
    <ComingSoon />
  </div>
  <router-view />
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss"></style>
