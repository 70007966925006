import { apiRequest } from './apiUtils'

// useless
export const getAllTasks = () => {
  return apiRequest('get', '/tasks')
}

export const getTaskById = (id: string) => {
  return apiRequest('get', `/tasks/${id}`)
}

export const addTask = (body: any) => {
  return apiRequest('post', '/tasks', body)
}

export const updateTask = (id: string, body: any) => {
  return apiRequest('put', `/tasks/${id}`, body)
}

export const deleteTask = (id: string) => {
  return apiRequest('delete', `/tasks/${id}`)
}
