import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28517278"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(_ctx.computedClasses),
    style: _normalizeStyle(_ctx.computedStyles),
    disabled: _ctx.isDisabled,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
    onMouseover: _cache[1] || (_cache[1] = ($event: any) => {; (_ctx.isHovered = true)}),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isHovered = false))
  }, [
    (_ctx.iconBefore)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: _normalizeClass(["icon-before", { 'no-margin': _ctx.onlyIcon }]),
          src: _ctx.iconBefore,
          alt: "Icon before text"
        }, null, 10, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.buttonText), 1),
    (_ctx.iconAfter)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          class: _normalizeClass([{ 'no-margin': _ctx.onlyIcon }, "icon-after"]),
          src: _ctx.iconAfter,
          alt: "Icon after text"
        }, null, 10, _hoisted_4))
      : _createCommentVNode("", true)
  ], 46, _hoisted_1))
}