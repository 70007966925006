<template>
  <SharedHeader titleWidth="30rem" title="Legal Market">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left"></div>
      <div class="sub-SharedHeader-right">

      </div>
    </div>
  </SharedHeader>
  <div class="container">
    <ComingSoon />

  </div>

</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />