import { getTeamMates } from '@/services/company'
import Cookies from 'js-cookie'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'
import TimeRange from '@/components/TimeRange/index.vue'
import DatePickerModal from '@/components/DatePickerModal/index.vue'

const DatePickerImage = new URL(
  '@/assets/icons/date-picker-icon.svg',
  import.meta.url
).href
const StartDateImage = new URL(
  '@/assets/icons/start-date-icon.svg',
  import.meta.url
).href
const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url
).href
const AttachFileIcon = new URL(
  '@/assets/icons/attach-file-icon.svg',
  import.meta.url
).href
const FlagIcon = new URL('@/assets/icons/flag-icon.svg', import.meta.url).href

export default defineComponent({
  name: 'AddTaskForm',
  props: {
    addTaskLoading: {
      type: Boolean,
      default: false,
    },
    isAddTaskForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const alert = (...args: any) => console.log(args)
    const { isValid, validate, reset, resetValidation } = useForm('form')
    return {
      alert,
      isAddTaskFormBool: this.isAddTaskForm,
      isValid,
      validate,
      reset,
      resetValidation,
      DatePickerImage,
      StartDateImage,
      MoreVertIcon,
      AttachFileIcon,
      FlagIcon,
      flagMenuOpen: false,
      loadingUpdate: false,
      membersLoading: false,
      teamMembers: [] as any,
      form: {
        name: '',
        tag: [],
        start_date: '',
        end_date: '',
        due_date: '',
        priority: 'low',
        section_stage_id: '',
        isOwner_counsel: false,
        owner_id: '',
        isAssigned_counsel: false,
        assigned_to_id: '',
        description: '',
        link_to: '',
        location: '',
        notes: '',
        budget: '',
        billable: '',
        // pre_charged: false,
        // value: '',
        // currency: 'USD',
      },
      file: [],
      time_range: {},
      due_time: '',
      flags: [
        { name: 'urgent', color: '#E53E3E' },
        { name: 'high', color: '#D69E2E' },
        { name: 'normal', color: '#296DAD' },
        { name: 'low', color: '#A0AEC0' },
      ],
    }
  },
  methods: {
    handleToggleAddTaskModal() {
      this.isAddTaskFormBool = !this.isAddTaskFormBool
      this.$emit('handleToggleAddTaskModal')
    },
    handleCreateTask() {
      this.$emit('handleCreateTask', this.form)
    },
    handleSelectedRangeDate(date: any) {
      this.form.start_date = date.start
      this.form.end_date = date.end
    },
    handleSelectedDate(date: any) {
      this.form.due_date = date.date
    },
    handleFlagMenuItemClick(flag: any) {
      console.log(`You selected: ${flag.name}`)
    },
    async handleGetMembers() {
      this.membersLoading = true
      const user = Cookies.get('haqqUser') as string
      const response = await getTeamMates(
        JSON.parse(user)?.user_roles[0].roleable_id
      )
      if (response.success) {
        this.teamMembers = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.membersLoading = false
    },
  },
  watch: {
    form: {
      handler(data: any) {
        console.log({ form: data })
      },
      deep: true, // Enable deep watching to detect changes within the object
    },
    handleChooseFlag(flag: any) {
      this.form.priority = flag.name
    },
    isAddTaskForm: {
      handler(data) {
        this.isAddTaskFormBool = data
        if (data) {
          this.handleGetMembers()
        }
      },
    },
  },
  components: {
    TimeRange,
    DatePickerModal,
  },
})
