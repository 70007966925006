import { defineComponent } from 'vue';
import Close from '@/assets/icons/Close.vue';
import FilterBoxes from '@/components/Calendar/FilterBoxes/index.vue';

export default defineComponent({
  components: {
    Close,
    FilterBoxes,
  },
  data() {
    return {
      loading: false,
      loaded: false,
    };
  },
  computed: {
    drawer(): boolean {
      return this.$stores.ui.showFilterDrawer;
    },
  },
  methods: {
    closeDrawer(): void {
      this.$stores.ui.showFilterDrawer = false;
    },
    search() {
      this.loading = true;

      setTimeout(() => {
        this.loading = false;
        this.loaded = true;
      }, 2000);
    },
  },
});
