import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ContactCard',
  props: {
    contact: {
      type: Object as () => {
        name: string
        id: number
        referals: { name: string; id: number; imageUrl: string }[]
      },
      required: true,
    },
  },
  mounted() {
    console.log(this.contact)
  },
})
