import { defineComponent, ref, computed, watch } from 'vue'
const DatePickerImage = new URL(
  '@/assets/icons/date-picker-icon.svg',
  import.meta.url
).href

export default defineComponent({
  name: 'TimeRangePickerComponent',
  props: {
    date: {
      type: Object as () => { start: string; end: string },
      required: true,
    },
    shape: {
      type: String,
      default: 'button',
      validator: (value: string) => {
        return ['button', 'icon', 'button-icon'].includes(value)
      },
    },
  },
  setup(props, { emit }) {
    const showDatePicker = ref<boolean>(false)

    interface SelectedDateRange {
      start: Date | null
      end: Date | null
    }

    const parseDate = (dateString: string): Date | null => {
      const parsedDate = new Date(dateString)
      return isNaN(parsedDate.getTime()) ? null : parsedDate
    }

    // Initialize selectedDateRange with nulls, to be updated by watch
    const selectedDateRange = ref<SelectedDateRange>({
      start: null,
      end: null,
    })

    const startHour = ref<number>(0)
    const startMinute = ref<number>(0)
    const endHour = ref<number>(0)
    const endMinute = ref<number>(0)

    // Watch for changes in props.date and update selectedDateRange
    watch(
      () => props.date,
      (newDate: { start: string; end: string }) => {
        if (newDate && newDate.start) {
          const parsedStartDate = parseDate(newDate.start)
          const parsedEndDate = parseDate(newDate.end)

          if (parsedStartDate) {
            selectedDateRange.value = {
              start: parsedStartDate,
              end: parsedEndDate || parsedStartDate, // Use start date if end date is not provided
            }

            startHour.value = parsedStartDate.getHours()
            startMinute.value = parsedStartDate.getMinutes()
            endHour.value = parsedEndDate
              ? parsedEndDate.getHours()
              : parsedStartDate.getHours()
            endMinute.value = parsedEndDate
              ? parsedEndDate.getMinutes()
              : parsedStartDate.getMinutes()
          }
        }
      },
      { immediate: true }
    )

    const formattedSelectedDate = computed(() => {
      const { start, end } = selectedDateRange.value
      if (start) {
        const optionsWithoutYear: Intl.DateTimeFormatOptions = {
          month: 'short',
          day: 'numeric',
        }
        const startDate = start.toLocaleDateString('en-US', optionsWithoutYear)
        const startTime = `${startHour.value
          .toString()
          .padStart(2, '0')}:${startMinute.value.toString().padStart(2, '0')}`

        if (end) {
          const optionsWithYear: Intl.DateTimeFormatOptions = {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          }
          const endDate = end.toLocaleDateString('en-US', optionsWithYear)
          const endTime = `${endHour.value
            .toString()
            .padStart(2, '0')}:${endMinute.value.toString().padStart(2, '0')}`
          return `${startDate} ${startTime} - ${endDate} ${endTime}`
        }
        return `${startDate} ${startTime}`
      }
      return null
    })

    const resetDate = (): void => {
      selectedDateRange.value = { start: null, end: null }
      startHour.value = 0
      startMinute.value = 0
      endHour.value = 0
      endMinute.value = 0
      emit('update:date', { start: '', end: '' }) // Reset the date and emit to parent
    }

    const saveDate = (): void => {
      const { start, end } = selectedDateRange.value

      if (start) {
        start.setHours(startHour.value)
        start.setMinutes(startMinute.value)
      }

      if (end) {
        end.setHours(endHour.value)
        end.setMinutes(endMinute.value)
      }

      // Convert to ISO string without adjusting for the timezone offset
      const startUTC = start ? start.toISOString() : null
      const endUTC = end ? end.toISOString() : null

      emit('update:date', {
        start: startUTC,
        end: endUTC,
      })

      showDatePicker.value = false // Close the date picker after saving
    }

    return {
      showDatePicker,
      selectedDateRange,
      startHour,
      startMinute,
      endHour,
      endMinute,
      formattedSelectedDate,
      resetDate,
      saveDate,
      DatePickerImage,
    }
  },
})
