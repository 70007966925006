<template>
  <div>
    <v-sheet class="d-flex" height="54" tile>
      <v-calendar
        ref="calendar"
        v-model="$stores.calendar.value"
        :events="$stores.calendar.events"
        :view-mode="calendarViewMode"
      ></v-calendar>
    </v-sheet>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss"/>
