<template>
  <div class="main">
    <SharedHeader titleWidth="30rem" title="Home">
      <div class="sub-SharedHeader">
        <div class="sub-SharedHeader-left"></div>
        <div class="sub-SharedHeader-right"></div>
      </div>
    </SharedHeader>
    <div class="container">
      <ComingSoon />
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>

<style lang="scss" scoped src="./styles.scss"></style>
