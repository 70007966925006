import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LegalMatterCard',
  props: {
    matter: {
      type: Object as any,
      required: true,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    selectedCards: {
      type: Array as () => number[],
      default: () => [],
    },
  },
  data() {
    console.log({ matter: this.matter })

    const case_manager_ids =
      this.matter?.item?.legal_matter_users
        .filter((user: any) => user.relationship_type === 'case_manager')
        .map((user: any) => user.user) || ('' as any)

    const personnel_ids =
      this.matter?.item?.legal_matter_users
        .filter((user: any) => user.relationship_type === 'personnel')
        .map((user: any) => user.user) || ('' as any)

    return {
      isChecked: this.selectedCards.includes(this.matter.id),
      case_manager_ids,
      personnel_ids,
    }
  },
  methods: {
    toggleChecked(value: any) {
      let updatedMatters = [...this.selectedCards]
      if (value) {
        if (!updatedMatters.includes(this.matter.id)) {
          updatedMatters.push(this.matter.id)
        }
      } else {
        updatedMatters = updatedMatters.filter((id) => id !== this.matter.id)
      }
      console.log('Checked items:', updatedMatters) // Log the selected items
      this.$emit('update:selectedCards', updatedMatters)
    },
  },
  watch: {
    // Watch for changes in selectedCards to update isChecked
    selectedCards(newVal) {
      this.isChecked = newVal.includes(this.matter.id)
    },
    isChecked(value) {
      this.toggleChecked(value)
    },
  },
})
