<template>
  <div class="layout-change-modal">
    <VaModal v-model="localIsShow" @ok="emitApplyChanges" cancel-text="Cancel" ok-text="Apply" size="large"
      @close="closeModal()">
      <div class="kanban-row">
        <div v-for="group in localGroups" :key="group.id" class="kanban-column">
          <div class="kanban-column-header">
            <h3>{{ group.title }}</h3>

          </div>
        </div>
        <div v-for="group in localGroups" :key="group.id" class="kanban-column">
          <div class="kanban-column-header">
            <h3>{{ group.title }}</h3>

          </div>
        </div>
      </div>
    </VaModal>
  </div>

</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />