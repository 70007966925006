import { defineComponent } from 'vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import KanbanColumn from '@/components/kanbanColumn/index.vue'
import ExpensesTable from '@/components/financial/ExpensesTable/index.vue'
import InvoicesTable from '@/components/financial/invoicesTable/index.vue'
import PaymentsHistoryTable from '@/components/paymentsHistoryTable/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import FinancialsDashboard from '@/components/financialsDashboard/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import AddInvoiceModal from '@/components/financial/AddInvoiceModal/index.vue'
import AddExpenseModal from '@/components/financial/AddExpenseModal/index.vue'
import { addInvoice, getAllInvoices } from '@/services/invoices'
import { addExpense, getAllExpenses } from '@/services/expenses'

export default defineComponent({
  name: 'ContactsPage',
  data() {
    return {
      tabs: [
        { id: 0, title: 'Dashboard' },
        { id: 1, title: 'Expenses' },
        { id: 2, title: 'Invoices' },
        { id: 3, title: 'Payments History' },
        { id: 4, title: 'On Retainer' },
        { id: 5, title: 'Budgets' },
      ],
      activeTabId: 2,
      activeTab: 'Invoices',
      activeView: 'list',
      paymentsHistoryHeaders: [
        { field: 'date', width: '20%', id: 1 },
        { field: 'invoice', width: '20%', id: 2 },
        { field: 'amount', width: '20%', id: 3 },
        { field: 'status', width: '20%', id: 4 },
        { field: 'actions', width: '20%', id: 5 },
      ],
      paymentsHistoryList: [
        {
          date: '12/12/2020',
          invoice: 'INV-123',
          amount: '$1000',
          status: 'Paid',
          actions: 'View',
        },
        {
          date: '12/12/2020',
          invoice: 'INV-123',
          amount: '$1000',
          status: 'Paid',
          actions: 'View',
        },
        {
          date: '12/12/2020',
          invoice: 'INV-123',
          amount: '$1000',
          status: 'Paid',
          actions: 'View',
        },
        {
          date: '12/12/2020',
          invoice: 'INV-123',
          amount: '$1000',
          status: 'Paid',
          actions: 'View',
        },
        {
          date: '12/12/2020',
          invoice: 'INV-123',
          amount: '$1000',
          status: 'Paid',
          actions: 'View',
        },
      ],
      lists: [
        {
          name: 'New Expanses',
          list: [
            {
              account: 'John Test',
              id: 1,
              title: 'Case 1',
              amount: 1000,
            },
            {
              account: 'Marc Test',
              id: 2,
              title: 'Case 2',
              amount: 2000,
            },
            {
              account: 'Khaled Test',
              id: 3,
              title: 'Case 3',
              amount: 3000,
            },
            {
              account: 'Cynthia Test',
              id: 4,
              title: 'Case 4',
              amount: 4000,
            },
          ],
        },
        {
          name: 'In Progress',
          list: [
            {
              account: 'John Test',
              id: 1,
              title: 'Case 1',
              amount: 1000,
            },
            {
              account: 'Marc Test',
              id: 2,
              title: 'Case 2',
              amount: 2000,
            },
            {
              account: 'Khaled Test',
              id: 3,
              title: 'Case 3',
              amount: 3000,
            },
            {
              account: 'Cynthia Test',
              id: 4,
              title: 'Case 4',
              amount: 4000,
            },
          ],
        },
        {
          name: 'Completed',
          list: [
            {
              account: 'John Test',
              id: 1,
              title: 'Case 1',
              amount: 1000,
            },
            {
              account: 'Marc Test',
              id: 2,
              title: 'Case 2',
              amount: 2000,
            },
            {
              account: 'Khaled Test',
              id: 3,
              title: 'Case 3',
              amount: 3000,
            },
            {
              account: 'Cynthia Test',
              id: 4,
              title: 'Case 4',
              amount: 4000,
            },
          ],
        },
      ],
      isAddInvoice: false,
      addInvoiceLoading: false,
      selectedInvoiceRows: [],
      invoiceTableLoading: false,

      invoices: [] as any,
      invoicesItemPerPage: 10,
      invoicePage: 1,
      invoiceSortField: '' as
        | 'IndividualContact'
        | 'OrganizationContact'
        | undefined,
      invoiceSortDirection: '' as 'asc' | 'desc' | undefined,
      invoiceSearchKeyword: '',
      invoiceTotalItems: 0,

      isAddExpense: false,
      addExpenseLoading: false,
      selectedExpenseRows: [],
      expenseTableLoading: false,

      expenses: [] as any,
      expensesItemPerPage: 10,
      expensePage: 1,
      expenseSortField: '' as
        | 'IndividualContact'
        | 'OrganizationContact'
        | undefined,
      expenseSortDirection: '' as 'asc' | 'desc' | undefined,
      expenseSearchKeyword: '',
      expenseTotalItems: 0,

      showInvoiceModal: false, // to show/hide modal
      selectedInvoice: null as any, // store invoice to be viewed
    }
  },
  methods: {
    changeTab(tab: { title: string; id: number }) {
      this.activeTabId = tab.id
      this.activeTab = tab.title
      this.$router.push({ query: { tab: tab.title } })
    },
    changeView(view: string) {
      this.activeView = view
    },
    searchData(search: string) {
      console.log(search)
    },
    getInvoiceSelectedRows(rows: any) {
      this.selectedInvoiceRows = rows
    },
    getExpenseSelectedRows(rows: any) {
      this.selectedExpenseRows = rows
    },
    toggleAddInvoice() {
      this.isAddInvoice = !this.isAddInvoice
    },
    toggleAddExpense() {
      this.isAddExpense = !this.isAddExpense
    },
    handleViewInvoice(invoice: any) {
      this.selectedInvoice = invoice
      this.showInvoiceModal = true
    },
    async handleCreateInvoice(form: any) {
      const {
        invoice_date,
        payment_due_date,
        individual_contact_id,
        ...subForm
      } = form
      this.addInvoiceLoading = true
      const response = await addInvoice({
        invoice_date: invoice_date.toString(),
        payment_due_date: payment_due_date.toString(),
        individual_contact_id: individual_contact_id.id,
        ...subForm,
      })
      if (response.success) {
        this.$vaToast.init({
          message: 'Invoice created successfully!',
          color: 'success',
        })
        this.handleGetInvoices()
        this.toggleAddInvoice()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.addInvoiceLoading = false
    },
    async handleCreateExpense(form: any) {
      const { due_date, billed_to_id, from_wallet_id, ...subForm } = form
      this.addExpenseLoading = true

      const response = await addExpense({
        due_date: due_date.toString(),
        billed_to_id: billed_to_id?.id,
        from_wallet_id: from_wallet_id?.id,
        ...subForm,
      })
      if (response.success) {
        this.$vaToast.init({
          message: 'Invoice created successfully!',
          color: 'success',
        })
        this.handleGetExpenses()
        this.toggleAddExpense()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.addExpenseLoading = false
    },
    async handleGetInvoices() {
      this.invoiceTableLoading = true

      const response = await getAllInvoices({
        per_page: this.invoicesItemPerPage,
        page: this.invoicePage,
      })
      if (response.success) {
        this.invoices = response.data
        this.invoiceTotalItems =
          response.data.total_pages * this.invoicesItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.invoiceTableLoading = false
    },
    async handleGetExpenses() {
      this.expenseTableLoading = true

      const response = await getAllExpenses({
        per_page: this.expensesItemPerPage,
        page: this.expensePage,
      })
      if (response.success) {
        this.expenses = response.data
        this.expenseTotalItems =
          response.data.total_pages * this.expensesItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.expenseTableLoading = false
    },
    async handlePaginationInvoices({ page, itemsPerPage, sortBy }: any) {
      this.invoicesItemPerPage = itemsPerPage
      this.invoicePage = page
      if (sortBy.length > 0) {
        this.invoiceSortField = sortBy[0].key.split('.')[1]
        this.invoiceSortDirection = sortBy[0].order
      } else {
        this.invoiceSortField = undefined
        this.invoiceSortDirection = undefined
      }

      await this.handleGetInvoices()
    },
    async handlePaginationExpenses({ page, itemsPerPage, sortBy }: any) {
      console.log({ page, itemsPerPage, sortBy })

      this.expensesItemPerPage = itemsPerPage
      this.expensePage = page
      if (sortBy.length > 0) {
        this.expenseSortField = sortBy[0].key.split('.')[1]
        this.expenseSortDirection = sortBy[0].order
      } else {
        this.expenseSortField = undefined
        this.expenseSortDirection = undefined
      }

      await this.handleGetExpenses()
    },
  },
  components: {
    KanbanColumn,
    SharedHeader,
    ExpensesTable,
    InvoicesTable,
    FinancialsDashboard,
    TabsBar,
    SearchAndViews,
    ActionsBar,
    PaymentsHistoryTable,
    ComingSoon,
    AddInvoiceModal,
    AddExpenseModal,
  },
  mounted() {
    const tab = this.$route.query.tab
    if (tab) {
      const activeTab = this.tabs.find((t) => t.title === tab)
      if (activeTab) {
        this.activeTabId = activeTab.id
        this.activeTab = activeTab.title
      }
    } else {
      this.activeTabId = 2
      this.activeTab = 'Invoices'
      this.$router.push({ query: { tab: 'Invoices' } })
    }
    this.handleGetInvoices()
    this.handleGetExpenses()
  },
  // watch: {
  //   isAddExpense: {
  //     handler(data) {
  //       console.log({ isAddExpense: data })
  //     },
  //   },
  // },
})
