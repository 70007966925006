<template>
  <h1 class="header">EXPENSE NAME: {{ form.name }}</h1>
  <VaForm
    ref="form"
    class="create-expense-form"
    @submit.prevent="handleFormSubmit"
  >
    <div class="body-expense-form">
      <VaInput
        :loading="loading"
        background="#ECF0F1"
        v-model="form.name"
        label="Name"
        placeholder="Name"
        requiredMark
        :rules="[(value) => value && value.length > 0]"
      />
      <div class="UQC-section">
        <VaInput
          :loading="loading"
          background="#ECF0F1"
          v-model="form.unit_price"
          style="width: 33%"
          label="Unit price"
          placeholder="Unit Price"
          requiredMark
          type="number"
          :rules="[(value) => value && value.length > 0]"
        />
        <VaInput
          :loading="loading"
          background="#ECF0F1"
          v-model="form.quantity"
          style="width: 33%"
          label="Quantity"
          placeholder="Quantity"
          requiredMark
          type="number"
          :rules="[
            (value) => value !== null && value !== undefined && value > 0,
          ]"
        />
        <VaSelect
          :loading="loading"
          style="width: 33%"
          label="currency"
          background="#ECF0F1"
          v-model="form.currency"
          :options="['USD', 'UAE']"
        />
      </div>
      <div class="TDT-section">
        <VaInput
          :loading="loading"
          style="width: 33%"
          background="#ECF0F1"
          v-model="form.total_amount"
          label="total value"
          placeholder="Total Value"
          requiredMark
          type="number"
          :rules="[(value) => value && value.length > 0]"
        />
        <VaInput
          :loading="loading"
          style="width: 33%"
          background="#ECF0F1"
          v-model="form.declared_amount"
          label="Declared value"
          placeholder="Declared Value"
          requiredMark
          type="number"
          :rules="[(value) => value && value.length > 0]"
        />
        <VaInput
          :loading="loading"
          style="width: 32%"
          background="#ECF0F1"
          v-model="form.total_tax"
          label="Tax value"
          placeholder="Tax Value"
          requiredMark
          type="number"
          :rules="[(value) => value && value.length > 0]"
        />
      </div>
      <VaSelect
        :loading="loading"
        style="width: 100%"
        label="Category"
        background="#ECF0F1"
        v-model="form.category"
        :options="categoryOption"
      />
      <VaTextarea
        :loading="loading"
        max-rows="11"
        v-model="form.description"
        label="description"
        placeholder="Add your description here..."
        requiredMark
        style="width: 100%; height: 10rem"
        autosize
        background="#ECF0F1"
        :rules="[(value) => value && value.length > 0]"
      />
      <!-- <VaSelect
        background="#ECF0F1"
        v-model="form.billed_to_id"
        style="width: 100%; height: 1.5rem"
        label="Paid To"
        searchable
        :options="$stores.mockContacts.mockContacts"
        :highlight-matched-text="false"
        :text-by="(option) => `${option.firstName} ${option.lastName}`"
        value-by="id"
      /> -->
      <VaSelect
        background="#ECF0F1"
        v-model="form.billed_to_id"
        style="width: 100%; height: 1.5rem"
        label="Paid To"
        placeholder="Paid To"
        searchable
        :highlight-matched-text="false"
        :options="contacts"
        :text-by="
          (option) =>
            `${option?.specific_data?.individual_first_name} ${option?.specific_data?.individual_last_name}`
        "
        :loading="contactsLoading"
        @update:search="handleSearchContacts"
      />
      <VaSelect
        :loading="contactsLoading"
        background="#ECF0F1"
        v-model="form.from_wallet_id"
        style="width: 100%; height: 1.5rem"
        label="Paid By"
        placeholder="Paid By"
        searchable
        :options="contacts"
        :highlight-matched-text="false"
        :text-by="
          (option) =>
            `${option.specific_data?.individual_first_name} ${option.specific_data?.individual_last_name}`
        "
        @update:search="handleSearchContacts"
      />
      <div class="ESE-section">
        <VaDateInput
          placeholder="MM/DD/YYYY"
          :loading="loading"
          v-model="form.due_date"
          manual-input
          label="Expenses due date"
          requiredMark
        />
        <VaSelect
          :loading="loading"
          label="Status"
          placeholder="Status"
          background="#ECF0F1"
          v-model="form.status"
          :options="statusOptions"
          value-by="value"
        />
      </div>
      <VaSelect
        :loading="loading"
        label="expense type"
        placeholder="Expense Type"
        background="#ECF0F1"
        v-model="form.expense_type"
        :options="expenseTypeOptions"
        value-by="text"
      />
      <div class="upload-docs">
        <VaFileUpload v-model="files" disabled>
          <VaButton
            icon="add"
            preset="secondary"
            border-color="primary"
            size="small"
          />
        </VaFileUpload>
        Upload documents (.jpg,.jpeg,.pdf,.png)
      </div>
    </div>

    <div class="add-expense-form-modal-buttons">
      <VaButton
        preset="secondary"
        border-color="primary"
        @click="handleToggleAddModal"
        >Cancel</VaButton
      >
      <VaButton
        @click="validate() && handleUpdateExpense()"
        :loading="updateLoading"
        >Save</VaButton
      >
    </div>
  </VaForm>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
