<template>
  <div class="legal-matter-details-page">
    <p>MATTER ID: {{ form?.id }}</p>
    <SharedHeader :title="form?.name">
      <div class="sub-SharedHeader">
        <div class="sub-SharedHeader-left"></div>
        <div class="sub-SharedHeader-right">
          <TimeRange
            @update:date="handleSelectedDate"
            :date="{ start: form?.start_date, end: form?.end_date }"
          />
          <DatePickerModal
            @update:date="handleSelectDueDate"
            :date="form?.due_date"
          />
        </div>
      </div>
    </SharedHeader>

    <div class="info-container">
      <h2 v-if="stages.length > 0" class="info-container-stages">
        Stage :
        <VaMenu>
          <template #anchor>
            <VaButton
              icon="arrow_drop_down"
              round
              size="small"
              :color="form?.section_stage_items[0]?.section_stage?.color"
            >
              {{ form?.section_stage_items[0]?.section_stage.name }}
            </VaButton>
          </template>
          <VaMenuItem
            v-for="(stage, index) in stages"
            :key="index"
            @click="handleStageMenuItemClick(stage)"
          >
            <VaIcon name="circle" :color="stage.color" />
            {{ stage.name }}
          </VaMenuItem>
        </VaMenu>
      </h2>
      <h2 v-else-if="form.parent_id">
        <VaButton
          icon="arrow_back"
          round
          @click="handleBackToParent(form?.parent_id)"
        >
          Parent
        </VaButton>
      </h2>
      <h2 v-else></h2>

      <h2 class="info-container-status">
        Status :
        <VaMenu>
          <template #anchor>
            <VaButton
              icon="arrow_drop_down"
              round
              size="small"
              :color="form?.status?.color"
            >
              {{ form?.status?.name }}
            </VaButton>
          </template>
          <VaMenuItem
            v-for="(stage, index) in statusOptions"
            :key="index"
            @click="handleStatusMenuItemClick(stage)"
          >
            <VaIcon name="circle" :color="stage.color" />
            {{ stage.name }}
            <VaDivider />
          </VaMenuItem>
        </VaMenu>
      </h2>
    </div>
    <div class="taskbar-container">
      <TabsBar
        :tabs="tabs"
        :activeTabId="activeTabId"
        @changeTab="changeTab"
        :addBtn="false"
      />
    </div>
    <div class="matters-scrollable-container">
      <div v-if="activeTab === 'MATTER INFO'" class="matter-info">
        <div class="matter-add-field-button-container">
          <VaButton
            icon-right="add"
            preset="secondary"
            border-color="primary"
            icon-color="#812E9E"
            disabled
          >
            Add Field
          </VaButton>
        </div>
        <p class="matter-info-text">
          <va-icon size="large" name="info" />
          You can change the layout of the fields here.
          <VaIcon name="edit" /> It will affect the layout of the table also.
        </p>
        <div class="matter-info-relationships">
          <h6>Relationship *</h6>
          <ol v-for="(relation, index) in relationships" :key="index">
            <li>
              <p>
                <va-icon size="large" name="subdirectory_arrow_right" />
                <b>{{ relation.name }}</b> : {{ relation.details }}
                <span class="matter-relation-subDetail"
                  >({{ relation.subDetail }})</span
                >
              </p>
              <VaButton
                icon-="link"
                size="small"
                preset="secondary"
                text-color="red"
                icon-color="red"
              >
                Remove
              </VaButton>
            </li>
          </ol>
          <VaButton icon="link" preset="secondary" icon-color="#812E9E">
            Link to another legal matter
          </VaButton>
        </div>
        <VaForm
          ref="form"
          class="update-matter-form"
          @submit.prevent="handleFormSubmit"
        >
          <div class="container">
            <VaInput
              background="#ECF0F1"
              v-model="form.name"
              style="width: 100%"
              label="name"
              requiredMark
              :rules="[(value) => value && value.length > 0]"
              placeholder="Name"
            />
            <div class="client-section">
              <VaSelect
                background="#ECF0F1"
                v-model="form.representing_contact"
                label="client"
                searchable
                :highlight-matched-text="false"
                :options="contacts"
                :text-by="
                  (option) =>
                    `${option?.specific_data?.individual_first_name} ${option?.specific_data?.individual_last_name}`
                "
                :loading="contactsLoading"
                @update:search="handleSearchContacts"
                placeholder="Select Client"
              />
              <VaSelect
                v-model="form.representation_type"
                :options="presentationTypeOption"
                label="representing"
                background="#ECF0F1"
                placeholder="Representing"
              />
            </div>

            <p><VaIcon name="link" /> Link to another Legal Matter</p>

            <div class="court-section">
              <VaSelect
                v-model="form.court"
                :options="courtOptions"
                label="Court"
                requiredMark
                :rules="[(value) => value]"
                style="width: 80%"
                background="#ECF0F1"
                placeholder="Court Type"
                :text-by="(option) => truncateText(option?.name, 110)"
              />

              <VaInput
                background="#ECF0F1"
                v-model="form.court_case_number"
                style="width: 20%"
                placeholder="Court ID"
                requiredMark
                :rules="[(value) => value && value.length > 0]"
                label="Court ID "
              />
            </div>

            <div class="jn-opp-section">
              <VaSelect
                background="#ECF0F1"
                v-model="form.judge"
                style="width: 100%; height: 1.5rem; padding-bottom: 1.5rem"
                label="judge name"
                requiredMark
                :rules="[(value) => value]"
                searchable
                :highlight-matched-text="false"
                :options="contacts"
                :text-by="
                  (option) =>
                    `${option.specific_data?.individual_first_name} ${option.specific_data?.individual_last_name}`
                "
                :loading="contactsLoading"
                @update:search="handleSearchContacts"
                placeholder="Select The Judge"
              />
              <VaSelect
                background="#ECF0F1"
                v-model="form.opponent_contact"
                style="width: 100%; height: 1.5rem"
                label="opponent name"
                requiredMark
                :rules="[(value) => value]"
                searchable
                :highlight-matched-text="false"
                :options="contacts"
                :text-by="
                  (option) =>
                    `${option?.specific_data?.individual_first_name} ${option?.specific_data?.individual_last_name}`
                "
                :loading="contactsLoading"
                @update:search="handleSearchContacts"
                placeholder="Select The Opponent"
              />
            </div>

            <!-- <div class="status-stage-section">
              <VaSelect
                label="Status"
                background="#ECF0F1"
                v-model="form.status"
                :options="statusOptions"
                :text-by="(option) => option?.name"
              />
              <VaSelect
                label="Stage"
                background="#ECF0F1"
                v-model="form.section_stage"
                :options="stages"
                :text-by="(option) => option?.name"
                :loading="stagesLoading"
              />
            </div> -->

            <VaSelect
              disabled
              background="#ECF0F1"
              style="width: 100%; height: 1.5rem"
              label="Team"
              clearable
              searchable
              :highlight-matched-text="false"
              :options="contacts"
              :text-by="
                (option) =>
                  `${option?.specific_data?.individual_first_name} ${option?.specific_data?.individual_last_name}`
              "
              :loading="contactsLoading"
              @update:search="handleSearchContacts"
              placeholder="Select A Team"
            />

            <VaSelect
              background="#ECF0F1"
              v-model="form.case_manager_ids"
              style="width: 100%; height: 1.5rem"
              label="case manager"
              requiredMark
              :rules="[(value) => !!value.length]"
              searchable
              :highlight-matched-text="false"
              :options="teamMembers"
              :text-by="
                (option) => `${option?.first_name} ${option?.last_name}`
              "
              :loading="contactsLoading"
              @update:search="handleSearchContacts"
              placeholder="Select Case Manager"
              multiple
              :max-visible-options="2"
              value-by="id"
            />

            <div class="case-personal">
              <div style="width: 70%">
                <VaSelect
                  background="#ECF0F1"
                  v-model="form.personnel_ids"
                  label="personnel"
                  clearable
                  searchable
                  :highlight-matched-text="false"
                  :options="teamMembers"
                  :text-by="
                    (option) => `${option?.first_name} ${option?.last_name}`
                  "
                  :loading="contactsLoading"
                  @update:search="handleSearchContacts"
                  placeholder="Select Personnel"
                  multiple
                  :max-visible-options="2"
                  value-by="id"
                />
              </div>
              <VaCheckbox
                disabled
                style="height: 0.5rem"
                v-model="case_personal_of_counsel"
                class="mb-6"
                label="Of Counsel"
              />
            </div>

            <div class="spe-cat-section">
              <VaSelect
                style="width: 100%"
                label="Specialization"
                clearable
                background="#ECF0F1"
                v-model="form.specialization"
                :options="specializationOptions"
                placeholder="Specialization"
                :text-by="(option) => option?.name"
              />
              <VaSelect
                style="width: 100%"
                label="Category"
                clearable
                background="#ECF0F1"
                v-model="form.category"
                :options="categoryOptions"
                placeholder="Category"
                :text-by="(option) => option?.name"
              />
            </div>

            <VaSelect
              placeholder="Jurisdictions"
              background="#ECF0F1"
              v-model="form.jurisdriction"
              :loading="countriesLoading"
              style="width: 100%; height: auto"
              label="Jurisdictions"
              requiredMark
              searchable
              :options="countries?.list"
              :highlight-matched-text="false"
              :text-by="(option) => option?.name"
              :rules="[(v) => !!v]"
            />
            <VaInput
              style="width: 100%"
              background="#ECF0F1"
              v-model="form.region"
              label="Region"
              placeholder="Region"
            />
            <VaTextarea
              max-rows="11"
              v-model="form.notes"
              label="notes"
              style="width: 100%; height: 10rem"
              autosize
              background="#ECF0F1"
              placeholder="Enter your notes here..."
            />
          </div>
        </VaForm>
        <div class="add-matter-form-modal-buttons">
          <VaButton
            preset="secondary"
            border-color="primary"
            @click="console.log('cancel')"
            >Cancel</VaButton
          >
          <VaButton @click="validate() && handleUpdate()" :loading="false"
            >Save</VaButton
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
