import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    task: {
      type: Object as any,
      required: true,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    selectedCards: {
      type: Array as () => number[],
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const isChecked = computed({
      get: () => props.selectedCards.includes(props.task.id),
      set: (value: boolean) => {
        let updatedTasks = [...props.selectedCards]
        if (value) {
          if (!updatedTasks.includes(props.task.id)) {
            updatedTasks.push(props.task.id)
          }
        } else {
          updatedTasks = updatedTasks.filter((id) => id !== props.task.id)
        }
        console.log('Checked items:', updatedTasks) // Log the selected items
        emit('update:selectedCards', updatedTasks)
      },
    })

    const formattedDate = computed(() => {
      const date = new Date(props.task.created_at)
      return date.toLocaleDateString('en-US', {
        month: 'short', // "Sep" for September
        day: 'numeric',
        year: 'numeric',
      })
    })

    // const formattedSelectedDate = computed(() => {
    //   const start = props.task.start
    //   const end = props.task.end
    //   if (start) {
    //     const optionsWithoutYear: Intl.DateTimeFormatOptions = {
    //       month: 'short',
    //       day: 'numeric',
    //     };
    //     const startDate = start.toLocaleDateString('en-US', optionsWithoutYear);

    //     if (end) {
    //       const optionsWithYear: Intl.DateTimeFormatOptions = {
    //         month: 'short',
    //         day: 'numeric',
    //         year: 'numeric',
    //       };
    //       const endDate = end.toLocaleDateString('en-US', optionsWithYear);
    //       return `${startDate}  - ${endDate} `;
    //     }
    //     return `${startDate} `;
    //   }
    //   return null;
    // });

    return {
      isChecked,
      formattedDate,
      // formattedSelectedDate
    }
  },
})
