import { ContactType, getContacts } from '@/services/contacts'
import { getExpenseById, updateExpense } from '@/services/expenses'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'

export default defineComponent({
  name: 'AddExpenseForm',
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')
    return {
      isValid,
      validate,
      reset,
      resetValidation,
      expenseDetails: [] as any,
      loading: false,
      updateLoading: false,
      files: [],
      form: {
        id: '',
        name: '',
        unit_price: '',
        quantity: '',
        currency: 'USD',
        total_amount: '',
        declared_amount: '',
        category: 'Administrative Law',
        description: '',
        billed_to_id: null as any,
        from_wallet_id: null as any,
        due_date: new Date(),
        status: 'not_started',
        expense_type: 'utilities',
        total_tax: '',
      },
      categoryOption: [
        'Administrative Law',
        'Alternative Dispute Resolution',
        'Anti-Corruption Law',
        'Arbitration',
        'Aviation Law',
        'Banking Law',
        'Bankruptcy Law',
        'Civil Litigation',
      ],
      statusOptions: [
        { value: 'not_started', text: 'Not Started' },
        { value: 'transferred', text: 'Transferred' },
        { value: 'in_progress', text: 'In Progress' },
        { value: 'not_retained', text: 'Not Retained' },
      ],
      expenseTypeOptions: [
        { value: 'office_rent', text: 'Office Rent/Lease' },
        {
          value: 'utilities',
          text: 'Utilities (Electricity, Water, Internet)',
        },
        { value: 'salaries_wages', text: 'Salaries and Wages' },
        { value: 'office_supplies', text: 'Office Supplies' },
        {
          value: 'software_licenses',
          text: 'Software Licenses (e.g., case management tools)',
        },
        {
          value: 'marketing_expenses',
          text: 'Marketing and Advertising Expenses',
        },
        {
          value: 'professional_liability_insurance',
          text: 'Professional Liability Insurance',
        },
        { value: 'travel_expenses', text: 'Travel Expenses' },
        {
          value: 'continuing_education',
          text: 'Continuing Education and Training',
        },
        {
          value: 'client_entertainment',
          text: 'Client Entertainment and Meals',
        },
      ],
      contactsLoading: false,
      contactPage: 1,
      contactSearchKeyword: '',
      contactsItemPerPage: 10,
      contactTotalItems: 0,
      contacts: [] as any,
      debounceTimeout: null as ReturnType<typeof setTimeout> | null, // Holds the debounce timeout ID
    }
  },
  methods: {
    handleToggleAddModal() {
      this.$emit('handleToggleAddModal')
    },
    handleFormSubmit() {
      console.log('Form submitted')
    },

    async handleGetExpenseByID() {
      this.loading = true
      const response = await getExpenseById(this.$route.params.id as string)
      if (response.success) {
        this.expenseDetails = response.data
        this.populateForm()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.loading = false
    },
    populateForm() {
      const form = this.form
      const expenseDetails = this.expenseDetails

      form.id = expenseDetails.id
      form.billed_to_id = expenseDetails.billed_to || null
      form.category = expenseDetails.category
      form.currency = expenseDetails.currency
      form.declared_amount = expenseDetails.declared_amount
      form.description = expenseDetails.description
      form.due_date = expenseDetails.due_date
      form.expense_type = expenseDetails.expense_type
      form.from_wallet_id = expenseDetails.from_wallet || null
      form.name = expenseDetails.name
      form.quantity = expenseDetails.quantity
      form.status = expenseDetails.status
      form.total_amount = expenseDetails.total_amount
      form.total_tax = expenseDetails.total_tax
      form.unit_price = expenseDetails.unit_price
    },

    async handleGetContacts() {
      this.contactsLoading = true

      const response = await getContacts({
        contact_type: ContactType.IndividualContact,
        per_page: this.contactsItemPerPage,
        page: this.contactPage,
        keyword: this.contactSearchKeyword,
      })
      if (response.success) {
        this.contacts = response.data.list
        this.contactTotalItems =
          response.data.total_pages * this.contactsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.contactsLoading = false
    },
    handleSearchContacts(search: string) {
      this.contactSearchKeyword = search

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts()
      }, 500)
    },
    async handleUpdateExpense() {
      this.updateLoading = true

      const { billed_to_id, from_wallet_id, ...updatedForm } = this.form

      const response = await updateExpense(this.$route.params.id as string, {
        ...updatedForm,
        billed_to_id: billed_to_id.id,
        from_wallet_id: from_wallet_id.id,
      })
      if (response.success) {
        this.$vaToast.init({
          message: 'Expense updated successfully!',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.updateLoading = false
    },
  },
  mounted() {
    this.handleGetExpenseByID()
    this.handleGetContacts()
  },
  watch: {
    form: {
      handler(newForm) {
        console.log('Form updated:', newForm)
      },
      deep: true,
    },
  },
})
