import { defineComponent } from 'vue'

const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url
).href

export default defineComponent({
  name: 'AllExpansesTable',
  props: {
    expenses: {
      type: Array as any,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    totalItems: {
      type: Number,
      default: 10,
    },
  },
  components: {},
  data() {
    return {
      localTotalItems: this.totalItems,
      selectedRows: [],
      MoreVertIcon,
      deleteDialog: false,
      currentPage: 1,
      currentSortBy: [] as any,
      currentItemsPerPage: this.itemsPerPage,
      headers: [
        {
          title: '#',
          sortable: false,
          key: 'id',
          align: 'start',
        },
        // {
        //   title: 'APPROVED',
        //   sortable: false,
        //   key: 'approved',
        //   align: 'start',
        // },
        {
          title: 'EXPENSE NAME',
          sortable: false,
          key: 'name',
          align: 'start',
        },
        {
          title: 'DESCRIPTION',
          sortable: false,
          key: 'description',
          align: 'start',
        },
        { title: 'LINK TO', sortable: false, key: 'linkTo', align: 'start' },
        {
          title: 'BILLED BY',
          sortable: false,
          key: 'billed_to',
          align: 'start',
        },
        {
          title: 'WALLET',
          sortable: false,
          key: 'from_wallet',
          align: 'start',
        },
        { title: 'STATUS', sortable: false, key: 'status', align: 'start' },
        { title: 'DUE DATE', sortable: false, key: 'due_date', align: 'start' },
        { title: 'TYPE', sortable: false, key: 'expense_type', align: 'start' },
        {
          title: 'UNIT PRICE',
          sortable: false,
          key: 'unit_price',
          align: 'start',
        },
        { title: 'QUANTITY', sortable: false, key: 'quantity', align: 'start' },
        { title: 'VAT', sortable: false, key: 'total_tax', align: 'start' },
        { title: 'CUR', sortable: false, key: 'currency', align: 'start' },
        {
          title: 'TOTAL',
          sortable: false,
          key: 'total_amount',
          align: 'start',
        },
        { title: '', align: 'start', key: 'action', sortable: false },
      ],
    }
  },
  emits: ['selectedRows', 'loadMore'],
  methods: {
    viewProfile(item: { id: any }) {
      this.$router.push(`/financial/expense/${item?.id}`)
    },
    handleLoadMore({
      page,
      itemsPerPage,
      sortBy,
    }: {
      page: number
      itemsPerPage: number
      sortBy: { key: string; order?: 'asc' | 'desc' }[]
    }) {
      const { currentPage, currentItemsPerPage, currentSortBy } = this

      // Check if there are any changes in pagination or sorting
      const hasPaginationChanged =
        page !== currentPage || itemsPerPage !== currentItemsPerPage
      const hasSortingChanged =
        JSON.stringify(sortBy) !== JSON.stringify(currentSortBy) // Compare sorting objects

      // Only emit if there's a change in pagination or sorting
      if (hasPaginationChanged || hasSortingChanged) {
        this.$emit('loadMore', { page, itemsPerPage, sortBy })

        // Update state after the change
        this.currentPage = page
        this.currentItemsPerPage = itemsPerPage
        this.currentSortBy = sortBy
      }
    },
  },
})
