// Import necessary modules
import axios from 'axios'
import Cookies from 'js-cookie' // For managing cookies
import { BASE_URL } from '../../settings' // Import base URL from your settings file

// Create an Axios instance with default configuration
const API = axios.create({
  baseURL: BASE_URL, // Set the base URL for all requests
  headers: {
    'Content-Type': 'application/json', // Default content type for requests
  },
})

// Retrieve token from cookies or fallback to a static token
const token = Cookies.get('authaqq')
const roleId = localStorage.getItem('RoleId')

// Function to update the Authorization token dynamically
export const setAuthToken = (token: string) => {
  if (token) {
    API.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete API.defaults.headers.common['Authorization']
  }
}

// If token exists, set it in the Authorization header
if (token) {
  setAuthToken(token)
}

// Function to set additional headers, including CORS headers
export const setHeaders = (headers: object) => {
  API.defaults.headers.common = {
    ...API.defaults.headers.common,
    ...headers,
  }
}

// if role id exist, set it in the header
if (roleId) {
  setHeaders({
    RoleId: roleId,
  })
}

// Example of setting CORS headers globally
setHeaders({
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
  'Access-Control-Allow-Headers':
    'Origin, X-Requested-With, Content-Type, Accept, Authorization',
  'Access-Control-Allow-Credentials': 'true',
})

// Export the API instance to be used across the project
export default API
