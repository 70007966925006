<template>
  <router-link
    class="contact-card link"
    :to="`/legal-matters/${matter.item.id}`"
  >
    <div class="contact-card-header">
      <h3>
        <input v-if="showCheckbox" type="checkbox" v-model="isChecked" />
        {{ matter.item.name }}
      </h3>
    </div>

    <div v-if="case_manager_ids.length > 0">
      <p v-if="case_manager_ids.length === 1">{{ $t('matter.client') }}</p>
      <p v-if="case_manager_ids.length > 1">{{ $t('matter.clients') }}</p>
      <ul class="referal-list">
        <li v-for="client in case_manager_ids" :key="client.id" class="referal">
          <img :src="client.imageUrl" alt="referal.name" />
          <p>{{ client.first_name }} {{ client.last_name }}</p>
        </li>
      </ul>
    </div>

    <div v-if="personnel_ids.length > 0">
      <p v-if="personnel_ids.length === 1">
        {{ $t('matter.leadLawyer') }}
      </p>
      <p v-if="personnel_ids.length > 1">{{ $t('matter.leadLawyers') }}</p>
      <ul class="referal-list">
        <li v-for="lead in personnel_ids" :key="lead.id" class="referal">
          <img :src="lead.imageUrl" alt="referal.name" />
          <p>{{ lead.first_name }} {{ lead.last_name }}</p>
        </li>
      </ul>
    </div>
    <div v-if="case_manager_ids.length > 0">
      <p v-if="case_manager_ids.length === 1">{{ $t('matter.lawyer') }}</p>
      <p v-if="case_manager_ids.length > 1">{{ $t('matter.lawyers') }}</p>
      <ul class="referal-list">
        <li v-for="lawyer in case_manager_ids" :key="lawyer.id" class="referal">
          <img :src="lawyer.imageUrl" alt="referal.name" />
          <p>{{ lawyer.first_name }} {{ lawyer.last_name }}</p>
        </li>
      </ul>
    </div>
  </router-link>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
