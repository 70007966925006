import { defineComponent } from 'vue'
const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url
).href

export default defineComponent({
  name: 'OrganizationTable',
  props: {
    loading: {
      type: Array,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    totalItems: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      localTotalItems: this.totalItems,
      selectedRows: [],
      MoreVertIcon,
      deleteDialog: false,
      deleteItem: null,
      currentPage: 1,
      currentSortBy: [] as any,
      currentItemsPerPage: this.itemsPerPage,
      headers: [
        { title: '', align: 'start', key: 'avatar', sortable: false },
        {
          title: 'Organization Name',
          align: 'start',
          key: 'specific_data.organization_name',
          sortable: true,
        },
        // { title: 'Owner', align: 'start', key: 'relationship_owner' },
        {
          title: 'Type',
          align: 'start',
          key: 'specific_data.organization_type',
          sortable: false,
        },
        {
          title: 'Industry',
          align: 'start',
          key: 'specific_data.organization_industry',
          sortable: false,
        },
        {
          title: 'Tax Identification Number',
          align: 'start',
          key: 'specific_data.organization_tax_identification_number',
          sortable: false,
        },
        {
          title: 'Registration Incorporation Number',
          align: 'start',
          key: 'specific_data.organization_registration_number',
          sortable: false,
        },
        {
          title: 'Date of Foundation',
          align: 'start',
          key: 'specific_data.organization_date_of_foundation',
          sortable: false,
        },
        {
          title: 'Designed Point Of Contact',
          align: 'start',
          key: 'designated_point_of_contact',
          sortable: false,
        },
        {
          title: 'Website',
          align: 'start',
          key: 'website_url',
          sortable: false,
        },
        // {
        //   title: 'Social Media Links',
        //   align: 'start',
        //   key: 'social_media_links',
        //   sortable: false,
        // },
        {
          title: 'Link to Organization',
          align: 'start',
          key: 'organization',
          sortable: false,
        },
        {
          title: 'Relationship Owner',
          align: 'start',
          key: 'relationship_owner',
          sortable: false,
        },
        {
          title: 'Assigned To',
          align: 'start',
          key: 'assigned_to',
          sortable: false,
        },
        {
          title: 'Referred By',
          align: 'start',
          key: 'referred_by',
          sortable: false,
        },
        {
          title: 'About',
          align: 'start',
          key: 'specific_data.organization_about',
          sortable: false,
        },
      ],
    }
  },
  emits: ['selectedRows', 'loadMore'],
  methods: {
    handleEdit(item: any) {
      console.log('Editing', item)
    },
    confirmDelete(item: null) {
      this.deleteItem = item
      this.deleteDialog = true
    },
    confirmDeleteAction() {
      console.log('Deleting', this.deleteItem)
      this.deleteDialog = false
    },
    viewProfile(item: any) {
      console.log('Viewing profile for', item)
    },
    getTagColor(tag: string) {
      if (tag === 'Home') return 'blue'
      if (tag === 'Work') return 'green'
      return 'gray'
    },
    handleLoadMore({
      page,
      itemsPerPage,
      sortBy,
    }: {
      page: number
      itemsPerPage: number
      sortBy: { key: string; order?: 'asc' | 'desc' }[]
    }) {
      const { currentPage, currentItemsPerPage, currentSortBy } = this

      // Check if there are any changes in pagination or sorting
      const hasPaginationChanged =
        page !== currentPage || itemsPerPage !== currentItemsPerPage
      const hasSortingChanged =
        JSON.stringify(sortBy) !== JSON.stringify(currentSortBy) // Compare sorting objects

      // Only emit if there's a change in pagination or sorting
      if (hasPaginationChanged || hasSortingChanged) {
        this.$emit('loadMore', { page, itemsPerPage, sortBy })

        // Update state after the change
        this.currentPage = page
        this.currentItemsPerPage = itemsPerPage
        this.currentSortBy = sortBy
      }
    },
  },
  watch: {
    selectedRows() {
      this.$emit('selectedRows', this.selectedRows)
    },
    totalItems(newValue) {
      this.localTotalItems = newValue
    },
  },
})
