import { apiRequest } from './apiUtils'

export const getAllExpenses = ({
  per_page,
  page,
  status,
}: {
  per_page: number
  page?: number
  status?: 'not_started' | 'transferred' | 'in_progress' | 'not_retained'
}) => {
  return apiRequest('get', '/expenses', null, {
    per_page,
    page,
    status,
  })
}

export const getExpenseById = (id: string) => {
  return apiRequest('get', `/expenses/${id}`)
}

export const addExpense = (body: any) => {
  return apiRequest('post', '/expenses', body)
}

export const updateExpense = (id: string, body: any) => {
  return apiRequest('put', `/expenses/${id}`, body)
}

export const deleteExpense = (id: string) => {
  return apiRequest('delete', `/expenses/${id}`)
}
