<template>
  <div class="tabs-bar" :style="{ gap: gapSize }" :class="{ 'full-tab': fullTab }">
    <div v-for="tab in tabs" :key="tab.id" @mouseover="hoveredTabId = tab.id" @mouseout="hoveredTabId = null"
      class="tab" :class="{ active: tab.id === activeTabId }" @click="changeTab(tab.id)">

      <VaIcon v-if="tab.icon" :name="tab.icon" size="small" color="primary" />

      <p v-if="tab.title">{{ tab.title }}</p>
      <p v-if="tab.name">{{ tab.name }}</p>


    </div>
    <div class="tabs-buttons">
      <VaButton round icon="add" preset="secondary" border-color="primary" size="small" @click="handleOpenAddTab" />
      <VaButton round icon="edit" preset="secondary" border-color="primary" size="small" @click="handleEditTab" />
    </div>

  </div>
  <VaModal v-model="IsTabOpen" ok-text="Save" size="auto" :ok="handleAddTab()">
    <h6 class="va-h3">
      Add New Tab
    </h6>
    <VaInput v-model="value" placeholder="Enter Tab Name" />
  </VaModal>
</template>






<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
