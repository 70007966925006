<template>
  <VaModal
    v-model="isAddTaskFormBool"
    size="auto"
    hide-default-actions
    fixed-layout
    noOutsideDismiss
    @update:modelValue="handleToggleAddTaskModal"
  >
    <template #header>
      <h1 class="add-task-form-modal-header">
        Add a Task
        <VaIcon
          name="close"
          size="30px"
          class="icon"
          @click="handleToggleAddTaskModal"
        />
      </h1>
    </template>
    <VaForm ref="form" class="add-task-form-modal">
      <div class="add-task-container">
        <VaInput
          placeholder="Title"
          background="#ECF0F1"
          v-model="form.name"
          style="width: 100%"
          label="title"
          requiredMark
          :rules="[(value) => value && value.length > 0]"
        />

        <div class="icon-container">
          <VaSelect
            background="#ECF0F1"
            v-model="form.tag"
            placeholder="Select Multiple"
            style="width: 50%; height: 2rem; margin-bottom: 1.5rem"
            label="tag"
            :options="['Hot', 'Important', 'Cool', 'Icebox']"
            :highlight-matched-text="false"
            :rules="[(v) => !!v]"
            multiple
            selected-top-shown
            :max-visible-options="2"
          />

          <div class="icon-sub-container">
            <VaPopover placement="top" message="Time Range">
              <TimeRange
                @update:date="handleSelectedRangeDate"
                :date="time_range"
                shape="button-icon"
              />
            </VaPopover>

            <VaPopover placement="top" message="Due Date">
              <DatePickerModal
                @update:date="handleSelectedDate"
                :date="due_time"
                shape="button-icon"
              />
            </VaPopover>

            <VaPopover placement="top" message="Attach a file">
              <VaFileUpload v-model="file">
                <VaButton color="#ECF0F1">
                  <img :src="AttachFileIcon" alt="" />
                </VaButton>
              </VaFileUpload>
            </VaPopover>

            <VaPopover placement="top" message="Flag">
              <VaMenu>
                <template #anchor>
                  <VaButton color="#ECF0F1">
                    <img :src="FlagIcon" alt="" style="color: aquamarine" />
                  </VaButton>
                </template>
                <VaMenuItem
                  v-for="(flag, index) in flags"
                  :key="index"
                  @click="handleFlagMenuItemClick(flag)"
                >
                  <VaIcon name="flag" :color="flag.color" />
                  {{ flag.name }}
                </VaMenuItem>
              </VaMenu>
            </VaPopover>

            <VaMenu class="add-task-options">
              <template #anchor>
                <VaButton preset="secondary">
                  <img :src="MoreVertIcon" alt="more-vert" />
                </VaButton>
              </template>

              <VaMenuItem @click="alert"> Add Watcher </VaMenuItem>
              <VaMenuItem @click="alert"> Add Time Estimate </VaMenuItem>
              <h1 class="vmenu-p">Dependencies</h1>
              <VaMenuItem @click="alert"> Waiting on </VaMenuItem>
              <VaMenuItem @click="alert"> Blocking </VaMenuItem>
            </VaMenu>
          </div>
        </div>

        <div style="width: 100%">
          <VaSelect
            placeholder="Status"
            background="#ECF0F1"
            v-model="form.section_stage_id"
            style="width: 50%; height: 2rem; margin-bottom: 1.5rem"
            label="Status"
            :options="$stores.mockTasks.status"
            :highlight-matched-text="false"
            :rules="[(v) => !!v]"
            :text-by="(option) => option.name"
            value-by="id"
          />
        </div>

        <div class="select-checkbox-container">
          <div style="width: 70%">
            <VaSelect
              placeholder="Task Owner"
              background="#ECF0F1"
              v-model="form.owner_id"
              style="width: 100%; height: 2rem; margin-bottom: 1.5rem"
              label="Task Owner"
              clearable
              searchable
              :loading="membersLoading"
              :options="teamMembers"
              :highlight-matched-text="false"
              :text-by="(option) => `${option.first_name} ${option.last_name}`"
              value-by="id"
            />
          </div>

          <VaCheckbox
            style="height: 0.5rem"
            v-model="form.isOwner_counsel"
            class="mb-6"
            label="Of Counsel"
          />
        </div>

        <div class="select-checkbox-container">
          <div style="width: 70%">
            <VaSelect
              placeholder="Assigned To"
              clearable
              background="#ECF0F1"
              v-model="form.assigned_to_id"
              style="width: 100%; height: 2rem; margin-bottom: 1.5rem"
              label="assigned to"
              searchable
              :loading="membersLoading"
              :options="teamMembers"
              :highlight-matched-text="false"
              :text-by="(option) => `${option.first_name} ${option.last_name}`"
              value-by="id"
            />
          </div>

          <VaCheckbox
            style="height: 0.5rem"
            v-model="form.isAssigned_counsel"
            class="mb-6"
            label="Of Counsel"
          />
        </div>

        <VaTextarea
          placeholder="Add description..."
          max-rows="11"
          v-model="form.description"
          label="Description"
          style="width: 100%; min-height: 10rem"
          autosize
          background="#ECF0F1"
        />

        <VaInput
          placeholder="Link To"
          background="#ECF0F1"
          v-model="form.link_to"
          disabled
          style="width: 100%"
          label="link to"
        >
          <template #prepend>
            <VaIcon name="link" class="mr-2" color="secondary" />
          </template>
        </VaInput>
        <VaInput
          placeholder="Location"
          background="#ECF0F1"
          v-model="form.location"
          style="width: 100%"
          label="location"
        >
          <template #prepend>
            <VaIcon name="location_on" class="mr-2" color="secondary" />
          </template>
        </VaInput>

        <VaInput
          placeholder="Note"
          background="#ECF0F1"
          v-model="form.notes"
          style="width: 100%"
          label="note"
        >
          <template #prepend>
            <VaIcon name="event_note" class="mr-2" color="secondary" />
          </template>
        </VaInput>

        <VaInput
          placeholder="Budget"
          background="#ECF0F1"
          v-model="form.budget"
          style="width: 100%"
          label="budget"
        >
          <template #prepend>
            <VaIcon name="attach_money" class="mr-2" color="secondary" />
          </template>
        </VaInput>

        <VaSwitch v-model="form.billable" label="bill task" size="small" />
        <!-- <VaCheckbox
          v-model="form.pre_charged"
          class="mb-6"
          label="pre-charge task"
          size="small"
        /> -->

        <!-- <div v-if="form.pre_charged" class="pre-charged-container">
          <VaInput
            background="#ECF0F1"
            v-model="form.value"
            style="width: 70%"
            label="title"

            requiredMark
            :rules="[(value) => (value && value.length > 0) ]"
          />

          <VaSelect
            background="#ECF0F1"
            v-model="form.currency"
            style="width: 19%; height: 2rem; margin-bottom: 1.5rem"
            label="currency"

            requiredMark
            :options="['USD', 'LBP', 'EUR', 'USDT']"
            :highlight-matched-text="false"
            :rules="[(v) => !!v ]"
          />
        </div> -->
      </div>
    </VaForm>
    <template #footer>
      <div class="add-task-form-modal-buttons">
        <VaButton preset="secondary" border-color="primary" disabled
          >Templates</VaButton
        >
        <VaButton
          style="width: 79%"
          :disabled="addTaskLoading"
          @click="validate() && handleCreateTask()"
          >Save
        </VaButton>
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
