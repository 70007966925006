<template>
  <div class="kanban-row">
    <div
      v-for="(stage, stageIndex) in stages"
      :key="stage?.id"
      :data-stage-index="stageIndex"
      class="kanban-column"
    >
      <div
        class="kanban-column-header"
        :style="{
          color: stage?.color || 'black',
          borderBottom: '0.5rem solid ' + (stage?.color || 'red'),
        }"
      >
        <h3>{{ stage?.name }}</h3>
        {{ stage.section_stage_items_count }}
      </div>
      <div v-if="isLoading" class="kanban-list-group">
        <VaSkeleton
          style="border-radius: 8px"
          inline
          width="269px"
          height="153px"
        />
        <VaSkeleton
          style="border-radius: 8px"
          inline
          width="269px"
          height="153px"
        />
      </div>
      <draggable
        v-model="localStages[stageIndex].tickets"
        group="tickets"
        @end="onDragEnd"
        class="kanban-list-group"
        :data-stage-id="stage.id"
      >
        <template v-if="isTasks" #item="{ element }">
          <TaskCard :task="element" />
        </template>

        <template v-else-if="isLegalMatters" #item="{ element }">
          <LegalMatterCard :matter="element" />
        </template>
      </draggable>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss"></style>
