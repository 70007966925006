import { defineComponent } from 'vue'
import draggable from 'vuedraggable'

export default defineComponent({
  name: 'paymentsHistoryTable',
  components: {
    draggable,
  },
  props: {
    headers: {
      type: Array as () => Array<{
        name: string
        field: string
        width: string
        id: number
      }>,
      required: true,
    },
    list: {
      type: Array as () => Array<{ [key: string]: string | number | boolean }>,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      dragging: false,
      refinedHeaders: [] as Array<{
        name: string
        field: string
        width: string
        id: number
      }>,
    }
  },
  methods: {
    generateHeaders() {
      this.refinedHeaders = this.headers.map((header) => {
        console.log(header)
        return {
          name: this.capitalize(header.field),
          field: header.field,
          width: header.width,
          id: header.id,
        }
      })
    },
    capitalize(key: string) {
      console.log(key)
      return key.charAt(0).toUpperCase() + key.slice(1)
    },
  },
  created() {
    this.generateHeaders()
  },
})
