import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Row',
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    isTotal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isRed(): boolean {
      console.log(this.value)
      return this.value < 0
    },
    isGreen(): boolean {
      console.log(this.value)
      return this.value > 0
    },
    displayValue(): string {
      if (this.value) {
        return new Intl.NumberFormat().format(Math.abs(this.value))
      }
      return ''
    },
  },
})
