import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'

export default defineComponent({
  name: 'ChatPage',
  data() {
    return {
      isCollapsed: true,
      tabs: [
        { id: 0, title: 'CONTACTS', },
        { id: 1, title: 'MEMBERS' },
        { id: 2, title: 'GROUPS' },
      ],
      activeTabId: 0,
      activeTab: 'CONTACTS',
    }
  },
  methods: {
    changeTab(tab: { title: string; id: number }) {
      this.activeTabId = tab.id
      this.activeTab = tab.title
    },
  },
  components: {
    TabsBar,
  }
})
