import { defineComponent } from 'vue'
import VueApexCharts from 'vue3-apexcharts'

export default defineComponent({
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [
        {
          name: 'Graph Label 1',
          data: [44, 55, 41, 64, 22, 43, 21, 67, 98, 55, 34, 67],
        },
        {
          name: 'Graph Label 2',
          data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 90, 78, 86],
        },
        {
          name: 'Graph Label 3',
          data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 36, 26, 45],
        },
      ],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
        },
        yaxis: {
          show: false,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val: number) {
              return '$ ' + val
            },
          },
        },
        grid: {
          show: false,
        },
        colors: ['#A0AEC0', '#D9D9D9', '#52709b'],
      },
      info: [
        {
          id: 1,
          title: 'Operating Activities',
          value: '$12,514',
        },
        {
          id: 2,
          title: 'Investing Activities',
          value: '$3,514',
        },
        {
          id: 3,
          title: 'Financing Activities',
          value: '$7,514',
        },
        {
          id: 4,
          title: 'Net Cash Flow',
          value: '$5,514',
        },
      ],
    }
  },
})
