import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e2be4bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs-btns-container" }
const _hoisted_2 = { class: "tabs-bar" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createElementBlock("div", {
          key: tab.id,
          class: _normalizeClass(["tab", { active: tab.id === _ctx.activeTabId }]),
          onClick: ($event: any) => (_ctx.changeTab(tab.id))
        }, _toDisplayString(tab.title), 11, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Button, {
        "button-text": "Period",
        "text-color": '#2D3748',
        "icon-before": _ctx.Calendar,
        "custom-styles": {
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#fff',
          border: '0.5px solid #E2E8F0',
        },
        "hover-styles": {
          border: '0.5px solid #2D3748',
        },
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('period')))
      }, null, 8, ["icon-before"]),
      _createVNode(_component_Button, {
        "button-text": "Contact 1",
        "text-color": '#2D3748',
        "icon-before": _ctx.Person,
        "custom-styles": {
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#fff',
          border: '0.5px solid #E2E8F0',
        },
        "hover-styles": {
          border: '0.5px solid #2D3748',
        },
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('Contact 1')))
      }, null, 8, ["icon-before"]),
      _createVNode(_component_Button, {
        "button-text": "Contact 2",
        "text-color": '#2D3748',
        "icon-before": _ctx.Person,
        "custom-styles": {
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#fff',
          border: '0.5px solid #E2E8F0',
        },
        "hover-styles": {
          border: '0.5px solid #2D3748',
        },
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('Contact 2')))
      }, null, 8, ["icon-before"]),
      _createVNode(_component_Button, {
        "button-text": "Currency",
        "text-color": '#2D3748',
        "icon-before": _ctx.Money,
        "custom-styles": {
          padding: '10px 15px',
          borderRadius: '5px',
          fontSize: '12px',
          fontWeight: '500',
          backgroundColor: '#fff',
          border: '0.5px solid #E2E8F0',
        },
        "hover-styles": {
          border: '0.5px solid #2D3748',
        },
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('Currency')))
      }, null, 8, ["icon-before"])
    ])
  ]))
}