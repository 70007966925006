<template>
  <VaModal
    v-model="isAddContactFormBool"
    size="auto"
    hide-default-actions
    fixed-layout
    @update:modelValue="toggleAddContactForm"
  >
    <template #header>
      <div class="create-form-header">
        <h1>Add Contacts</h1>
        <VaIcon
          class="header-icon"
          name="close"
          size="30px"
          @click="toggleAddContactForm"
        />
      </div>
    </template>
    <VaForm ref="form" class="add-contact-form-modal">
      <div class="add-contact-form-modal-body">
        <div class="left">
          <VaFileUpload v-model="files" file-types="csv,png" undo>
            <div class="custom-upload-file">
              <img :src="UploadAvatarIcon" alt="" />
              <div class="custom-upload-file-text">
                <h1>Upload Profile Picture</h1>
                <h2>RECOMMENDED 1000X1000 PX</h2>
                <h3>Max. 1.3Mb</h3>
                <div>
                  <VaButton
                    preset="secondary"
                    border-color="primary"
                    size="small"
                    >Upload</VaButton
                  >
                </div>
              </div>
            </div>
          </VaFileUpload>

          <VaInput
            placeholder="Title"
            background="#ECF0F1"
            v-model="form.individual_title"
            style="width: 100%"
            label="title"
          />

          <VaInput
            placeholder="First Name"
            background="#ECF0F1"
            v-model="form.individual_first_name"
            style="width: 100%"
            :rules="[(value) => value && value.length > 0]"
            label="First Name"
            requiredMark
          />

          <VaInput
            placeholder="Middle Name"
            background="#ECF0F1"
            v-model="form.individual_middle_name"
            style="width: 100%"
            label="Middle Name"
          />

          <VaInput
            placeholder="Last Name"
            background="#ECF0F1"
            v-model="form.individual_last_name"
            style="width: 100%"
            :rules="[(value) => value && value.length > 0]"
            label="Last Name"
            requiredMark
          />

          <VaInput
            placeholder="Suffix"
            background="#ECF0F1"
            v-model="form.individual_suffix"
            style="width: 100%"
            label="Suffix"
          />

          <VaInput
            placeholder="Job Title"
            background="#ECF0F1"
            v-model="form.individual_job_title"
            style="width: 100%"
            label="job title"
          />

          <VaInput
            placeholder="Tax Identification Number"
            background="#ECF0F1"
            v-model="form.individual_tax_identification_number"
            style="width: 100%"
            label="Tax Identification Number"
          />

          <div class="form-sex">
            <p>Gender</p>
            <VaRadio v-model="form.individual_gender" :options="sexOptions" />
          </div>

          <VaDateInput
            placeholder="MM/DD/YYYY"
            label="Date Of Birth"
            background="#ECF0F1"
            v-model="form.individual_date_of_birth"
            manual-input
          />

          <VaInput
            placeholder="Primary Email"
            background="#ECF0F1"
            v-model="form.email_addresses[0].email_address"
            style="width: 100%"
            :rules="[
                (value: string) => !!value,
                (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || 'Invalid email address'
              ]"
            label="Primary Email"
            requiredMark
          />

          <VaInput
            placeholder="Personal Website"
            background="#ECF0F1"
            v-model="form.website_url"
            style="width: 100%"
            label="Personal Website"
          />

          <VaInput
            placeholder="Primary Phone Number"
            background="#ECF0F1"
            v-model="form.phone_numbers[0].phone_number"
            style="width: 100%"
            label="Primary Phone Number"
            requiredMark
            :rules="[(value) => value && value.length > 0]"
          />

          <VaInput
            placeholder="Nationality"
            background="#ECF0F1"
            v-model="form.individual_nationality"
            style="width: 100%"
            label="Nationality"
          />

          <div>
            <VaSelect
              placeholder="Link To Organization"
              background="#ECF0F1"
              v-model="form.organization_contact_id"
              style="width: 100%; height: auto"
              label="Link To Organization"
              searchable
              clearable
              :highlight-matched-text="false"
              :options="organizations"
              :text-by="
                (option) =>
                  truncateText(option.specific_data.organization_name, 50)
              "
              :loading="organizationsLoading"
              @update:search="handleSearchOrganizations"
            />
          </div>

          <VaSelect
            placeholder="Relationship Owner"
            background="#ECF0F1"
            v-model="form.relationship_owner_id"
            style="width: 100%; height: auto"
            label="Relationship Owner"
            requiredMark
            searchable
            :options="teamMembers"
            :highlight-matched-text="false"
            :text-by="(option) => `${option.first_name} ${option.last_name}`"
            value-by="id"
            :rules="[(v) => !!v]"
          />

          <VaSelect
            placeholder="Assigned To"
            background="#ECF0F1"
            v-model="form.assigned_to_id"
            style="width: 100%; height: auto"
            label="Assigned To"
            requiredMark
            searchable
            :options="teamMembers"
            :highlight-matched-text="false"
            :text-by="(option) => `${option.first_name} ${option.last_name}`"
            value-by="id"
            :rules="[(v) => !!v]"
          />
          <div>
            <VaSelect
              placeholder="Referred by"
              background="#ECF0F1"
              v-model="form.referred_by_id"
              style="width: 100%; height: auto"
              label="Referred by"
              searchable
              clearable
              :highlight-matched-text="false"
              :options="contacts"
              :text-by="
                (option) =>
                  `${option.specific_data.individual_first_name} ${option.specific_data.individual_last_name}`
              "
              :loading="contactsLoading"
              @update:search="handleSearchContacts"
            />
          </div>

          <VaTextarea
            max-rows="7"
            v-model="form.notes"
            label="Notes"
            style="width: 100%"
            autosize
            background="#ECF0F1"
            placeholder="Add your note here..."
          />

          <VaAccordion v-model="isAddressAccordion">
            <VaCollapse header="Address" class="address-accordion">
              <div class="address-accordion">
                <div style="width: 65.5%">
                  <VaSelect
                    placeholder="Country"
                    background="#ECF0F1"
                    v-model="form.country"
                    :loading="countriesLoading"
                    style="width: 100%; height: auto"
                    label="Country"
                    requiredMark
                    searchable
                    :options="countries?.list"
                    :highlight-matched-text="false"
                    :text-by="(option) => option.name"
                    value-by="id"
                    :rules="[(v) => !!v]"
                    @update:modelValue="handleGetState"
                  />
                </div>
                <VaInput
                  placeholder="Zip Code"
                  background="#ECF0F1"
                  v-model="form.contact_addresses_attributes[0].zip_code"
                  style="width: 28.5%; padding-top: 0.3rem"
                  label="Zip Code"
                />

                <VaSelect
                  placeholder="Province/State"
                  background="#ECF0F1"
                  v-model="form.province_state"
                  :loading="statesLoading"
                  style="width: 100%; height: auto"
                  label="Province/State"
                  requiredMark
                  searchable
                  :options="states?.list"
                  :highlight-matched-text="false"
                  :text-by="(option) => option.name"
                  value-by="id"
                  :rules="[(v) => !!v]"
                  @update:modelValue="handleGetCities"
                />

                <VaSelect
                  placeholder="City"
                  background="#ECF0F1"
                  v-model="form.contact_addresses_attributes[0].city_id"
                  :loading="citiesLoading"
                  style="width: 100%; height: auto"
                  label="City"
                  requiredMark
                  searchable
                  :options="cities?.list"
                  :highlight-matched-text="false"
                  :text-by="(option) => option.name"
                  value-by="id"
                  :rules="[(v) => !!v]"
                />

                <VaInput
                  placeholder="Street"
                  background="#ECF0F1"
                  v-model="form.contact_addresses_attributes[0].street_name"
                  style="width: 100%"
                  label="Street"
                />

                <VaInput
                  placeholder="Building"
                  background="#ECF0F1"
                  v-model="form.contact_addresses_attributes[0].building_name"
                  style="width: 100%"
                  label="Building"
                />

                <VaInput
                  placeholder="Office Number"
                  background="#ECF0F1"
                  v-model="form.contact_addresses_attributes[0].phone_number"
                  style="width: 48.5%"
                  label="Office Number"
                />

                <VaInput
                  placeholder="Floor"
                  background="#ECF0F1"
                  v-model="form.contact_addresses_attributes[0].floor"
                  style="width: 48.5%"
                  label="Floor"
                />

                <VaInput
                  placeholder="Google Maps Link"
                  background="#ECF0F1"
                  v-model="form.contact_addresses_attributes[0].google_maps_url"
                  style="width: 100%"
                  label="Google Maps Link"
                />
              </div>
            </VaCollapse>
          </VaAccordion>
        </div>

        <VDivider vertical />
        <div class="right">
          <VaCheckbox
            v-model="form.kyc_completed"
            class="mb-6"
            label="KYC Complete"
          />

          <VaTextarea
            max-rows="11"
            v-model="form.kyc_notes"
            label="KYC Notes"
            style="width: 100%"
            autosize
            background="#ECF0F1"
            placeholder="Add your KYC note here..."
          />

          <div class="custom-upload-file-area-kyc-container">
            <h3>KYC DOCUMENTS</h3>
            <VaFileUpload v-model="kyc_documents">
              <div class="custom-upload-file-area-kyc">
                <p class="mb-2">Upload or drag and drop files here</p>
              </div>
            </VaFileUpload>
          </div>

          <VaTextarea
            max-rows="11"
            v-model="form.extra_notes"
            label="Office Notes"
            style="width: 100%"
            autosize
            background="#ECF0F1"
            placeholder="Add your Office note here..."
          />
        </div>
      </div>
    </VaForm>
    <template #footer>
      <div class="add-contact-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="toggleAddContactForm"
          >Cancel</VaButton
        >
        <VaButton
          :loading="addContactLoading"
          @click="validate() && handleCreateContact()"
          >Save</VaButton
        >
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
