<template>
  <div class="contact-card">
    <div class="contact-card-header">
      <h3>{{ contact.name }}</h3>
      <div class="line" />
      <p>{{ $t('contacts.referalName') }}</p>
    </div>
    <div>
      <ul class="referal-list">
        <li
          v-for="referal in contact.referals"
          :key="referal.id"
          class="referal"
        >
          <img :src="referal.imageUrl" alt="referal.name" />
          <p>{{ referal.name }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
