<template>
  <router-link
    class="task-card link"
    :to="{ path: `/tasks/${task.item.id}`, force: true }"
  >
    <div class="task-card__title">
      <h1>
        <input v-if="showCheckbox" type="checkbox" v-model="isChecked" />
        <VaIcon name="flag" size="large" color="primary" />
        {{ task.item.name }}
      </h1>
    </div>
    <div class="task-card__info">
      <h4>{{ task.item.description }}</h4>
      <p class="value"><span>Value</span> : $ {{ task.item.budget }}</p>
      <div class="employee-info">
        <img :src="task.item.assigned_to?.avatar.thumbnail" alt="" />
        <p>
          {{ task.item.assigned_to?.first_name }}
          {{ task.item.assigned_to?.last_name }}
        </p>
      </div>
      <div class="card-date">
        <p>{{ formattedDate }}</p>
      </div>
      <p class="companyName">
        {{ task.item.owner?.first_name }} {{ task.item.owner?.last_name }}
      </p>
    </div>
  </router-link>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" />
