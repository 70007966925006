<template>
  <div class="column">
    <div class="column-header">
      <h3>{{ title }}</h3>
    </div>
    <draggable
      class="list-group"
      :list="list"
      group="people"
      @change="log"
      itemKey="name"
    >
      <template #item="{ element }">
        <div
          style="width: 100%"
          @click="navigateToDetail(element.id)"
          @mousedown.stop
          @touchstart.stop
        >
          <ContactCard v-if="isContacts" :contact="element" />
          <LegalMatterCard v-if="isLegalMatters" :matter="element" />
          <TaskCard v-if="isTasks" :matter="element" />
          <FinancialCard v-if="isFinancial" :expense="element" />
        </div>
      </template>
    </draggable>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
