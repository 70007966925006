import { apiRequest } from './apiUtils'

export const getAllInvoices = ({
  per_page,
  page,
  status,
}: {
  per_page: number
  page?: number
  status?: 'pending' | 'paid'
}) => {
  return apiRequest('get', '/invoices', null, {
    per_page,
    page,
    status,
  })
}

export const getNextInvoiceNumber = () => {
  return apiRequest('get', '/invoices/next_invoice_number')
}

export const getInvoiceById = (id: string) => {
  return apiRequest('get', `/invoices/${id}`)
}

export const addInvoice = (body: any) => {
  return apiRequest('post', '/invoices', body)
}

export const updateInvoice = (id: string, body: any) => {
  return apiRequest('put', `/invoices/${id}`, body)
}

export const deleteInvoice = (id: string) => {
  return apiRequest('delete', `/invoices/${id}`)
}
