<template>
  <router-link :to="`/hearings/${id}`">
    <VaCard class="task-card link">
      <VaCardTitle>
        <h1>{{ title }}</h1>
      </VaCardTitle>
      <div class="card-content">

        <div class="card-date">
          <span>
            <VaIcon name="calendar_month" size="medium" />
            Court Date : <p>{{ date }} </p>
          </span>

        </div>

        <div class="card-assign-to">
          <p>Assigned To</p>
          <span>
            <VaIcon name="account_circle" size="2rem" />
            {{ assignedTo }}
          </span>
        </div>

        <div class="card-status">
          <p>Status</p>
          <td :class="`status-td status-${status.toLowerCase().replace(' ', '-')}`">
            {{ status }}
          </td>
        </div>

        <div class="card-assign-to">
          <p>Participation of Clients</p>
          <span>
            <VaIcon name="account_circle" size="2rem" />
            {{ client }}
          </span>
        </div>

        <div class="card-assign-to">
          <p>Participation of Lawyers</p>
          <span>
            <VaIcon name="account_circle" size="2rem" />
            {{ lawyer }}
          </span>
        </div>



      </div>
    </VaCard>
  </router-link>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss"></style>