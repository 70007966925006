<template>
  <div class="table">
    <div class="col-8">
      <table class="table">
        <thead class="table-header">
          <tr class="header-row">
            <th scope="col" v-for="header in headers" :key="header">
              {{ header.name }}
            </th>
          </tr>
        </thead>
        <draggable v-model="list" tag="tbody" item-key="name">
          <template #item="{ element }">
            <tr>
              <td scope="row">{{ element.id }}</td>
              <td>{{ element.name }}</td>
              <td>{{ element.email }}</td>
            </tr>
          </template>
        </draggable>
      </table>
    </div>

    <rawDisplayer class="col-3" :value="list" title="List" />
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style scoped lang="scss" src="./styles.scss"></style>
