<template>
  <p>ORGANIZATION ID: #{{ organizationDetails.id }}</p>
  <SharedHeader
    titleWidth="30rem"
    :title="organizationDetails?.specific_data?.organization_name"
  >
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left"></div>
      <div class="sub-SharedHeader-right"></div>
    </div>
  </SharedHeader>

  <TabsBar :tabs="tabs" :activeTabId="activeTabId" @changeTab="changeTab" />

  <VaForm
    v-if="activeTabId == 1"
    ref="organizationDetailsFormRef"
    class="contact-info-container"
  >
    <div class="contact-image">
      <!-- <img src="https://i.pravatar.cc/302" class="contact-profile"
        :alt="`${contactDetails.first_name || ''} ${contactDetails.middle_name || ''} ${contactDetails.last_name || ''}`"> -->
      <div class="avatar-container">
        <!-- <img v-if="form?.specific_data" :src="form.specific_data?.individual_profile_picture?.url" alt="Avatar"
          class="avatar" /> -->
        <div class="avatar-fallback">
          {{ organizationDetails?.specific_data?.organization_name[0] }}
        </div>
      </div>
      <p>
        <img
          :src="verifiedImage"
          alt="VERIFIED"
          class="contact-profile-icon"
        />Verified <br />
        HAQQ User
      </p>
      <div class="delete-button">
        <VaButton @click="isDeleteModal = !isDeleteModal"> Delete </VaButton>
        <VaModal
          v-model="isDeleteModal"
          message="Would you like to Delete?"
          ok-text="Delete"
          size="auto"
          @ok="handleDelete"
        />
      </div>
    </div>

    <VaInput
      placeholder="Organization Name"
      background="#ECF0F1"
      v-model="form.specific_data.organization_name"
      style="width: 49%"
      label="organization name"
      requiredMark
      :rules="[(value) => value && value.length > 0]"
    />

    <VaInput
      placeholder="Email"
      background="#ECF0F1"
      v-model="form.organization_owner"
      style="width: 49%"
      label="organization Owner"
    />

    <div style="width: 49%">
      <VaSelect
        background="#ECF0F1"
        v-model="form.specific_data.organization_type"
        style="width: 100%; height: 2rem; margin-bottom: 1.5rem"
        label="Organization Type"
        requiredMark
        :options="organizationTypeOptions"
        :highlight-matched-text="false"
        :text-by="(option) => option.name"
        value-by="id"
      />
    </div>

    <div style="width: 49%">
      <VaSelect
        placeholder="Organization Industry"
        background="#ECF0F1"
        v-model="form.specific_data.organization_industry"
        style="width: 100%; height: 2rem; margin-bottom: 1.5rem"
        label="industry"
        selected-top-shown
        :options="['TYPE 1', 'TYPE 2']"
        :highlight-matched-text="false"
      />
    </div>

    <VaInput
      placeholder="Tax Identification Number"
      background="#ECF0F1"
      v-model="form.specific_data.organization_tax_identification_number"
      style="width: 49%"
      label="tax identification number"
    />

    <VaInput
      placeholder="Registration Incorporation Number"
      background="#ECF0F1"
      v-model="form.specific_data.organization_registration_number"
      style="width: 49%"
      label="registration incorporation Number"
    />
    <div style="width: 49%">
      <VaDateInput
        placeholder="MM/DD/YYYY"
        label="Date Of Foundation"
        background="#ECF0F1"
        style="width: 100%"
        v-model="form.specific_data.organization_date_of_foundation"
        manual-input
      />
    </div>
    <div style="width: 49%">
      <VaSelect
        placeholder="Designed Point Of Contact"
        background="#ECF0F1"
        requiredMark
        v-model="form.designated_point_of_contact.id"
        style="width: 100%; height: 2rem; margin-bottom: 1.8rem"
        label="Designated point of contact"
        searchable
        :options="teamMembers"
        :highlight-matched-text="false"
        :text-by="(option) => `${option.first_name} ${option.last_name}`"
        value-by="id"
        :rules="[(v) => !!v]"
      />
    </div>
    <div style="width: 49%">
      <VaSelect
        placeholder="Jurisdiction Of Incorporation"
        background="#ECF0F1"
        v-model="form.specific_data.organization_jurisdiction_of_incorporation"
        :loading="countriesLoading"
        style="width: 100%; height: 2rem; margin-bottom: 1.5rem"
        label="jurisdiction of incorporation"
        searchable
        requiredMark
        :options="countries?.list"
        :highlight-matched-text="false"
        :text-by="(option) => option.name"
        value-by="name"
        :rules="[(v) => !!v]"
      />
    </div>

    <VaInput
      placeholder="Organizational Website"
      background="#ECF0F1"
      v-model="form.website_url"
      style="width: 49%"
      label="Organization website"
    />
    <div style="width: 49%">
      <VaSelect
        placeholder="Link To Organization"
        background="#ECF0F1"
        v-model="form.organization_contact"
        style="width: 100%; height: auto"
        label="Link To Organization"
        searchable
        clearable
        :highlight-matched-text="false"
        :options="organizations"
        :text-by="
          (option) => truncateText(option.specific_data?.organization_name, 50)
        "
        :loading="organizationsLoading"
        @update:search="handleSearchOrganizations"
      />
    </div>

    <div style="width: 49%">
      <VaSelect
        placeholder="Relationship Owner"
        background="#ECF0F1"
        v-model="form.relationship_owner.id"
        style="width: 100%; height: 2rem"
        label="Relationship Owner"
        requiredMark
        searchable
        :options="teamMembers"
        :highlight-matched-text="false"
        :text-by="(option) => `${option.first_name} ${option.last_name}`"
        value-by="id"
      />
    </div>

    <div style="width: 49%">
      <VaSelect
        placeholder="Assigned To"
        background="#ECF0F1"
        v-model="form.assigned_to.id"
        style="width: 100%; height: 2rem; margin-bottom: 1.8rem"
        label="Assigned To"
        requiredMark
        searchable
        :options="teamMembers"
        :highlight-matched-text="false"
        :text-by="(option) => `${option.first_name} ${option.last_name}`"
        value-by="id"
        :rules="[(v) => !!v]"
      />
    </div>
    <div style="width: 49%">
      <VaSelect
        placeholder="Referred by"
        background="#ECF0F1"
        v-model="form.referred_by"
        style="width: 100%; height: auto"
        label="Referred by"
        searchable
        clearable
        :highlight-matched-text="false"
        :options="contacts"
        :text-by="
          (option) =>
            `${option?.specific_data?.individual_first_name} ${option?.specific_data?.individual_last_name}`
        "
        :loading="contactsLoading"
        @update:search="handleSearchContacts"
      />
    </div>

    <div style="width: 49%">
      <div v-for="(phoneNB, index) in form.phone_numbers" :key="index">
        <VaInput
          placeholder="Phone Number"
          background="#ECF0F1"
          v-model="phoneNB.phone_number"
          style="width: 100%"
          :label="`${phoneNB.label}`"
          requiredMark
          :rules="[(value) => value && value.length > 0]"
        />
      </div>
      <!-- Button to add more email input fields -->
      <!-- <button @click="addEmailAddress">Add Another Email</button> -->
    </div>

    <div style="width: 49%">
      <div v-for="(emailAddress, index) in form.email_addresses" :key="index">
        <VaInput
          placeholder="Email"
          background="#ECF0F1"
          v-model="emailAddress.email_address"
          style="width: 100%"
          :rules="[
      (value: string) => !!value ,
      (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || 'Invalid email address'
    ]"
          :label="`${emailAddress.label}`"
          requiredMark
        />
      </div>
      <!-- Button to add more email input fields -->
      <!-- <button @click="addEmailAddress">Add Another Email</button> -->
    </div>

    <VaTextarea
      max-rows="7"
      v-model="form.specific_data.organization_about"
      label="about "
      style="width: 99%"
      autosize
      placeholder="Add your note here..."
      background="#ECF0F1"
    />

    <VaAccordion v-model="isAddressAccordion">
      <VaCollapse header="Address">
        <template #content>
          <div style="width: 79%">
            <VaSelect
              background="#ECF0F1"
              v-model="form.contact_addresses[0].country.name"
              :loading="countriesLoading"
              style="width: 100%; height: 2rem"
              label="Country"
              requiredMark
              searchable
              :options="countries?.list"
              :highlight-matched-text="false"
              :text-by="(option) => option.name"
              value-by="id"
              :rules="[(v) => !!v]"
              @update:modelValue="handleGetState"
            />
          </div>
          <VaInput
            background="#ECF0F1"
            v-model="form.contact_addresses[0].zip_code"
            style="width: 19%"
            label="Postal/Zip Code"
          />
          <div style="width: 49%">
            <VaSelect
              background="#ECF0F1"
              v-model="form.contact_addresses[0].state.name"
              :loading="statesLoading"
              style="width: 100%; height: 2rem; margin-bottom: 1.8rem"
              label="Province/State"
              searchable
              requiredMark
              :options="states?.list"
              :highlight-matched-text="false"
              :text-by="(option) => option.name"
              value-by="id"
              :rules="[(v) => !!v]"
              @update:modelValue="handleGetCities"
            />
          </div>

          <div style="width: 49%">
            <VaSelect
              background="#ECF0F1"
              v-model="form.contact_addresses[0].city.id"
              :loading="citiesLoading"
              style="width: 100%; height: 2rem; margin-bottom: 1.8rem"
              label="City"
              requiredMark
              searchable
              :options="cities?.list"
              :highlight-matched-text="false"
              :text-by="(option) => option.name"
              value-by="id"
              :rules="[(v) => !!v]"
            />
          </div>
          <VaInput
            background="#ECF0F1"
            v-model="form.contact_addresses[0].street_name"
            style="width: 49%"
            label="Street"
          />
          <VaInput
            background="#ECF0F1"
            v-model="form.contact_addresses[0].building_name"
            style="width: 49%"
            label="Building"
          />

          <VaInput
            background="#ECF0F1"
            v-model="form.contact_addresses[0].phone_number"
            style="width: 49%"
            label="Office number"
          />

          <VaInput
            background="#ECF0F1"
            v-model="form.contact_addresses[0].floor"
            style="width: 49%"
            label="Floor"
          />

          <VaInput
            background="#ECF0F1"
            v-model="form.contact_addresses[0].google_maps_url"
            style="width: 99%"
            label="Google Maps Link"
          />
        </template>
      </VaCollapse>
    </VaAccordion>

    <VaAccordion v-model="isBankAccountAccordion">
      <VaCollapse header="Bank Account">
        <template #content>
          <VaInput
            background="#ECF0F1"
            v-model="form.contact_bank_accounts[0].iban"
            style="width: 49%"
            label="iban"
            requiredMark
            :rules="[(v) => !!v]"
          />
          <VaInput
            background="#ECF0F1"
            v-model="form.contact_bank_accounts[0].swift_or_bic"
            style="width: 49%"
            label="swift/bic code"
            requiredMark
            :rules="[(v) => !!v]"
          />
          <VaInput
            background="#ECF0F1"
            v-model="form.contact_bank_accounts[0].account_number"
            style="width: 49%"
            label="account number"
            requiredMark
            :rules="[(v) => !!v]"
          />
          <VaInput
            background="#ECF0F1"
            v-model="form.contact_bank_accounts[0].bank_name"
            style="width: 49%"
            label="bank name"
            requiredMark
            :rules="[(v) => !!v]"
          />
          <VaInput
            background="#ECF0F1"
            v-model="form.contact_bank_accounts[0].branch_name"
            style="width: 49%"
            label="branch name"
          />
          <div style="width: 49%">
            <VaSelect
              background="#ECF0F1"
              v-model="form.contact_bank_accounts[0].branch_country"
              :loading="countriesLoading"
              style="width: 100%; height: 2rem"
              label="Branch country"
              requiredMark
              :rules="[(v) => !!v]"
              searchable
              :options="countries?.list"
              :highlight-matched-text="false"
              :text-by="(option) => option.name"
              value-by="name"
            />
          </div>

          <VaInput
            background="#ECF0F1"
            v-model="form.contact_bank_accounts[0].routing_number"
            style="width: 49%"
            label="routing number"
          />

          <VaInput
            background="#ECF0F1"
            v-model="form.contact_bank_accounts[0].short_code"
            style="width: 49%"
            label="short code"
          />

          <VaInput
            background="#ECF0F1"
            v-model="form.contact_bank_accounts[0].bsb_number"
            style="width: 49%"
            label="BSB number"
          />

          <VaInput
            background="#ECF0F1"
            v-model="form.contact_bank_accounts[0].fcs_code"
            style="width: 49%"
            label="ifcs code"
          />
          <VaInput
            background="#ECF0F1"
            v-model="form.contact_bank_accounts[0].transit_number"
            style="width: 49%"
            label="transit number"
          />
          <VaInput
            background="#ECF0F1"
            v-model="form.contact_bank_accounts[0].clabe"
            style="width: 49%"
            label="clabe"
          />

          <VaTextarea
            max-rows="11"
            v-model="form.contact_bank_accounts[0].extra_notes"
            label="other "
            style="width: 99%"
            autosize
            placeholder="Add Notes Here"
            background="#ECF0F1"
          />
        </template>
      </VaCollapse>
    </VaAccordion>
    <div class="action-buttons">
      <VaButton preset="secondary" border-color="primary" @click="handleBack">
        Back
      </VaButton>

      <VaButton preset="primary" @click="validate() && handleUpdate()">
        Update
      </VaButton>
    </div>
  </VaForm>

  <div v-else class="container">
    <ComingSoon />
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
