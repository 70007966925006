import { PropType, defineComponent } from 'vue'

const Excel = new URL('@/assets/icons/excel-icon.svg', import.meta.url).href
const Pdf = new URL('@/assets/icons/pdf-icon.svg', import.meta.url).href
const Jpg = new URL('@/assets/icons/jpg-icon.svg', import.meta.url).href
const MoreVertIcon = new URL('@/assets/icons/More-vert-icon.svg', import.meta.url)
  .href;

export default defineComponent({
  name: 'files-grid',
  props: {
    file: {
      type: {} as PropType<{
        id: number
        name: string
        size: string
        labels: { id: number; name: string }[]
        user: { name: string; id: number; imageUrl: string }
        type: string
        image: string
        monetized: boolean
        previewUrl?: string
      }>,
      required: false,
      default: {},
    },
  },
  data() {
    return {
      MoreVertIcon,
      icons: {
        pdf: Pdf,
        excel: Excel,
        jpg: Jpg,
      },
    }
  },
  computed: {
    icon(): string {
      switch (this.file.type) {
        case 'pdf':
          return this.icons.pdf
        case 'xls':
          return this.icons.excel
        case 'jpg':
          return this.icons.jpg
        default:
          return this.icons.jpg
      }
    },
  },
  methods: {},
  mounted() {
    console.log(this.file)
  },
})
