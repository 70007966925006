import { defineComponent, ref, nextTick } from 'vue'
import Button from '@/components/ui/button/index.vue';

const filterIcon = new URL('@/assets/icons/filter.svg', import.meta.url).href;
const WorkLoadIcon = new URL('@/assets/icons/Workload-view-icon.svg', import.meta.url).href;
const PieChartIcon = new URL('@/assets/icons/Pie-chart-icon.svg', import.meta.url).href;
const ListIcon = new URL('@/assets/icons/list-view-icon-borders.svg', import.meta.url).href;
const ListViewIcon = new URL('@/assets/icons/List-view-icon.svg', import.meta.url).href;
const DownIcon = new URL('@/assets/icons/chevron-down-icon.svg', import.meta.url).href;
const MoreVertIcon = new URL('@/assets/icons/More-vert-icon.svg', import.meta.url)
  .href;
const expandOfficesIcon = new URL(
  '@/assets/icons/open-workspaces-icon.svg',
  import.meta.url
).href;
const collapsedWorkspacesImage = new URL(
  '@/assets/icons/close-workspaces-icon.svg',
  import.meta.url
).href;
const expandedWorkspacesImage = new URL(
  '@/assets/icons/open-workspaces-icon.svg',
  import.meta.url
).href;

export default defineComponent({
  name: 'FirmTimeSheet',
  data() {
    return {
      activeView: 'List',
      MoreVertIcon,
      isShowUpdateLayoutModal: false,
      filterIcon,
      search: '',
      breadList: ['Company All Offices'],
      showWorkSpaces: false,
      WorkLoadIcon,
      PieChartIcon,
      ListIcon,
      ListViewIcon,
      expandOfficesIcon,
      collapsedWorkspacesImage,
      expandedWorkspacesImage,
      DownIcon,
      isSeeMore: false,
      isTeamOpen: false,
      offices: [
        'Global',
        'Italy',
        'Lebanon',
        'UAE',
        'KSA'
      ],
      members: [
        { name: 'Tony Tony', img: 'https://i.pravatar.cc/300' },
        { name: 'Asaad Ali', img: 'https://i.pravatar.cc/300' }
      ],
      teamMembers: [
        { name: 'Khaled Khaled', img: 'https://i.pravatar.cc/300' },
        { name: 'Tony Abdallah', img: 'https://i.pravatar.cc/300' },
        { name: 'Sami Al Samarani', img: 'https://i.pravatar.cc/300' },
      ],
      companyPath: '',
      activeIndices: [] as number[],
      contentRefs: [] as HTMLElement[], // Array to store references to content elements
      groupBy: [
        {
          key: 'title',
          // order: 'asc',
        },
      ],
      headers: [
        {
          sortable: false, title: '', align: 'start', value: 'img'
        },
        {
          sortable: false, title: 'Name', value: 'title'
        },
        {
          sortable: false, title: 'Position', value: 'position'
        },
        {
          sortable: false, title: 'Team Member', value: 'team_member'
        },
        {
          sortable: false, title: 'Task Type/Title', value: 'task_title'
        },
        {
          sortable: false, title: 'Description', value: 'task_description'
        },
        {
          sortable: false, title: 'Client', value: 'client'
        },
        {
          sortable: false, title: 'Legal Matter', value: 'legal_matter'
        },
        {
          sortable: false, title: 'Date', value: 'date'
        },
        {
          sortable: false, title: 'Time', value: 'time'
        },
        {
          sortable: false, title: 'Duration', value: 'duration'
        },
        {
          sortable: false, title: 'Billable', value: 'billable'
        },
        {
          sortable: false, title: '', align: 'start', value: 'action'
        }
      ],
      list: [
        {
          title: 'Jane Doe',
          position: 'Senior Project Manager',
          team_member: 'Alex Johnson',
          task_title: 'Develop Marketing Campaign',
          task_description:
            'Plan and execute a full-scale marketing campaign for the launch of the new product line, including digital ads, social media, and press release.',
          client_avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
          client_name: 'Susan Parker',
          client_title: 'Chief Marketing Officer',
          legal_matter: 'Marketing Strategy',
          date: 'Sep 12, 2024',
          time: '2:15 PM',
          duration: '03:45:00',
          billable: '$5,400.00',
          currency: 'USD',
        },
        {
          title: 'Jane Doe',
          position: 'Senior Project Manager',
          team_member: 'Alex Johnson',
          task_title: 'Develop Marketing Campaign',
          task_description:
            'Plan and execute a full-scale marketing campaign for the launch of the new product line, including digital ads, social media, and press release.',
          client_avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
          client_name: 'Susan Parker',
          client_title: 'Chief Marketing Officer',
          legal_matter: 'Marketing Strategy',
          date: 'Sep 12, 2024',
          time: '2:15 PM',
          duration: '03:45:00',
          billable: '$5,400.00',
          currency: 'USD',
        },
        {
          title: 'Michael Scott',
          position: 'Regional Manager',
          team_member: 'Pam Beesly',
          task_title: 'Office Renovation Plan',
          task_description:
            'Coordinate with contractors and designers to develop a new office layout that improves workflow and employee satisfaction.',
          client_avatar: 'https://randomuser.me/api/portraits/men/46.jpg',
          client_name: 'David Wallace',
          client_title: 'CEO',
          legal_matter: 'Business Development',
          date: 'Sep 10, 2024',
          time: '9:00 AM',
          duration: '02:15:00',
          billable: '$7,000.00',
          currency: 'USD',
        },
      ],
    };
  },
  methods: {
    changeView(view: string) {
      this.activeView = view;
    },
    searchData(search: string) {
      console.log(search);
    },
    openFilterModal() {
      console.log('openFilterModal');

    },
    changeCompanyPath(pathName: string) {
      this.companyPath = pathName
      this.breadList.push(pathName)
    },
    handlePathBack() {
      if (!this.breadCrumbParentView) {
        this.companyPath = this.breadList[0];
        this.breadList.pop()
      }

    },

    viewMemberDetails(index: number) {
      const i = this.activeIndices.indexOf(index)
      if (i > -1) {
        this.activeIndices.splice(i, 1) // Remove from active
      } else {
        this.activeIndices.push(index) // Add to active
      }
      this.$nextTick(() => {
        this.adjustHeights()
      })
    },
    adjustHeights() {
      const contentWrappers = this.$refs.contentWrappers as HTMLElement[] | HTMLElement[][] // Cast the refs
      if (Array.isArray(contentWrappers)) {
        this.members.forEach((_, index) => {
          const contentWrapper = contentWrappers[index] as HTMLElement // Ensure type casting
          if (contentWrapper) {
            if (this.activeIndices.includes(index)) {
              contentWrapper.style.height = contentWrapper.scrollHeight + 'px'
            } else {
              contentWrapper.style.height = '0'
            }
          }
        })
      }
    },
    toggleTeam() {
      this.isTeamOpen = !this.isTeamOpen;
      // Adjust height dynamically after toggling
      this.$nextTick(() => {
        const contentWrapper = this.$refs.contentWrapper as HTMLElement;
        if (this.isTeamOpen) {
          contentWrapper.style.height = contentWrapper.scrollHeight + 'px'; // Set height to auto
        } else {
          contentWrapper.style.height = '0px'; // Collapse the accordion
        }
      });
    },
  },
  computed: {
    breadCrumbParentView(): boolean {
      return this.breadList.length == 1
    },
    breadCrumbChildView(): boolean {
      return this.breadList.length == 2
    },
    currentWorkspaceImage(): string {
      return this.showWorkSpaces
        ? this.collapsedWorkspacesImage
        : this.expandedWorkspacesImage;
    },
  },
  mounted() {
    this.companyPath = this.breadList[0];
  },
  components: {
    Button,
  },
});
