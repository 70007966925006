import { vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9fd0a38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contact-card-header" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "referal-list" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "referal-list" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { key: 2 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "referal-list" }
const _hoisted_16 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: "contact-card link",
    to: `/legal-matters/${_ctx.matter.item.id}`
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", null, [
          (_ctx.showCheckbox)
            ? _withDirectives((_openBlock(), _createElementBlock("input", {
                key: 0,
                type: "checkbox",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isChecked) = $event))
              }, null, 512)), [
                [_vModelCheckbox, _ctx.isChecked]
              ])
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.matter.item.name), 1)
        ])
      ]),
      (_ctx.case_manager_ids.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.case_manager_ids.length === 1)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('matter.client')), 1))
              : _createCommentVNode("", true),
            (_ctx.case_manager_ids.length > 1)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t('matter.clients')), 1))
              : _createCommentVNode("", true),
            _createElementVNode("ul", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.case_manager_ids, (client) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: client.id,
                  class: "referal"
                }, [
                  _createElementVNode("img", {
                    src: client.imageUrl,
                    alt: "referal.name"
                  }, null, 8, _hoisted_6),
                  _createElementVNode("p", null, _toDisplayString(client.first_name) + " " + _toDisplayString(client.last_name), 1)
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.personnel_ids.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_ctx.personnel_ids.length === 1)
              ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t('matter.leadLawyer')), 1))
              : _createCommentVNode("", true),
            (_ctx.personnel_ids.length > 1)
              ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.$t('matter.leadLawyers')), 1))
              : _createCommentVNode("", true),
            _createElementVNode("ul", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.personnel_ids, (lead) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: lead.id,
                  class: "referal"
                }, [
                  _createElementVNode("img", {
                    src: lead.imageUrl,
                    alt: "referal.name"
                  }, null, 8, _hoisted_11),
                  _createElementVNode("p", null, _toDisplayString(lead.first_name) + " " + _toDisplayString(lead.last_name), 1)
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.case_manager_ids.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            (_ctx.case_manager_ids.length === 1)
              ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_ctx.$t('matter.lawyer')), 1))
              : _createCommentVNode("", true),
            (_ctx.case_manager_ids.length > 1)
              ? (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_ctx.$t('matter.lawyers')), 1))
              : _createCommentVNode("", true),
            _createElementVNode("ul", _hoisted_15, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.case_manager_ids, (lawyer) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: lawyer.id,
                  class: "referal"
                }, [
                  _createElementVNode("img", {
                    src: lawyer.imageUrl,
                    alt: "referal.name"
                  }, null, 8, _hoisted_16),
                  _createElementVNode("p", null, _toDisplayString(lawyer.first_name) + " " + _toDisplayString(lawyer.last_name), 1)
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["to"]))
}