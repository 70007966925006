import { truncateText } from '@/lib/utils'
import { getAllCountries } from '@/services/address'
import { getTeamMates } from '@/services/company'
import { getCompanyConfigurations } from '@/services/companyConfigurations'
import { ContactType, getContacts } from '@/services/contacts'
import Cookies from 'js-cookie'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'

export default defineComponent({
  name: 'AddMatterForm',
  props: {
    addMatterLoading: {
      type: Boolean,
      default: false,
    },
    isAddMatter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')

    return {
      isAddMatterBool: this.isAddMatter,
      isValid,
      validate,
      reset,
      resetValidation,
      case_personal_of_counsel: false,
      form: {
        name: '',
        representing_contact_id: '' as any,
        court_id: '' as any,
        court_case_number: '',
        judge_id: '' as any,
        representation_type: '',
        opponent_contact_id: '' as any,
        status_id: '' as any,
        case_manager_ids: [] as any,
        personnel_ids: [] as any,
        specialization_id: '' as any,
        category_id: '' as any,
        jurisdriction_id: '' as any,
        region: '',
        notes: '',
        section_stage_id: '' as any,
      },

      countriesLoading: false,
      contactsLoading: false,
      membersLoading: false,

      contactPage: 1,
      contactSearchKeyword: '',
      contactsItemPerPage: 10,
      contactTotalItems: 0,
      contacts: [] as any,
      debounceTimeout: null as ReturnType<typeof setTimeout> | null,

      courtOptions: [] as any,
      statusOptions: [] as any,
      categoryOptions: [] as any,
      specializationOptions: [] as any,
      presentationTypeOption: ['Plaintiff', 'Defendant'],

      countries: [] as any,
      teamMembers: [] as any,
      truncateText,
    }
  },
  methods: {
    handleToggleAddModal() {
      this.isAddMatterBool = !this.isAddMatterBool
      this.$emit('handleToggleAddModal')
    },
    handleFormSubmit() {
      console.log('Form submitted')
    },
    handleCreateMatter() {
      const { representation_type, ...form } = this.form
      this.$emit('handleCreateMatter', {
        ...form,
        representation_type: representation_type.toLocaleLowerCase(),
      })
    },
    ///////////////////////////////////////////////////////////////
    async handleGetContacts() {
      this.contactsLoading = true

      const response = await getContacts({
        contact_type: ContactType.IndividualContact,
        per_page: this.contactsItemPerPage,
        page: this.contactPage,
        keyword: this.contactSearchKeyword,
      })
      if (response.success) {
        this.contacts = response.data.list
        this.contactTotalItems =
          response.data.total_pages * this.contactsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.contactsLoading = false
    },
    handleSearchContacts(search: string) {
      this.contactSearchKeyword = search

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts()
      }, 500)
    },
    //////////////////////////////////////////////////////////////
    async handleGetCompanyConfigurations() {
      this.contactsLoading = true

      const response = await getCompanyConfigurations()
      if (response.success) {
        const data = response.data.list.reduce((acc: any, config: any) => {
          const { configuration_type } = config

          if (!acc[configuration_type]) {
            acc[configuration_type] = []
          }

          acc[configuration_type]?.push(config)
          return acc
        }, {} as any)

        this.courtOptions = data?.court
        this.statusOptions = data?.legal_matter_status
        this.categoryOptions = data?.legal_matter_category
        this.specializationOptions = data?.legal_matter_specialization
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.contactsLoading = false
    },
    ////////////////////////////////////////////////////
    async handleGetAllCountries() {
      this.countriesLoading = true
      const response = await getAllCountries()

      if (response.success) {
        this.countries = response.data
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.countriesLoading = false
    },
    ////////////////////////////////////////////////////
    async handleGetMembers() {
      this.membersLoading = true
      const user = Cookies.get('haqqUser') as string
      const response = await getTeamMates(
        JSON.parse(user)?.user_roles[0].roleable_id
      )
      if (response.success) {
        this.teamMembers = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.membersLoading = true
    },
  },
  watch: {
    isAddMatter: {
      handler(data) {
        this.isAddMatterBool = data
        if (data) {
          this.handleGetContacts()
          this.handleGetCompanyConfigurations()
          this.handleGetAllCountries()
          this.handleGetMembers()
          this.form.section_stage_id = this.$stores.matter?.stages[0]?.id
        }
      },
    },
    form: {
      handler(data) {
        console.log({
          form: data,
        })
      },
      deep: true,
    },
  },
})
