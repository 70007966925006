import { defineComponent } from 'vue'
import Table from '@/components/foldersTable/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import SearchAndViews from '@/components/searchAndViews/index.vue'
import ActionsBar from '@/components/actionsBar/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import FilesGrid from '@/components/filesGrid/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'

export default defineComponent({
  name: 'ContactsPage',
  data() {
    return {
      tabs: [
        { id: 0, title: 'My Files' },
        { id: 1, title: 'Shared Files' },
        { id: 2, title: 'Librairies' },
        { id: 3, title: 'One Drive' },
        { id: 4, title: 'Bought Items' },
        { id: 5, title: 'Signature' },
      ],
      activeTabId: 1,
      activeTab: 'Shared Files',
      activeView: 'board',
      folders: [
        {
          id: 1,
          name: 'Folder 1',
          size: '1.2 GB',
          labels: [
            { id: 1, name: 'label 1' },
            { id: 2, name: 'label 2' },
          ],
          user: {
            name: 'John Doe',
            id: 1,
            imageUrl: 'https://picsum.photos/50/50',
          },
        },
        {
          id: 2,
          name: 'Folder 2',
          size: '1.2 GB',
          labels: [
            { id: 1, name: 'label 1' },
            { id: 2, name: 'label 2' },
          ],
          user: {
            name: 'John Doe',
            id: 1,
            imageUrl: 'https://picsum.photos/50/50',
          },
        },
        {
          id: 3,
          name: 'Folder 3',
          size: '1.2 GB',
          labels: [
            { id: 1, name: 'label 1' },
            { id: 2, name: 'label 2' },
          ],
          user: {
            name: 'John Doe',
            id: 1,
            imageUrl: 'https://picsum.photos/50/50',
          },
        },
        {
          id: 4,
          name: 'Folder 4',
          size: '1.2 GB',
          labels: [
            { id: 1, name: 'label 1' },
            { id: 2, name: 'label 2' },
          ],
          user: {
            name: 'John Doe',
            id: 1,
            imageUrl: 'https://picsum.photos/50/50',
          },
        },
        {
          id: 5,
          name: 'Folder 5',
          size: '1.2 GB',
          labels: [
            { id: 1, name: 'label 1' },
            { id: 2, name: 'label 2' },
          ],
          user: {
            name: 'John Doe',
            id: 1,
            imageUrl: 'https://picsum.photos/50/50',
          },
        },
      ],
      files: [
        {
          id: 1,
          name: 'File 1',
          size: '1.2 GB',
          labels: [
            { id: 1, name: 'label 1' },
            { id: 2, name: 'label 2' },
          ],
          user: {
            name: 'John Doe',
            id: 1,
            imageUrl: 'https://picsum.photos/50/50',
          },
          type: 'pdf',
          image: 'https://picsum.photos/200/200',
          monetized: true,
          previewUrl: 'https://picsum.photos/200/200',
        },
        {
          id: 2,
          name: 'File 2',
          size: '1.2 GB',
          labels: [
            { id: 1, name: 'label 1' },
            { id: 2, name: 'label 2' },
          ],
          user: {
            name: 'John Doe',
            id: 1,
            imageUrl: 'https://picsum.photos/50/50',
          },
          type: 'doc',
          image: 'https://picsum.photos/200/200',
          monetized: false,
          previewUrl: 'https://picsum.photos/200/200',
        },
        {
          id: 3,
          name: 'File 3',
          size: '1.2 GB',
          labels: [
            { id: 1, name: 'label 1' },
            { id: 2, name: 'label 2' },
          ],
          user: {
            name: 'John Doe',
            id: 1,
            imageUrl: 'https://picsum.photos/50/50',
          },
          type: 'xls',
          image: 'https://picsum.photos/200/200',
          monetized: true,
          previewUrl: 'https://picsum.photos/200/200',
        },
        {
          id: 4,
          name: 'File 4',
          size: '1.2 GB',
          labels: [
            { id: 1, name: 'label 1' },
            { id: 2, name: 'label 2' },
          ],
          user: {
            name: 'John Doe',
            id: 1,
            imageUrl: 'https://picsum.photos/50/50',
          },
          type: 'png',
          image: 'https://picsum.photos/200/200',
          monetized: false,
          previewUrl: 'https://picsum.photos/200/200',
        },
        {
          id: 5,
          name: 'File 5',
          size: '1.2 GB',
          labels: [
            { id: 1, name: 'label 1' },
            { id: 2, name: 'label 2' },
          ],
          user: {
            name: 'John Doe',
            id: 1,
            imageUrl: 'https://picsum.photos/50/50',
          },
          type: 'jpg',
          image: 'https://picsum.photos/200/200',
          monetized: true,
          previewUrl: 'https://picsum.photos/200/200',
        },
      ],
    }
  },
  methods: {
    changeTab(tab: { title: string; id: number }) {
      this.activeTabId = tab.id
      this.activeTab = tab.title
    },
    changeView(view: string) {
      this.activeView = view
    },
    searchData(search: string) {
      console.log(search)
    },
  },
  components: {
    Table,
    TabsBar,
    SearchAndViews,
    ActionsBar,
    SharedHeader,
    ComingSoon,
    FilesGrid,
  },
})
