import { defineComponent } from 'vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'

export default defineComponent({
  name: 'HomePage',
  components: {
    ComingSoon,
    SharedHeader,
  },
  mounted() {
    console.log(this.$stores.auth.token)
    console.log('members', this.$stores.generalData.members)
  },
})
