<template>
  <VaModal
    v-model="isAddExpenseBool"
    size="auto"
    hide-default-actions
    noOutsideDismiss
    @update:modelValue="handleToggleAddModal"
  >
    <template #header>
      <div class="create-expense-form-header">
        <h1>Add General Expense</h1>
        <div style="width: 7rem"></div>
        <VaIcon
          class="header-icon"
          name="close"
          size="30px"
          @click="handleToggleAddModal"
        /></div
    ></template>
    <VaForm
      ref="form"
      class="create-expense-form"
      @submit.prevent="handleFormSubmit"
    >
      <div class="body-expense-form">
        <VaInput
          background="#ECF0F1"
          v-model="form.name"
          label="Name"
          placeholder="Name"
          requiredMark
          :rules="[(value) => value && value.length > 0]"
        />
        <div class="UQC-section">
          <VaInput
            background="#ECF0F1"
            v-model="form.unit_price"
            style="width: 33%"
            label="Unit price"
            placeholder="Unit Price"
            requiredMark
            type="number"
            :rules="[(value) => value && value.length > 0]"
          />
          <VaInput
            background="#ECF0F1"
            v-model="form.quantity"
            style="width: 33%"
            label="Quantity"
            placeholder="Quantity"
            requiredMark
            type="number"
            :rules="[(value) => value && value.length > 0]"
          />
          <VaSelect
            style="width: 33%"
            label="currency"
            background="#ECF0F1"
            v-model="form.currency"
            :options="['USD', 'UAE']"
          />
        </div>
        <div class="TD-section">
          <VaInput
            style="width: 33%"
            background="#ECF0F1"
            v-model="form.total_amount"
            label="total value"
            placeholder="Total Value"
            requiredMark
            type="number"
            :rules="[(value) => value && value.length > 0]"
          />
          <VaInput
            style="width: 33%"
            background="#ECF0F1"
            v-model="form.declared_amount"
            label="Declared value"
            placeholder="Declared Value"
            requiredMark
            type="number"
            :rules="[(value) => value && value.length > 0]"
          />
          <VaInput
            :loading="loading"
            style="width: 32%"
            background="#ECF0F1"
            v-model="form.total_tax"
            label="Tax value"
            placeholder="Tax Value"
            requiredMark
            type="number"
            :rules="[(value) => value && value.length > 0]"
          />
        </div>
        <VaSelect
          style="width: 100%"
          label="Category"
          background="#ECF0F1"
          v-model="form.category"
          :options="categoryOption"
        />
        <VaTextarea
          max-rows="11"
          v-model="form.description"
          label="description"
          placeholder="Add your description here..."
          requiredMark
          style="width: 100%; height: 10rem"
          autosize
          background="#ECF0F1"
          :rules="[(value) => value && value.length > 0]"
        />
        <VaSelect
          background="#ECF0F1"
          v-model="form.billed_to_id"
          style="width: 100%; height: 1.5rem"
          label="Paid To"
          placeholder="Paid To"
          searchable
          clearable
          :highlight-matched-text="false"
          :options="contacts"
          :text-by="
            (option) =>
              `${option.specific_data.individual_first_name} ${option.specific_data.individual_last_name}`
          "
          :loading="contactsLoading"
          @update:search="handleSearchContacts"
        />
        <VaSelect
          :loading="contactsLoading"
          background="#ECF0F1"
          v-model="form.from_wallet_id"
          style="width: 100%; height: 1.5rem"
          label="Paid By"
          placeholder="Paid By"
          searchable
          clearable
          :options="contacts"
          :highlight-matched-text="false"
          :text-by="
            (option) =>
              `${option.specific_data?.individual_first_name} ${option.specific_data?.individual_last_name}`
          "
          @update:search="handleSearchContacts"
        />
        <div class="ESE-section">
          <VaDateInput
            placeholder="MM/DD/YYYY"
            v-model="form.due_date"
            manual-input
            label="Expenses due date"
            requiredMark
          />
          <VaSelect
            label="Status"
            placeholder="Status"
            background="#ECF0F1"
            v-model="form.status"
            :options="statusOptions"
            value-by="value"
          />
        </div>
        <VaSelect
          label="expense type"
          placeholder="Expense Type"
          background="#ECF0F1"
          v-model="form.expense_type"
          :options="expenseTypeOptions"
          value-by="text"
        />
        <div class="upload-docs">
          <VaFileUpload v-model="files" disabled>
            <VaButton
              icon="add"
              preset="secondary"
              border-color="primary"
              size="small"
            />
          </VaFileUpload>
          Upload documents (.jpg,.jpeg,.pdf,.png)
        </div>
      </div>
    </VaForm>
    <template #footer>
      <div class="add-expense-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleAddModal"
          >Cancel</VaButton
        >
        <VaButton
          @click="validate() && handleCreateExpense()"
          :loading="addExpenseLoading"
          >Save</VaButton
        >
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
