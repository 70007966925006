<template>
  <div class="hearing-details-page">
    <p>HEARING ID: {{ id }}</p>
    <SharedHeader titleWidth="30rem" :title="title">
      <div class="sub-SharedHeader">
        <div class="sub-SharedHeader-left">
          <p>
            Client:
            <span> <va-icon size="large" name="account_circle" /></span> Ramy
            Torbey
          </p>
        </div>
        <div class="sub-SharedHeader-right">
          <TimeRange @update:selectedDate="handleSelectedDate" />
          <VaButton preset="secondary" class="estimate-time-button">
            <span class="estimate-time-button">
              <p>Time Estimate</p>
              <p>1h 30m</p>
            </span>
          </VaButton>
        </div>
      </div>
    </SharedHeader>
    <div class="info-container">
      <div class="info-container-stage">
        <p>Stage :</p>
        <span
          :class="`hearing-status-detail status-${status
            .toLowerCase()
            .replace(' ', '-')}`"
        >
          {{ stage }} <va-icon name="arrow_drop_down" />
        </span>
      </div>
      <div class="info-container-status">
        <p>Status :</p>
        <span
          :class="`hearing-status-detail status-${status
            .toLowerCase()
            .replace(' ', '-')}`"
        >
          {{ status }} <va-icon name="arrow_drop_down" />
        </span>
      </div>
    </div>

    <div class="taskbar-container">
      <TabsBar
        :tabs="tabs"
        :activeTabId="activeTabId"
        @changeTab="changeTab"
        :addBtn="false"
      />
    </div>

    <div class="hearing-scrollable-container">
      <div v-if="activeTab === 'INFO'" class="hearing-info">
        <div class="hearing-add-field-button-container">
          <VaButton
            icon-right="add"
            preset="secondary"
            border-color="primary"
            icon-color="#812E9E"
          >
            Add Field
          </VaButton>
        </div>

        <p class="hearing-info-text">
          <va-icon size="large" name="info" />
          You can change the layout of the fields here.
          <VaIcon name="edit" /> It will affect the layout of the table also.
        </p>

        <div class="hearing-info-relationships">
          <h6>Relationship *</h6>
          <ol v-for="(relation, index) in relationships" :key="index">
            <li>
              <p>
                <va-icon size="large" name="subdirectory_arrow_right" />
                <b>{{ relation.name }}</b> : {{ relation.details }}
                <span class="hearing-relation-subDetail"
                  >({{ relation.subDetail }})</span
                >
              </p>
              <VaButton
                icon-="link"
                size="small"
                preset="secondary"
                text-color="red"
                icon-color="red"
              >
                Remove
              </VaButton>
            </li>
          </ol>
          <VaButton icon="link" preset="secondary" icon-color="#812E9E">
            Link to another legal matter
          </VaButton>
        </div>

        <VaForm ref="formRef" class="hearing-form">
          <VaSelect
            background="#ECF0F1"
            v-model="form.assignTo"
            style="width: 100%"
            label="Assign to *"
            placeholder="Search..."
            :options="judgeNameOptions"
            multiple
            selected-top-shown
            autocomplete
            highlight-matched-text
            :rules="[(v) => v || 'Required']"
          />

          <VaSelect
            background="#ECF0F1"
            v-model="form.client"
            style="width: 100%"
            label="Client *"
            :options="judgeNameOptions"
            :rules="[(v) => v || 'Required']"
          />

          <VaSelect
            background="#ECF0F1"
            v-model="form.opponentName"
            style="width: 100%"
            label="Client *"
            :options="judgeNameOptions"
            :rules="[(v) => v || 'Required']"
          />

          <div class="hearing-flex-gap">
            <VaSelect
              v-model="form.court"
              :options="courtOptions"
              :rules="[(v) => v || 'Required']"
              label="Court *"
              style="width: 80%"
              background="#ECF0F1"
            />

            <VaInput
              background="#ECF0F1"
              v-model="form.courtID"
              style="width: 20%"
              placeholder="eg: CR35"
              :rules="[(value) => (value && value.length > 0) || 'Required']"
              label="Court ID *"
            />
          </div>

          <VaSelect
            background="#ECF0F1"
            v-model="form.judgeName"
            style="width: 100%"
            label="Judge Name *"
            :options="judgeNameOptions"
            :rules="[(v) => v || 'Required']"
          />

          <VaSelect
            background="#ECF0F1"
            v-model="form.jurisdictions"
            style="width: 100%"
            label="Jurisdictions "
            :options="representingOptions"
            placeholder="Search..."
            multiple
            selected-top-shown
            autocomplete
          />

          <VaSelect
            background="#ECF0F1"
            v-model="form.judicialDistrict"
            style="width: 100%"
            label="Judicial District "
            :options="representingOptions"
            placeholder="Search..."
            multiple
            selected-top-shown
            autocomplete
          />

          <VaSelect
            background="#ECF0F1"
            v-model="form.Region"
            style="width: 100%"
            label="Region "
            placeholder="Search..."
            :options="representingOptions"
            multiple
            selected-top-shown
            autocomplete
          >
          </VaSelect>

          <VaTextarea
            v-model="form.summary"
            counter
            label="Summary "
            style="width: 100%"
            autosize
            min-rows="5"
            background="#ECF0F1"
          />
        </VaForm>
      </div>
    </div>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
