<template>
  <div class="main">
    <SharedHeader titleWidth="30rem" title="Calendar">
      <div class="sub-SharedHeader">
        <div class="sub-SharedHeader-left"></div>
        <div class="sub-SharedHeader-right">
        </div>
      </div>


    </SharedHeader>
    <div class="container">
      <ComingSoon />

    </div>

  </div>

  <!-- <div class="calendar-page">
    <CalendarPopup />
    <v-main>
      <header class="calendar-page__header">
        <h1>Calendar</h1>
        <div class="calender-type-select">
          <v-select
            v-model="$stores.calendar.type"
            :items="$stores.calendar.types"
            density="compact"
            class="ma-2"
            label="View Mode"
            variant="outlined"
            hide-details
          ></v-select>
        </div>
      </header>
      <CalendarButtons />
      <div class="calendar-page__content">
        <div class="left-side">
          <DatePicker />
          <UIButton
            :button-text="$t('calendar.filters')"
            @click="showFiltersDrawer"
            backgroundColor="transparent"
            textColor="#66023c"
          />
        </div>
        <div class="right-side">
          <CalednarSearch />
          <Calendar />
        </div>
      </div>
    </v-main>
  </div> -->

</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
