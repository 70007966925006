import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d1542e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-section" }
const _hoisted_2 = { class: "task-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VaCardContent = _resolveComponent("VaCardContent")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_VaCard = _resolveComponent("VaCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_VaCard, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: `/tasks/${_ctx.id}`
          }, {
            default: _withCtx(() => [
              _createVNode(_component_VaCardContent, null, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.text), 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.formattedDateRange), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      })
    ])
  ]))
}