import { defineComponent } from 'vue';
const MoreVertIcon = new URL('@/assets/icons/More-vert-icon.svg', import.meta.url)
    .href;

export default defineComponent({
    name: 'HearingsTable',
    data() {
        return {
            selectedRows: [],
            MoreVertIcon,
            headers: [
                { title: 'COURT DATE', align: 'start', key: 'courtDate' },
                { title: 'NAME', align: 'starts', key: 'name' },
                { title: 'ASSIGNED TO', align: 'starts', key: 'assignedTo' },
                { title: 'MATTER', align: 'starts', key: 'matter' },
                { title: 'INTERNAL MATTER ID', align: 'starts', key: 'internalMatterId' },
                { title: 'CLIENT', align: 'starts', key: 'client' },
                { title: 'OPPONENT NAME', align: 'starts', key: 'opponentName' },
                { title: 'COURT ID', align: 'starts', key: 'courtId' },
                { title: 'COURT', align: 'starts', key: 'court' },
                { title: 'JUDGE NAME', align: 'starts', key: 'judgeName' },
                { title: 'JURISDICTION', align: 'starts', key: 'jurisdiction' },
                { title: 'JUDICIAL DISTRICT', align: 'starts', key: 'judicialDistrict' },
                { title: 'REGION', align: 'starts', key: 'region' },
                { title: 'ATTENDANCE', align: 'starts', key: 'attendance' },
                { title: 'SUMMARY', align: 'starts', key: 'summary' },
                { title: 'STATUS', align: 'starts', key: 'status' },
                { title: '', align: 'starts', key: 'action' },
            ],
            list: [
                {
                    courtDate: '2022-02-02',
                    name: 'John Doe',
                    assignedTo: 'Jane Smith',
                    matter: 'Breach of Contract',
                    internalMatterId: 'IM-12345',
                    client: 'ABC Corp',
                    opponentName: 'XYZ Inc',
                    courtId: 'C-98765',
                    court: 'Supreme Court',
                    judgeName: 'Judge Judy',
                    jurisdiction: 'Federal',
                    judicialDistrict: 'Eastern District',
                    region: 'Northeast',
                    attendance: 'Present',
                    summary: 'Summary of the case goes here.',
                    status: 'In Progress',
                },
                {
                    courtDate: '2023-03-03',
                    name: 'Alice Brown',
                    assignedTo: 'Bob Johnson',
                    matter: 'Patent Infringement',
                    internalMatterId: 'IM-67890',
                    client: 'DEF Ltd',
                    opponentName: 'GHI LLC',
                    courtId: 'C-54321',
                    court: 'District Court',
                    judgeName: 'Judge Harris',
                    jurisdiction: 'State',
                    judicialDistrict: 'Southern District',
                    region: 'Southeast',
                    attendance: 'Absent',
                    summary: 'Summary of the patent case.',
                    status: 'Pending',
                },
                {
                    courtDate: '2024-04-04',
                    name: 'Charlie Davis',
                    assignedTo: 'Emily Clark',
                    matter: 'Employment Dispute',
                    internalMatterId: 'IM-11223',
                    client: 'JKL Co',
                    opponentName: 'MNO Pvt',
                    courtId: 'C-67890',
                    court: 'Appellate Court',
                    judgeName: 'Judge Taylor',
                    jurisdiction: 'Municipal',
                    judicialDistrict: 'Western District',
                    region: 'Midwest',
                    attendance: 'Present',
                    summary: 'Details of the employment dispute.',
                    status: 'Completed',
                },
                {
                    courtDate: '2025-05-05',
                    name: 'David Evans',
                    assignedTo: 'Fiona Green',
                    matter: 'Property Dispute',
                    internalMatterId: 'IM-44556',
                    client: 'PQR Group',
                    opponentName: 'STU Enterprises',
                    courtId: 'C-11111',
                    court: 'Circuit Court',
                    judgeName: 'Judge White',
                    jurisdiction: 'County',
                    judicialDistrict: 'Northern District',
                    region: 'Northwest',
                    attendance: 'Present',
                    summary: 'Property dispute details.',
                    status: 'In Progress',
                },
                {
                    courtDate: '2026-06-06',
                    name: 'Evan Foster',
                    assignedTo: 'Grace Hall',
                    matter: 'Tax Evasion',
                    internalMatterId: 'IM-77889',
                    client: 'VWX Ltd',
                    opponentName: 'YZA Corp',
                    courtId: 'C-22222',
                    court: 'Tax Court',
                    judgeName: 'Judge Black',
                    jurisdiction: 'State',
                    judicialDistrict: 'Central District',
                    region: 'Southwest',
                    attendance: 'Absent',
                    summary: 'Summary of the tax evasion case.',
                    status: 'Pending',
                },
                {
                    courtDate: '2027-07-07',
                    name: 'Fiona Grant',
                    assignedTo: 'Henry Moore',
                    matter: 'Personal Injury',
                    internalMatterId: 'IM-99000',
                    client: 'BCD Inc',
                    opponentName: 'EFG Partners',
                    courtId: 'C-33333',
                    court: 'County Court',
                    judgeName: 'Judge Gray',
                    jurisdiction: 'Municipal',
                    judicialDistrict: 'Eastern District',
                    region: 'Southeast',
                    attendance: 'Present',
                    summary: 'Personal injury case summary.',
                    status: 'Pending',
                },
            ]

        }
    },
    emits: ['selectedRows'],
    methods: {
        handleButtonClick(item: any) {
            // Handle the button click for the specific item (row)
            console.log('Button clicked for:', item);
        },
    },
    watch: {
        selectedRows() {
            this.$emit('selectedRows', this.selectedRows);
        }
    }
})