import { ContactType, getContacts } from '@/services/contacts'
import { defineComponent } from 'vue'
import { useForm } from 'vuestic-ui/web-components'

export default defineComponent({
  name: 'AddExpenseForm',
  props: {
    addExpenseLoading: {
      type: Boolean,
      default: false,
    },
    isAddExpense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { isValid, validate, reset, resetValidation } = useForm('form')

    return {
      isAddExpenseBool: this.isAddExpense,
      isValid,
      validate,
      reset,
      resetValidation,
      files: [],
      form: {
        name: '',
        unit_price: '',
        quantity: '',
        currency: 'USD',
        total_amount: '',
        declared_amount: '',
        category: 'Administrative Law',
        description: '',
        billed_to_id: null,
        from_wallet_id: null,
        due_date: new Date(),
        status: 'not_started',
        expense_type: 'utilities',
      },
      categoryOption: [
        'Administrative Law',
        'Alternative Dispute Resolution',
        'Anti-Corruption Law',
        'Arbitration',
        'Aviation Law',
        'Banking Law',
        'Bankruptcy Law',
        'Civil Litigation',
      ],
      statusOptions: [
        { value: 'not_started', text: 'Not Started' },
        { value: 'transferred', text: 'Transferred' },
        { value: 'in_progress', text: 'In Progress' },
        { value: 'not_retained', text: 'Not Retained' },
      ],
      expenseTypeOptions: [
        { value: 'office_rent', text: 'Office Rent/Lease' },
        {
          value: 'utilities',
          text: 'Utilities (Electricity, Water, Internet)',
        },
        { value: 'salaries_wages', text: 'Salaries and Wages' },
        { value: 'office_supplies', text: 'Office Supplies' },
        {
          value: 'software_licenses',
          text: 'Software Licenses (e.g., case management tools)',
        },
        {
          value: 'marketing_expenses',
          text: 'Marketing and Advertising Expenses',
        },
        {
          value: 'professional_liability_insurance',
          text: 'Professional Liability Insurance',
        },
        { value: 'travel_expenses', text: 'Travel Expenses' },
        {
          value: 'continuing_education',
          text: 'Continuing Education and Training',
        },
        {
          value: 'client_entertainment',
          text: 'Client Entertainment and Meals',
        },
      ],
      contactsLoading: false,
      contactPage: 1,
      contactSearchKeyword: '',
      contactsItemPerPage: 10,
      contactTotalItems: 0,
      contacts: [] as any,
      debounceTimeout: null as ReturnType<typeof setTimeout> | null, // Holds the debounce timeout ID
    }
  },
  methods: {
    handleToggleAddModal() {
      this.$emit('handleToggleAddModal')
    },
    handleFormSubmit() {
      console.log('Form submitted')
    },
    async handleCreateExpense() {
      this.isAddExpenseBool = !this.isAddExpenseBool
      this.$emit('handleCreateExpense', this.form)
    },
    async handleGetContacts() {
      this.contactsLoading = true

      const response = await getContacts({
        contact_type: ContactType.IndividualContact,
        per_page: this.contactsItemPerPage,
        page: this.contactPage,
        keyword: this.contactSearchKeyword,
      })
      if (response.success) {
        this.contacts = response.data.list
        this.contactTotalItems =
          response.data.total_pages * this.contactsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.contactsLoading = false
    },
    handleSearchContacts(search: string) {
      this.contactSearchKeyword = search
      console.log(this.contactSearchKeyword)

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts()
      }, 500)
    },
  },
  // mounted() {
  //   this.handleGetContacts()
  // },
  watch: {
    form: {
      handler(newForm) {
        console.log('Form updated:', newForm)
      },
      deep: true,
    },
    isAddExpense: {
      handler(data) {
        this.isAddExpenseBool = data
        if (data) {
          this.handleGetContacts()
        }
      },
    },
  },
})
