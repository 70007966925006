import { defineComponent } from 'vue';
import SharedHeader from '@/components/sharedHeader/index.vue';
import SearchAndViews from '@/components/searchAndViews/index.vue';
import ActionsBar from '@/components/actionsBar/index.vue';
import HearingsTable from '@/components/Hearing/ListView/index.vue';
import HearingCards from '@/components/Hearing/CardView/index.vue';
import ComingSoon from '@/components/ComingSoon/index.vue'


export type Card = {
    id: number,
    title: string;
    date: string;
    assignedTo: string;
    status: string;
    client: string;
    lawyer: string;
};

export default defineComponent({
    name: 'HearingsPage',
    data() {


        const cardsList: Card[] = [
            {
                id: 1, title: 'Intra Case',
                date: '2-jan-2024',
                assignedTo: 'Tarek',
                status: 'In Progress',
                client: 'Julien Sfeir',
                lawyer: 'Antoine Kanaan'
            },
            {
                id: 12, title: 'Patent Dispute',
                date: '3-sep-2024',
                assignedTo: 'Sara',
                status: 'Pending',
                client: 'Nadine Khoury',
                lawyer: 'Georges Abi Saad'
            },
            {
                id: 13, title: 'Property Litigation',
                date: '4-oct-2024',
                assignedTo: 'Rami',
                status: 'Completed',
                client: 'Marc Jaber',
                lawyer: 'Maya Saliba'
            },
            {
                id: 14, title: 'Employment Dispute',
                date: '5-jul-2024',
                assignedTo: 'Lara',
                status: 'In Progress',
                client: 'Hassan Daher',
                lawyer: 'Carla Younes'
            },
            {
                id: 15, title: 'Property Litigation',
                date: '4-oct-2024',
                assignedTo: 'Rami',
                status: 'Completed',
                client: 'Marc Jaber',
                lawyer: 'Maya Saliba'
            },
            {
                id: 16, title: 'Employment Dispute',
                date: '5-sep-2024',
                assignedTo: 'Lara',
                status: 'In Progress',
                client: 'Hassan Daher',
                lawyer: 'Carla Younes'
            }
        ];

        return {
            activeView: 'list',
            selectedRows: [],
            isSelect: false,
            cardsList,
        }
    },
    methods: {
        changeView(view: string) {
            this.activeView = view;
        },
        searchData(search: string) {
            console.log(search);
        },
        getSelectedRows(rows: any) {
            this.selectedRows = rows
        }
    },
    components: {
        SharedHeader,
        SearchAndViews,
        ActionsBar,
        HearingsTable,
        HearingCards,
        ComingSoon
    },

})