import { defineComponent } from 'vue'
import TabsBar from '@/components/tabsBar/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import { useRoute } from 'vue-router'
import { useForm } from 'vuestic-ui'
import {
  ContactType,
  deleteContact,
  getContactById,
  getContacts,
  updateOrganizationContact,
} from '@/services/contacts'
import ComingSoon from '@/components/ComingSoon/index.vue'
import Cookies from 'js-cookie'
import { getTeamMates } from '@/services/company'
import { getAllCities, getAllCountries, getAllStates } from '@/services/address'
import { truncateText } from '@/lib/utils'

const verifiedImage = new URL(
  '@/assets/icons/verified-icon.svg',
  import.meta.url
).href

export default defineComponent({
  name: 'OrganizationDetailsPage',
  data() {
    const route = useRoute()
    return {
      verifiedImage,
      route,
      loading: false,
      organizationDetails: {} as any, // Initialized but empty until populated in created()
      tabs: [
        { id: 0, title: 'DASHBOARD', icon: 'dashboard' },
        { id: 1, title: 'ORGANIZATION INFO', icon: 'info' },
        { id: 2, title: 'LEADS', icon: 'my_location' },
        { id: 12, title: 'LEGAL MATTERS', icon: 'inventory_2' },
        { id: 3, title: 'TASKS', icon: 'assignment' },
        { id: 4, title: 'CALENDAR', icon: 'calendar_month' },
        { id: 5, title: 'MILESTONES', icon: 'flag' },
        { id: 6, title: 'FILES', icon: 'file_copy' },
        { id: 7, title: 'FINANCIAL', icon: 'attach_money' },
        { id: 8, title: 'HEARINGS', icon: 'gavel' },
        { id: 9, title: 'ENGAGEMENT LETTER', icon: 'article' },
        { id: 10, title: 'EMAILS', icon: 'mail' },
        { id: 11, title: 'KYC DOCUMENTS', icon: 'article' },
      ],
      activeTabId: 1,
      activeTab: 'ORGANIZATION INFO',
      countries: [] as any,
      states: [] as any,
      cities: [] as any,
      countriesLoading: false,
      statesLoading: false,
      citiesLoading: false,
      loadingUpdate: false,
      organizationTypeOptions: [
        { name: 'Sole Proprietorship', id: '0' },
        { name: 'Individual Intrapreneur', id: '1' },
        { name: 'Freelancer', id: '2' },
        { name: 'Partnership', id: '3' },
        { name: 'General Partnership', id: '4' },
        { name: 'Limited Partnership', id: '5' },
      ],
      teamMembers: [] as any,
      contactTypeOptions: [
        { id: 0, title: 'Person' },
        { id: 1, title: 'Organization' },
      ],
      isAddressAccordion: [false],
      isBankAccountAccordion: [false],
      isDeleteModal: false,
      form: {
        id: '',
        organization_contact: {} as any,
        specific_data: {} as any,
        organization_owner: '',
        relationship_owner: { id: '' },
        phone_numbers: [{ label: 'Primary Phone', phone_number: '' }],
        email_addresses: [{ label: 'Primary Email', email_address: '' }],
        website_url: '',
        assigned_to: { id: '' },
        referred_by: null as any,
        designated_point_of_contact: { id: '' },
        contact_addresses: [
          {
            city: { id: '' },
            state: { name: '' },
            country: { name: '' },
            phone_number: '',
            floor: '',
            building_name: '',
            street_name: '',
            zip_code: '',
            google_maps_url: '',
          },
        ],
        contact_bank_accounts: [
          {
            id: '',
            iban: '',
            swift_or_bic: '',
            account_number: '',
            bank_name: '',
            branch_name: '',
            branch_country: '',
            routing_number: '',
            short_code: '',
            transit_number: '',
            clabe: '',
            extra_notes: '',
            bsb_number: '',
            fcs_code: '',
          },
        ],
      },

      contactsLoading: false,
      contactPage: 1,
      contactSearchKeyword: '',
      contactsItemPerPage: 10,
      contactTotalItems: 0,
      contacts: [] as any,

      organizationsLoading: false,
      organizationPage: 1,
      organizationSearchKeyword: '',
      organizationsItemPerPage: 10,
      organizationTotalItems: 0,
      organizations: [] as any,

      debounceTimeout: null as ReturnType<typeof setTimeout> | null,
      truncateText,
    }
  },

  methods: {
    changeTab(tab: { title: string; id: number }) {
      this.activeTabId = tab.id
      this.activeTab = tab.title
      console.log({ activeTab: this.activeTab })
    },
    async handleGetContactByID() {
      this.loading = true
      const response = await getContactById(this.route.params.id as string)
      if (response.success) {
        this.organizationDetails = response.data
        this.populateForm()
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loading = false
    },
    async handleGetMembers() {
      const user = Cookies.get('haqqUser') as string
      const response = await getTeamMates(
        JSON.parse(user)?.user_roles[0].roleable_id
      )
      if (response.success) {
        this.teamMembers = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
    },
    async handleGetAllCountries() {
      this.countriesLoading = true
      const response = await getAllCountries()

      if (response.success) {
        this.countries = response.data
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.countriesLoading = false
    },
    async handleGetState(value: any) {
      this.statesLoading = true
      const response = await getAllStates(value)

      if (response.success) {
        this.states = response.data
      } else {
        // this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.statesLoading = false
    },
    async handleGetCities(value: any) {
      this.citiesLoading = true
      const response = await getAllCities(value)

      if (response.success) {
        this.cities = response.data
      } else {
        // this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.citiesLoading = false
    },

    populateForm() {
      const form = this.form
      const organizationDetails = this.organizationDetails

      form.id = organizationDetails.id
      form.specific_data = organizationDetails.specific_data

      form.email_addresses =
        Array.isArray(organizationDetails?.email_addresses) &&
        organizationDetails.email_addresses.length > 0
          ? organizationDetails.email_addresses
          : [{ label: 'Primary Email', email_address: '' }]

      form.website_url = organizationDetails.website_url || ''

      form.phone_numbers =
        Array.isArray(organizationDetails?.phone_numbers) &&
        organizationDetails?.phone_numbers.length > 0
          ? organizationDetails.phone_numbers
          : [{ label: 'Primary Phone', phone_number: '' }]

      // form.organization = organizationDetails.organization || '';
      form.relationship_owner = organizationDetails.relationship_owner || {
        id: '',
      }

      form.assigned_to = organizationDetails.assigned_to || { id: '' }
      form.organization_contact = organizationDetails.organization_contact || {
        id: '',
      }
      form.referred_by = organizationDetails.referred_by || null
      form.designated_point_of_contact =
        organizationDetails.designated_point_of_contact || { id: '' }

      form.contact_addresses =
        organizationDetails?.contact_addresses?.length > 0
          ? organizationDetails.contact_addresses
          : [
              {
                id: '',
                city: { id: '' },
                state: { id: '' },
                country: { id: '' },
                phone_number: '',
                floor: '',
                building_name: '',
                street_name: '',
                zip_code: '',
                google_maps_url: '',
              },
            ]

      form.contact_bank_accounts =
        organizationDetails?.contact_bank_accounts?.length > 0
          ? organizationDetails.contact_bank_accounts
          : [
              {
                id: '',
                iban: '',
                swift_or_bic: '',
                account_number: '',
                bank_name: '',
                branch_name: '',
                branch_country: '',
                routing_number: '',
                short_code: '',
                transit_number: '',
                clabe: '',
                extra_notes: '',
                bsb_number: '',
                fcs_code: '',
              },
            ]

      organizationDetails?.contact_addresses[0]?.country?.id &&
        this.handleGetState(organizationDetails.contact_addresses[0].country.id)
      organizationDetails?.contact_addresses[0]?.state?.id &&
        this.handleGetCities(organizationDetails.contact_addresses[0].state.id)
    },
    async handleDelete() {
      console.log('delete')
      const response = await deleteContact(this.route.params.id as string)
      if (response.success) {
        this.$router.push({ name: 'ContactsView' })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
    },
    handleBack() {
      this.$router.back()
    },
    async handleUpdate() {
      this.loadingUpdate = true
      const updatedEmailArr = this.form.email_addresses.map(
        ({ email, ...rest }: any) => ({
          email_address: email, // Change 'email' to 'email_address'
          ...rest, // Keep the rest of the properties
        })
      )

      const {
        contact_addresses,
        contact_bank_accounts,
        designated_point_of_contact,
        specific_data,
        assigned_to,
        relationship_owner,
        referred_by,
        id,
        organization_contact,
        ...form
      } = this.form
      const { organization_logo, ...UpdatedSpecific_data } = specific_data

      const response = await updateOrganizationContact({
        id: this.route.params.id as string,
        body: {
          ...form,
          ...UpdatedSpecific_data,
          id: Number(id),
          organization_date_of_foundation:
            UpdatedSpecific_data.organization_date_of_foundation &&
            String(UpdatedSpecific_data.organization_date_of_foundation),

          assigned_to_id: assigned_to?.id,
          relationship_owner_id: relationship_owner?.id,
          organization_contact_id: organization_contact?.id || null,
          referred_by_id: referred_by?.id || null,

          designated_point_of_contact_id: designated_point_of_contact.id,

          ...(this.isAddressAccordion[0]
            ? {
                contact_addresses_attributes: contact_addresses
                  .slice(0, 1)
                  .map(
                    ({
                      id,
                      city,
                      phone_number,
                      floor,
                      building_name,
                      street_name,
                      zip_code,
                      google_maps_url,
                    }: any) => ({
                      id,
                      city_id: city.id,
                      phone_number,
                      floor,
                      building_name,
                      street_name,
                      zip_code,
                      google_maps_url,
                    })
                  ),
              }
            : {}),
          ...(this.isBankAccountAccordion[0]
            ? {
                contact_bank_accounts_attributes: [
                  (({
                    id,
                    iban,
                    swift_or_bic,
                    account_number,
                    bank_name,
                    branch_name,
                    branch_country,
                    routing_number,
                    short_code,
                    bsb_number,
                    fcs_code,
                    transit_number,
                    clabe,
                    extra_notes,
                  }) => ({
                    id,
                    iban,
                    swift_or_bic,
                    account_number,
                    bank_name,
                    branch_name,
                    branch_country,
                    routing_number,
                    short_code,
                    bsb_number,
                    fcs_code,
                    transit_number,
                    clabe,
                    extra_notes,
                  }))(contact_bank_accounts[0]),
                ],
              }
            : {}),

          email_addresses: updatedEmailArr,
          phone_numbers: form.phone_numbers,
        },
      })
      if (response.success) {
        this.$vaToast.init({
          message: 'Contact updates successfully!',
          color: 'success',
        })
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loadingUpdate = false
    }, ////////////////////////////////////////////////////////////////
    async handleGetContacts() {
      this.contactsLoading = true

      const response = await getContacts({
        contact_type: ContactType.IndividualContact,
        per_page: this.contactsItemPerPage,
        page: this.contactPage,
        keyword: this.contactSearchKeyword,
      })
      if (response.success) {
        this.contacts = response.data.list
        console.log({ con: this.contacts })

        this.contactTotalItems =
          response.data.total_pages * this.contactsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.contactsLoading = false
    },
    handleSearchContacts(search: string) {
      this.contactSearchKeyword = search

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetContacts()
      }, 500)
    },
    /////////////////////////////////////////////////////////////////////
    async handleGetOrganizations() {
      this.organizationsLoading = true

      const response = await getContacts({
        contact_type: ContactType.OrganizationContact,
        per_page: this.organizationsItemPerPage,
        page: this.organizationPage,
        keyword: this.organizationSearchKeyword,
      })
      if (response.success) {
        this.organizations = response.data.list
        this.organizationTotalItems =
          response.data.total_pages * this.organizationsItemPerPage
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.organizationsLoading = false
    },
    handleSearchOrganizations(search: string) {
      this.organizationSearchKeyword = search

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.handleGetOrganizations()
      }, 500)
    },
  },
  mounted() {
    this.handleGetContactByID()
    this.handleGetMembers()
    this.handleGetAllCountries()
    this.handleGetOrganizations()
    this.handleGetContacts()
  },

  setup() {
    const { isValid, validate, reset, resetValidation } = useForm(
      'organizationDetailsFormRef'
    )
    return { isValid, validate, reset, resetValidation }
  },
  components: {
    TabsBar,
    SharedHeader,
    ComingSoon,
  },
})
