<template>
  <div class="invoices-table-container">
    <v-data-table-server
      v-model:selection="selectedRows"
      :loading="loading"
      :headers="headers"
      :items="invoices?.list"
      show-select
      class="custom-table"
      :items-length="localTotalItems"
      :items-per-page="itemsPerPage"
      @update:options="handleLoadMore"
      :items-per-page-options="[10, 25, 50]"
    >
      <template #[`item.invoice_number`]="{ item }">
        <router-link :to="`/financial/invoice/${item?.id}`">
          <p>{{ item?.invoice_number }}</p>
        </router-link>
      </template>

      <template #[`item.status`]="{ item }">
        <p class="status">
          {{ item.status }}
        </p>
      </template>

      <template #[`item.created_at`]="{ item }">
        <p>
          {{ item.created_at.split('T')[0] }}
        </p>
      </template>

      <template #[`item.action`]="{ item }">
        <td class="invoice-action-td">
          <va-dropdown :offset="[0, -70]" close-on-content-click>
            <template #anchor>
              <img :src="MoreVertIcon" alt="MoreVertIcon" />
            </template>
            <va-dropdown-content>
              <!-- <p class="invoice-action-option" @click="handleEdit(item)">
                                <va-icon name="edit" /> Edit
                            </p>
                            <p class="invoice-action-option" @click="confirmDelete(item)">
                                <va-icon name="delete" /> Delete
                            </p> -->
              <p class="invoice-action-option" @click="viewProfile(item)">
                <va-icon name="person" /> View Invoice
              </p>
            </va-dropdown-content>
          </va-dropdown>
        </td>
      </template>
    </v-data-table-server>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style scoped lang="scss" src="./styles.scss"></style>
