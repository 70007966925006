<template>
  <div class="file">
    <div class="folder-header">
      <div class="info">
        <img :src="icon" alt="folder icon" class="file-type" />
        <div class="file-details">
          <div class="name-container">
            <img
              v-show="file.monetized"
              src="../../assets/icons/monetary-icon.svg"
              alt="money icon"
            />
            <h3 class="name">{{ file.name }}</h3>
          </div>
          <p class="size">{{ file.size }}</p>
        </div>
      </div>
      <div class="file-options">
        <img :src="MoreVertIcon" alt="MoreVertIcon" />
      </div>
    </div>
    <div class="labels">
      <div v-for="label in file.labels" :key="label.id" class="label">
        <p>{{ label.name }}</p>
      </div>
    </div>
    <div class="file-preview" v-if="file.previewUrl">
      <iframe
        :src="file.previewUrl"
        frameborder="0"
        style="border: none; width: 100%; height: 100%"
        lazy
      ></iframe>
    </div>
    <div class="user">
      <img :src="file.user.imageUrl" :alt="file.user.name" />
      <h4>{{ file.user.name }}</h4>
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
