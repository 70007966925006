import draggable from 'vuedraggable'
export default {
  name: 'table-example',
  display: 'Table',
  order: 8,
  components: {
    draggable,
  },
  data() {
    return {
      headers: [
        { name: '#', sortable: true, value: 'id' },
        { name: 'Name', sortable: false },
        { name: 'Email', sortable: false },
        { name: 'Actions', sortable: false },
      ],
      list: [
        { id: 1, name: 'Abby', email: 'abby@test.com' },
        { id: 2, name: 'Brooke', email: 'brooke@test.com' },
        { id: 3, name: 'Courtenay', email: 'courtenay@test.com' },
        { id: 4, name: 'David', email: 'david@test.com' },
      ],
      dragging: false,
    }
  },
}
