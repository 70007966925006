import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "filters-drawer" }
const _hoisted_2 = { class: "filters-drawer__header" }
const _hoisted_3 = { class: "filters-drawer__boxes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Close = _resolveComponent("Close")!
  const _component_FilterBoxes = _resolveComponent("FilterBoxes")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createBlock(_component_v_navigation_drawer, {
    modelValue: _ctx.drawer,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawer) = $event)),
    temporary: "",
    location: "right",
    style: {"min-width":"40%"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("calendar.filters")), 1),
          _createVNode(_component_Close, { onClick: _ctx.closeDrawer }, null, 8, ["onClick"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_FilterBoxes)
        ]),
        _createVNode(_component_v_text_field, {
          "prepend-inner-icon": "mdi-magnify",
          label: _ctx.$t('general.search'),
          loading: _ctx.loading,
          "hide-details": "",
          "single-line": "",
          density: "compact",
          "onClick:prependInner": _ctx.search,
          variant: "solo"
        }, null, 8, ["label", "loading", "onClick:prependInner"]),
        _createVNode(_component_v_select, {
          label: _ctx.$t('views.contacts'),
          items: ['Label 1', 'Label 2', 'Label 3'],
          color: "#b8c4d6",
          "base-color": "#b8c4d6",
          rounded: "5px",
          "hide-details": ""
        }, null, 8, ["label"]),
        _createVNode(_component_v_select, {
          label: _ctx.$t('views.legalMatters'),
          items: ['Label 1', 'Label 2', 'Label 3'],
          color: "#b8c4d6",
          "base-color": "#b8c4d6",
          rounded: "5px",
          "hide-details": ""
        }, null, 8, ["label"]),
        _createVNode(_component_v_select, {
          label: _ctx.$t('general.locations'),
          items: ['Label 1', 'Label 2', 'Label 3'],
          color: "#b8c4d6",
          "base-color": "#b8c4d6",
          rounded: "5px",
          "hide-details": ""
        }, null, 8, ["label"]),
        _createVNode(_component_v_select, {
          label: _ctx.$t('general.tags'),
          items: ['Label 1', 'Label 2', 'Label 3'],
          color: "#b8c4d6",
          "base-color": "#b8c4d6",
          rounded: "5px",
          "hide-details": ""
        }, null, 8, ["label"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}