<template>
  <div class="tasks-table-container">
    <v-data-table-server
      return-object
      :loading="false"
      v-model="selectedRows"
      show-select
      :headers="headers"
      :items="$stores.mockTasks.mockTasks.data.list"
      :items-length="$stores.mockTasks.mockTasks.data.list?.length"
      items-per-page="10"
    >
      <template #[`item.name`]="{ item }">
        <router-link class="link" :to="`/tasks/${item.id}`">
          <p class="tasks-description-td">
            {{ item.name }}
          </p>
        </router-link>
      </template>

      <template #[`item.description`]="{ item }">
        <p class="tasks-description-td">
          {{ item.description }}
        </p>
      </template>

      <template #[`item.assigned_to`]="{ item }">
        <td class="tasks-assignedTo-td">
          <VaButton round icon="person" size="small" />{{
            item.assigned_to?.first_name
          }}
          {{ item.assigned_to?.last_name }}
        </td>
      </template>

      <template #[`item.owner`]="{ item }">
        <td class="tasks-taskOwner-td">
          <VaButton round icon="person" size="small" />{{
            item.owner?.first_name
          }}
          {{ item.owner?.last_name }}
        </td>
      </template>

      <template #[`item.status`]="{ item }">
        <td
          :class="`tasks-status-td status-${item.status
            .toLowerCase()
            .replace(' ', '-')}`"
        >
          {{ item.status }}
        </td>
      </template>

      <template #[`item.start_date`]="{ item }">
        <p class="tasks-description-td">
          {{ item.start_date ? formattedDate(item.start_date) : '-' }}
        </p>
      </template>

      <template #[`item.end_date`]="{ item }">
        <p class="tasks-description-td">
          {{ item.start_date ? formattedDate(item.end_date) : '-' }}
        </p>
      </template>

      <template #[`item.priority`]="{ item }">
        <td class="tasks-priority-td">
          <VaButton round icon="flag" size="small" />
          {{ item.priority }}
        </td>
      </template>

      <template #[`item.action`]="{ item }">
        <td class="task-action-td">
          <va-dropdown :offset="[0, -70]">
            <template #anchor>
              <img :src="MoreVertIcon" alt="MoreVertIcon" />
            </template>
            <VaDropdownContent>
              <p class="task-action-option" @click="handleButtonClick(item)">
                Open with
              </p>
              <p class="task-action-option" @click="handleButtonClick(item)">
                Request Signature
              </p>
              <p class="task-action-option" @click="handleButtonClick(item)">
                Preview
              </p>
              <p class="task-action-option" @click="handleButtonClick(item)">
                Rename
              </p>
              <p class="task-action-option" @click="handleButtonClick(item)">
                File Info
              </p>
              <p class="task-action-option" @click="handleButtonClick(item)">
                Make A Copy
              </p>
              <p class="task-action-option" @click="handleButtonClick(item)">
                Access Permissions
              </p>
              <p class="task-action-option" @click="handleButtonClick(item)">
                Share
              </p>
              <p class="task-action-option" @click="handleButtonClick(item)">
                Move
              </p>
              <p class="task-action-option" @click="handleButtonClick(item)">
                Make Available Offline
              </p>
              <p class="task-action-option" @click="handleButtonClick(item)">
                delete
              </p>
            </VaDropdownContent>
          </va-dropdown>
        </td>
      </template>
    </v-data-table-server>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" scoped></style>
