import { defineComponent } from 'vue';
import UIButton from '../../../components/ui/button/index.vue';

export default defineComponent({
  name: 'CalendarButtons',
  components: {
    UIButton,
  },
  data() {
    return {
      icons: [],
    };
  },
  computed: {
    filters(): string[] {
      return this.$stores.calendar.titles;
    },
    activeFilter(): string {
      return this.$stores.calendar.addNewType;
    },
  },
  methods: {
    setActiveFilter(filter: string) {
      this.addNewDate(filter);
    },
    addNewDate(filter: string) {
      this.$stores.calendar.addNewType = filter;
      this.$stores.ui.showAddCalendarPopup = true;
    },
  },
});
