<template>
  <SharedHeader titleWidth="30rem" title="Workspace: Organizational structure">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left"></div>
      <div class="sub-SharedHeader-right">

      </div>
    </div>


  </SharedHeader>
  <TabsBar :tabs="tabs" :activeTabId="activeTabId" @changeTab="changeTab" />




  <TimeSheet v-if="activeTabId === 10" />

  <Tree v-else-if="activeTabId === 11" />

  <Accordion v-else-if="activeTabId === 12" :user="user" :users="users" />

  <div v-else class="container">
    <ComingSoon />

  </div>

</template>


<script lang="ts" src="./script.ts"></script>

<style lang="scss" scoped src="./styles.scss"></style>
