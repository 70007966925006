<template>
  <div id="chart">
    <apexchart
      type="donut"
      width="380"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import VueApexCharts from 'vue3-apexcharts'

  export default defineComponent({
    name: 'DonutChart',
    components: {
      apexchart: VueApexCharts,
    },
    data() {
      return {
        series: [9713, 2791],
        chartOptions: {
          chart: {
            width: 380,
            type: 'donut',
          },
          dataLabels: {
            enabled: false,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
          legend: {
            show: false,
          },
          colors: ['#58B081', '#ED7C7C'],
        },
      }
    },
  })
</script>
