import { defineStore } from 'pinia'

export const useTasksStore = defineStore({
  id: 'tasks',
  state: () => ({
    list: [
      {
        id: 1,
        title: 'Lead Generation',
        group: '0',
        details: 'Follow up with potential leads',
        contactName: 'John Doe',
        value: 1500,
        employeeName: 'Alice Johnson',
        cardDate: '15-aug-2024',
        companyName: 'Tech Innovators Inc.',
        employeeProfileImg: 'https://picsum.photos/50/50',
      },
      {
        id: 2,
        title: 'Product Launch',
        group: '0',
        details: 'Coordinate the launch of the new product line',
        contactName: 'Sarah Parker',
        value: 2500,
        employeeName: 'Michael Smith',
        cardDate: '15-aug-2024',
        companyName: 'Global Markets Ltd.',
        employeeProfileImg: 'https://picsum.photos/51/50',
      },
      {
        id: 3,
        title: 'Client Onboarding',
        group: '1',
        details: 'Assist with the onboarding process for new clients',
        contactName: 'Emily Davis',
        value: 2000,
        employeeName: 'David Brown',
        cardDate: '15-aug-2024',
        companyName: 'Fintech Solutions LLC',
        employeeProfileImg: 'https://picsum.photos/52/50',
      },
      {
        id: 4,
        title: 'Market Research',
        group: '1',
        details: 'Conduct market research for upcoming projects',
        contactName: 'Robert Wilson',
        value: 1800,
        employeeName: 'Laura White',
        cardDate: '15-aug-2024',
        companyName: 'Market Insights Co.',
        employeeProfileImg: 'https://picsum.photos/53/50',
      },
      {
        id: 5,
        title: 'Website Redesign',
        group: '2',
        details: 'Overhaul the company website design',
        contactName: 'Jessica Lee',
        value: 3000,
        employeeName: 'James Taylor',
        cardDate: '15-aug-2024',
        companyName: 'Creative Agency Ltd.',
        employeeProfileImg: 'https://picsum.photos/54/50',
      },
      {
        id: 6,
        title: 'Sales Strategy',
        group: '2',
        details: 'Develop a new sales strategy for Q3',
        contactName: 'Chris Harris',
        value: 2200,
        employeeName: 'Sophia Martinez',
        cardDate: '15-aug-2024',
        companyName: 'Salesforce Pros Inc.',
        employeeProfileImg: 'https://picsum.photos/55/50',
      },
    ],
    mockTasks: [] as any,
    groups: [
      { id: 0, title: 'Open', color: '#38A169', index: '0' },
      { id: 1, title: 'In Process', color: '#DD6B20', index: '1' },
      { id: 2, title: 'Completed', color: '#805AD5', index: '2' },
      { id: 3, title: 'Archived', color: '#00B5D8', index: '3' },
    ],
    status: [] as any,
  }),
  actions: {
    setTasks(newTasks: any) {
      this.mockTasks = newTasks
    },
    updateTaskList(newList: any[]) {
      this.mockTasks.list = newList
    },
    updateGroups(newGroups: any[]) {
      this.mockTasks.groups = newGroups
    },
  },
})
