import { defineComponent } from 'vue'
import ColumnsChart from './columnsChart/index.vue'
import DonutChart from './donutChart/index.vue'
import InlineTabs from './inlineTabs/index.vue'
import Card from './card/index.vue'
import Row from './row/index.vue'
import Table from './table/index.vue'
import DataTable from './dataTable/index.vue'

const Person = new URL('../../assets/icons/person-icon.svg', import.meta.url)
  .href
const Time = new URL('../../assets/icons/time-icon.svg', import.meta.url).href
const Note = new URL('../../assets/icons/note-icon.svg', import.meta.url).href

export default defineComponent({
  name: 'FinancialPage',
  components: {
    ColumnsChart,
    DonutChart,
    InlineTabs,
    Card,
    Row,
    Table,
    DataTable,
  },
  data() {
    return {
      tabs: [
        { id: 0, title: 'General' },
        { id: 1, title: 'Client Billing Reports' },
        { id: 2, title: 'Time Management Reports' },
      ],
      activeTabId: 0,
      profitAndLoss: {
        revenue: 8000,
        expenses: 5000,
        netIncome: 3000,
      },
      expenseSummary: {
        total: -5000,
      },
      clientProfitability: {
        revenue: 12000,
        expenses: 1000,
        netIncome: 11000,
      },
      currentAssets: [
        {
          id: 1,
          title: 'Checking Account',
          value: 5000,
        },
        {
          id: 2,
          title: 'Savings Account',
          value: 10000,
        },
        {
          id: 3,
          title: 'Petty Cash',
          value: 2000,
        },
        {
          id: 4,
          title: 'Accounts Receivable',
          value: 3000,
        },
        {
          id: 5,
          title: 'Inventory',
          value: 5000,
        },
        {
          id: 6,
          title: 'Prepaid Insurance',
          value: 1000,
        },
      ],
      totalCurrentAssets: 25000,
      nonCurrentAssets: [
        {
          id: 1,
          title: 'Accumulated Depreciation',
          value: -10000,
        },
        {
          id: 2,
          title: 'Computer',
          value: 20000,
        },
        {
          id: 3,
          title: 'Building',
          value: 500000,
        },
        {
          id: 4,
          title: 'Land',
          value: 0,
        },
      ],
      totalNonCurrentAssets: 510000,
      totalAssets: 50000,
      currentLiabilities: [
        {
          id: 1,
          title: 'Accounts Payable',
          value: 5000,
        },
        {
          id: 2,
          title: 'Line of Credit',
          value: 1000,
        },
        {
          id: 3,
          title: 'Payroll Liabilities',
          value: 2000,
        },
      ],
      totalCurrentLiabilities: 8000,
      nonCurrentLiabilities: [
        {
          id: 1,
          title: 'Long-term Debt (Loan)',
          value: 200000,
        },
      ],
      totalNonCurrentLiabilities: 200000,
      equity: [
        {
          id: 1,
          title: 'Owners Capital',
          value: 10000,
        },
        {
          id: 2,
          title: 'Retained Earnings',
          value: 5000,
        },
      ],
      totalEquity: 15000,
      totalLiabilitiesAndEquity: 50000,
      invoiced: 10000,
      received: 8000,
      outstanding: 2000,
      partnerOrLawyerHeaders: [
        { name: 'PARTNER/LAWYER', sortable: true, feild: 'person' },
        { name: 'REVENUE GENERATED', sortable: true, feild: 'revenue' },
        { name: 'BILLABLE HOURS', sortable: true, feild: 'billableHours' },
        { name: 'UTILIZATION RATE', sortable: true, feild: 'utilizationRate' },
        { name: 'CLIENT RATINGS', sortable: true, feild: 'clientRatings' },
        { name: '', sortable: true, feild: 'actions' },
      ],
      partnerOrLawyerList: [
        {
          id: 1,
          person: 'John Doe',
          revenue: 5000,
          billableHours: 100,
          utilizationRate: 80,
          clientRatings: 4.5,
        },
        {
          id: 2,
          person: 'Jane Doe',
          revenue: 5000,
          billableHours: 100,
          utilizationRate: 80,
          clientRatings: 4.5,
        },
      ],
      outstandingInvoiceReportHeaders: [
        { name: '#', sortable: true, feild: 'id' },
        { name: 'DATE ISSUED', sortable: true, feild: 'dateIssued' },
        { name: 'AMOUNT', sortable: true, feild: 'amount' },
        { name: 'DUE DATE', sortable: true, feild: 'dueDate' },
        { name: 'ACCOUNT', sortable: true, feild: 'account' },
        { name: 'STATUS', sortable: true, feild: 'status' },
        { name: '', sortable: true, feild: 'actions' },
      ],
      outstandingInvoiceReportList: [
        {
          id: 1,
          dateIssued: '01/01/2021',
          amount: 1000,
          dueDate: '01/02/2021',
          account: 'Amazon',
          status: 'Unpaid',
        },
        {
          id: 2,
          dateIssued: '01/01/2021',
          amount: 1000,
          dueDate: '01/02/2021',
          account: 'Amazon',
          status: 'Unpaid',
        },
        {
          id: 3,
          dateIssued: '01/01/2021',
          amount: 1000,
          dueDate: '01/02/2021',
          account: 'Amazon',
          status: 'Unpaid',
        },
      ],
      paymentHistoryReportHeaders: [
        { name: '#', sortable: true, feild: 'id' },
        { name: 'PAYMENT AMOUNT', sortable: true, feild: 'amount' },
        { name: 'DATE', sortable: true, feild: 'date' },
        { name: 'ACCOUNT', sortable: true, feild: 'account' },
        { name: 'TIME FRAME', sortable: true, feild: 'timeFrame' },
        { name: '', sortable: true, feild: 'actions' },
      ],
      paymentHistoryReportList: [
        {
          id: 1,
          amount: 1000,
          date: '01/01/2021',
          account: 'Amazon',
          timeFrame: '30-60',
        },
        {
          id: 2,
          amount: 1000,
          date: '01/01/2021',
          account: 'Amazon',
          timeFrame: '30-60',
        },
        {
          id: 3,
          amount: 1000,
          date: '01/01/2021',
          account: 'Amazon',
          timeFrame: '30-60',
        },
      ],
      clientActivityReportHeaders: [
        { name: '#', sortable: true, feild: 'id' },
        { name: 'ACCOUNT', sortable: true, feild: 'account' },
        { name: 'BILLABLE HOURS', sortable: true, feild: 'billableHours' },
        { name: 'EXPENSE INCURRED', sortable: true, feild: 'expenseIncurred' },
        { name: 'PAYMENT RECEIVED', sortable: true, feild: 'paymentReceived' },
        { name: '', sortable: true, feild: 'actions' },
      ],
      clientActivityReportList: [
        {
          id: 1,
          account: 'Amazon',
          billableHours: 100,
          expenseIncurred: 1000,
          paymentReceived: 800,
        },
        {
          id: 2,
          account: 'Amazon',
          billableHours: 100,
          expenseIncurred: 1000,
          paymentReceived: 800,
        },
        {
          id: 3,
          account: 'Amazon',
          billableHours: 100,
          expenseIncurred: 1000,
          paymentReceived: 800,
        },
      ],
      agingReportsHeaders: [
        { name: '#', sortable: true, feild: 'id' },
        { name: 'AMOUT DUE', sortable: true, feild: 'amountDue' },
        { name: 'NUMBER OF DAYS', sortable: true, feild: 'numberOfDays' },
        { name: 'ACCOUNT', sortable: true, feild: 'account' },
        { name: 'OVERDUE SEGMENT', sortable: true, feild: 'overdueSegment' },
        { name: '', sortable: true, feild: 'actions' },
      ],
      agingReportsList: [
        {
          id: 1,
          amountDue: 1000,
          numberOfDays: 30,
          account: 'Amazon',
          overdueSegment: '30-60',
        },
        {
          id: 2,
          amountDue: 1000,
          numberOfDays: 30,
          account: 'Amazon',
          overdueSegment: '30-60',
        },
        {
          id: 3,
          amountDue: 1000,
          numberOfDays: 30,
          account: 'Amazon',
          overdueSegment: '30-60',
        },
      ],
      detailsBillingReportsHeaders: [
        { name: '#', sortable: true, feild: 'id' },
        { name: 'ACCOUNT', sortable: true, feild: 'account' },
        { name: 'DATE', sortable: true, feild: 'date' },
        { name: 'DESCRIPTION', sortable: true, feild: 'description' },
        { name: 'TIME SPENT', sortable: true, feild: 'timeSpent' },
        { name: 'HOURLY RATE', sortable: true, feild: 'hourlyRate' },
        { name: 'EXPENSE AMOUNT', sortable: true, feild: 'expenseAmount' },
        { name: 'BILLED BY', sortable: true, feild: 'billedBy' },
        { name: '', sortable: true, feild: 'actions' },
      ],
      detailsBillingReportsList: [
        {
          id: 1,
          account: 'Amazon',
          date: '01/01/2021',
          description: 'Office Supplies',
          timeSpent: 100,
          hourlyRate: 50,
          expenseAmount: 1000,
          billedBy: 'Amazon',
        },
        {
          id: 2,
          account: 'Amazon',
          date: '01/01/2021',
          description: 'Office Supplies',
          timeSpent: 100,
          hourlyRate: 50,
          expenseAmount: 1000,
          billedBy: 'Amazon',
        },
        {
          id: 3,
          account: 'Amazon',
          date: '01/01/2021',
          description: 'Office Supplies',
          timeSpent: 100,
          hourlyRate: 50,
          expenseAmount: 1000,
          billedBy: 'Amazon',
        },
      ],
      totalTimeBilledHeaders: [
        { name: 'LAWYER', sortable: true, feild: 'lawyer' },
        { name: 'BILLABLE HOURS', sortable: true, feild: 'billableHours' },
        { name: 'LINK TO', sortable: true, feild: 'linkTo' },
        { name: 'DATE RANGE', sortable: true, feild: 'dateRange' },
        { name: 'BILLED TO', sortable: true, feild: 'billedTo' },
        { name: '', sortable: true, feild: 'actions' },
      ],
      totalTimeBilledList: [
        {
          id: 1,
          lawyer: 'John Doe',
          billableHours: 100,
          linkTo: 'www.amazon.com',
          dateRange: '0-30 days',
          billedTo: 'Amazon',
        },
        {
          id: 2,
          lawyer: 'John Doe',
          billableHours: 100,
          linkTo: 'www.amazon.com',
          dateRange: '0-30 days',
          billedTo: 'Amazon',
        },
        {
          id: 3,
          lawyer: 'John Doe',
          billableHours: 100,
          linkTo: 'www.amazon.com',
          dateRange: '0-30 days',
          billedTo: 'Amazon',
        },
      ],
      nonBillableHoursHeaders: [
        { name: 'LAWYER', sortable: true, feild: 'lawyer' },
        {
          name: 'NON-BILLABLE HOURS',
          sortable: true,
          feild: 'nonBillableHours',
        },
        { name: 'LINK TO', sortable: true, feild: 'linkTo' },
        { name: 'DATE RANGE', sortable: true, feild: 'dateRange' },
        { name: 'BILLED TO', sortable: true, feild: 'billedTo' },
        { name: '', sortable: true, feild: 'actions' },
      ],
      nonBillableHoursList: [
        {
          id: 1,
          lawyer: 'John Doe',
          nonBillableHours: 100,
          linkTo: 'www.amazon.com',
          dateRange: '0-30 days',
          billedTo: 'Amazon',
        },
        {
          id: 2,
          lawyer: 'John Doe',
          nonBillableHours: 100,
          linkTo: 'www.amazon.com',
          dateRange: '0-30 days',
          billedTo: 'Amazon',
        },
        {
          id: 3,
          lawyer: 'John Doe',
          nonBillableHours: 100,
          linkTo: 'www.amazon.com',
          dateRange: '0-30 days',
          billedTo: 'Amazon',
        },
      ],
      matterTimeBreakdownHeaders: [
        { name: 'DATE', sortable: true, feild: 'date' },
        { name: 'TIME', sortable: true, feild: 'time' },
        { name: '# HOURS', sortable: true, feild: 'hours' },
        { name: 'LINK TO CLIENT', sortable: true, feild: 'linkToClient' },
        { name: '', sortable: true, feild: 'actions' },
      ],
      matterTimeBreakdownList: [
        {
          id: 1,
          date: '01/01/2021',
          time: '10:00 AM',
          hours: 2,
          linkToClient: 'www.amazon.com',
        },
        {
          id: 2,
          date: '01/01/2021',
          time: '10:00 AM',
          hours: 2,
          linkToClient: 'www.amazon.com',
        },
        {
          id: 3,
          date: '01/01/2021',
          time: '10:00 AM',
          hours: 2,
          linkToClient: 'www.amazon.com',
        },
      ],
      utilizationrateHeaders: [
        { name: '#', sortable: true, feild: 'id' },
        { name: 'LAWYER', sortable: true, feild: 'lawyer' },
        { name: 'TOTAL WORK HOURS', sortable: true, feild: 'totalWorkHours' },
        { name: 'BILLABLE HOURS', sortable: true, feild: 'billableHours' },
        { name: '', sortable: true, feild: 'actions' },
      ],
      utilizationrateList: [
        {
          id: 1,
          lawyer: 'John Doe',
          totalWorkHours: 100,
          billableHours: 80,
        },
        {
          id: 2,
          lawyer: 'John Doe',
          totalWorkHours: 100,
          billableHours: 80,
        },
        {
          id: 3,
          lawyer: 'John Doe',
          totalWorkHours: 100,
          billableHours: 80,
        },
      ],
      totalTimeBilledTag: {
        title: 'Total Billed Time',
        value: '100 hrs',
        icon: Time,
      },
      nonBillableHoursTag: {
        title: 'Non Billable Hours',
        value: '20 hrs',
        icon: Time,
      },
      matterTimeBreakdownTag: {
        title: 'Time Spent',
        value: '73.23 hrs',
        icon: Time,
      },
      utilizationrateTag: {
        title: 'Utilization Rate',
        value: '80%',
        icon: Person,
      },
      outstandingInvoiceReportTag: {
        title: 'Outstanding Total',
        value: '$837,192',
        icon: Note,
      },
      paymentHistoryReportTag: {
        title: 'Total Payments',
        value: '%81.43',
        icon: Time,
      },
      agingReportsTag: {
        title: 'Invoices',
        value: '17',
        icon: Note,
      },
    }
  },
  methods: {
    changeTab(tab: { title: string; id: number }) {
      console.log(tab.id)
      this.activeTabId = tab.id
    },
  },
})
