<template>
  <div class="table">
    <div class="col-8">
      <table class="table">
        <thead class="table-header">
          <tr class="header-row">
            <th
              scope="col"
              v-for="header in headers"
              :key="header.feild"
              :style="{ minWidth: header.width, verticalAlign: 'middle' }"
            >
              <span class="header-text">{{ header.name }}</span>
            </th>
          </tr>
        </thead>
        <draggable
          v-model="list"
          tag="tbody"
          class="draggable-body"
          :item-key="(row: any) => row.id"
        >
          <template v-slot:item="{ element }">
            <tr>
              <td v-for="header in headers" :key="header.feild">
                <img
                  class="icon"
                  v-if="header.feild === 'icon'"
                  :src="element[header.feild]"
                />
                <div v-else :class="setClass(header.feild)">
                  {{ element[header.feild] }}
                </div>
              </td>
            </tr>
          </template>
        </draggable>
      </table>
    </div>

    <rawDisplayer class="col-3" :value="list" title="List" />
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style scoped lang="scss" src="./styles.scss"></style>
