import ChatComponent from '@/components/chat/index.vue'
import FilterDrawer from '@/components/FilterDrawer/index.vue'
import { getTeamMates } from '@/services/company'
import { useTimerStore } from '@/stores/timer'
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

const MoreVertIcon = new URL(
  '@/assets/icons/More-vert-icon.svg',
  import.meta.url
).href
const tlaImage = new URL('@/assets/images/tla.png', import.meta.url).href
const tlaLogoImage = new URL(
  '@/assets/images/tribonian_law_advisors_logo.png',
  import.meta.url
).href
const arabicImage = new URL('../../src/assets/icons/saudi.png', import.meta.url)
  .href
const englishImage = new URL('../../src/assets/icons/usa.png', import.meta.url)
  .href
const logoImage = new URL('../../src/assets/icons/logo.svg', import.meta.url)
  .href
const teamsImage = new URL(
  '../../src/assets/icons/team-icon.svg',
  import.meta.url
).href
const verifiedImage = new URL(
  '../../src/assets/icons/verified-icon.svg',
  import.meta.url
).href
const zoomImage = new URL(
  '../../src/assets/icons/zoom-icon.svg',
  import.meta.url
).href
const frenchImage = new URL(
  '../../src/assets/icons/france.png',
  import.meta.url
).href
const collapsedWorkspacesImage = new URL(
  '../../src/assets/icons/close-workspaces-icon.svg',
  import.meta.url
).href
const expandedWorkspacesImage = new URL(
  '../../src/assets/icons/open-workspaces-icon.svg',
  import.meta.url
).href
const collapseRightImage = new URL(
  '../../src/assets/icons/collapse-right-icon.svg',
  import.meta.url
).href
const expandadLeftImage = new URL(
  '../../src/assets/icons/collapse-left-icon.svg',
  import.meta.url
).href
const playImage = new URL(
  '../../src/assets/icons/play-icon.svg',
  import.meta.url
).href
const stopImage = new URL(
  '../../src/assets/icons/stop-icon.svg',
  import.meta.url
).href
const descriptionImage = new URL(
  '../../src/assets/icons/description-icon.svg',
  import.meta.url
).href
const description2Image = new URL(
  '../../src/assets/icons/description2-icon.svg',
  import.meta.url
).href

export default defineComponent({
  name: 'DefaultLayout',
  data() {
    const router = useRouter()

    return {
      views: [
        {
          name: `${this.$t('views.home')}`,
          path: '/',
          icon: 'home',
          active: true,
        },
        { name: `${this.$t('views.firm')}`, path: '/firm', icon: 'business' },
        {
          name: `${this.$t('views.contacts')}`,
          path: '/contacts',
          icon: 'contacts',
        },
        {
          name: `${this.$t('views.legalMatters')}`,
          path: '/legal-matters',
          icon: 'inventory_2',
        },
        {
          name: `${this.$t('views.legalLibrary')}`,
          path: '/legal-library',
          icon: 'library_books',
        },
        {
          name: `${this.$t('views.legalMarket')}`,
          path: '/legal-market',
          icon: 'storefront',
        },
        {
          name: `${this.$t('views.hearings')}`,
          path: '/hearings',
          icon: 'gavel',
        },
        {
          name: `${this.$t('views.tasks')}`,
          path: '/tasks',
          icon: 'assignment',
        },
        {
          name: `${this.$t('views.calendar')}`,
          path: '/calendar',
          icon: 'event',
        },
        { name: `${this.$t('views.files')}`, path: '/files', icon: 'folder' },
        {
          name: `${this.$t('views.financial')}`,
          path: '/financial',
          icon: 'attach_money',
        },
        {
          name: `${this.$t('views.emails')}`,
          path: '/emails',
          icon: 'mail',
        },
      ],
      // isCollapsed: false,
      // isWorkspacesOpen: false,
      lang: localStorage.getItem('locale') || 'en',
      router,
      arabicImage,
      tlaImage,
      tlaLogoImage,
      englishImage,
      frenchImage,
      logoImage,
      teamsImage,
      MoreVertIcon,
      zoomImage,
      verifiedImage,
      playImage,
      stopImage,
      descriptionImage,
      description2Image,
      collapsedWorkspacesImage,
      expandedWorkspacesImage,
      collapseRightImage,
      expandadLeftImage,
      changeLanguage: false,
      showNotifications: false,
      showRecord: false,
      showWorkSpaces: false,
      notificationNumber: 12,
      value: [true, false, false, false, false],
      collapses: [
        {
          title: 'Client-related',
          data: [
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
          ],
        },

        {
          title: 'Internal',
          data: [
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
          ],
        },
        {
          title: 'Billing and Financial',
          data: [
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
          ],
        },
        {
          title: 'Calendar and Deadline',
          data: [
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
          ],
        },
        {
          title: 'General',
          data: [
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
            {
              content: 'First collapse content',
              name: 'Ali Tarraf',
              position: 'Position',
              companyName: 'EPTALEX GLOBAL',
              team: 'Testy team',
              time: '12 min ago',
            },
          ],
        },
      ],
      items: [
        { title: 'Dashboard', icon: 'dashboard' },
        { title: 'Sidebar demo', icon: 'room', active: true },
        { title: 'Loop', icon: 'loop' },
      ],
      minimized: false,
      fav: true,
      menu: false,
      message: false,
      hints: true,
    }
  },
  methods: {
    selectLanguage(lang: string) {
      if (lang === this.lang) return
      localStorage.setItem('locale', lang)
      window.location.reload()
    },
    handleCloseNotification() {
      this.showNotifications = !this.showNotifications
    },
    handleLogOut() {
      localStorage.removeItem('authaqq')
      console.log('Logged out successfully')
      this.router.push({ name: 'LoginView' })
    },
  },
  computed: {
    currentLanguage(): string {
      if (this.lang === 'ar') return 'العربية'
      if (this.lang === 'en') return 'English'
      if (this.lang === 'fr') return 'Français'
      return 'English'
    },
    currentFlag(): string {
      if (this.lang === 'ar') return this.arabicImage
      if (this.lang === 'en') return this.englishImage
      if (this.lang === 'fr') return this.frenchImage
      return this.englishImage
    },
    currentWorkspaceImage(): string {
      return this.showWorkSpaces
        ? this.collapsedWorkspacesImage
        : this.expandedWorkspacesImage
    },
    currentSideBarImage(): string {
      const locale = localStorage.getItem('locale') || 'en'
      if (locale === 'ar') {
        if (this.minimized) return this.expandadLeftImage
        return this.collapseRightImage
      }
      if (this.minimized) return this.collapseRightImage
      return this.expandadLeftImage
    },
    isCalendar(): boolean {
      return this.$route.path.includes('/calendar')
    },
  },
  setup() {
    const timerStore = useTimerStore()

    return {
      timerStore,
    }
  },
  components: {
    ChatComponent,
    FilterDrawer,
  },
})
