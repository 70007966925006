import { defineComponent, reactive } from 'vue'
import SharedHeader from '@/components/sharedHeader/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import TimeRange from '@/components/TimeRange/index.vue'
import { useForm } from 'vuestic-ui'
const { isValid, validate, reset, resetValidation } = useForm('formRef')

export default defineComponent({
  name: 'HearingsDetails',
  data() {
    const taskBarTabs = [
      { id: 0, title: 'DASHBOARD', icon: 'dashboard' },
      { id: 1, title: 'INFO', icon: 'info' },
      { id: 2, title: 'LINKED INFO', icon: 'task' },
      { id: 3, title: 'TASKS', icon: 'assignment' },
      { id: 4, title: 'CALENDAR', icon: 'calendar_month' },
      { id: 5, title: 'MILESTONES', icon: 'flag' },
      { id: 6, title: 'FILES', icon: 'file_copy' },
      { id: 7, title: 'FINANCIAL', icon: 'attach_money' },
      { id: 8, title: 'HEARINGS', icon: 'gavel' },
      { id: 9, title: 'ENGAGEMENT LETTER', icon: 'article' },
      { id: 10, title: 'EMAILS', icon: 'mail' },
      { id: 11, title: 'KYC DOCUMENTS', icon: 'article' },
    ]

    return {
      selectedDate: null,
      title: 'Register Company',
      id: '#12345',
      status: 'In Progress',
      stage: 'Pre-Litigation',
      tabs: taskBarTabs,
      activeTab: 'INFO',
      activeTabId: 1,
      relationships: [
        { name: 'Legal Matter', details: 'Recent 2', subDetail: 'child' },
        { name: 'Legal Matter', details: 'Recent 2', subDetail: 'child' },
      ],
      form: reactive({
        court: [],
        courtID: '',
        judgeName: [],
        client: [],
        assignTo: [],
        opponentName: '',
        jurisdictions: [],
        judicialDistrict: [],
        Region: '',
        summary: '',
      }),
      courtOptions: [
        { value: '0', text: 'Alternative Dispute Resolution' },
        { value: '1', text: 'Anti-Corruption law' },
        { value: '2', text: 'Arbitration' },
        { value: '3', text: 'Aviation Law' },
        { value: '4', text: 'Banking Law' },
        { value: '5', text: 'Bankruptcy Law' },
        { value: '6', text: 'Civil Litigation' },
      ],
      judgeNameOptions: [
        { value: 0, text: 'Abdo Habib', icon: 'account_circle' },
        { value: 1, text: 'Alex Pestoulls', icon: 'account_circle' },
        { value: 2, text: 'Antoine Kanaan', icon: 'account_circle' },
        { value: 3, text: 'Armand Bejjani', icon: 'account_circle' },
      ],
      representingOptions: [
        { value: '0', text: 'Alternative Dispute Resolution' },
        { value: '1', text: 'Anti-Corruption law' },
        { value: '2', text: 'Arbitration' },
        { value: '3', text: 'Aviation Law' },
        { value: '4', text: 'Banking Law' },
        { value: '5', text: 'Bankruptcy Law' },
        { value: '6', text: 'Civil Litigation' },
      ],
    }
  },
  methods: {
    changeTab(tab: { title: string; id: number }) {
      this.activeTabId = tab.id
      this.activeTab = tab.title
    },
    handleSelectedDate(date: any) {
      this.selectedDate = date
    },
  },
  components: {
    SharedHeader,
    TabsBar,
    TimeRange,
  },
})
