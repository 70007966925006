import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c151f22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "invoices-table-container" }
const _hoisted_2 = { class: "status" }
const _hoisted_3 = { class: "invoice-action-td" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_va_dropdown_content = _resolveComponent("va-dropdown-content")!
  const _component_va_dropdown = _resolveComponent("va-dropdown")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_data_table_server, {
      selection: _ctx.selectedRows,
      "onUpdate:selection": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedRows) = $event)),
      loading: _ctx.loading,
      headers: _ctx.headers,
      items: _ctx.invoices?.list,
      "show-select": "",
      class: "custom-table",
      "items-length": _ctx.localTotalItems,
      "items-per-page": _ctx.itemsPerPage,
      "onUpdate:options": _ctx.handleLoadMore,
      "items-per-page-options": [10, 25, 50]
    }, {
      [`item.invoice_number`]: _withCtx(({ item }) => [
        _createVNode(_component_router_link, {
          to: `/financial/invoice/${item?.id}`
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(item?.invoice_number), 1)
          ]),
          _: 2
        }, 1032, ["to"])
      ]),
      [`item.status`]: _withCtx(({ item }) => [
        _createElementVNode("p", _hoisted_2, _toDisplayString(item.status), 1)
      ]),
      [`item.created_at`]: _withCtx(({ item }) => [
        _createElementVNode("p", null, _toDisplayString(item.created_at.split('T')[0]), 1)
      ]),
      [`item.action`]: _withCtx(({ item }) => [
        _createElementVNode("td", _hoisted_3, [
          _createVNode(_component_va_dropdown, {
            offset: [0, -70],
            "close-on-content-click": ""
          }, {
            anchor: _withCtx(() => [
              _createElementVNode("img", {
                src: _ctx.MoreVertIcon,
                alt: "MoreVertIcon"
              }, null, 8, _hoisted_4)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_va_dropdown_content, null, {
                default: _withCtx(() => [
                  _createElementVNode("p", {
                    class: "invoice-action-option",
                    onClick: ($event: any) => (_ctx.viewProfile(item))
                  }, [
                    _createVNode(_component_va_icon, { name: "person" }),
                    _createTextVNode(" View Invoice ")
                  ], 8, _hoisted_5)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024)
        ])
      ]),
      _: 2
    }, 1032, ["selection", "loading", "headers", "items", "items-length", "items-per-page", "onUpdate:options"])
  ]))
}