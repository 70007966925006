import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3eae661b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", null, [
    _createElementVNode("img", {
      src: require('../../assets/icons/add-icon.svg'),
      alt: "add",
      class: "add",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('add')))
    }, null, 8, _hoisted_1)
  ]))
}