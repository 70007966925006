<template>
  <v-dialog v-model="showModal" class="add-popup" max-width="40rem">
    <v-card>
      <Input type="text" :placeholder="$t('calendar.addPopup.addTitle')" class="add-popup__input"
        v-model="form.title" />
      <CalendarButtons />
      <div class="add-popup__select-container">
        <v-select :label="$t('calendar.addPopup.selectLabel')" :items="['Label 1', 'Label 2', 'Label 3']"
          color="#b8c4d6" base-color="#b8c4d6" rounded="5px"></v-select>
        <v-select :label="$t('calendar.addPopup.selectProirity')" :items="['Normal', 'Urgent', 'Critical']"
          color="#b8c4d6" base-color="#b8c4d6" rounded="5px"></v-select>
      </div>
      <div class="add-popup__time">
        <div class="time-sentence">
          Thursday, January 18, 2024
          <button>{{ $t("calendar.addPopup.addTime") }}</button>
        </div>
      </div>
      <div class="add-popup__time-estimate">Time Estimate</div>
      <v-select :label="$t('calendar.addPopup.noRepeat')" :items="['Label 1', 'Label 2', 'Label 3']" color="#b8c4d6"
        base-color="#b8c4d6" rounded="5px" clearable selected-top-shown chips></v-select>
      <button class="add-popup__blue-btn">
        {{ $t("calendar.addPopup.addReminder") }}
      </button>
      <Input type="text" :placeholder="$t('calendar.addPopup.taskOwner')" class="add-popup__input"
        v-model="form.taskOwner" />
      <Input type="text" :placeholder="$t('calendar.addPopup.assignTo')" class="add-popup__input"
        v-model="form.assignTo" />
      <button class="add-popup__blue-btn">
        {{ $t("calendar.addPopup.linkTo") }}
      </button>
      <button class="add-popup__blue-btn">
        {{ $t("calendar.addPopup.addSubTask") }}
      </button>
      <button class="add-popup__blue-btn">
        {{ $t("calendar.addPopup.addSubTask") }}
      </button>
      <div class="add-popup__input-icon">
        <Input type="text" :placeholder="$t('calendar.addPopup.addLocation')" class="add-popup__input"
          v-model="form.assignTo" />
      </div>
      <button class="add-popup__blue-btn">
        {{ $t("calendar.addPopup.addAttachment") }}
      </button>
      <div class="add-popup__input-icon">
        <Input type="text" :placeholder="$t('calendar.addPopup.addNote')" class="add-popup__input"
          v-model="form.assignTo" />
      </div>
      <div class="add-popup__input-icon">
        <Input type="text" :placeholder="$t('calendar.addPopup.addValue')" class="add-popup__input"
          v-model="form.assignTo" />
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="add-popup__button-container">
          <UIButton :button-text="$t('calendar.addPopup.moreOptions')" backgroundColor="transparent"
            hoverBackgroundColor="#66023c" textColor="#66023c" />
          <UIButton :button-text="$t('calendar.addPopup.save')" @click="saveEvent" />
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" />
