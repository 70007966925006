<template>
  <div class="container">
    <ComingSoon />

  </div>
  <!-- <div>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel title="Legislation Team" value="Legislation Team">
        <v-expansion-panel-text>
          <div class="details">
            <p class="description">
              Lorem ipsum dolor sit amet consectetur. Donec a nunc blandit
              congue leo dictum sed est. Enim mattis blandit diam malesuada et
              pellentesque gravida ipsum velit.
            </p>
            <div class="team">
              <span>Legislation Team</span>
            </div>
            <small>*This tag is autimatically created for every team.</small>
            <h5>Jurisdictions</h5>
            <router-link to="">
              <span>Chat with the team here</span>
            </router-link>
            <p>Team Manager</p>
            <UserCard :user="user" />
            <p>Team Members</p>
            <UserCard v-for="user in users" :key="user.id" :user="user" />
          </div> </v-expansion-panel-text
      ></v-expansion-panel>
      <v-expansion-panel
        title="Accounting Team"
        value="Accounting Team"
      ></v-expansion-panel>
      <v-expansion-panel
        title="International Law Team"
        value="International Law Team"
      ></v-expansion-panel>
    </v-expansion-panels>
  </div> -->
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />

<!-- <script lang="ts">
  import { defineComponent } from 'vue'
  import UserCard from '@/components/userCard/index.vue'

  export default defineComponent({
    props: {
      user: {
        type: Object as () => {
          id: number
          name: string
          title: string
          company: string
          avatar: string
        },
        required: true,
      },
      users: {
        type: Array as () => {
          id: number
          name: string
          title: string
          company: string
          avatar: string
        }[],
        required: true,
      },
    },
    data() {
      return {
        panel: [],
      }
    },
    components: {
      UserCard,
    },
    methods: {},
  })
</script>

<style lang="scss" scoped>
  .details {
    @include flex-box(column, flex-start, flex-start, 5px);
    .description {
      color: $black;
      font-size: 12px;
      font-weight: 400;
    }
    .team {
      @include flex-box(column, center, center, 8px);
      height: 28px;
      padding: 8px 16px;
      border-radius: 50px;
      border: 0.2px solid rgba(0, 44, 108, 0.5);
      max-width: fit-content;

      span {
        color: #1a202c;
        font-size: 12px;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }
</style> -->
