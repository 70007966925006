import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_calendar = _resolveComponent("v-calendar")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_sheet, {
      class: "d-flex",
      height: "54",
      tile: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_calendar, {
          ref: "calendar",
          modelValue: _ctx.$stores.calendar.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$stores.calendar.value) = $event)),
          events: _ctx.$stores.calendar.events,
          "view-mode": _ctx.calendarViewMode
        }, null, 8, ["modelValue", "events", "view-mode"])
      ]),
      _: 1
    })
  ]))
}