import { defineComponent } from 'vue'
import draggable from 'vuedraggable'
import TaskCard from '@/components/Task/Card/index.vue'
import LegalMatterCard from '@/components/LegalMatter/legalMatterCard/index.vue'

export default defineComponent({
  name: 'DragBoard',
  components: {
    draggable,
    TaskCard,
    LegalMatterCard,
  },
  props: {
    stages: {
      type: Array as () => any[],
      required: true,
    },
    isLoadingLocal: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTasks: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLegalMatters: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    console.log()

    return {
      localStages: this.stages as any,
      isLoading: this.isLoadingLocal,
    }
  },
  emits: ['ticketMoved'],
  watch: {
    isLoadingLocal(newVal) {
      this.isLoading = newVal
    },
  },

  methods: {
    onDragEnd(evt: any) {
      const droppedOnStageId = evt.to.getAttribute('data-stage-id')
      const draggedTicketId = evt?.item?._underlying_vm_?.item.id
      const draggedTicketName = evt?.item?._underlying_vm_?.item.name
      const newIndex = evt.newIndex

      this.$emit('ticketMoved', {
        id: draggedTicketId,
        name: draggedTicketName,
        section_stage_id: droppedOnStageId,
        section_stage_order: newIndex,
      })
    },
  },
})
