<template>
  <div class="task-page">
    <p>TASK ID: {{ form?.id }}</p>
    <SharedHeader titleWidth="50rem" :title="form?.name" flag="flag">
      <div class="sub-SharedHeader">
        <div class="sub-SharedHeader-left"></div>
        <div class="sub-SharedHeader-right">
          <TimeRange
            @update:date="handleSelectedDate"
            :date="{ start: form?.start_date, end: form?.end_date }"
          />

          <DatePickerModal
            @update:date="handleSelectDueDate"
            :date="form.due_date"
          />

          <VaButton preset="primary">
            <div class="timer-container">
              <img
                :src="timerStore.isPlaying ? stopImage : playImage"
                alt="LOGO"
                @click="timerStore.toggleTimer"
              />
              <div
                v-if="timerStore.elapsedTime > 0"
                style="flex-shrink: 0; width: 5rem"
              >
                {{ timerStore.formattedTime }}
              </div>
            </div>
          </VaButton>
        </div>
      </div>
    </SharedHeader>
    <div class="info-container">
      <h2 v-if="stages.length > 0" class="info-container-status">
        Status :
        <VaMenu>
          <template #anchor>
            <VaButton icon="arrow_drop_down" round size="small">
              {{ form?.section_stage_items[0]?.section_stage.name }}
            </VaButton>
          </template>
          <VaMenuItem
            v-for="(stage, index) in stages"
            :key="index"
            @click="handleStageMenuItemClick(stage)"
          >
            {{ stage.name }}
          </VaMenuItem>
        </VaMenu>
      </h2>
      <h2 v-else-if="form.parent_id">
        <VaButton
          icon="arrow_back"
          round
          @click="handleBackToParent(form?.parent_id)"
        >
          Parent
        </VaButton>
      </h2>
      <h2 v-else></h2>

      <h2 class="info-container-priority">
        Priority :
        <VaMenu>
          <template #anchor>
            <VaButton
              icon="arrow_drop_down"
              round
              size="small"
              :color="getPriorityColor(form?.priority)"
            >
              {{ form?.priority }}
            </VaButton>
          </template>
          <VaMenuItem
            v-for="(flag, index) in flags"
            :key="index"
            @click="handleFlagMenuItemClick(flag)"
          >
            <VaIcon name="flag" :color="flag.color" />
            {{ flag.name }}
          </VaMenuItem>
        </VaMenu>
      </h2>
    </div>

    <div class="taskbar-scrollable-container">
      <TabsBar :tabs="tabs" :activeTabId="activeTabId" @changeTab="changeTab" />
    </div>

    <div class="content-container">
      <!-- everything inside here is scrollable; outside this box is sticky -->

      <!-- <div class="test" v-if="activeTab === 'TASK INFO'"> -->
      <div v-if="activeTab === 'TASK INFO'" class="task-info">
        <VaForm ref="form">
          <div class="OwnerContainer">
            <div class="owner">
              <h2>Owner</h2>
              <span class="owner-container">
                <va-avatar
                  color="#ECF0F1"
                  :src="form?.owner?.avatar?.thumbnail_url"
                  :alt="form?.owner?.first_name + ' ' + form?.owner?.last_name"
                  style="border-radius: 50%; width: 3rem; height: 3rem"
                >
                  <span
                    style="color: black"
                    v-if="!form?.owner?.avatar?.thumbnail_url"
                    >{{
                      form?.owner?.first_name.charAt(0) +
                      ' ' +
                      form?.owner?.last_name.charAt(0)
                    }}</span
                  >
                </va-avatar>

                <div>
                  <h2>
                    {{ form?.owner?.first_name || 'No Owner' }}
                    {{ form?.owner?.last_name }}
                    <VaIcon
                      name="cancel"
                      @click="handleRemoveOwner"
                      v-if="form?.owner?.first_name"
                    />
                  </h2>
                  <OwnerAssignTo
                    @handleAssign="handleChooseOwner"
                    :list="teamMembers"
                  />
                </div>
              </span>
            </div>
            <div class="owner">
              <h2>Assign to</h2>
              <span class="owner-container">
                <va-avatar
                  color="#ECF0F1"
                  :src="form?.assigned_to?.avatar?.thumbnail_url"
                  :alt="
                    form?.assigned_to?.first_name +
                    ' ' +
                    form?.assigned_to?.last_name
                  "
                  style="border-radius: 50%; width: 3rem; height: 3rem"
                >
                  <span
                    style="color: black"
                    v-if="!form?.assigned_to?.avatar?.thumbnail_url"
                    >{{
                      form?.assigned_to?.first_name.charAt(0) +
                      ' ' +
                      form?.assigned_to?.last_name.charAt(0)
                    }}</span
                  >
                </va-avatar>

                <div>
                  <h2>
                    {{ form?.assigned_to?.first_name || 'No Assigned' }}
                    {{ form?.assigned_to?.last_name }}
                    <VaIcon
                      name="cancel"
                      @click="handleRemoveAssign"
                      v-if="form?.assigned_to?.first_name"
                    />
                  </h2>
                  <OwnerAssignTo
                    @handleAssign="handleChooseAssign"
                    :list="teamMembers"
                  />
                </div>
              </span>
            </div>
          </div>
          <div class="taskContainer">
            <div class="task-name-container">
              <h1>Task Title</h1>
              <VaInput
                v-model="form.name"
                placeholder="Title"
                class="task-name"
                preset="bordered"
                requiredMark
                :rules="[(value) => value && value.length > 0]"
              />
            </div>
            <div class="add-label-container">
              <div class="chips-container">
                <span v-for="(label, index) in labels" :key="index">
                  <CustomChip
                    closeable
                    @close="handleDeleteLabel(label.id)"
                    :label="label.name"
                    :color="label.color"
                  />
                </span>
              </div>
              <v-menu
                v-model="isLabelModal"
                :close-on-content-click="false"
                location="right top"
              >
                <template v-slot:activator="{ props }">
                  <VaButton
                    icon-right="add"
                    preset="secondary"
                    border-color="primary"
                    icon-color="#812E9E"
                    v-bind="props"
                  >
                    Add Label
                  </VaButton>
                </template>

                <v-card width="300" class="pa-4">
                  <v-text-field
                    v-model="labelInputValue"
                    placeholder="Add Label"
                  />

                  <div class="color-palette">
                    <VaColorPalette
                      v-model="selectedLabelColor"
                      :palette="palette"
                    />
                  </div>

                  <VaButton
                    icon-right="add"
                    preset="secondary"
                    border-color="primary"
                    icon-color="#812E9E"
                    @click="handleAddLabel()"
                  >
                    Add
                  </VaButton>
                </v-card>
              </v-menu>
            </div>

            <div class="description-container">
              <h1>Description</h1>
              <VaTextarea
                placeholder="Add description..."
                v-model="form.description"
                counter
                class="description"
                autosize
                max-rows="6"
                background="#ECF0F1"
              />
            </div>
          </div>
        </VaForm>

        <VaForm ref="subTaskForm" class="sub-task-container">
          <h1>Subtask (Optional)</h1>
          <div class="sub-tasks" v-for="(task, index) in subTasks" :key="index">
            <SubTask
              :id="task.id"
              :text="task.name"
              :dateRange="{ start: task.start_date, end: task.end_date }"
            />
          </div>
          <VaInput
            v-if="showAddSubtaskInput"
            background="#ECF0F1"
            v-model="subTaskForm.name"
            placeholder="Add Subtask"
            class="sub-task"
            label="Subtask"
            @keydown.enter="handleKeydownAddTask"
            :rules="[(value) => (value && value.length > 0) || 'Required']"
          >
            <template #appendInner>
              <span class="sub-task-buttons-container">
                <TimeRange
                  @update:date="handleSelectedSubDate"
                  :date="{
                    start: subTaskForm.start_date,
                    end: subTaskForm.end_date,
                  }"
                  shape="icon"
                />
                <VaIcon
                  name="check"
                  size="small"
                  color="primary"
                  @click="handleKeydownAddTask"
                />
                <VaIcon
                  name="close"
                  size="small"
                  color="primary"
                  @click=";(showAddSubtaskInput = false), (subtaskDetails = '')"
                />
              </span>
            </template>
          </VaInput>
          <VaButton
            icon="add"
            preset="secondary"
            border-color="primary"
            icon-color="#812E9E"
            @click="showAddSubtaskInput = !showAddSubtaskInput"
          >
            Add a subtask
          </VaButton>
        </VaForm>

        <div class="action-buttons">
          <VaButton
            preset="secondary"
            border-color="primary"
            @click="handleBack"
          >
            Back
          </VaButton>

          <VaButton
            preset="primary"
            :loading="loadingUpdate"
            @click="validate() && handleUpdate()"
          >
            Update
          </VaButton>
        </div>
      </div>

      <!-- <div class="logs">
          <ChatActivity />

        </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style scoped lang="scss" src="./styles.scss"></style>
