import { defineStore } from 'pinia';

export const useTimerStore = defineStore({
    id: 'Timer',
    state: () => ({
        timer: null as number | null,
        elapsedTime: 0,
        timeRecords: [] as { startTime: string; duration: string }[],
        isPlaying: false,
        startTime: null as string | null,
    }),
    getters: {
        formattedTime(state) {
            const hours = Math.floor(state.elapsedTime / 3600);
            const minutes = Math.floor((state.elapsedTime % 3600) / 60);
            const seconds = state.elapsedTime % 60;
            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        },
    },
    actions: {
        toggleTimer() {
            if (this.isPlaying) {
                // Stop the timer and log the elapsed time with the start date
                if (this.timer !== null) {
                    clearInterval(this.timer);
                }
                const endTime = this.formattedTime;
                const startTimeString = this.startTime || 'Unknown Start Time';
                this.timeRecords.push({
                    startTime: startTimeString,
                    duration: endTime,
                });
                this.elapsedTime = 0; // Reset timer to zero
            } else {
                // Start the timer and save the start time
                this.startTime = new Date().toLocaleString(); // Save the current date and time
                this.elapsedTime++; // Immediately increment the time
                this.timer = setInterval(() => {
                    this.elapsedTime++;
                }, 1000);
            }
            this.isPlaying = !this.isPlaying;
        },
        resetTimer() {
            if (this.timer !== null) {
                clearInterval(this.timer);
            }
            this.elapsedTime = 0;
            this.isPlaying = false;
        },
    },
});
