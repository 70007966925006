import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-203ede98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "column" }
const _hoisted_2 = { class: "column-header" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactCard = _resolveComponent("ContactCard")!
  const _component_LegalMatterCard = _resolveComponent("LegalMatterCard")!
  const _component_TaskCard = _resolveComponent("TaskCard")!
  const _component_FinancialCard = _resolveComponent("FinancialCard")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1)
    ]),
    _createVNode(_component_draggable, {
      class: "list-group",
      list: _ctx.list,
      group: "people",
      onChange: _ctx.log,
      itemKey: "name"
    }, {
      item: _withCtx(({ element }) => [
        _createElementVNode("div", {
          style: {"width":"100%"},
          onClick: ($event: any) => (_ctx.navigateToDetail(element.id)),
          onMousedown: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
          onTouchstart: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
        }, [
          (_ctx.isContacts)
            ? (_openBlock(), _createBlock(_component_ContactCard, {
                key: 0,
                contact: element
              }, null, 8, ["contact"]))
            : _createCommentVNode("", true),
          (_ctx.isLegalMatters)
            ? (_openBlock(), _createBlock(_component_LegalMatterCard, {
                key: 1,
                matter: element
              }, null, 8, ["matter"]))
            : _createCommentVNode("", true),
          (_ctx.isTasks)
            ? (_openBlock(), _createBlock(_component_TaskCard, {
                key: 2,
                matter: element
              }, null, 8, ["matter"]))
            : _createCommentVNode("", true),
          (_ctx.isFinancial)
            ? (_openBlock(), _createBlock(_component_FinancialCard, {
                key: 3,
                expense: element
              }, null, 8, ["expense"]))
            : _createCommentVNode("", true)
        ], 40, _hoisted_3)
      ]),
      _: 1
    }, 8, ["list", "onChange"])
  ]))
}