<template>
  <div class="card-container">
    <div class="card-header">
      <h3 class="card-title">
        {{ title }}
      </h3>
      <button class="card-btn">
        {{ $t('financialDashboard.card.viewDetails') }}
      </button>
    </div>
    <div class="card-body">
      <div v-if="hasTag" class="tag">
        <img :src="tag.icon" alt="tag" class="tag-icon" />
        <div>
          <p class="tag-value">{{ tag.value }}</p>
          <p class="tag-title">{{ tag.title }}</p>
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style scoped lang="scss" src="./styles.scss"></style>
