<!-- <template>
  <SharedHeader titleWidth="30rem" title="Files">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left"></div>
      <div class="sub-SharedHeader-right">
      </div>
    </div>
  </SharedHeader>
  <TabsBar :tabs="tabs" :activeTabId="activeTabId" @changeTab="changeTab" />
  <SearchAndViews :activeView="activeView" @search="searchData" @changeView="changeView" />
  <ActionsBar :showSelectButton="true" :showDownloadIcon="true" :showArchieveIcon="true" :showLabelIcon="true"
    :showShareIcon="true" :secondAddButton="$t('actionsBar.addCase')" :editColumns="true" />
  <VaBreadcrumbs color="secondary" separator=">" separator-color="primary" class="breadcrumb-custom mb-6">

    <VaBreadcrumbsItem label="Files:" disabled />
    <VaBreadcrumbsItem label="Firm" />
    <VaBreadcrumbsItem label="Cases" />
    <VaBreadcrumbsItem label="Corporate" />
  </VaBreadcrumbs>
  <div v-if="activeTab === 'Shared Files' && activeView === 'board'">
    <FilesGrid :folders="folders" :files="files" />
  </div>
  <div v-else-if="activeTab === 'Shared Files' && activeView === 'list'">
    <Table />
  </div>
  <div v-else class="container">
    <ComingSoon />

  </div>
</template> -->

<template>
  <SharedHeader titleWidth="30rem" title="Files">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left"></div>
      <div class="sub-SharedHeader-right">
      </div>
    </div>
  </SharedHeader>
  <div class="container">
    <ComingSoon />

  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>

<!-- using styles specific for vuestic components; if the styles is scoped the component wont take css  -->

<style>
.breadcrumb-custom {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  padding: 10px 15px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.breadcrumb-custom .va-breadcrumbs__item {
  text-transform: uppercase;
  font-weight: bold;
  color: #52709b;
  cursor: pointer;
  /* Add cursor pointer */
}

.breadcrumb-custom .va-breadcrumbs__item:hover {
  color: #3498db;
  /* Interactive hover effect */
}

.breadcrumb-custom .va-breadcrumbs__item:last-child {
  color: #66023c;
  /* Highlight last item */
}

.breadcrumb-custom .va-breadcrumbs-separator {
  font-size: 1.3rem;
  /* Make the separator slightly bigger */
  color: #7f8c8d;
  /* Muted color for separator */
}
</style>