import { defineComponent } from 'vue'
import Calendar from '@/components/Calendar'
import CalendarFilterBoxes from '@/components/Calendar/FilterBoxes/index.vue'
import CalendarButtons from '@/components/Calendar/Buttons/index.vue'
import CalednarSearch from '@/components/Calendar/Search/index.vue'
import DatePicker from '@/components/DatePickerOld/index.vue'
import CalendarPopup from '@/components/Calendar/Popup/index.vue'
import UIButton from '@/components/ui/button/index.vue'
import ComingSoon from '@/components/ComingSoon/index.vue'
import SharedHeader from '@/components/sharedHeader/index.vue'

export default defineComponent({
  name: 'CalendarPage',
  components: {
    Calendar,
    CalendarFilterBoxes,
    CalendarButtons,
    DatePicker,
    CalednarSearch,
    CalendarPopup,
    UIButton,
    ComingSoon,
    SharedHeader,
  },
  data() {
    return {
      value: new Date(),
    }
  },
  methods: {
    showFiltersDrawer(): void {
      this.$stores.ui.showFilterDrawer = true
    },
  },
})
