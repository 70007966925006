import { defineComponent } from 'vue';
import UIButton from '@/components/ui/button/index.vue';
import CalendarButtons from '@/components/Calendar/Buttons/index.vue';

export default defineComponent({
  name: 'Popup',
  components: {
    UIButton,
    CalendarButtons,
  },
  data() {
    return {
      message: 'hello',
      form: {
        title: '',
        taskOwner: '',
        assignTo: '',
      },
    };
  },
  computed: {
    showModal(): boolean {
      return this.$stores.ui.showAddCalendarPopup;
    },
  },
  methods: {
    saveEvent(): void {
      this.$stores.ui.showAddCalendarPopup = false;
    },
  },
});
