import { defineComponent, reactive, watch } from 'vue';

interface Group {
    id: number;
    title: string;
    index: string;
}

export default defineComponent({
    name: 'UpdateLayoutColumns',
    props: {
        isShow: {
            type: Boolean,
            required: false,
            default: false
        },
        groups: {
            type: Array as () => Group[],
            required: false,
        }
    },
    data() {
        return {
            localIsShow: this.isShow
        };
    },
    setup(props) {
        const localGroups = reactive(
            [...(props.groups || [])].sort((a, b) => Number(a.index) - Number(b.index))
        );

        return {
            localGroups
        };
    },
    watch: {
        isShow(newVal) {
            this.localIsShow = newVal;
        }
    },
    methods: {
        emitApplyChanges() {
            this.$emit('applyLayoutChanges'); // Emit the apply event to the parent component
            this.localIsShow = true;
        },
        closeModal() {
            this.$emit('update:isShow', false);
        }
    },
});