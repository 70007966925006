import { defineStore } from 'pinia'
import { i18n } from '../main'

export enum Language {
  EN = 'en',
  AR = 'ar',
  FR = 'fr',
}

export const useLanguageStore = defineStore('language', {
  state: () => ({
    locale: 'en',
  }),
  actions: {
    setLocale(locale: Language) {
      this.locale = locale
      i18n.global.locale.value = locale
    },
  },
})
