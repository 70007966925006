<template>
  <div>
    <VaIcon v-if="!enabled" name="chat" size="large" color="primary" @click="enabled = !enabled" />
    <VaIcon v-if="!enabled" name="article" size="large" color="primary" @click="enabled = !enabled" />

    <VaSidebar width="20rem" v-model="enabled" class="absolute right-0 left-auto " animated="right">
      <div class="container">
        <VaCheckbox v-model="enabled" label="Show2" />

        aliiiiiiiiii
      </div>

    </VaSidebar>
  </div>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss"></style>