<template>
  <v-menu
    v-model="menuOwner"
    :close-on-content-click="false"
    location="bottom center"
  >
    <template v-slot:activator="{ props }">
      <p v-if="shape == 'button'" class="change-owner-title" v-bind="props">
        Change
      </p>
      <!-- <VaIcon v-if="shape == 'icon'" name="calendar_month" size="small" color="primary" v-bind="props" /> -->
    </template>

    <v-card width="300" height="500" class="pa-4 card-container">
      <div class="search-container">
        <VaInput v-model="value" class="mb-6" placeholder="Search">
          <template #prependInner>
            <VaIcon name="search" color="secondary" />
          </template>
        </VaInput>
      </div>
      <div v-if="filteredAssignees.length">
        <p class="section-title">Assignees</p>
        <div
          v-for="(data, index) in filteredAssignees"
          :key="'assignee-' + index"
        >
          <div class="align-items option" @click="handleChooseOwner(data)">
            <va-avatar
              color="#ECF0F1"
              :src="data?.avatar?.thumbnail_url"
              :alt="data?.first_name + ' ' + data?.last_name"
              style="border-radius: 50%; width: 2rem; height: 3rem"
            >
              <span style="color: black" v-if="!data?.avatar?.thumbnail_url">{{
                data?.first_name.charAt(0) + ' ' + data?.last_name.charAt(0)
              }}</span>
            </va-avatar>

            <p>{{ data?.first_name }} {{ data?.last_name }}</p>
          </div>
        </div>
      </div>

      <div v-if="filteredOrganizations.length">
        <p class="section-title">Organizations</p>
        <div
          v-for="(data, index) in filteredOrganizations"
          :key="'assignee-' + index"
        >
          <div class="align-items option" @click="handleChooseOwner(data)">
            <va-avatar
              color="#ECF0F1"
              :src="data?.avatar?.thumbnail_url"
              :alt="data?.first_name + ' ' + data?.last_name"
              style="border-radius: 50%; width: 2rem; height: 3rem"
            >
              <span style="color: black" v-if="!data?.avatar?.thumbnail_url">{{
                data?.first_name.charAt(0) + ' ' + data?.last_name.charAt(0)
              }}</span>
            </va-avatar>

            <p>{{ data?.first_name }} {{ data?.last_name }}</p>
          </div>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss"></style>
