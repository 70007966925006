<template>
  <SharedHeader titleWidth="30rem" title="Tasks">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left"></div>
      <div class="sub-SharedHeader-right"></div>
    </div>
  </SharedHeader>

  <TabsBar :tabs="sections" :activeTabId="activeTabId" @changeTab="changeTab" />

  <UpdateLayoutColumns
    :isShow="isShowUpdateLayoutModal"
    @update:isShow="isShowUpdateLayoutModal = $event"
    @applyLayoutChanges="handleApplyLayoutChanges"
    :groups="$stores.mockTasks.groups"
  />

  <SearchAndViews
    :activeView="activeView"
    :withViews="true"
    @search="searchData"
    @changeView="changeView"
  />

  <ActionsBar
    :showSelectButton="true"
    :showDownloadIcon="true"
    :showArchieveIcon="true"
    :showLabelIcon="true"
    :showShareIcon="true"
    :addButton="$t('actionsBar.addTask')"
    :editColumns="true"
    @addButtonAction="handleToggleAddTaskModal"
  />

  <div v-if="activeTab === 'All Tasks'">
    <AddTaskModal
      :addTaskLoading="isAddLoading"
      :isAddTaskForm="isAddTask"
      @handleToggleAddTaskModal="handleToggleAddTaskModal"
      @handleCreateTask="handleCreateTask"
    />
    <div class="contacts-kanban" v-if="activeView === 'board'">
      <!-- <DraggableColumns :isTasks="true" :groups="sections?.find((item: any) => item.name === 'All Tasks').section_stages"
      @update:groups="updateGroups" @update:list="updateTasks" :isSelect="isSelect" :list="list"
      :isLoading="isLoading" /> -->
      <KanbanInfiniteLoad
        v-if="stages.length > 0"
        :isTasks="true"
        :stages="stages"
        @ticketMoved="handleTicketMoved"
        :isLoadingLocal="isTasksLoading"
      />
    </div>
    <div v-else-if="activeView === 'list'">
      <!-- <TasksTable :isSelect="isSelect" @selectedRows="getSelectedRows" /> -->
    </div>
  </div>

  <div v-else>To be implemented</div>
</template>

<script lang="ts" scoped src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss" />
