import SharedHeader from '@/components/sharedHeader/index.vue'
import TabsBar from '@/components/tabsBar/index.vue'
import TimeRange from '@/components/TimeRange/index.vue'
import DatePickerModal from '@/components/DatePickerModal/index.vue'
import CustomChip from '@/components/Chip/index.vue'
import OwnerAssignTo from '@/components/Task/OwnerAssignTo/index.vue'
import ChatActivity from '@/components/Task/ChatActivity/index.vue'
import SubTask from '@/components/Task/SubTask/index.vue'
import { defineComponent, ref } from 'vue'
import { useTimerStore } from '@/stores/timer'
import { addTask, getTaskById, updateTask } from '@/services/tasks'
import { useRoute } from 'vue-router'
import Cookies from 'js-cookie'
import { getTeamMates } from '@/services/company'
import { useForm } from 'vuestic-ui/web-components'
import { getAllStages } from '@/services/stages'

const stopImage = new URL(
  '../../../assets/icons/stop-icon.svg',
  import.meta.url
).href
const playImage = new URL(
  '../../../assets/icons/play-icon.svg',
  import.meta.url
).href
const FlagIcon = new URL('@/assets/icons/flag-icon.svg', import.meta.url).href

export default defineComponent({
  name: 'TaskDetails',

  data() {
    const route = useRoute()

    const labels = ref<{ id: number; name: string; color: string }[]>([
      {
        id: 100,
        name: 'testy',
        color: '#ed6c02',
      },
    ])

    const secondaryColor = ref('$secondary-50')

    const palette = ref(['#fff', '#ef476f', '#ffd166', '#06d6a0', '#8338ec'])

    const selectedLabelColor = ref('')

    const taskBarTabs = [
      { id: 0, title: 'TASK INFO', icon: 'info' },
      { id: 2, title: 'LEGAL MATTERS', icon: 'inventory_2' },
      { id: 3, title: 'FILES', icon: 'file_copy' },
      { id: 4, title: 'EXPENSES', icon: 'bar_chart' },
      { id: 6, title: 'ENGAGEMENT LETTER', icon: 'article' },
      { id: 7, title: 'EMAILS', icon: 'mail' },
    ]

    return {
      stages: [] as any,
      route,
      FlagIcon,
      membersLoading: false,
      isAddLoading: false,
      teamMembers: [] as any,
      taskDetail: {} as any,
      selectedSubtaskDate: null,
      subtaskDetails: '',
      tabs: taskBarTabs,
      labels,
      labelId: 0,
      showAddLabelInput: false,
      showAddSubtaskInput: false,
      labelInputValue: '',
      activeTabId: 0,
      activeTab: 'TASK INFO',
      secondaryColor,
      isLabelModal: false,
      palette,
      selectedLabelColor,
      stopImage,
      playImage,
      loading: false,
      isMenuOwner: false,
      isMenuAssign: false,
      loadingUpdate: false,
      flags: [
        { name: 'urgent', color: '#E53E3E' },
        { name: 'high', color: '#D69E2E' },
        { name: 'normal', color: '#296DAD' },
        { name: 'low', color: '#A0AEC0' },
      ],
      form: {
        assigned_to: {
          id: '',
          avatar: {
            id: '',
            large_url: '',
            medium_url: '',
            thumbnail_url: '',
            url: '',
          },
          class_name: '',
          email: '',
          first_name: '',
          last_name: '',
          middle_name: '',
        },
        owner: {
          id: '',
          avatar: {
            id: '',
            large_url: '',
            medium_url: '',
            thumbnail_url: '',
            url: '',
          },
          class_name: '',
          email: '',
          first_name: '',
          last_name: '',
          middle_name: '',
        },
        description: '',
        start_date: '',
        end_date: '',
        due_date: '',
        name: '',
        id: '',
        priority: '',
        parent_id: null,
        section_stage_items: [{ section_stage: { id: '', name: '' } }] as any,
      },
      subTaskForm: {
        name: '',
        start_date: '',
        end_date: '',
      },
      subTasks: [] as any,
    }
  },
  methods: {
    changeTab(tab: { title: string; id: number }) {
      this.activeTabId = tab.id
      this.activeTab = tab.title
    },
    handleSelectedSubDate(date: any) {
      this.subTaskForm.start_date = date.start
      this.subTaskForm.end_date = date.end
    },
    handleSelectedDate(date: any) {
      this.form.start_date = date.start
      this.form.end_date = date.end
    },
    handleSelectDueDate(date: any) {
      this.form.due_date = date.date
    },
    handleDeleteLabel(id: number) {
      this.labels = this.labels.filter((label) => label.id !== id)
    },
    handleRemoveOwner() {
      this.form.owner = {
        id: '',
        avatar: {
          id: '',
          large_url: '',
          medium_url: '',
          thumbnail_url: '',
          url: '',
        },
        class_name: '',
        email: '',
        first_name: '',
        last_name: '',
        middle_name: '',
      }
    },
    handleRemoveAssign() {
      this.form.assigned_to = {
        id: '',
        avatar: {
          id: '',
          large_url: '',
          medium_url: '',
          thumbnail_url: '',
          url: '',
        },
        class_name: '',
        email: '',
        first_name: '',
        last_name: '',
        middle_name: '',
      }
    },
    handleAddLabel() {
      if (this.labelInputValue.length > 0) {
        this.labels.push({
          name: this.labelInputValue,
          color: this.selectedLabelColor,
          id: this.labelId,
        })
        this.labelInputValue = ''
        this.labelId = this.labelId + 1
        this.isLabelModal = false
      }
    },
    handleFlagMenuItemClick(flag: any) {
      this.form.priority = flag.name
    },
    handleStageMenuItemClick(stage: any) {
      if (
        this.form &&
        this.form.section_stage_items &&
        this.form.section_stage_items[0] &&
        this.form.section_stage_items[0]?.section_stage
      ) {
        this.form.section_stage_items[0].section_stage = {
          ...this.form.section_stage_items[0]?.section_stage,
          id: stage.id,
          name: stage.name,
        }
      }
    },
    getPriorityColor(priority: string): string | null {
      const flag = this.flags.find((flag) => flag.name === priority)
      return flag ? flag.color : null // Return the color if found, otherwise null
    },
    async handleGetStagesSection(section_id: any) {
      const response = await getAllStages(section_id)
      if (response.success) {
        this.stages = response.data.list
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
    },
    ///////////////////////////////////////////////////////////////

    async handleGetTaskByID(id: string) {
      this.loading = true
      const response = await getTaskById(id)

      if (response.success) {
        this.taskDetail = response.data
        this.populateForm()

        if (response.data.section_stage_items[0]?.section_stage.section_id) {
          this.handleGetStagesSection(
            response.data.section_stage_items[0]?.section_stage.section_id
          )
        } else {
          this.stages = []
        }
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loading = false
    },
    async handleUpdate() {
      this.loadingUpdate = true
      const response = await updateTask(this.route.params.id as string, {
        ...this.form,
        assigned_to_id: this.form.assigned_to.id,
        owner_id: this.form.owner.id,
        section_stage_id: this.form?.section_stage_items[0]?.section_stage.id,
      })

      if (response.success) {
        this.$vaToast.init({
          message: 'Task updates successfully!',
          color: 'success',
        })
        this.handleGetTaskByID(this.route.params.id as string)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.loadingUpdate = false
    },
    populateForm() {
      this.form.assigned_to = this.taskDetail?.assigned_to || {
        id: '',
        avatar: {
          id: '',
          large_url: '',
          medium_url: '',
          thumbnail_url: '',
          url: '',
        },
        class_name: '',
        email: '',
        first_name: '',
        last_name: '',
        middle_name: '',
      }
      this.form.owner = this.taskDetail?.owner || {
        id: '',
        avatar: {
          id: '',
          large_url: '',
          medium_url: '',
          thumbnail_url: '',
          url: '',
        },
        class_name: '',
        email: '',
        first_name: '',
        last_name: '',
        middle_name: '',
      }

      this.form.section_stage_items = this.taskDetail.section_stage_items || [
        { section_stage: { id: '', name: '' } },
      ]

      this.form.description = this.taskDetail?.description || ''
      this.form.start_date = this.taskDetail?.start_date || ''
      this.form.end_date = this.taskDetail?.end_date || ''
      this.form.due_date = this.taskDetail?.due_date || ''
      this.form.name = this.taskDetail?.name || ''
      this.form.id = this.taskDetail?.id || ''
      this.form.priority = this.taskDetail?.priority || ''
      this.form.parent_id = this.taskDetail?.parent_id || null

      this.subTasks = this.taskDetail?.sub_tasks || []
    },
    ///////////////////////////////////////////////////////////////

    handleChooseOwner(data: any) {
      this.form.owner = data
    },
    handleChooseAssign(data: any) {
      this.form.assigned_to = data
    },
    async handleGetMembers() {
      this.membersLoading = true
      const user = Cookies.get('haqqUser') as string
      const response = await getTeamMates(
        JSON.parse(user)?.user_roles[0].roleable_id
      )
      if (response.success) {
        this.teamMembers = response.data.list
        console.log(response.data.list)
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }
      this.membersLoading = false
    },
    handleBack() {
      this.$router.back()
    },
    handleBackToParent(id: any) {
      console.log({ id })

      this.$router.push({ path: `/tasks/${id}` })
    },
    async handleAddTask() {
      this.isAddLoading = true

      const response = await addTask({
        ...this.subTaskForm,
        parent_id: Number(this.route.params.id as string),
        owner_id: this.form?.owner?.id,
        priority: this.form?.priority,
      })
      if (response.success) {
        this.$vaToast.init({ message: 'Sub Task Added!', color: 'success' })
        this.subTaskReset()
        this.handleGetTaskByID(this.route.params.id as string)
        this.showAddSubtaskInput = false
      } else {
        this.$vaToast.init({ message: `${response.message}`, color: 'danger' })
      }

      this.isAddLoading = false
    },
    handleKeydownAddTask(event: { preventDefault: () => void }) {
      event.preventDefault()
      this.subTaskValidate() && this.handleAddTask()
    },
  },
  setup() {
    const timerStore = useTimerStore()
    const { isValid, validate, reset, resetValidation } = useForm('form')
    const {
      isValid: subTaskIsValid,
      validate: subTaskValidate,
      reset: subTaskReset,
      resetValidation: subTaskResetValidation,
    } = useForm('subTaskForm')
    return {
      timerStore,
      isValid,
      validate,
      reset,
      resetValidation,
      subTaskIsValid,
      subTaskValidate,
      subTaskReset,
      subTaskResetValidation,
    }
  },
  watch: {
    form: {
      handler(data) {
        console.log({
          form: data,
        })
      },
      deep: true,
    },
    // subTaskForm: {
    //   handler(data) {
    //     console.log({
    //       form: data,
    //     })
    //   },
    //   deep: true,
    // },
    '$route.params.id'(newId) {
      // Logic to handle ID change
      this.handleGetTaskByID(newId)
      this.handleGetMembers()
    },
  },
  mounted() {
    this.handleGetTaskByID(this.route.params.id as string)
    this.handleGetMembers()
  },
  components: {
    SharedHeader,
    TabsBar,
    TimeRange,
    DatePickerModal,
    OwnerAssignTo,
    CustomChip,
    SubTask,
    ChatActivity,
  },
})
