<template>
  <div class="date-picker-wrapper">
    <v-menu
      v-model="showDatePicker"
      :close-on-content-click="false"
      location="bottom center"
    >
      <template v-slot:activator="{ props }">
        <VaButton
          v-if="shape == 'button'"
          preset="secondary"
          v-bind="props"
          class="open-button"
        >
          <span class="open-button">
            <p>Task Durations</p>
            <p v-if="formattedSelectedDate">{{ formattedSelectedDate }}</p>
            <p v-else class="dueDate-empty-state">Choose date and time</p>
          </span>
        </VaButton>

        <VaIcon
          v-if="shape == 'icon'"
          name="calendar_month"
          size="small"
          color="primary"
          v-bind="props"
        />

        <VaButton v-if="shape == 'button-icon'" v-bind="props" color="#ECF0F1">
          <img :src="DatePickerImage" alt="" />
        </VaButton>
      </template>

      <v-card width="auto" class="pa-4">
        <div class="date-picker-container">
          <VaDatePicker
            mode="range"
            v-model="selectedDateRange"
            @close="showDatePicker = false"
            :min-date="new Date()"
            :stateful="true"
            style="margin: 0.5rem"
          />
        </div>

        <!-- Time selection inputs for hours and minutes -->
        <div class="time-picker-container">
          <fieldset>
            <legend>Start Time:</legend>
            <div class="time-input-group">
              <input
                type="number"
                v-model="startHour"
                min="0"
                max="23"
                placeholder="HH"
                aria-label="Start Hour"
              />
              <span>:</span>
              <input
                type="number"
                v-model="startMinute"
                min="0"
                max="59"
                placeholder="MM"
                aria-label="Start Minute"
              />
            </div>
          </fieldset>

          <fieldset>
            <legend>End Time:</legend>
            <div class="time-input-group">
              <input
                type="number"
                v-model="endHour"
                min="0"
                max="23"
                placeholder="HH"
                aria-label="End Hour"
              />
              <span>:</span>
              <input
                type="number"
                v-model="endMinute"
                min="0"
                max="59"
                placeholder="MM"
                aria-label="End Minute"
              />
            </div>
          </fieldset>
        </div>

        <div class="date-picker-wrapper-buttons">
          <VaButton preset="primary" @click="resetDate" style="margin: 0.5rem">
            Reset
          </VaButton>
          <VaButton
            preset="primary"
            :disabled="!formattedSelectedDate"
            @click="saveDate"
            style="margin: 0.5rem"
          >
            Save
          </VaButton>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script lang="ts" src="./script.ts" />

<style lang="scss" scoped src="./styles.scss"></style>
