<template>
  <SharedHeader titleWidth="30rem" title="Legal Matters">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left"></div>
      <div class="sub-SharedHeader-right"></div>
    </div>
  </SharedHeader>
  <TabsBar :tabs="sections" :activeTabId="activeTabId" @changeTab="changeTab" />

  <div v-if="activeTab === 'Matters'">
    <SearchAndViews
      :activeView="activeView"
      :withViews="true"
      @search="searchData"
      @changeView="changeView"
    />

    <ActionsBar
      :showSelectButton="true"
      :showDownloadIcon="true"
      :showArchieveIcon="true"
      :showLabelIcon="true"
      :showShareIcon="true"
      :secondAddButton="$t('actionsBar.addMatter')"
      :editColumns="true"
      @secondButtonAction="toggleAddMatter"
    />

    <AddMatterModal
      @handleToggleAddModal="toggleAddMatter"
      @handleCreateMatter="handleCreateMatter"
      :addMatterLoading="addMatterLoading"
      :isAddMatter="isAddMatter"
    />

    <div class="contacts-kanban" v-if="activeView === 'board'">
      <!-- <DraggableColumns
        :isLegalMatters="true"
        :groups="groups"
        :list="list"
        :isSelect="isSelect"
        :isLoading="false"
      /> -->
      <KanbanInfiniteLoad
        v-if="stages.length > 0"
        :isLegalMatters="true"
        :stages="stages"
        @ticketMoved="handleTicketMoved"
        :isLoadingLocal="isMattersLoading"
      />
    </div>

    <div v-else-if="activeView === 'list'">
      <MatterTable :isSelect="isSelect" @selectedRows="getSelectedRows" />
    </div>
  </div>

  <div v-else class="container">
    <ComingSoon />
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
