<template>
  <div class="hearing-table-container">
    <v-data-table-server
      v-model="selectedRows"
      return-object
      show-select
      :loading="false"
      :headers="headers"
      :items="list"
      :items-length="list?.length"
      items-per-page="10"
    >
      <template #[`item.assignedTo`]="{ item }">
        <td class="hearing-assignedTo-td">
          <VaButton round icon="person" size="small" />{{ item.assignedTo }}
        </td>
      </template>

      <template #[`item.client`]="{ item }">
        <td class="hearing-client-td">
          <VaButton round icon="person" size="small" />{{ item.client }}
        </td>
      </template>

      <template #[`item.attendance`]="{ item }">
        <td class="hearing-attendance-td">
          <VaButton round icon="person" size="small" />{{ item.attendance }}
        </td>
      </template>

      <template #[`item.status`]="{ item }">
        <td
          :class="`hearing-status-td status-${item.status
            .toLowerCase()
            .replace(' ', '-')}`"
        >
          {{ item.status }}
        </td>
      </template>

      <template #[`item.action`]="{ item }">
        <td class="hearing-action-td">
          <!-- <VaIcon name="more_vert" size="medium" @click="handleButtonClick(item)" /> -->
          <va-dropdown :offset="[0, -70]">
            <template #anchor>
              <img :src="MoreVertIcon" alt="MoreVertIcon" />
            </template>
            <VaDropdownContent>
              <p class="hearing-action-option" @click="handleButtonClick(item)">
                Open with
              </p>
              <p class="hearing-action-option" @click="handleButtonClick(item)">
                Request Signature
              </p>
              <p class="hearing-action-option" @click="handleButtonClick(item)">
                Preview
              </p>
              <p class="hearing-action-option" @click="handleButtonClick(item)">
                Rename
              </p>
              <p class="hearing-action-option" @click="handleButtonClick(item)">
                File Info
              </p>
              <p class="hearing-action-option" @click="handleButtonClick(item)">
                Make A Copy
              </p>
              <p class="hearing-action-option" @click="handleButtonClick(item)">
                Access Permissions
              </p>
              <p class="hearing-action-option" @click="handleButtonClick(item)">
                Share
              </p>
              <p class="hearing-action-option" @click="handleButtonClick(item)">
                Move
              </p>
              <p class="hearing-action-option" @click="handleButtonClick(item)">
                Make Available Offline
              </p>
              <p class="hearing-action-option" @click="handleButtonClick(item)">
                delete
              </p>
            </VaDropdownContent>
          </va-dropdown>
        </td>
      </template>
    </v-data-table-server>
  </div>
</template>

<script lang="ts" src="./script.ts" />
<style lang="scss" src="./styles.scss" />
