<template>
  <VaModal
    v-model="isAddInvoiceBool"
    size="auto"
    hide-default-actions
    noOutsideDismiss
    fixed-layout
    @update:modelValue="handleToggleAddModal"
  >
    <template #header
      ><div class="create-invoice-form-header">
        <h1>Create Invoice</h1>
        <VaIcon
          class="header-icon"
          name="close"
          size="30px"
          @click="handleToggleAddModal"
        /></div
    ></template>
    <VaForm
      ref="form"
      class="create-invoice-form"
      @submit.prevent="handleFormSubmit"
    >
      <div class="scrollable-container">
        <div class="create-invoice-form-container">
          <div style="width: 76%">
            <VaSelect
              background="#ECF0F1"
              v-model="form.individual_contact_id"
              style="width: 70%; height: 1.5rem; margin-bottom: 1.8rem"
              label="Paid To"
              placeholder="Paid To"
              searchable
              :highlight-matched-text="false"
              :options="contacts"
              :text-by="
                (option) =>
                  `${option.specific_data.individual_first_name} ${option.specific_data.individual_last_name}`
              "
              :loading="contactsLoading"
              @update:search="handleSearchContacts"
              @update:modelValue="(option) => handleSelectContact(option)"
            />
          </div>
          <VaInput
            background="#ECF0F1"
            v-model="invoice_number"
            style="width: 22%"
            label="Invoice number"
            placeholder="Invoice Number"
            :loading="invoiceNumberLoading"
            disabled
          />
          <div style="width: 76%">
            <VaInput
              background="#ECF0F1"
              v-model="form.customer_name"
              style="width: 70%"
              label="Invoice title"
              placeholder="Invoice Title"
              requiredMark
              :rules="[(value) => value && value.length > 0]"
            />
          </div>
          <div class="form-invoice-date">
            <VaDateInput
              placeholder="MM/DD/YYYY"
              label="Invoice Date"
              background="#ECF0F1"
              v-model="form.invoice_date"
              manual-input
              requiredMark
              :rules="[(value) => value]"
            />
          </div>
          <div style="width: 76%">
            <VaInput
              background="#ECF0F1"
              v-model="form.customer_email"
              style="width: 70%"
              label="email"
              placeholder="Email"
              requiredMark
              :rules="[
                (value: string) => !!value ,
                (value: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) 
              ]"
            />
          </div>
          <div class="form-payment-due">
            <VaDateInput
              placeholder="MM/DD/YYYY"
              label="Payment Due"
              background="#ECF0F1"
              v-model="form.payment_due_date"
              manual-input
            />
          </div>

          <!-- <VAIcon name="group" color="#bbb" /> -->
          <p>Link to matter</p>
        </div>

        <VaDivider inset />

        <div class="items-container">
          <table class="invoice-table">
            <!-- Header Section -->
            <thead>
              <tr class="invoice-header">
                <th class="invoice-item-header">Items</th>
                <th class="invoice-quantity-header">Quantity</th>
                <th class="invoice-price-header">Price</th>
                <th class="invoice-amount-header">Tax</th>
                <th class="invoice-amount-header">Discount Type</th>
                <th class="invoice-amount-header">Discount</th>
                <th class="invoice-amount-header">Amount</th>
                <th class="invoice-delete-header"></th>
              </tr>
            </thead>

            <!-- Items Section -->
            <tbody>
              <tr
                v-for="(item, index) in form.invoice_items_attributes"
                :key="index"
                class="invoice-row"
              >
                <td class="invoice-item-cell">
                  <VaInput
                    background="#fff"
                    v-model="item.description"
                    class="invoice-item-input"
                    placeholder="Enter item description"
                    :rules="[(value) => value && value.length > 0]"
                  />
                </td>
                <td class="invoice-quantity-cell">
                  <VaInput
                    background="#fff"
                    v-model="item.quantity"
                    type="number"
                    min="1"
                    placeholder="Quantity"
                    class="invoice-quantity-input"
                  />
                </td>
                <td class="invoice-price-cell">
                  <VaInput
                    background="#fff"
                    v-model="item.unit_price"
                    type="number"
                    placeholder="Price"
                    class="invoice-price-input"
                  />
                </td>
                <td class="invoice-quantity-cell">
                  <VaInput
                    background="#fff"
                    v-model="item.tax_percentage"
                    type="number"
                    placeholder="Tax"
                    class="invoice-quantity-input"
                    ><template #prependInner>
                      <VaIcon name="percent" color="secondary" /> </template
                  ></VaInput>
                </td>
                <td class="invoice-quantity-cell">
                  <div class="switch-container">
                    <div
                      @click="toggleItemSwitch(index)"
                      :class="[
                        'switch',
                        { 'switch-on': item.discount_type === 'amount' },
                      ]"
                    >
                      <div class="switch-handle"></div>
                    </div>
                    <span class="switch-label">{{
                      item.discount_type === 'amount' ? 'Amnt' : '%'
                    }}</span>
                  </div>
                </td>
                <td class="invoice-quantity-cell">
                  <VaInput
                    background="#fff"
                    v-model="item.discount_type_amount"
                    type="number"
                    placeholder="Discount"
                    class="invoice-quantity-input"
                  >
                    <template
                      v-if="item.discount_type === 'percentage'"
                      #prependInner
                    >
                      <VaIcon name="percent" color="secondary" />
                    </template>
                  </VaInput>
                </td>
                <td class="invoice-amount-cell">
                  {{
                    totalAmount(
                      item.quantity,
                      item.unit_price,
                      item.tax_percentage
                    )
                  }}
                </td>
                <td class="invoice-delete-cell">
                  <VaIcon
                    name="delete"
                    class="invoice-delete-icon"
                    color="#bbb"
                    @click="removeInvoiceItem(index)"
                  />
                </td>
              </tr>
              <!-- Item Tax Percentage Section -->
              <!-- <tr class="invoice-tax-row"></tr> -->
              <!-- Add Item Button -->
              <tr class="invoice-add-item-row">
                <td colspan="5" class="invoice-add-item-cell">
                  <va-button
                    @click="addInvoiceItem"
                    class="add-item-button"
                    icon="add_circle"
                    color="primary"
                    preset="secondary"
                  >
                    Add an item
                  </va-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <VaDivider />
      </div>

      <div class="price-section">
        <div class="subtotal-price">
          <p>Subtotal</p>
          <span>{{ computedSubTotal() }}</span>
        </div>

        <div class="total-price">
          <div class="switch-container">
            <span class="switch-label1">{{
              form.discount_type === 'amount' ? 'Amnt' : '%'
            }}</span>
            <div
              @click="toggleInvoiceSwitch()"
              :class="[
                'switch',
                { 'switch-on': form.discount_type === 'amount' },
              ]"
            >
              <div class="switch-handle"></div>
            </div>
          </div>
          <p>Discount</p>
          <VaInput
            style="width: 6rem"
            background="#fff"
            v-model="form.discount_type_amount"
            type="number"
            ><template #prependInner>
              <VaIcon
                v-if="form.discount_type === 'percentage'"
                name="percent"
                color="secondary"
              /> </template
          ></VaInput>
        </div>
        <!-- <div class="total-price">
        <p>Tax</p>
        <VaInput
          style="width: 6rem"
          background="#fff"
          v-model="form.tax"
          type="number"
          ><template #prependInner>
            <VaIcon name="percent" color="secondary" /> </template
        ></VaInput>
      </div> -->
        <div class="total-price">
          <h2>Total</h2>
          <div style="width: 5rem">
            <VaSelect
              background="#fff"
              v-model="form.currency"
              :options="['USD', 'UAE']"
            />
          </div>
          <span>{{ computedGrandTotal() }}</span>
        </div>
      </div>
      <div class="text-area-section">
        <div class="payment-group">
          <VaSelect
            style="width: 33%"
            label="payment due"
            placeholder="Payment Due"
            background="#fff"
            v-model="form.payment_details_note"
            :options="['Due Today', 'Net 15', 'Net 30', 'Net 60']"
          />
          <VaSelect
            style="width: 33%"
            label="payment address"
            placeholder="Payment Address"
            background="#fff"
            v-model="form.signature_note"
            :options="['UAE BANK', 'US BANK']"
          />
          <VaSelect
            style="width: 33%"
            label="terms"
            background="#fff"
            v-model="form.terms_and_conditions_note"
            placeholder="Terms"
            :options="['Term 1', 'Term 2', 'Term 3']"
          />
        </div>

        <VaTextarea
          max-rows="7"
          v-model="form.general_note"
          label="general note"
          placeholder="Add your note here..."
          style="width: 100%; height: 10rem"
          autosize
        />
      </div>
    </VaForm>

    <template #footer>
      <div class="add-invoice-form-modal-buttons">
        <VaButton
          preset="secondary"
          border-color="primary"
          @click="handleToggleAddModal"
          >Cancel</VaButton
        >
        <VaButton
          @click="validate() && handleCreateInvoice()"
          :loading="addInvoiceLoading"
          >Save</VaButton
        >
      </div>
    </template>
  </VaModal>
</template>
<script lang="ts" src="./script.ts" />
<style lang="scss" scoped src="./styles.scss" />
