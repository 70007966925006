import { defineComponent } from 'vue'
import draggable from 'vuedraggable'

const pdf = new URL('@/assets/icons/pdf-icon.svg', import.meta.url).href
const excel = new URL('@/assets/icons/excel-icon.svg', import.meta.url).href
const jpg = new URL('@/assets/icons/jpg-icon.svg', import.meta.url).href
const folder = new URL('@/assets/icons/file-icon.svg', import.meta.url).href

export default defineComponent({
  name: 'table-example',
  display: 'Table',
  order: 8,
  components: {
    draggable,
  },
  data() {
    return {
      icons: {
        pdf: pdf,
        excel: excel,
        jpg: jpg,
        folder: folder,
      },
      headers: [
        {
          name: '',
          sortable: false,
          feild: 'icon',
          value: 'icon',
          width: '70px',
        },
        {
          name: 'NAME',
          feild: 'name',
          sortable: false,
          width: '90px',
        },
        {
          name: 'SOURCE',
          feild: 'source',
          sortable: false,
          width: '200px',
        },
        { name: 'CONTACT', feild: 'contact', sortable: false, width: '100px' },
        { name: 'EVENT', feild: 'event', sortable: false, width: '100px' },
        {
          name: 'MATTER',
          feild: 'matter',
          sortable: false,
          width: '100px',
        },
        { name: 'VALUE', feild: 'value', sortable: false, width: '100px' },
        {
          name: 'LAST MODIFIED',
          feild: 'lastModified',
          sortable: false,
          width: '100px',
        },
      ],
      list: [
        {
          id: 1,
          icon: pdf,
          name: 'Matter 1',
          source: 'Source 1',
          contact: 'Contact 1',
          event: 'Event 1',
          matter: 'Matter 1',
          value: 'Value 1',
          lastModified: 'Last Modified 1',
        },
        {
          id: 2,
          icon: folder,
          name: 'Matter 2',
          source: 'Source 2',
          contact: 'Contact 2',
          event: 'Event 2',
          matter: 'Matter 2',
          value: 'Value 2',
          lastModified: 'Last Modified 2',
        },
        {
          id: 3,
          icon: excel,
          name: 'Matter 3',
          source: 'Source 3',
          contact: 'Contact 3',
          event: 'Event 3',
          matter: 'Matter 3',
          value: 'Value 3',
          lastModified: 'Last Modified 3',
        },
        {
          id: 4,
          icon: jpg,
          name: 'Matter 4',
          source: 'Source 4',
          contact: 'Contact 4',
          event: 'Event 4',
          matter: 'Matter 4',
          value: 'Value 4',
          lastModified: 'Last Modified 4',
        },
        {
          id: 5,
          icon: pdf,
          name: 'Matter 5',
          source: 'Source 5',
          contact: 'Contact 5',
          event: 'Event 5',
          matter: 'Matter 5',
          value: 'Value 5',
          lastModified: 'Last Modified 5',
        },
        {
          id: 6,
          icon: folder,
          name: 'Matter 6',
          source: 'Source 6',
          contact: 'Contact 6',
          event: 'Event 6',
          matter: 'Matter 6',
          value: 'Value 6',
          lastModified: 'Last Modified 6',
        },
        {
          id: 7,
          icon: excel,
          name: 'Matter 7',
          source: 'Source 7',
          contact: 'Contact 7',
          event: 'Event 7',
          matter: 'Matter 7',
          value: 'Value 7',
          lastModified: 'Last Modified 7',
        },
      ],
      dragging: false,
    }
  },
  methods: {
    sortList(): {
      id: number
      icon: string
      name: string
      source: string
      contact: string
      event: string
      matter: string
      value: string
      lastModified: string
    }[] {
      return this.list.sort((a, b) => {
        if (a.icon === folder && b.icon !== folder) {
          return -1
        }
        if (a.icon !== folder && b.icon === folder) {
          return 1
        }
        return 0
      })
    },
    setClass(name: string) {
      console.log(name)
      switch (name) {
        case 'matterName':
          return 'matter-name'
        case 'status':
          return 'status'
        case 'client':
          return 'client'
        case 'leadLawyers':
          return 'lead-lawyers'
        case 'lawyers':
          return 'lawyers'
        case 'jurisdiction':
          return 'jurisdiction'
        case 'actions':
          return 'actions'
        default:
          return 'cell'
      }
    },
  },
  mounted() {
    this.sortList()
  },
})
