<template>
  <SharedHeader titleWidth="30rem" title="Financials">
    <div class="sub-SharedHeader">
      <div class="sub-SharedHeader-left"></div>
      <div class="sub-SharedHeader-right"></div>
    </div>
  </SharedHeader>
  <TabsBar :tabs="tabs" :activeTabId="activeTabId" @changeTab="changeTab" />

  <div v-if="activeTab === 'Invoices'">
    <SearchAndViews :activeView="activeView" @search="searchData" />
    <ActionsBar
      :showSelectButton="true"
      :showDownloadIcon="true"
      :showArchieveIcon="true"
      :showLabelIcon="true"
      :showShareIcon="true"
      :editColumns="true"
      :secondAddButton="$t('actionsBar.addInvoice')"
      @SecondButtonAction="toggleAddInvoice"
    />

    <AddInvoiceModal
      @handleToggleAddModal="toggleAddInvoice"
      @handleCreateInvoice="handleCreateInvoice"
      :addInvoiceLoading="addInvoiceLoading"
      :isAddInvoice="isAddInvoice"
    />

    <InvoicesTable
      @selectedRows="getInvoiceSelectedRows"
      :loading="invoiceTableLoading"
      :itemsPerPage="invoicesItemPerPage"
      @loadMore="handlePaginationInvoices"
      :totalItems="invoiceTotalItems"
      :invoices="invoices"
      @handleViewInvoice="handleViewInvoice"
    />
  </div>

  <div v-else-if="activeTab === 'Expenses'">
    <SearchAndViews :activeView="activeView" @search="searchData" />
    <ActionsBar
      :showSelectButton="true"
      :showDownloadIcon="true"
      :showArchieveIcon="true"
      :showLabelIcon="true"
      :showShareIcon="true"
      :editColumns="true"
      :secondAddButton="$t('actionsBar.addExpense')"
      @SecondButtonAction="toggleAddExpense"
    />

    <AddExpenseModal
      @handleToggleAddModal="toggleAddExpense"
      @handleCreateExpense="handleCreateExpense"
      :addInvoiceLoading="addInvoiceLoading"
      :isAddExpense="isAddExpense"
    />

    <ExpensesTable
      @selectedRows="getExpenseSelectedRows"
      :loading="expenseTableLoading"
      @loadMore="handlePaginationExpenses"
      :totalItems="invoiceTotalItems"
      :expenses="expenses"
      @handleViewInvoice="handleViewInvoice"
    />

    <!-- <div class="financials-kanban">
      <KanbanColumn
        v-for="(list, index) in lists"
        :key="index"
        :expenses-list="list.list"
        :isFinancials="true"
        :title="list.name"
      />
    </div> -->
  </div>

  <!-- <div v-else-if="activeTab === 'Dashboard'">
    <FinancialsDashboard />
  </div>
  
  <div v-else-if="activeTab === 'Payments History' && activeView === 'list'">
    <PaymentsHistoryTable
      :headers="paymentsHistoryHeaders"
      :list="paymentsHistoryList"
    />
  </div>
  <div v-else-if="activeTab === 'On Retainer' && activeView === 'list'">
    <DefaultTable />
  </div> -->
  <div v-else>
    <div class="container">
      <ComingSoon />
    </div>
  </div>
</template>

<script lang="ts" src="./script.ts"></script>
<style lang="scss" scoped src="./styles.scss"></style>
