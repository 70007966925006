import { apiRequest } from './apiUtils'

export enum ContactType {
  IndividualContact = 'IndividualContact',
  OrganizationContact = 'OrganizationContact',
}

export const getContacts = ({
  contact_type,
  per_page,
  page,
  sort_by_field,
  sort_by_direction,
  keyword,
}: {
  contact_type: ContactType
  per_page: number
  page?: number
  sort_by_field?: 'IndividualContact' | 'OrganizationContact'
  sort_by_direction?: 'asc' | 'desc'
  keyword?: string
}) => {
  return apiRequest('get', '/contacts', null, {
    contact_type,
    per_page,
    page,
    sort_by_field,
    sort_by_direction,
    keyword,
  })
}

export const getContactById = (id: string) => {
  return apiRequest('get', `/contacts/${id}`)
}

export const addIndividualContact = (body: any) => {
  return apiRequest('post', '/contacts/individuals', body)
}

export const addOrganizationContact = (body: any) => {
  return apiRequest('post', '/contacts/organizations', body)
}

export const updateIndividualContact = ({ id, body }: any) => {
  return apiRequest('put', `/contacts/individuals/${id}`, body)
}

export const updateOrganizationContact = ({ id, body }: any) => {
  return apiRequest('put', `/contacts/organizations/${id}`, body)
}

export const deleteContact = (id: string) => {
  return apiRequest('delete', `/contacts/${id}`)
}
