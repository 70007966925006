import { defineComponent } from 'vue'
import Row from '../row/index.vue'

export default defineComponent({
  props: {
    header: {
      type: String,
      required: true,
    },
    items: {
      type: Array as () => { id: number; title: string; value: number }[],
      required: true,
    },
    totalValue: {
      type: Number,
      required: true,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Row,
  },
  computed: {
    isRed(): boolean {
      return this.totalValue < 0
    },
    isGreen(): boolean {
      return this.totalValue > 0
    },
    totalDisplayValue(): string {
      return this.totalValue ? Math.abs(this.totalValue).toString() : ''
    },
  },
})
